type Organ = { name: string; value: string };
export interface OrganGroup {
  name: string;
  organs: Organ[];
}
export const digestiveOrgan = {
  name: "Digestive organ",
  organs: [
    { name: "Esophagus", value: "Digestive organ-Esophagus" },
    { name: "Stomach", value: "Digestive organ-Stomach" },
    { name: "Duodenum", value: "Digestive organ-Duodenum" },
    { name: "Jejunum", value: "Digestive organ-Jejunum" },
    { name: "Ileum", value: "Digestive organ-Ileum" },
    { name: "Vermiform appendix", value: "Digestive organ-Vermiform appendix" },
    { name: "Appendix", value: "Digestive organ-Appendix" },
    {
      name: "Gallbladder/Bile duct",
      value: "Digestive organ-Gallbladder/Bile duct"
    },
    { name: "Ascending colon", value: "Digestive organ-Ascending colon" },
    { name: "Transverse colon", value: "Digestive organ-Transverse colon" },
    { name: "Descending colon", value: "Digestive organ-Descending colon" },
    { name: "Sigmoid colon", value: "Digestive organ-Sigmoid colon" },
    { name: "Rectum", value: "Digestive organ-Rectum" },
    { name: "Anus", value: "Digestive organ-Anus" },
    { name: "Liver", value: "Digestive organ-Liver" },
    { name: "Pancreas", value: "Digestive organ-Pancreas" }
  ]
};
export const femaleOrgan = {
  name: "Female organ",
  organs: [
    { name: "Vulva", value: "Female organ-Vulva" },
    {
      name: "Uterine vagina/Cervix",
      value: "Female organ-Uterine vagina/Cervix"
    },
    {
      name: "Endometrium of uterine body",
      value: "Female organ-Endometrium of uterine body"
    },
    { name: "Womb contents", value: "Female organ-Womb contents" },
    { name: "Left ovary", value: "Female organ-Left ovary" },
    { name: "Right ovary", value: "Female organ-Right ovary" },
    { name: "Left fallopian tube", value: "Female organ-Left fallopian tube" },
    { name: "Right fallopian tube", value: "Female organ-Right fallopian tube" }
  ]
};

export const urinaryOrgan = {
  name: "Urinary organ",
  organs: [
    { name: "Left kidney", value: "Urinary organ-Left kidney" },
    { name: "Right kidney", value: "Urinary organ-Right kidney" },
    { name: "Bladder", value: "Urinary organ-Bladder" },
    { name: "prostate", value: "Urinary organ-prostate" },
    { name: "Left testis", value: "Urinary organ-Left testis" },
    { name: "Right testis", value: "Urinary organ-Right testis" }
  ]
};

export const mouthNose = {
  name: "Mouth/Nose",
  organs: [
    { name: "Oral cavity/Lips", value: "Mouth/Nose-Oral cavity/Lips" },
    { name: "Salivary gland", value: "Mouth/Nose-Salivary gland" },
    { name: "Pharynx", value: "Mouth/Nose-Pharynx" },
    { name: "Tonsils", value: "Mouth/Nose-Tonsils" },
    { name: "Larynx", value: "Mouth/Nose-Larynx" },
    { name: "Nasal cavity", value: "Mouth/Nose-Nasal cavity" }
  ]
};

export const skinMammaryGland = {
  name: "Skin/Mammary gland",
  organs: [
    { name: "Skin", value: "Skin/Mammary gland-Skin" },
    {
      name: "Subcutaneous tumor",
      value: "Skin/Mammary gland-Subcutaneous tumor"
    },
    { name: "Soft tissue", value: "Skin/Mammary gland-Soft tissue" },
    {
      name: "Left mammary gland",
      value: "Skin/Mammary gland-Left mammary gland"
    },
    {
      name: "Right mammary gland",
      value: "Skin/Mammary gland-Right mammary gland"
    }
  ]
};

export const locomotorOrgans = {
  name: "Locomotor organs/Blood vessels",
  organs: [
    {
      name: "Bone/cartilage",
      value: "Locomotor organs/Blood vessels-Bone/cartilage"
    },
    { name: "Synovium", value: "Locomotor organs/Blood vessels-Synovium" },
    { name: "Joint", value: "Locomotor organs/Blood vessels-Joint" },
    { name: "Muscle", value: "Locomotor organs/Blood vessels-Muscle" },
    {
      name: "Blood vessels",
      value: "Locomotor organs/Blood vessels-Blood vessels"
    }
  ]
};

export const respiratorySyste = {
  name: "Respiratory syste",
  organs: [
    { name: "Bronchi", value: "Respiratory syste-Bronchi" },
    { name: "Lung", value: "Respiratory syste-lung" },
    { name: "Left lung", value: "Respiratory syste-Left lung" },
    { name: "Right lung", value: "Respiratory syste-Right lung" }
  ]
};

export const hematopoieticOrgan = {
  name: "Hematopoietic organ",
  organs: [
    { name: "Spleen", value: "Hematopoietic organ-Spleen" },
    { name: "Bone marrow", value: "Hematopoietic organ-Bone marrow" },
    { name: "Lymph node", value: "Hematopoietic organ-Lymph node" }
  ]
};

export const endocrine = {
  name: "Endocrine",
  organs: [
    { name: "Left thyroid", value: "Endocrine-Left thyroid" },
    { name: "Right thyroid", value: "Endocrine-Right thyroid" }
  ]
};

export const allOrgans = [
  ...digestiveOrgan.organs,
  ...femaleOrgan.organs,
  ...urinaryOrgan.organs,
  ...mouthNose.organs,
  ...skinMammaryGland.organs,
  ...locomotorOrgans.organs,
  ...respiratorySyste.organs,
  ...hematopoieticOrgan.organs,
  ...endocrine.organs
];
