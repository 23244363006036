export const notificationTypes: any = {
  "double-check-request": "asked you for double-check.",
  "double-check-complete": "has completed double-check.",
  "double-check-return": "has returned double-check.",
  "assigned-as-pathologist": "A new case was assigned to you.",
  request_quote: "Diagnosis Request Quote",
  return_request: "Diagnosis Return Request",
  send_quote: "Diagnosis Send Quote",
  order: "Diagnosis Order",
  request_check: "Diagnosis Request Check",
  return_check: "Diagnosis Return Check",
  complete_check: "Diagnosis Complete Check",
  done: "Diagnosis Done",
  cancel_before_ordering: "Diagnosis Cancel Before Ordering",
  cancel_after_ordered: "Diagnosis Cancel After Ordered",
  new_reply: "replied to the comment."
};
