import { Form, TreeSelect } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Button,
  debounceFunction,
  exceptionHandler,
  FilePlusIcon,
  Modal,
  Typography
} from "shared-components";

import { Spacer } from "../Spacer";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      marginTop: "-2% !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-content": {
        marginTop: "1%"
      },

      "& .ant-modal-body": {
        minWidth: 850,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-drag": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 25,
        justifyContent: "center",
        height: 135,
        width: 514,
        textAlign: "center",
        background: theme.textWhite
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      },
      "& .ant-upload-drag-container": {
        marginTop: -5,

        "& article": {
          textAlign: "center"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    logo: {
      "& svg": {
        height: 25,
        width: 34,
        marginRight: 15
      }
    },
    modal: {
      maxWidth: "100%"
    },
    modalButton: {
      textAlign: "center"
    },

    button: {
      height: 36,
      width: 160,
      fontSize: 14
    },
    draggerComponent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    draggerSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 514,
      border: "1px dashed #d9d9d9",
      marginTop: 23,
      height: 135
    },
    middleText: {
      marginTop: 20,
      marginBottom: 22,
      "& article": {
        textAlign: "center"
      }
    },
    text: {
      color: theme.primary,
      textAlign: "center",
      letterSpacing: "1.08px",
      marginBottom: 4,
      "& article": {
        textAlign: "center"
      }
    },
    textBlue: {
      color: theme.primary
    },
    textGreen: {
      color: theme.textGreen
    },
    inputCaseNo: {
      width: "fit-content"
    }
  };
});

interface Props {
  visible: boolean;
  folder_id?: number;
  case_id?: number;
  onSubmitFolder?: (folder_id: number, parent_folder_id: number) => void;
  onSubmitCase?: (case_id: number, parent_folder_id: number) => void;
  onCancel: () => void;
}
const MoveToFolderModal = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);
  const { defaultTeam } = useContext(AuthContext);

  const [state, setState] = useState(-1);

  const [treeData, setTreeData] = useState<FolderTreeItem[]>([
    {
      id: 1,
      pId: 0,
      value: 1,
      title: t("Case Library"),
      isLeaf: false
    }
  ]);

  const [loadedKeys, setLoadedKeys] = useState<React.Key[]>([]);

  const handleGetCaseFolderChildren = async (pIdPlusOne: number) => {
    setLoading(true);
    try {
      const response: any = await API.get(
        `case-folders/${pIdPlusOne - 1}/children`,
        {
          params: {
            team_id: defaultTeam,
            page: 1,
            pageSize: 100
          }
        }
      );
      if (
        response &&
        response.data &&
        response.data.children &&
        response.data.children.length
      ) {
        const newTreeData: FolderTreeItem[] = response.data.children
          .filter((child: any) => {
            return child.type === "folder";
          })
          .map((child: any) => {
            const data = child.data;
            return {
              id: data.id + 1,
              pId: pIdPlusOne,
              value: data.id + 1,
              title: data.name,
              isLeaf: data.id === props.folder_id,
              disabled: data.id === props.folder_id
            };
          });
        newTreeData && setTreeData(treeData.concat(newTreeData));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleModalField = (id: number) => {
    setState(id - 1);
  };

  const handleExpandNode = async (item: any) => {
    handleGetCaseFolderChildren(item.id);
  };

  const handleTreeLoad = async (keys: any) => {
    setLoadedKeys(keys);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      debounceFunction(() => {
        return props.folder_id && props.onSubmitFolder
          ? props.onSubmitFolder(props.folder_id, state)
          : props.case_id &&
              props.onSubmitCase &&
              props.onSubmitCase(props.case_id, state);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={() => {
        setTreeData([
          {
            id: 1,
            pId: 0,
            value: 1,
            title: t("Case Library"),
            isLeaf: false
          }
        ]);
        setState(-1);
        setLoadedKeys([]);
        props.onCancel();
      }}
      width="850px"
      mask={false}
    >
      <div className={classes.header}>
        <div className={classes.logo}>
          <FilePlusIcon />
        </div>
        <Typography type="title">{t("Move Folder")}</Typography>
      </div>
      <Spacer padding={"16px"} />
      <Form
        className={classes.modal}
        onFinish={() => {
          return handleSubmit();
        }}
      >
        <Typography type="body2" className={classes.text}>
          {t("Select Destination Folder")}
        </Typography>
        <Form.Item
          id="name"
          name="name"
          rules={[
            {
              required: true,
              message: t("Required")
            }
          ]}
        >
          <TreeSelect
            loading={loading}
            allowClear
            treeDataSimpleMode
            style={{ width: "100%" }}
            value={state}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder={t("Select Destination Folder")}
            onChange={handleModalField}
            loadData={handleExpandNode}
            treeLoadedKeys={loadedKeys}
            onTreeLoad={handleTreeLoad}
            treeData={treeData}
          />
        </Form.Item>
        <Form.Item className={classes.modalButton}>
          <Button
            className={classes.button}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            data-testid="create-button"
          >
            {t("Move")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { MoveToFolderModal };
