import { Button, Checkbox } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, exceptionHandler, Typography } from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      maxWidth: "320px",
      marginLeft: "9px"
    },

    title: {
      marginTop: "13px",
      marginBottom: "25px"
    },

    text: {
      marginTop: "24px",
      display: "flex",
      marginBottom: "37px"
    },
    linkText: {
      marginBottom: "25px"
    },
    checkBoxStyle: {
      marginRight: "5px"
    },

    btnText: {
      display: "flex",
      justifyContent: "center"
    },
    addURLbtn: {
      width: "202px",
      padding: "6px  34px",
      background: "#1A51C3",
      color: "#ffffff",
      borderRadius: "2px",
      opacity: 1
    }
  };
});

interface Props {
  publicLink?: string;
  reportId: number;
  handlePublicLinkChange: (value: string) => void;
}

const PublicLink = ({
  publicLink,
  reportId,
  handlePublicLinkChange
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const createURLHandler = async () => {
    try {
      const response: any = await API.post(`reports/${reportId}/public-link`, {
        confirm: true,
        personal: checked
      });
      if (response && response.data) {
        handlePublicLinkChange(response.data.public_link);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const removeURLHandler = async () => {
    try {
      const response: any = await API.post(`reports/${reportId}/public-link`, {
        confirm: false
      });
      if (response && response.data) {
        handlePublicLinkChange(response.data.public_link);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleClick = (event: any) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.wrapper}>
      <Typography fontSize="14px" color="#5A5A5A" className={classes.title}>
        {t("Public URL")}
      </Typography>

      {publicLink ? (
        <>
          <Typography
            fontSize="14px"
            color="#5A5A5A"
            className={classes.linkText}
          >
            <a
              href={`${window.location.origin}/public-report/${publicLink}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${window.location.origin}/public-report/${publicLink}`}
            </a>
          </Typography>
          <div className={classes.btnText}>
            <Button
              className={classes.addURLbtn}
              type="primary"
              htmlType="button"
              onClick={removeURLHandler}
            >
              {t("Remove URL")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={classes.text}>
            <Typography fontSize="14px" color="#5A5A5A">
              {t("Public URL has not been created")}
            </Typography>
          </div>

          <div className={classes.text}>
            <Checkbox
              className={classes.checkBoxStyle}
              onClick={handleClick}
              checked={checked}
            />
            <Typography fontSize="14px" color="#5A5A5A">
              {t("Display the patient's personal information on the report")}
            </Typography>
          </div>
          <div className={classes.btnText}>
            <Button
              className={classes.addURLbtn}
              type="primary"
              htmlType="button"
              onClick={createURLHandler}
            >
              {t("Create URL")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export { PublicLink };
