import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Tabs, Typography } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router";
import {
  AuthContext,
  Button,
  CompleteTickIcon,
  DatePickerComponent,
  DoubleTickIcon,
  exceptionHandler,
  FilePlusIcon,
  FlagIcon,
  Search,
  SelectComponent,
  TeamBookmarkIcon,
  TeamIcon,
  UserBookmarkIcon,
  UserIcon
} from "shared-components";

import { CreateCaseModal } from "../../molecules/CreateCaseModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: "15px 40px",
      paddingBottom: 0,
      background: theme.textWhite,
      borderBottom: `1px solid ${theme.colorGrey}`,
      "& .ant-tabs-nav": {
        "&:before": {
          borderBottom: "none"
        }
      }
    },
    rowWrapper: {
      "& .ant-col": {
        marginBottom: 5,
        position: "relative"
      }
    },
    createButton: {
      float: "right",
      borderRadius: 16,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      boxShadow: " 0px 3px 6px #00000029"
    },

    button: {
      borderRadius: 0
    },

    datepicker: {
      background: "transparent",
      border: "none",
      padding: 0,
      marginLeft: "14px",
      "& input": {
        display: "none"
      },
      cursor: "pointer",
      "& svg": {
        height: 16,
        width: 16,
        color: theme.primary,
        margin: 5
      }
    },
    emptyContainer: {
      display: "inline-block",
      width: "100%",
      marginTop: 30
    },
    filterRow: {
      marginTop: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    tabTitle: {
      fontSize: 16,
      verticalAlign: "center",
      position: "relative"
    },
    tabPane: {
      marginLeft: 32
    },
    tabs: {
      "& .ant-tabs-nav": {
        marginBottom: "0px",
        "& .ant-tabs-nav-list": {
          "& .ant-tabs-tab:last-of-type": {
            marginLeft: 32
          }
        }
      },
      "& .ant-tabs-tab": {
        "&:focus": {
          outline: "none"
        }
      }
    },
    pagination: {
      display: "inline-block",
      margin: 5,
      marginRight: 15
    },
    icons: {
      fontSize: 16
    },
    flagIconGreen: {
      fontSize: 30,
      color: theme.textGreen
    },
    flagIconYellow: {
      fontSize: 30,
      color: theme.textYellow
    },
    flagWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 5,
      "& .ant-btn": {
        background: "none !important"
      }
    },
    selectOrder: {
      margin: 0,
      width: 90,
      border: "none",
      fontSize: 14,
      "& .ant-select-selector": {
        border: "none !important"
      }
    },
    wrapperSelect: {
      width: "auto",
      marginLeft: 30
    },
    divider: {
      position: "absolute",
      color: theme.border,
      height: 20,
      pointerEvents: "none",
      borderRight: "1px solid",
      left: "-40px",
      marginTop: "4px"
    },
    spacedivider: {
      pointerEvents: "none"
    },
    closeButton: {
      position: "absolute",
      padding: 5,
      borderRadius: "50%",
      right: "-28px",
      top: "0px",
      zIndex: 1,
      border: "none",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "none",
        cursor: "pointer"
      }
    },
    closeIcon: {
      fontSize: 20
    },
    paginationButton: {
      padding: 5,
      marginLeft: 5,
      borderRadius: "50%",
      border: "none",
      "&:hover": {
        backgroundColor: "none"
      }
    },
    flagContainer: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  };
});

interface Props {
  handleFilterChange: (name: string, value: string | number | boolean) => void;
  clearFilters: () => void;
  filters: CaseFilters;
  count: number;
  openCaseModal: boolean;
  lastTab: string;
}

const FilterComponent = ({
  handleFilterChange,
  clearFilters,
  filters,
  count,
  openCaseModal,
  lastTab
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState(false);
  const { defaultTeam, profile } = useContext(AuthContext);
  const [clear, setClear] = useState(true as boolean);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(undefined as string | undefined);
  const [userSettings, setUserSettings] = useState({} as any);

  const settings = () => {
    const userConfig: any = {};
    for (const setting of profile.user_settings) {
      userConfig[setting.name] = setting.value;
    }
    setUserSettings(userConfig);
  };

  useEffect(() => {
    settings();
  }, []);

  const toggleVisibility = () => {
    if (!defaultTeam) {
      exceptionHandler("You are not part of a team.", t);
      return;
    }
    setVisibility(!visibility);
  };
  const clearAllFilters = () => {
    clearFilters();
    setUserSettings({
      ...userSettings,
      case_from_date: "",
      case_keyword: "",
      case_to_date: ""
    });
    setClear(true);
  };

  useEffect(() => {
    if (openCaseModal) {
      setVisibility(true);
    }
  }, [openCaseModal]);

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  useEffect(() => {
    const tab = location.search === "?teamTasks" ? "all" : undefined;
    if (tab) {
      setActiveTab(tab);
      return;
    }

    if (lastTab) {
      const tab = lastTab;
      setActiveTab(tab);
    }
  }, [lastTab]);

  return (
    <div className={classes.container}>
      <Row gutter={15} className={classes.rowWrapper}>
        <Col lg={8} xs={24}>
          <Search
            allowClear={true}
            clear={clear}
            setClear={setClear}
            name="search"
            placeholder={t("Hospital name, Doctor name, etc...")}
            onChange={handleFilterChange}
            initialValue={userSettings.case_keyword}
          />
        </Col>
        <Col lg={5} xs={24}>
          <DatePickerComponent
            clear={clear}
            disabledDate={disabledFromDate}
            name="from"
            placeholder={t("From")}
            onChange={(name, value) => {
              handleFilterChange(name, value);
              setUserSettings({ ...userSettings, case_from_date: value });
            }}
            initialValue={
              userSettings.case_from_date && moment(userSettings.case_from_date)
            }
            onClear={() => {
              return setUserSettings({
                ...userSettings,
                case_from_date: ""
              });
            }}
          />
        </Col>

        <Col lg={5} xs={24}>
          <DatePickerComponent
            clear={clear}
            name="to"
            disabledDate={disabledToDate}
            placeholder={t("To")}
            onChange={(name, value) => {
              handleFilterChange(name, value);
              setUserSettings({ ...userSettings, case_to_date: value });
            }}
            initialValue={
              userSettings.case_to_date && moment(userSettings.case_to_date)
            }
            onClear={() => {
              return setUserSettings({
                ...userSettings,
                case_to_date: ""
              });
            }}
          />
          {!(
            filters.from === "" &&
            filters.to === "" &&
            filters.search === ""
          ) && (
            <Button
              onClick={clearAllFilters}
              className={classes.closeButton}
              shape="circle"
              icon={<CloseOutlined className={classes.closeIcon} />}
            />
          )}
        </Col>

        <Col lg={6} xs={24}>
          <Button
            icon={<FilePlusIcon />}
            onClick={toggleVisibility}
            className={classes.createButton}
          >
            {t("Create New")}
          </Button>
        </Col>
      </Row>
      <Row className={classes.filterRow}>
        <Col lg={13} md={24}>
          <Tabs
            activeKey={activeTab}
            className={classes.tabs}
            onChange={(value: string) => {
              return handleFilterChange("type", value);
            }}
          >
            <Tabs.TabPane
              tab={
                <div className={classes.tabTitle}>
                  <UserIcon />
                  {t("My Tasks")}
                </div>
              }
              key="incharge"
            />
            <Tabs.TabPane
              tab={
                <div className={classes.tabTitle}>
                  <DoubleTickIcon />
                  {t("Double Check")}
                </div>
              }
              key="double_check"
            />
            <Tabs.TabPane
              tab={
                <div className={classes.tabTitle}>
                  <CompleteTickIcon />
                  {t("Completed")}
                </div>
              }
              key="complete"
            />
            <Tabs.TabPane
              className={classes.tabPane}
              tab={
                <div className={classes.tabTitle}>
                  <Divider type="vertical" className={classes.divider} />
                  <TeamIcon />
                  {t("Team Tasks")}
                </div>
              }
              key="all"
            />
          </Tabs>
        </Col>
        <Col lg={6} md={12} className={classes.flagWrapper}>
          <Typography className={classes.pagination}>{`${
            count > 0 ? (filters.page - 1) * filters.pageSize + 1 : 0
          } - ${
            filters.pageSize * filters.page >= count
              ? count
              : filters.pageSize * filters.page
          } of ${count}`}</Typography>
          <Button
            onClick={() => {
              return handleFilterChange("page", filters.page - 1);
            }}
            disabled={filters.page === 1}
            className={classes.paginationButton}
            shape="circle"
            icon={<LeftOutlined className={classes.icons} />}
          />
          <Button
            onClick={() => {
              return handleFilterChange("page", filters.page + 1);
            }}
            disabled={filters.pageSize * filters.page >= count}
            className={classes.paginationButton}
            shape="circle"
            icon={<RightOutlined className={classes.icons} />}
          />
        </Col>
        <Col lg={5} md={12} className={classes.flagWrapper}>
          <div
            className={classes.flagContainer}
            onClick={() => {
              return handleFilterChange("userBookmark", !filters.userBookmark);
            }}
          >
            {filters.userBookmark ? (
              <UserBookmarkIcon className={`${classes.flagIconYellow}`} />
            ) : (
              <FlagIcon className={classes.flagIconYellow} />
            )}
          </div>
          <div
            className={classes.flagContainer}
            onClick={() => {
              return handleFilterChange("marked", !filters.marked);
            }}
          >
            {filters.marked ? (
              <TeamBookmarkIcon className={`${classes.flagIconGreen}`} />
            ) : (
              <FlagIcon className={classes.flagIconGreen} />
            )}
          </div>
          {userSettings.case_sort !== undefined && (
            <SelectComponent
              className={classes.selectOrder}
              wrapperClassName={classes.wrapperSelect}
              name="orderBy"
              size="middle"
              allowClear
              defaultValue={userSettings.case_sort || "desc"}
              options={[
                {
                  label: t("ascending"),
                  value: "asc"
                },
                {
                  label: t("descending"),
                  value: "desc"
                }
              ]}
              placeholder={t("Select Order")}
              onChange={(event: any, value: any) => {
                return handleFilterChange("orderBy", value);
              }}
            />
          )}
        </Col>
      </Row>
      <CreateCaseModal visible={visibility} onCancel={toggleVisibility} />
    </div>
  );
};

export { FilterComponent };
