import { TagFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Loader, PdfContext, Typography } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    detailsWrapper: {
      height: 186,
      display: "flex"
    },
    imageTitleWrapper: {
      height: 30,
      display: "flex"
    },
    tag: {
      color: theme.lightBg,
      marginTop: 4
    },
    tagBackground: {
      display: "flex",
      backgroundColor: theme.darkBackground,
      borderRadius: 3,
      padding: "2px 6px",
      height: 24,
      marginBottom: 4
    },
    circle: {
      marginTop: 4
    },
    tagDetails: {
      color: theme.textWhite,
      padding: 0,
      marginLeft: 8,
      fontSize: 14,
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    comment: {
      fontWeight: 800
    },
    commentWrapper: {
      maxHeight: 85,
      minHeight: 30,
      display: "-webkit-box",
      "-webkit-line-clamp": 5,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    pdfCommentWrapper: {
      wordBreak: "break-word"
    },
    wrapper: {
      maxWidth: "webkit-fill-available"
    },
    imageDiv: {
      height: 186,
      minWidth: 134,
      flex: 1,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      border: "1px solid #ccc",
      borderRadius: 5,
      marginRight: 10
    },
    column: {
      minHeight: 186
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 130
    },
    caseImageText: {
      display: "inline-block",
      fontSize: 12,
      marginLeft: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    imageNumberText: {
      display: "inline-block",
      fontSize: 16,
      marginLeft: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    checkContainer: {
      display: "flex"
    },
    tagsContainer: (isConsult: boolean) => {
      return {
        background: isConsult ? theme.greenBackground : theme.formBackground,
        padding: "19px 25px 20px 25px",
        marginBottom: 9
      };
    },
    psuedoTagsContainer: (isConsult: boolean) => {
      return {
        background: isConsult ? theme.greenBackground : theme.formBackground,
        padding: "none",
        marginBottom: 9
      };
    }
  };
});

interface Props {
  data: CaseTag[];
  caseImages?: CaseImages[];
  loading?: boolean;
  caseConfirm?: boolean;
  dbId: number;
  isConsult?: boolean;
  publicReport?: boolean;
}
const ReportTags = (props: Props) => {
  const { pdfLoading } = useContext(PdfContext);
  const classes = useStyles(props.isConsult);

  const { t } = useTranslation();

  const { data: caseTagsData } = props;

  return (
    <div
      className={
        pdfLoading ? classes.psuedoTagsContainer : classes.tagsContainer
      }
      id="caseTags"
    >
      <Row gutter={[32, 20]}>
        {caseTagsData.map((tag: CaseTag, index: any) => {
          return (
            <Col
              key={index}
              xs={24}
              sm={24}
              lg={24}
              md={24}
              xl={24}
              xxl={pdfLoading ? 24 : 12}
              span={8}
              className={`${pdfLoading && classes.column}`}
            >
              <Row className={classes.imageTitleWrapper}>
                <Typography className={classes.imageNumberText}>
                  {`Fig. ${
                    props.caseImages
                      ? props.caseImages.length + index + 1
                      : index + 1
                  } `}
                </Typography>
              </Row>
              <Row className={classes.detailsWrapper}>
                <Col xs={12}>
                  <div
                    className={classes.imageDiv}
                    style={{
                      backgroundImage: `url(${
                        pdfLoading ? tag.caseImageDataURL : tag.caseImageURL
                      })`
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <Row>
                    <Col span={24} className={classes.checkContainer}>
                      <Typography className={classes.caseImageText}>
                        {`from ${props.caseImages &&
                          props.caseImages.find((image: CaseImages) => {
                            return image.id === tag.caseImageId;
                          })?.name}`}
                      </Typography>
                    </Col>
                  </Row>
                  <Row>
                    {tag.disease && (
                      <div className={classes.tagBackground}>
                        <TagFilled rotate={275} className={classes.tag} />
                        <Typography className={classes.tagDetails}>
                          {tag.observation ? `${t(tag.observation)}` : ""}
                          {tag.significance ? ` / ${t(tag.significance)}` : ""}
                        </Typography>
                      </div>
                    )}
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      className={`${
                        pdfLoading
                          ? classes.pdfCommentWrapper
                          : classes.commentWrapper
                      }`}
                    >
                      <Typography type="body3" className={classes.comment}>
                        {tag.comment}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          );
        })}
        {props.loading && (
          <Col
            xs={24}
            sm={24}
            lg={24}
            md={24}
            xl={12}
            xxl={pdfLoading ? 12 : 8}
            span={8}
            className={classes.loading}
          >
            <Loader />
          </Col>
        )}
      </Row>
    </div>
  );
};
export { ReportTags };
