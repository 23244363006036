import { Typography as Text } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";

interface Props {
  type?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "boldBody"
    | "body1"
    | "body2"
    | "body3"
    | "body4"
    | "body5"
    | "button1"
    | "button2"
    | "link1"
    | "title"
    | "link2";
  children: any;
  center?: boolean;
  fontSize?: string;
  lineHeight?: string;
  bold?: boolean;
  color?: string;
  className?: any;
  onClick?: any;
  style?: any;
  href?: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    textClass: {
      display: "block",
      fontStyle: "normal",
      textAlign: ({ center }: Props) => {
        return center ? "center" : "left";
      },
      fontWeight: ({ type, bold }: Props) => {
        switch (type) {
          case "boldBody":
            return "bold";
          default:
            return bold ? "bold" : "normal";
        }
      },
      textDecoration: ({ type }: Props) => {
        switch (type) {
          case "link1":
          case "link2":
            return "underline";
          default:
            return "";
        }
      },
      letterSpacing: "0.3px",
      fontSize: ({ type, fontSize }: Props) => {
        switch (type) {
          case "h1":
            return "52px";
          case "h2":
            return "42px";
          case "h3":
            return "32px";
          case "h4":
            return "22px";
          case "h5":
            return "20px";
          case "boldBody":
            return "18px";
          case "body1":
            return "18px";
          case "body2":
            return "16px";
          case "body3":
            return "14px";
          case "body4":
            return "12px";
          case "body5":
            return "10px";
          case "button1":
            return "18px";
          case "button2":
            return "16px";
          case "link1":
            return "18px";
          case "link2":
            return "16px";
          case "title":
            return "27px";
          default:
            return fontSize ? fontSize : "18px";
        }
      },
      lineHeight: ({ type, lineHeight }: Props) => {
        switch (type) {
          case "h1":
            return "61px";
          case "h2":
            return "49px";
          case "h3":
            return "37px";
          case "h4":
            return "26px";
          case "h5":
            return "23px";
          case "boldBody":
            return "21px";
          case "body1":
            return "21px";
          case "body2":
            return "19px";
          case "body3":
            return "16px";
          case "body4":
            return "13px";
          case "body5":
            return "10px";
          case "button1":
            return "21px";
          case "button2":
            return "19px";
          case "link1":
            return "21px";
          case "link2":
            return "19px";
          case "title":
            return "39ox";
          default:
            return lineHeight ? lineHeight : "21px";
        }
      }
    }
  };
});

const Typography: React.FC<Props> = props => {
  const classes = useStyles({ ...props });
  if (props.type === "link1" || props.type === "link2") {
    return (
      <Link
        to={props.href ? props.href : ""}
        style={props.style}
        className={`${classes.textClass} ${props.className}`}
      >
        {props.children}
      </Link>
    );
  }
  return (
    <Text
      style={props.style}
      className={`${classes.textClass} ${props.className}`}
    >
      {props.children}
    </Text>
  );
};

export { Typography };
