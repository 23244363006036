import * as Sentry from "@sentry/browser";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import {
  Alert,
  AuthContext,
  Button,
  captureMessage,
  Container,
  exceptionHandler,
  firebase,
  i18n,
  Logo,
  Typography,
  Wrapper
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    buttonDiv: {
      marginBottom: 30
    },
    bottomLogo: {
      position: "absolute",
      bottom: "3%",
      left: "50%",
      transform: "translate(-50%)"
    },
    row: {
      marginTop: 30
    },
    text: {
      color: theme.textWhite,
      marginBottom: "30px",
      letterSpacing: "0.25px"
    },
    mailText: {
      marginBottom: 0,
      color: theme.textWhite,
      letterSpacing: "0.25px"
    },
    textWrapper: {
      maxWidth: 280
    },
    errorText: {
      color: theme.yellow5,
      marginBottom: "30px",
      letterSpacing: "0.25px"
    },
    button: {
      borderRadius: 26,
      width: 145,
      height: 28,
      background: theme.textYellow,
      color: theme.textPrimary
    }
  };
});

const EmailConfirmation = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  const confirmationURL = window.location.origin + "/account-settings";

  useEffect(() => {
    if (user) {
      verificationResend();
    }
    checkEmailVerified();
  }, []);

  const checkEmailVerified = () => {
    const timer = setInterval(async () => {
      try {
        if (firebase.auth().currentUser) {
          await firebase.auth().currentUser!.reload();

          if (firebase.auth().currentUser!.emailVerified) {
            clearInterval(timer);
            history.push("/account-settings");
          }
        } else {
          clearInterval(timer);
        }
      } catch (error) {
        captureMessage(
          `User Authentication Reload Error: ${JSON.stringify(error)}`,
          Sentry.Severity.Fatal
        );
      }
    }, 10000);

    setTimeout(() => {
      clearInterval(timer);
    }, 600000);
  };

  if (user == null) {
    return <Redirect to="/sign-in" />;
  }

  if (user!.emailVerified) {
    return <Redirect to="/account-settings" />;
  }

  const handleSignOut = () => {
    firebase.auth().signOut();
    localStorage.clear();
    sessionStorage.clear();
    setUser(null);
    history.replace("/sign-up");
  };

  const verificationResend = async () => {
    setLoading(true);
    try {
      firebase.auth().languageCode = i18n.language;
      await user!.sendEmailVerification({
        url: confirmationURL
      });
      setLoading(false);
      Alert("success", "success", t("Verification email has been sent"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <Container center>
      <Wrapper>
        <Logo />
        <Row gutter={8} className={classes.row}>
          <Col span={24}>
            <div className={classes.textWrapper}>
              <Typography type="body3" className={classes.text}>
                {t(
                  "You must activate your account by opening the link that is sent to your email address. This is done in order to verify that the email address you have given us is indeed yours and valid."
                )}
              </Typography>
            </div>
            <div className={classes.textWrapper}>
              <Typography type="body3" className={classes.mailText}>
                {t("Mail address")}:
              </Typography>
              <Typography type="body3" className={classes.text}>
                {user!.email}
              </Typography>
            </div>
            <div className={classes.textWrapper}>
              <Typography type="body3" className={classes.mailText}>
                {t(
                  "If you did not receive the email, please check your junk/spam folder."
                )}
              </Typography>
              <Typography type="body3" className={classes.text}>
                {t(
                  "If you still can’t find the email, resend the activation email."
                )}
              </Typography>
            </div>
            <div className={classes.buttonDiv}>
              <Button
                size="small"
                htmlType="submit"
                type="primary"
                onClick={verificationResend}
                loading={loading}
                className={classes.button}
              >
                {t("Resend")}
              </Button>
            </div>
            <div className={classes.textWrapper}>
              <Typography type="body3" className={classes.text}>
                {t(
                  "If you entered an incorrect email address, you will need to re-register with the correct email address."
                )}
              </Typography>
            </div>
            <Button
              size="small"
              type="primary"
              onClick={handleSignOut}
              className={classes.button}
            >
              {t("Back to Sign Up")}
            </Button>
          </Col>
        </Row>
      </Wrapper>
      <div className={classes.bottomLogo}>
        <Logo logo="bonbon" />
      </div>
    </Container>
  );
};

export { EmailConfirmation };
