import React from "react";

import { ImageComponent } from "./ImageComponent";
import { ReportTags } from "./ReportTags";

interface Props {
  caseImageRef: React.MutableRefObject<CaseImages[]>;
  setCaseImagesRef: (data: any, handleFunction?: any) => Promise<void>;
  caseNumber: string;
  caseId: number;
  hasSidebar: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  caseConfirm?: boolean;
  setImageView: React.Dispatch<React.SetStateAction<boolean>>;
  isConsult?: boolean;
  publicReport?: boolean;
  getCaseTags: () => Promise<void>;
  caseTags: CaseTag[];
  tagsLoading?: boolean;
  imageLoading?: boolean;
}

const ReportImageAndTags = ({
  caseImageRef,
  setCaseImagesRef,
  caseNumber,
  caseId,
  hasSidebar,
  setSaving,
  caseConfirm,
  setImageView,
  isConsult,
  getCaseTags,
  caseTags,
  tagsLoading,
  imageLoading,
  publicReport
}: Props) => {
  return (
    <>
      <ImageComponent
        images={caseImageRef}
        caseId={caseNumber}
        caseConfirm={caseConfirm}
        dbId={caseId}
        hasSidebar={hasSidebar}
        setSaving={setSaving}
        setCaseImagesRef={setCaseImagesRef}
        setImageView={setImageView}
        getCaseTags={getCaseTags}
        isConsult={isConsult}
        publicReport={publicReport}
        loading={imageLoading}
      />

      {(caseTags.length !== 0 || tagsLoading) && (
        <ReportTags
          caseConfirm={caseConfirm}
          data={caseTags}
          dbId={caseId}
          caseImages={caseImageRef.current}
          loading={tagsLoading}
          isConsult={isConsult}
          publicReport={publicReport}
        />
      )}
    </>
  );
};

export { ReportImageAndTags };
