import React from "react";

import {
  ColorectalView,
  FindingsDiagnosis,
  General,
  SelectFinding
} from "../forms";

export interface CaseFormValue {
  reportId: number;
  name: string;
  value: string;
}

interface Props {
  formName: string;
  reportId: number;
  formValues: CaseFormValue[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  hasSidebar: boolean;
  setCaseData: (response: any) => void;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}

const RenderForm = ({
  formName,
  reportId,
  formValues,
  setSaving,
  hasSidebar,
  setCaseData,
  isConsult,
  publicReport,
  readOnly
}: Props) => {
  switch (formName) {
    case "ipfuip": {
      return (
        <FindingsDiagnosis
          reportId={reportId}
          formValues={formValues}
          setSaving={setSaving}
          hasSidebar={hasSidebar}
          setCaseData={setCaseData}
          publicReport={publicReport}
          isConsult={isConsult}
          readOnly={readOnly}
        />
      );
    }
    case "general": {
      return (
        <General
          isConsult={isConsult}
          reportId={`${reportId}`}
          formValues={formValues}
          setSaving={setSaving}
          setCaseData={setCaseData}
          publicReport={publicReport}
          readOnly={readOnly || hasSidebar}
        />
      );
    }
    case "colorectal": {
      return (
        <ColorectalView
          reportId={reportId}
          formValues={formValues}
          setSaving={setSaving}
          setCaseData={setCaseData}
          publicReport={publicReport}
          isConsult={isConsult}
          readOnly={readOnly || hasSidebar}
        />
      );
    }
    case "": {
      return (
        <SelectFinding
          reportId={reportId}
          setSaving={setSaving}
          setCaseData={setCaseData}
          isConsult={isConsult}
        />
      );
    }
    default: {
      return (
        <FindingsDiagnosis
          isConsult={isConsult}
          reportId={reportId}
          formValues={formValues}
          setSaving={setSaving}
          hasSidebar={hasSidebar}
          setCaseData={setCaseData}
          publicReport={publicReport}
        />
      );
    }
  }
};

export { RenderForm };
