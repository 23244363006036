import { MoreOutlined } from "@ant-design/icons";
import { Popover, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  Alert,
  API,
  Button,
  exceptionHandler,
  Typography
} from "shared-components";
import { DeleteConfirm } from "shared-components/src";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";
import { statusLabel, statusValue } from "./actionValueMap";
import { RequestActionModal } from "./RequestActionModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      margin: "50px 100px"
    },
    table: {
      marginTop: 50,
      "& .ant-table-row": {
        cursor: "pointer"
      }
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    header: {},
    heading: {
      float: "left"
    },
    tabTitle: {
      fontSize: 16,
      verticalAlign: "center",
      position: "relative"
    },
    tabPane: {
      marginLeft: 32
    },
    tabs: {
      width: "fit-content",
      marginTop: 50,
      "& .ant-tabs-nav": {
        marginBottom: "0px",
        "& .ant-tabs-nav-list": {
          "& .ant-tabs-tab:last-of-type": {
            marginLeft: 32
          }
        }
      },
      "& .ant-tabs-tab": {
        "&:focus": {
          outline: "none"
        }
      }
    },
    actions: {
      display: "flex",
      flexDirection: "column"
    },
    actionMenu: {
      padding: "10px 15px",
      minWidth: 120,
      cursor: "pointer",
      "&:hover": {
        background: theme.background
      }
    },
    popoverContainer: {
      "& .ant-popover-inner-content": {
        padding: "0px !important"
      }
    },
    iconMenu: {
      padding: "0px 5px",
      fontSize: "16px"
    }
  };
});

export const DiagnosisList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true as boolean);
  const [requests, setRequests] = useState([] as DiagnosisRequestData[]);
  const [totalRequests, setTotalRequests] = useState(0 as number);
  const [requestAction, setRequestAction] = useState({
    open: false,
    action: "cancel_request",
    handleConfirm: null,
    data: {} as DiagnosisRequestData
  } as RequestAction);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 } as {
    page: number;
    pageSize?: number;
  });
  const [activeTab, setActiveTab] = useState("" as string);

  const basicColumns = [
    {
      title: `${activeTab === "sent" ? t("To") : t("From")}`,
      dataIndex: `${
        activeTab === "sent" ? "receiver_team_name" : "sender_team_name"
      }`,
      key: "receiver_team_name || sender_team_name"
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (text: string, data: any) => {
        return t(statusLabel[data.status]);
      }
    },
    {
      title: t("Request type"),
      dataIndex: "request_type",
      key: "request_type",
      render: (text: string) => {
        return t(text);
      }
    },
    {
      title: t("Transaction ID"),
      dataIndex: "transaction_id",
      key: "transaction_id"
    },
    {
      title: t("Due date"),
      dataIndex: "due_date",
      key: "due_date",
      render: (text: string, data: any) => {
        return data && data.due_date
          ? moment(data.due_date).format("YYYY-MM-DD")
          : "N/A";
      }
    },
    {
      title: t("Updated"),
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string, data: any) => {
        return moment(data.updated_at).format("YYYY-MM-DD HH:mm");
      }
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      render: (text: string, data: any) => {
        return data && data.price ? thousands_separators(data.price) : "N/A";
      }
    }
  ];

  const actionColumn = {
    title: t("Action"),
    key: "action",
    render: (text: string, data: DiagnosisRequestData) => {
      return (
        <span
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            return event.stopPropagation();
          }}
        >
          <Popover
            content={() => {
              return popOverContent(data);
            }}
            overlayClassName={classes.popoverContainer}
            trigger="hover"
          >
            <MoreOutlined className={classes.iconMenu} />
          </Popover>
        </span>
      );
    }
  };

  const columns =
    activeTab === "sent" ? [...basicColumns, actionColumn] : [...basicColumns];

  useEffect(() => {
    const pageSize = parseInt(localStorage.getItem("diagnosisPageSize") || "");
    if (pageSize && pageSize !== pagination.pageSize) {
      setPagination({ ...pagination, pageSize });
    }
    let tab = localStorage.getItem("diagnosisTab");
    if (tab === null) tab = "sent";
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    if (activeTab !== "") getDiagnosisList();
  }, [activeTab, pagination]);

  const getDiagnosisList = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`requests`, {
        params: {
          request: activeTab,
          ...pagination
        }
      });
      setLoading(false);
      if (!response.data) {
        setRequests([]);
        return;
      }
      response.data.forEach((item: any) => {
        item.transaction_id = `${item.transaction_id}`.padStart(6, "0");
      });
      setRequests(response.data);
      setTotalRequests(response.count);
    } catch (error) {
      setLoading(false);
      setRequests([]);
      setTotalRequests(0);
      exceptionHandler(error, t);
    }
  };

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    localStorage.setItem("diagnosisTab", value);
  };

  const thousands_separators = (num: any) => {
    const num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  const handleRequestDuplicate = async (data: DiagnosisRequestData) => {
    setLoading(true);
    try {
      const response: any = await API.post(`requests/${data.id}/duplicate`);
      history.push(`/diagnosis/${response.data.id}`);
      setLoading(false);
      Alert("success", "success", t("Request duplicated successfully."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleRequestCancel = async (params: {
    action: string;
    comment?: string;
    data: DiagnosisRequestData;
  }) => {
    try {
      if (
        params.data.status !== statusValue.Estimating &&
        params.data.status !== statusValue.EstimateReturned &&
        params.data.status !== statusValue.Ordering &&
        params.data.status !== statusValue.Checking &&
        params.data.status !== statusValue.Working
      ) {
        Alert("error", "error", t("Request cannot be canceled."), t);
        setLoading(false);
        return;
      }
      await API.post(`requests/${params.data.id}/cancel`, {
        comment: params.comment
      });
      setRequestAction({ open: false, action: "", handleConfirm: null });
      getDiagnosisList();
      setLoading(false);
      Alert("success", "success", t("Request cancelled successfully."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleRequestRemove = (data: DiagnosisRequestData) => {
    DeleteConfirm(async () => {
      setLoading(true);
      try {
        if (data.status !== statusValue.Editing) {
          Alert("error", "error", t("Request cannot be removed."), t);
          setLoading(false);
          return;
        }
        await API.delete(`requests/${data.id}`);
        getDiagnosisList();
        setLoading(false);
        Alert("success", "success", t("Request removed successfully."), t);
      } catch (error) {
        setLoading(false);
        exceptionHandler(error, t);
      }
    }, "Are you sure you want to delete the request you are creating?");
  };

  const popOverContent = (data: DiagnosisRequestData) => {
    return (
      <div className={classes.actions}>
        <p
          className={classes.actionMenu}
          onClick={() => {
            return handleRequestDuplicate(data);
          }}
        >
          {t("Duplicate")}
        </p>
        {[
          statusValue.Estimating,
          statusValue.Ordering,
          statusValue.Working,
          statusValue.Checking,
          statusValue.EstimateReturned
        ].includes(data.status) && (
          <p
            className={classes.actionMenu}
            onClick={() => {
              return setRequestAction({
                open: true,
                action: "cancel_request",
                handleConfirm: handleRequestCancel,
                data: data
              });
            }}
          >
            {t("Cancel")}
          </p>
        )}
        {data.status === statusValue.Editing && (
          <p
            className={classes.actionMenu}
            onClick={() => {
              return handleRequestRemove(data);
            }}
          >
            {t("Remove")}
          </p>
        )}
      </div>
    );
  };

  const handleRow = (record: DiagnosisRequestData) => {
    return {
      onClick: () => {
        history.push(`/diagnosis/${record.id}`);
      }
    };
  };

  const [createLoading, setCreateLoading] = useState(false as boolean);

  const handleCreateRequest = async () => {
    setCreateLoading(true);
    try {
      const response = await API.post("/requests", {});
      if (response.data) {
        history.push(`/diagnosis/${response.data.id}`);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setCreateLoading(false);
  };

  const handleSizeChange = (page: number, pageSize: number) => {
    localStorage.setItem("diagnosisPageSize", `${pageSize}`);
    return setPagination({ page, pageSize });
  };

  return (
    <ComponentWrapper>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography fontSize="24px" className={classes.heading}>
            {t("Diagnosis request")}
          </Typography>
          <Button
            loading={createLoading}
            type="primary"
            size="middle"
            onClick={handleCreateRequest}
            className={classes.createButton}
          >
            {t("Create request")}{" "}
          </Button>
        </div>
        <div className="clearfix" />
        <Tabs
          activeKey={activeTab}
          className={classes.tabs}
          onChange={handleTabChange}
        >
          <Tabs.TabPane
            tab={<div className={classes.tabTitle}>{t("Sent requests")}</div>}
            key="sent"
          />
          <Tabs.TabPane
            tab={
              <div className={classes.tabTitle}>{t("Received requests")}</div>
            }
            key="received"
          />
        </Tabs>
        <Table
          loading={loading}
          dataSource={requests}
          columns={columns}
          className={classes.table}
          onRow={handleRow}
          rowKey="id"
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            position: ["bottomCenter"],
            total: totalRequests,
            showSizeChanger: true,
            onShowSizeChange: handleSizeChange,
            onChange: (page: number, pageSize?: number) => {
              return setPagination({ page, pageSize });
            }
          }}
        />
        <RequestActionModal
          requestAction={requestAction}
          setRequestAction={setRequestAction}
        />
      </div>
    </ComponentWrapper>
  );
};
