import axios from "axios";

/**
 * axios instance
 */
let POROUSAPI = axios.create({
  baseURL: process.env.REACT_APP_POROUS_API_URL,
  headers: { "Content-Type": "application/json" }
});

export { POROUSAPI };
