import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  API,
  Container,
  exceptionHandler,
  firebase,
  LocalStorageService,
  Logo,
  Wrapper
} from "shared-components";

import { SignInComponent } from "../../organisms/SignInComponent";

const useStyles = createUseStyles(() => {
  return {
    bottomLogo: {
      position: "fixed",
      bottom: "3%",
      left: "50%",
      transform: "translate(-50%)"
    }
  };
});

const SignIn = (props: any) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const localStorageService = LocalStorageService.getService();
  const history = useHistory();
  const handleSignIn = async (state: any) => {
    setLoading(true);
    try {
      const token = await executeRecaptcha!("userSignIn");
      const recaptchaData: any = await API.post(`verify-recaptcha`, {
        response: token
      });
      if (
        recaptchaData &&
        recaptchaData.data &&
        recaptchaData.data.score > 0.5
      ) {
        const result = await firebase
          .auth()
          .signInWithEmailAndPassword(state.email, state.password)
          .catch(error => {
            exceptionHandler(error, t);
          });

        if (!result || !result.user || !firebase.auth().currentUser) {
          setLoading(false);
        } else {
          const idTokenResult = await result.user!.getIdTokenResult();
          const token = idTokenResult.token;
          localStorageService.setAccessToken(token);
          if (props.state && props.state.prevPath) {
            history.push(
              props.state.prevPath.pathname + props.state.prevPath.search
            );
          } else {
            history.push("/cases");
          }
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <Container center>
      <Wrapper>
        <Logo />

        <SignInComponent onSubmit={handleSignIn} loading={loading} />
      </Wrapper>

      <div className={classes.bottomLogo}>
        <Logo logo="bonbon" />
      </div>
    </Container>
  );
};

export { SignIn };
