import { Spin } from "antd";
import React from "react";

interface Props {
  size?: "small" | "large" | "default";
  text?: string;
}

const Loader: React.FC<Props> = props => {
  return <Spin size={props.size} tip={props.text} />;
};

export { Loader };
