import { RightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  exceptionHandler,
  keyValuePair,
  PdfContext,
  POROUSAPI,
  Typography
} from "shared-components";
import { LoaderComponent } from "shared-components/src/components/atoms/Loader/index.stories";

import { FormTitle } from "../../../forms/Layout";
import { CaseImage } from "../../../molecules/CaseImage";

interface Props {
  images: React.MutableRefObject<CaseImages[]>;
  caseId: string;
  dbId: number;
  hasSidebar: boolean;
  caseConfirm?: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseImagesRef: (data: any, handleFunction?: any) => Promise<void>;
  setImageView: React.Dispatch<React.SetStateAction<boolean>>;
  getCaseTags: () => Promise<void>;
  isConsult?: boolean;
  pdfLoading?: boolean;
  publicReport?: boolean;
  loading?: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      marginTop: 40
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20
    },
    title: {
      padding: 0
    },
    mainContainer: {
      display: "flex",
      background: ({ pdfLoading, isConsult }: Props) => {
        if (pdfLoading) {
          return isConsult ? theme.greenBackground : theme.formBackground;
        }
        return "";
      }
    },
    imageContainer: {
      flex: 1,
      pointerEvents: ({ hasSidebar }: Props) => {
        return hasSidebar && "none";
      }
    },
    Container: ({ isConsult }: Props) => {
      return {
        background: isConsult ? theme.greenBackground : theme.formBackground,
        padding: "19px 40px 25px 22px",
        marginBottom: 9
      };
    },
    divider: {
      borderLeft: `2px solid ${theme.formLabelColor}`,
      marginRight: 12
    },
    loader: {
      width: "100%",
      height: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    imageTitleWrapper: {
      height: 30,
      display: "flex"
    },
    caseImageText: {
      display: "inline-block",
      fontSize: 12,
      marginLeft: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    imageNumberText: {
      display: "inline-block",
      fontSize: 16,
      marginLeft: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    selectImage: {
      minWidth: 200,
      minHeight: 28,
      fontSize: 14,
      color: theme.selectText,
      border: "1px solid",
      borderColor: theme.selectText,
      borderRadius: 2,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 16px",
      "& span": {
        fontSize: 14,
        color: theme.selectText
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    imageMessage: {
      minHeight: 100,
      paddingTop: 20,
      display: "flex",
      justifyContent: "center",
      fontSize: 16,
      color: theme.selectText
    }
  };
});

let mounted = false;

const ImageComponent: React.FC<Props> = props => {
  const { pdfLoading } = useContext(PdfContext);
  const classes = useStyles({ ...props, pdfLoading });
  const { t } = useTranslation();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleClick = (image: CaseImages) => {
    if (props.publicReport) {
      return;
    }
    image.url !== ""
      ? window.open(`/cases/${props.dbId}?by=${image.slide_id}`)
      : Alert("error", "error", "This file has not finished processing.", t);
  };

  const [_dataLoading, _setDataLoading] = useState([] as number[]);
  const dataLoading = useRef(_dataLoading);
  const setDataLoading = async (data: any) => {
    dataLoading.current = data;
    _setDataLoading(data);
  };

  useEffect(() => {
    mounted = true;
    initialLoad();
    return () => {
      mounted = false;
    };
  }, [props.images.current]);

  useEffect(() => {
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);

  const createThumbnail = (slideId: string, height: number, width: number) => {
    try {
      return POROUSAPI.post(
        `/slides/${slideId}/crop`,
        {
          height: height,
          width: width,
          theta: 0,
          x: 0,
          y: 0
        },
        {
          cancelToken: source.token
        }
      );
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const checkImage = async (
    apiImageResp: CaseImages,
    porousApiImageResp: any
  ) => {
    if (apiImageResp.id && porousApiImageResp.data) {
      if (
        porousApiImageResp.data.state === "waiting" ||
        porousApiImageResp.data.state === "processing"
      ) {
        const newImages = props.images.current.map((image: CaseImages) => {
          if (image.id === apiImageResp.id) {
            return {
              ...image,
              imageStatus: porousApiImageResp.data.state
            };
          }
          return image;
        });
        if (mounted) {
          props.setCaseImagesRef([...newImages]);
        }
        setTimeout(() => {
          getCaseImage(apiImageResp);
        }, 1000);
      }
      if (porousApiImageResp.data.state === "failed") {
        Alert("error", "error", "Failed to upload Image", t);
        try {
          await API.delete(`uploads/${apiImageResp.id}`, {
            cancelToken: source.token
          });
          const newImages = props.images.current.filter((image: CaseImages) => {
            return image.id !== apiImageResp.id;
          });
          if (mounted) {
            props.setCaseImagesRef([...newImages]);
          }
        } catch (error) {
          exceptionHandler(error, t);
        }
        return;
      }
      if (porousApiImageResp.data.state === "done" && apiImageResp.url === "") {
        const thumbnail: any = await createThumbnail(
          apiImageResp.slide_id,
          porousApiImageResp.data.height,
          porousApiImageResp.data.width
        );
        try {
          await API.put(
            `uploads/${apiImageResp.id}`,
            {
              case_id: props.dbId,
              format: porousApiImageResp.data.format,
              overlap: porousApiImageResp.data.overlap,
              height: porousApiImageResp.data.height,
              width: porousApiImageResp.data.width,
              tile_size: porousApiImageResp.data.tileSize,
              image_url: `https://storage.googleapis.com/${process.env.REACT_APP_POROUS_API_TILEBUCKET}/${porousApiImageResp.data.groupId}/${apiImageResp.slide_id}/${porousApiImageResp.data.slug}/`,
              thumbnail: thumbnail.data.url
            },
            {
              cancelToken: source.token
            }
          );

          const newImages = props.images.current.map((image: CaseImages) => {
            if (image.id === apiImageResp.id) {
              return {
                ...image,
                url: thumbnail.data.url,
                imageStatus: porousApiImageResp.data.state,
                Image: {
                  Format: porousApiImageResp.data.format,
                  Overlap: porousApiImageResp.data.overlap,
                  Size: {
                    Height: porousApiImageResp.data.height,
                    Width: porousApiImageResp.data.width
                  },
                  TileSize: porousApiImageResp.data.tileSize,
                  Url: image.Image.Url
                }
              };
            }
            return image;
          });
          if (mounted) {
            props.setCaseImagesRef([...newImages]);
          }
        } catch (error) {
          exceptionHandler(error, t);
        }
      }
    }
  };

  const initialLoad = () => {
    if (props.images.current && props.images.current.length > 0) {
      props.images.current.map((image: CaseImages) => {
        if (!dataLoading.current.includes(image.id)) {
          setDataLoading([...dataLoading.current, image.id]);
          getCaseImage(image);
        }
        return null;
      });
    }
  };

  const getCaseImage = async (image: CaseImages) => {
    try {
      if (image.url === "") {
        const porousApiImageResponse: any = await POROUSAPI.get(
          `slides/${image.slide_id}`,
          {
            cancelToken: source.token
          }
        );
        checkImage(image, porousApiImageResponse);
      } else {
        const newImages = props.images.current.map(
          (currentImage: CaseImages) => {
            if (image.id === currentImage.id) {
              return {
                ...currentImage,
                imageStatus: "done"
              };
            }
            return currentImage;
          }
        );
        props.setCaseImagesRef([...newImages]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleChangeImage = (imageId: number, change: keyValuePair) => {
    const newImages = props.images.current.map((image: CaseImages) => {
      if (image.id === imageId) {
        return {
          ...image,
          ...change
        };
      }
      return image;
    });
    props.setCaseImagesRef([...newImages]);
  };

  const onDelete = (imageId: number) => {
    const newImage = props.images.current.filter((image: CaseImages) => {
      return image.id !== imageId;
    });
    props.setCaseImagesRef(newImage);
    props.getCaseTags();
  };
  return (
    <div className={classes.Container} id="image">
      <div className={classes.titleContainer}>
        <FormTitle isConsult={props.isConsult} className={classes.title}>
          {t("Specimen Images")}
        </FormTitle>
        {!props.publicReport && (
          <div
            className={classes.selectImage}
            onClick={() => {
              return props.setImageView(true);
            }}
          >
            {t("Select Images")} <RightOutlined />
          </div>
        )}
      </div>
      {props.images.current && props.images.current.length > 0 ? (
        <div className={classes.mainContainer} id="caseImages">
          <div className={classes.divider} />
          <Row gutter={15} className={classes.imageContainer}>
            {props.loading ? (
              <div className={classes.loader}>
                <LoaderComponent />
              </div>
            ) : (
              props.images.current.map((image: CaseImages, index: number) => {
                return (
                  <Col
                    xl={pdfLoading ? 12 : 8}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={index}
                  >
                    <Row className={classes.imageTitleWrapper}>
                      <Typography className={classes.imageNumberText}>
                        {`Fig. ${index + 1} ${image.name}`}
                      </Typography>
                    </Row>
                    <CaseImage
                      imageUrl={
                        pdfLoading
                          ? image.dataUrl
                          : image.dataUrl || `${image.url}`
                      }
                      caseConfirm={props.caseConfirm}
                      imageName={image.name}
                      imageStatus={image.imageStatus}
                      onDelete={onDelete}
                      onClick={() => {
                        return !pdfLoading && handleClick(image);
                      }}
                      slideId={image.slide_id}
                      imageId={image.id}
                      caseId={props.dbId}
                      setSaving={props.setSaving}
                      state={{
                        organ: image.organ,
                        part: image.part,
                        staining: image.staining
                      }}
                      handleChangeImage={handleChangeImage}
                      publicReport={props.publicReport}
                    />
                  </Col>
                );
              })
            )}
          </Row>
        </div>
      ) : (
        <div className={classes.imageMessage}>
          {t("Please check images to be pasted from the image/link list.")}
        </div>
      )}
    </div>
  );
};

export { ImageComponent };
