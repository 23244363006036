import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Divider, Modal, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, exceptionHandler, Loader, POROUSAPI } from "shared-components";

import Annotation from "../openSeadragon/Annotation";

const useStyles = createUseStyles((theme: any) => {
  return {
    "@-webkit-keyframes slide-right": {
      from: {
        webkitTransform: "translateX(-100px)",
        transform: "translateX(-100px)"
      },
      to: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)"
      }
    },
    "@keyframes slide-right": {
      from: {
        webkitTransform: "translateX(-100px)",
        transform: "translateX(-100px)"
      },
      to: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)"
      }
    },
    slideRightIn: {
      webkitAnimation:
        "slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
      animation:
        "slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both"
    },
    modalContainer: {
      overflow: "hidden",
      height: "100%",
      paddingBottom: "0px",
      width: "100% !important",
      "& .ant-modal-content": {
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0
      },
      "& .ant-modal-body": {
        padding: "0px",
        height: "100%"
      },
      "& .ant-modal-close-x": {
        color: "white",
        lineHeight: "22px"
      }
    },
    titleDiv: {
      height: 30,
      background: "black",
      display: "flex",
      alignItems: "center"
    },
    whiteText: {
      fontSize: 16,
      color: "white",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      minWidth: 20
    },
    idTitle: {
      marginRight: 10,
      marginLeft: 8
    },
    idText: {
      marginRight: 20,
      width: 450
    },
    divider: {
      height: "16px",
      margin: "0px 10px 0px 0px",
      borderColor: theme.border
    },
    imageContainer: {
      display: "flex",
      height: "100%"
    },
    imageGridContainer: {
      display: "flex"
    },
    openSeadragon: {
      height: "97%",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center"
    },
    arrow: {
      fontSize: 25,
      color: theme.colorGrey,
      margin: {
        top: 5
      }
    },
    blueArrow: {
      color: "blue"
    },
    arrowContainer: {
      position: "absolute",
      width: 40,
      height: 36,
      left: 0,
      top: 0,
      display: "flex",
      flexDirection: "column",
      background: theme.darkBackground,
      borderBottomRightRadius: 10,
      zIndex: 999999999
    },
    imageGrid: ({ mainCollapse }: any) => {
      return {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: mainCollapse ? 135 : 40,
        height: "-webkit-fill-available",
        background: mainCollapse ? theme.darkBackground : theme.textWhite,
        justifyContent: "center"
      };
    },
    imageGridAssociated: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      width: 135,
      height: "-webkit-fill-available",
      background: theme.darkBackground,
      justifyContent: "center",
      borderLeft: `2px solid ${theme.royalBlue}`,
      paddingLeft: 7
    },
    imageGridWrapper: {
      height: "100%",
      margin: {
        top: 50,
        right: 5,
        bottom: 37
      },
      padding: {
        right: 20,
        left: 20
      },
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey,
        borderRadius: "10px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor
      }
    },
    singleImage: {
      marginBottom: 11
    },
    image: {
      width: 85,
      height: 85,
      border: `1px solid ${theme.imageBorder}`,
      cursor: "pointer"
    },
    imageName: {
      color: "white",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    imageNotfound: {
      color: "white"
    },
    pointerSwitch: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginRight: "90px"
    },
    name: {
      width: 600
    },
    loading: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: any;
  images: CaseImages[];
  selectedImage: string;
  caseId: string;
  dbId: number;
  updateCaseTags: (tag: any, remove?: boolean | undefined) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTagsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reportId: number;
  caseConfirm?: boolean;
}

const ImageViewer = (props: Props) => {
  const [selectedImage, setSelectedImage] = useState({} as CaseImages);

  const [mainImage, setMainImage] = useState({} as CaseImages);

  const [_associatedImages, _setAssociatedImages] = useState(
    [] as AssociatedImages[]
  );
  const associatedImages = useRef(_associatedImages);
  const setAssociatedImages = (value: AssociatedImages[]) => {
    associatedImages.current = value;
    _setAssociatedImages(value);
  };

  const [associatedLoading, setAssociatedLoading] = useState(true as boolean);

  const [cacheBreaker, setCacheBreaker] = useState(new Date().getTime());

  const [mainCollapse, setMainCollapase] = useState(true as boolean);
  const { t } = useTranslation();
  const [pointer, setPointer] = useState(false);
  const classes = useStyles({ selectedImage, pointer, mainCollapse });

  useEffect(() => {
    props.images.map(image => {
      if (!selectedImage.id) {
        if (image.slide_id === props.selectedImage) {
          setSelectedImage(image);
          setMainImage(image);
        }
      }
    });
  }, [props.selectedImage, props.images]);

  useEffect(() => {
    loadImage();
  }, [selectedImage]);

  const loadImage = async () => {
    if (!selectedImage || !selectedImage.id || selectedImage.associated) {
      return;
    }
    try {
      const imageResp = await POROUSAPI.get(`slides/${selectedImage.slide_id}`);
      setAssociatedImages([]);
      if (
        imageResp.data &&
        imageResp.data.associatedImages &&
        imageResp.data.associatedImages.length
      ) {
        imageResp.data.associatedImages.map(async (image: any) => {
          const currentAssociatedImage = selectedImage.associatedImages?.find(
            (value: AssociatedImages) => {
              return value.name === image.name;
            }
          );
          if (currentAssociatedImage) {
            setAssociatedImages([
              ...associatedImages.current,
              currentAssociatedImage
            ]);
            setAssociatedLoading(false);
            return;
          }
          const thumbnail: any = await createThumbnail(
            selectedImage.slide_id,
            image.height,
            image.width,
            image.name
          );
          const response: any = await API.post(`associated-image`, {
            id: thumbnail.data.id,
            case_image_id: selectedImage.id,
            height: image.height,
            width: image.width,
            thumbnail: thumbnail.data.url,
            url: `https://storage.googleapis.com/${process.env.REACT_APP_POROUS_API_TILEBUCKET}/${imageResp.data.groupId}/${selectedImage.slide_id}/${image.name}/`,
            name: image.name
          });
          setAssociatedImages([...associatedImages.current, response.data]);
          setAssociatedLoading(false);
        });
      } else {
        setAssociatedLoading(false);
      }
    } catch (err) {}
  };

  const createThumbnail = (
    slideId: string,
    height: number,
    width: number,
    name: string
  ) => {
    try {
      return POROUSAPI.post(`/slides/${slideId}/crop`, {
        height,
        width,
        theta: 0,
        x: 0,
        y: 0,
        name
      });
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const toggleCollapse = (value: boolean) => {
    setMainCollapase(value);
  };

  useEffect(() => {
    const pointerState = localStorage.getItem("pointer");
    if (pointerState === "true") {
      setPointer(true);
    }
  }, []);

  if (!props.visible) {
    return null;
  }

  const handlePointer = (value: boolean) => {
    setPointer(value);
    if (value) {
      localStorage.setItem("pointer", "true");
    } else {
      localStorage.removeItem("pointer");
    }
  };

  return (
    <Modal
      centered
      footer={null}
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={props.onCancel}
      mask={false}
    >
      <div className={classes.titleDiv}>
        <span className={`${classes.idTitle} ${classes.whiteText}`}>ID</span>
        <span className={`${classes.idText} ${classes.whiteText}`}>
          {props.caseId}
        </span>
        <Divider className={classes.divider} type="vertical" />
        <span className={`${classes.whiteText} ${classes.name}`}>
          {selectedImage.name}
        </span>
        <div className={classes.pointerSwitch}>
          <Switch
            checkedChildren={t("Pointer")}
            unCheckedChildren={t("Pointer")}
            onClick={handlePointer}
            checked={pointer}
          />
        </div>
      </div>
      <div className={classes.imageContainer}>
        <div className={classes.imageGridContainer}>
          <div className={classes.imageGrid}>
            <div className={classes.arrowContainer}>
              {mainCollapse ? (
                <LeftOutlined
                  className={classes.arrow}
                  onClick={() => {
                    return toggleCollapse(false);
                  }}
                />
              ) : (
                <RightOutlined
                  className={classes.arrow}
                  onClick={() => {
                    return toggleCollapse(true);
                  }}
                />
              )}
            </div>
            {mainCollapse && (
              <div className={classes.imageGridWrapper}>
                {props.images.map((image: CaseImages, index: number) => {
                  return (
                    <div className={classes.singleImage} key={index}>
                      <div
                        onClick={() => {
                          if (selectedImage.id !== image.id) {
                            setSelectedImage(image);
                            setMainImage(image);
                            setAssociatedImages([]);
                            setAssociatedLoading(true);
                          }
                        }}
                        key={index}
                        className={classes.image}
                        style={{
                          background: `url(${image.url}) center/cover no-repeat`,
                          borderWidth:
                            selectedImage.id === image.id ? "3px" : "1px"
                        }}
                      />
                      <p className={classes.imageName}>{image.name}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {mainCollapse && (
            <div
              className={`${classes.imageGridAssociated} ${classes.slideRightIn}`}
            >
              <div className={classes.imageGridWrapper}>
                {associatedLoading ? (
                  <div className={classes.loading}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div className={classes.singleImage}>
                      <div
                        onClick={() => {
                          return setSelectedImage(mainImage);
                        }}
                        className={classes.image}
                        style={{
                          background: `url(${mainImage.url}) center/cover no-repeat`,
                          borderWidth:
                            selectedImage.id === mainImage.id &&
                            !selectedImage.associated
                              ? "3px"
                              : "1px"
                        }}
                      />
                      <p className={classes.imageName}>{mainImage.name}</p>
                    </div>
                    {associatedImages.current.map(
                      (image: AssociatedImages, index: number) => {
                        return (
                          <div className={classes.singleImage} key={index}>
                            <img
                              src={`${image.thumbnail}?t=${cacheBreaker}`}
                              alt=""
                              onError={(event: any) => {
                                return setCacheBreaker(new Date().getTime());
                              }}
                              onClick={() => {
                                const newImage = {
                                  ...selectedImage,
                                  Image: {
                                    ...selectedImage.Image,
                                    Size: {
                                      Height: image.height,
                                      Width: image.width
                                    },
                                    Url: image.url
                                  },
                                  associated: image.name
                                };
                                setSelectedImage(newImage);
                              }}
                              key={index}
                              className={classes.image}
                              style={{
                                borderWidth:
                                  selectedImage.associated === image.name
                                    ? "3px"
                                    : "1px"
                              }}
                            />
                            <p className={classes.imageName}>{image.name}</p>
                          </div>
                        );
                      }
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={classes.openSeadragon}>
          <Annotation
            dbId={props.dbId}
            reportId={props.reportId}
            caseConfirm={props.caseConfirm}
            selectedImage={selectedImage}
            pointer={pointer}
          />
        </div>
      </div>
    </Modal>
  );
};

export { ImageViewer };
