import { AutoComplete, Select } from "antd";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { PdfContext } from "../../../PdfContext";
import { Typography } from "../Typography";

interface Option {
  label: string;
  value: string;
  disable?: boolean;
}

interface Props {
  options: any | Option[];
  value?: string | number;
  label?: string;
  error?: string | boolean | number;
  required?: boolean;
  onBlur?: any;
  name: string;
  placeholder?: string;
  className?: string;
  showSearch?: boolean;
  wrapperClassName?: string;
  size?: "small" | "middle" | "large";
  allowClear?: boolean;
  onChange: (field: string, value: any) => any;
  max?: number;
  subLabel?: string;
  disabled?: boolean;
  hoverOpen?: boolean;
  defaultValue?: string;
  labelFontSize?: number;
  id?: string;
  type?: "select" | "input";
}

let useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    text: {
      lineHeight: "30px",
      borderRadius: 3,
      marginBottom: 20,
      marginTop: 4,
      width: "100%",
      "& .ant-select-selector": {
        display: "flex",
        height: "28px",
        borderRadius: "3px !important",
        alignItems: "center"
      }
    },
    label: {
      marginBottom: "0",
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    },
    labelContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: -4
    },
    maxHolder: {
      borderRadius: 2,
      border: "1px solid #C9C9C9",
      width: 32,
      height: 13,
      fontSize: 9,
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 8
    },
    subLabel: {
      fontSize: 12,
      fontWeight: 300,
      marginBottom: -3,
      marginTop: 4,
      color: theme.formLabelColor
    },
    select: {
      width: "100%"
    },
    loadingField: {
      "& .ant-select-selector": {
        background: "#fff !important",
        color: "#000 !important"
      }
    },
    error: {
      marginTop: -20,
      marginBottom: 0,
      fontSize: 12,
      color: theme.error,
      fontWeight: 300,
      display: "flex",
      lineHeight: "20px"
    },
    dropdown: {
      borderRadius: 3
    }
  };
});

export const SelectComponent = ({
  label,
  required,
  options,
  value,
  onBlur,
  name,
  placeholder,
  className,
  showSearch,
  size,
  wrapperClassName,
  max,
  subLabel,
  allowClear,
  onChange,
  disabled,
  hoverOpen = true,
  defaultValue,
  error,
  labelFontSize,
  id,
  type
}: Props) => {
  const { Option } = Select;
  const { pdfLoading } = useContext(PdfContext);
  const { t } = useTranslation();
  const classes = useStyles(pdfLoading);

  const handleChange = (value: any) => {
    onChange(name, value);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  return (
    <div className={`${classes.container} ${wrapperClassName}`}>
      <div className={classes.labelContainer}>
        {label && (
          <Typography
            fontSize={labelFontSize ? `${labelFontSize}px` : "14px"}
            className={classes.label}
          >
            {label} {required ? "*" : null}
          </Typography>
        )}
        {!pdfLoading && max && (
          <div className={classes.maxHolder}>Max {max}</div>
        )}
      </div>
      {subLabel && <p className={classes.subLabel}>{subLabel}</p>}
      <div
        className={classes.select}
        id={id}
        onMouseOver={() => {
          return hoverOpen ? setOpen(true) : null;
        }}
        onMouseLeave={() => {
          return hoverOpen ? setOpen(false) : null;
        }}
      >
        {type === "input" ? (
          <AutoComplete
            options={options.map((option: any) => {
              return {
                label: t(`${option.label}`),
                value: option.value
              };
            })}
            filterOption={(inputValue, option) => {
              return (
                option!.value
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              );
            }}
            placeholder={pdfLoading || disabled ? "" : placeholder}
            value={value ? `${value}` : undefined}
            defaultValue={defaultValue}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            size={size || "small"}
            showSearch={showSearch}
            allowClear={allowClear}
            className={`${classes.text} ${pdfLoading &&
              classes.loadingField} ${className}`}
          />
        ) : (
          <Select
            className={`${classes.text} ${pdfLoading &&
              classes.loadingField} ${className}`}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            placeholder={pdfLoading || disabled ? "" : placeholder}
            dropdownClassName={classes.dropdown}
            size={size || "small"}
            showSearch={showSearch}
            allowClear={allowClear}
            open={hoverOpen ? open : undefined}
            disabled={disabled}
            defaultValue={defaultValue}
          >
            {options.map((option: Option) => {
              return (
                <Option
                  disabled={option.disable}
                  key={option.value}
                  value={option.value}
                >
                  {t(`${option.label}`)}
                </Option>
              );
            })}
          </Select>
        )}
      </div>
      {error && <p className={classes.error}>{t(`${error}`)}</p>}
    </div>
  );
};
