import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, debounceFunction, exceptionHandler } from "shared-components";

import { CaseFormValue } from "../organisms/RenderForm";
import { Diagnosis } from "./Diagnosis";
import { Findings } from "./Findings";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      marginTop: 9,
      background: theme.lightBg
    }
  };
});

interface Props {
  reportId: number;
  formValues: CaseFormValue[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  hasSidebar: boolean;
  setCaseData: (response: any) => void;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}

const FindingsDiagnosis = ({
  reportId,
  formValues: newformValues,
  setSaving,
  hasSidebar,
  setCaseData,
  isConsult,
  publicReport,
  readOnly
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({} as Option);

  const handleChange = (name: string, value: string) => {
    const oldFormValues = formValues;
    const newFormValues = { ...oldFormValues, [name]: value };
    setFormValues(newFormValues);
    const formUpdateValues = {
      report_id: reportId,
      name: name,
      value: value
    };

    debounceFunction(() => {
      return handleUpdateApi(formUpdateValues, oldFormValues);
    });
  };

  const handleUpdateApi = async (
    formUpdateValues: any,
    oldFormValues: Option
  ) => {
    if (publicReport) {
      return;
    }
    setSaving(true);
    try {
      await API.post("/form-value", formUpdateValues);
    } catch (error) {
      setFormValues(oldFormValues);
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  useEffect(() => {
    const formValues = {} as Option;
    newformValues.map((value: CaseFormValue) => {
      formValues[value.name] = value.value;
      return null;
    });
    setFormValues({ ...formValues });
  }, [newformValues]);

  return (
    <div className={classes.wrapper} id="findingsDiagnosis">
      <Findings
        reportId={reportId}
        formValues={formValues}
        handleChange={handleChange}
        hasSidebar={hasSidebar}
        setCaseData={setCaseData}
        setSaving={setSaving}
        isConsult={isConsult}
        publicReport={publicReport}
        readOnly={readOnly}
      />
      <Diagnosis
        reportId={reportId}
        formValues={formValues}
        handleChange={handleChange}
        hasSidebar={hasSidebar}
        isConsult={isConsult}
        publicReport={publicReport}
        readOnly={readOnly}
      />
    </div>
  );
};

export { FindingsDiagnosis };
