import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Alert, Button, Modal, TextField, Typography } from "shared-components";
import { API, exceptionHandler } from "shared-components/src";

const useStyles = createUseStyles((theme: any) => {
  return {
    button: {
      height: 28,
      width: 160,
      fontSize: 14,
      lineHeight: "20px",
      borderRadius: 3,
      marginTop: 11,
      marginBottom: 56.5
    },
    modalButton: {
      textAlign: "right"
    },
    buttonWrapper: {
      textAlign: "center",
      marginLeft: 20,
      marginBottom: 16
    },
    modal: {
      maxWidth: "60%"
    },
    labeltext: {
      color: theme.textBlue,
      fontSize: "12px",
      letterSpacing: 0,
      lineHeight: "14px"
    },
    nameLabel: {
      marginBottom: 7
    },
    urlLabel: {
      marginBottom: 3
    },
    radioGroup: {
      display: "flex"
    },
    modalBox: {
      height: 480,
      maxwidth: 850,
      marginTop: 5,
      "& .ant-modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    formItem: {
      marginBottom: 19
    }
  };
});

interface Props {
  visible: boolean;
  linkLoading: boolean;
  setLinkModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkLoading: React.Dispatch<React.SetStateAction<boolean>>;
  caseId: number;
  teamId: number;
  reportId: number;
  getCaseLinks: () => Promise<void>;
}

const UploadLinkModal = ({
  visible,
  linkLoading,
  setLinkModalVisibility,
  setLinkLoading,
  caseId,
  teamId,
  reportId,
  getCaseLinks
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [state, setState] = useState({
    url: "",
    name: ""
  });

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const handleSubmit = async () => {
    setLinkLoading(true);
    try {
      const { name, url } = state;
      await API.post(`cases/${caseId}/links`, {
        case_id: caseId,
        report_id: reportId,
        name: name,
        url: url
      });
      getCaseLinks();
      setLinkLoading(false);
      form.resetFields();
      setLinkModalVisibility(false);
      Alert("success", "success", t("Link has been uploaded."), t);
    } catch (error) {
      setLinkLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <Modal
      onCancel={() => {
        return setLinkModalVisibility(false);
      }}
      visible={visible}
      mask={false}
      className={classes.modalBox}
      centered
    >
      <Form form={form} className={classes.modal} onFinish={handleSubmit}>
        <Form.Item
          id="name"
          name="name"
          className={classes.formItem}
          rules={[
            {
              required: true,
              message: t("Required")
            },
            {
              max: 60,
              message: t("Maximum input limit is 60 characters")
            }
          ]}
        >
          <div>
            <Typography className={`${classes.labeltext} ${classes.nameLabel}`}>
              {t("Link Name")}
            </Typography>
            <TextField
              autoComplete="off"
              name="name"
              onChange={handleChange}
              maxLength={60}
            />
          </div>
        </Form.Item>
        <Form.Item
          id="url"
          name="url"
          className={classes.formItem}
          rules={[
            {
              type: "url",
              message: t("Not a valid url")
            },
            {
              required: true,
              message: t("Required")
            },
            {
              max: 2048,
              message: t("Maximum input limit is 60 characters")
            }
          ]}
        >
          <div>
            <Typography className={`${classes.labeltext} ${classes.urlLabel}`}>
              {t("Link")}
            </Typography>
            <TextField autoComplete="off" name="url" onChange={handleChange} />
          </div>
        </Form.Item>
        <Form.Item className={classes.modalButton}>
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              type="primary"
              htmlType="submit"
              loading={linkLoading}
            >
              {t("Submit")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { UploadLinkModal };
