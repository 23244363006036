import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { API, AuthContext, exceptionHandler, Loader } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    loader: {
      marginLeft: "48%",
      marginTop: "20%"
    }
  };
});

const CaseExternal = ({ errorBoundary }: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { state: locationState }: any = useLocation();
  const { defaultTeam, setDefaultTeam } = useContext(AuthContext);

  const [loading, setLoading] = useState(true as boolean);
  const [caseId, setCaseId] = useState("" as string);

  const { t } = useTranslation();

  useEffect(() => {
    if (!locationState) {
      history.push("/");
    }
    if (
      locationState &&
      locationState.teamId &&
      parseInt(locationState.teamId) &&
      parseInt(locationState.teamId) !== defaultTeam
    ) {
      handleChangeTeam();
    } else {
      if (locationState && locationState.pathologyNumber && defaultTeam) {
        handleCases();
      }
    }
  }, [locationState, defaultTeam]);

  const handleChangeTeam = async () => {
    const teamId = parseInt(locationState.teamId);
    try {
      const response = await API.post(`members/change-teams`, {
        team_id: teamId
      });

      if (response.data) {
        setDefaultTeam(teamId);
      }
    } catch (error) {
      exceptionHandler(error, t);
      history.push("/");
    }
  };

  const handleCases = async () => {
    const id = locationState.pathologyNumber;
    try {
      const response = await API.get(
        `external-case/${id}?team_id=${defaultTeam}`
      );
      if (response && response.data && response.data.id) {
        setCaseId(`${response.data.id}`);
      }
      if (locationState.pathpresenterName && locationState.pathpresenterToken) {
        await API.post(`cases/${response.data.id}/links`, {
          case_id: response.data.id,
          report_id: 1,
          name: locationState.pathpresenterName,
          url: `https://jrs.pathpresenter.net/#/public/presentation/display?token=${locationState.pathpresenterToken}`
        });
      }
    } catch (error) {
      errorBoundary(error);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader size="large" />
      </div>
    );
  }

  if (caseId) {
    return <Redirect to={`/cases/${caseId}`} />;
  }

  return null;
};

export { CaseExternal };
