import { select, text } from "@storybook/addon-knobs";
import React from "react";

import { Loader } from "./index";

export default {
  component: Loader,
  title: "Components/Atoms/Loader"
};

export const LoaderComponent = () => {
  return (
    <Loader
      text={text("Text", "")}
      size={select(
        "Size",
        {
          small: "small",
          default: "default",
          large: "large"
        },
        "default"
      )}
    />
  );
};
