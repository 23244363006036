import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  exceptionHandler,
  TextField
} from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import {
  ControlDatePicker,
  ControlSelectField,
  ControlTextArea,
  ControlTextField,
  OrganField,
  RadioButton,
  SelectDatePicker
} from "../../../form-items";
import { Container, FormTitle } from "../../../forms/Layout";
import { CaseDoubleCheck, Member } from "../../../organisms/DoubleCheck";

const useStyles = createUseStyles(() => {
  return {
    pathologistSelectStyle: { marginBottom: "-20px" },
    showData: {
      marginBottom: 10
    },
    showDataContainer: {
      paddingTop: 10,
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr"
    },
    radio: {
      "& .ant-radio-wrapper": {
        display: "block"
      }
    },
    remarks: {
      paddingTop: 10
    },
    input: {
      marginTop: "8px",
      "& article": {
        marginLeft: "8px"
      }
    },
    doubleCheck: {
      marginTop: 8
    },
    organNames: {
      lineBreak: "anywhere"
    }
  };
});

export function FundamentalInformation({
  state,
  setState,
  setSaving,
  members,
  caseDoubleCheck,
  publicReport,
  readOnly
}: {
  state: CaseData;
  setState: React.Dispatch<React.SetStateAction<CaseData>>;
  members: Member[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  caseDoubleCheck: CaseDoubleCheck | null;
  publicReport?: boolean;
  readOnly?: boolean;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { externalUser } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({} as any);
  const [lastState, setLastState] = useState(state);
  const [formState, setFormState] = useState(state);
  const [teamUserId, setTeamUserId] = useState(0);
  const [doubleCheckUser, setDoubleCheckUser] = useState(
    undefined as Member | undefined
  );

  useEffect(() => {
    if (!lastState.id && !!state.id) {
      setLastState(state);
    }
  }, [state]);

  const { handleSubmit, control, watch } = useForm<CaseData>({
    defaultValues: state
  });
  const submitButton = useRef<HTMLButtonElement>(null);

  async function onSubmit(formData: CaseData) {
    setFormState({ ...state, ...formData });
  }
  function submit() {
    submitButton.current?.click();
  }

  useEffect(() => {
    if (formState.age == "0" && formState.birthday !== state.birthday) {
      const birthDate = moment(formState.birthday, "YYYY-MM-DD");
      const today = moment();
      const ageDiff = today.diff(birthDate, "y").toString();
      setFormState({ ...state, ...formState, age: ageDiff });
    }
  }, [formState]);

  useEffect(() => {
    setFormErrors({
      ...formErrors,
      age:
        parseInt(formState.age) < 0 && parseInt(formState.age) != null
          ? t("Please enter value that is not negative and zero")
          : "",
      numberOfSpecimen:
        parseInt(formState.numberOfSpecimen) < 0 &&
        parseInt(formState.numberOfSpecimen) != null
          ? t("Please enter value that is not negative and zero")
          : ""
    });
  }, [formState]);

  useEffect(() => {
    if (members.length > 0 && formState.pathologist) {
      const teamUsers = members.filter((member: Member) => {
        return member.id === formState.pathologist;
      });
      if (teamUsers.length > 0) {
        setTeamUserId(teamUsers[0].team_user_id);
      }
    } else {
      setTeamUserId(0);
    }
  }, [members, formState.pathologist]);

  useEffect(() => {
    const f = async () => {
      if (publicReport) {
        return;
      }
      setSaving(true);
      const error = Object.entries(formErrors).some(item => {
        if (item[1] !== "") {
          return true;
        }
        return false;
      });
      if (error) {
        setSaving(false);
        return;
      }
      const stateArray = Object.entries(formState);
      let notContinue = stateArray.some(item => {
        const name = item[0];
        if (item[1] === "" && lastState[name] === undefined) {
          return false;
        }
        if (item[1] !== lastState[name]) {
          return true;
        }
        return false;
      });
      if (!notContinue) {
        setSaving(false);
        return;
      }
      try {
        await API.put(`reports/${formState.reportId}`, {
          registration_date: formState.registrationDate,
          team_id: formState.teamId,
          pathologist_user_id: formState.pathologist,
          pathology_number: formState.caseNumber,
          patient_name: formState.patientName,
          hospital: formState.hospital,
          birthday: formState.birthday,
          age: parseInt(formState.age),
          sex: formState.sex,
          clinician: formState.clinician,
          occupation: formState.occupation,
          medical_record_id: formState.newMedicalRecordId,
          date_of_collection: formState.dateOfCollection,
          number_of_specimen: formState.numberOfSpecimen,
          organs: formState.organs,
          organ_other_text: formState.organOtherText,
          remarks: formState.remarks,
          team_user_id: teamUserId || undefined
        });
        setState({ ...formState });
        setLastState({ ...formState });
      } catch (exception) {
        setState(lastState);
        setFormState(lastState);
        exceptionHandler(exception, t);
      }
      setSaving(false);
    };
    f();
  }, [formErrors]);

  useEffect(() => {
    if (caseDoubleCheck && members && members.length) {
      const user = members.find((member: Member) => {
        return member.id === caseDoubleCheck.userId;
      });
      setDoubleCheckUser(user);
    }
  }, [caseDoubleCheck, members]);

  const isConsult = state.consultation;

  const readOnlyView = isConsult || publicReport || readOnly;

  const getOrganName = (value: string) => {
    let watchOrgans: string = watch("organs", value);
    const watchOrganOtherText: string = watch(
      "organOtherText",
      state.organOtherText
    );
    const makeOrgansArray = watchOrgans?.split(",") || [];
    const requiredOrgansArray = makeOrgansArray.map(organ => {
      return organ.substring(organ.indexOf("-") + 1);
    });
    watchOrgans = requiredOrgansArray.toString();

    const wholeString = `${watchOrgans},${watchOrganOtherText}`
      .split(",")
      .filter(v => {
        return v !== "";
      })
      .map((v: any) => {
        return t(v);
      });

    return wholeString;
  };
  return (
    <Container isConsult={isConsult}>
      <FormTitle isConsult={isConsult}>
        <div className="flex flex-wrap">
          <div className="mr-4">{t("Fundamental Information")}</div>
        </div>
      </FormTitle>
      {readOnlyView ? (
        <div className="divide-y divide-gray-400">
          <div className="divide-x divide-gray-400 flex pb-4">
            <div className="flex-1 pr-4">
              <ShowData
                className={classes.showData}
                label={t("Pathology number")}
                value={state.caseNumber}
              />
              <ShowData
                className={classes.showData}
                label={t("Pathologist")}
                value={state.pathologistName}
              />
              {!isConsult && caseDoubleCheck && doubleCheckUser && (
                <ShowData
                  label={t("Double-checker")}
                  value={doubleCheckUser.name}
                />
              )}
            </div>
            <div className="flex-1 pl-4">
              <ShowData
                className={classes.showData}
                label={t("Registration date")}
                value={
                  state.registrationDate !== null
                    ? moment(state.registrationDate).format("YYYY/MM/DD HH:mm")
                    : moment(state.createDateTime).format("YYYY/MM/DD HH:mm")
                }
              />

              <ShowData
                className={classes.showData}
                label={t("Hospital")}
                value={state.hospital || "-"}
              />
              <ShowData label={t("Clinician")} value={state.clinician || "-"} />
            </div>
          </div>
          {!externalUser && (
            <div>
              <div className={classes.showDataContainer}>
                <ShowData
                  label={t("Medical record ID")}
                  value={state.newMedicalRecordId}
                />
                <ShowData label={t("Patient Name")} value={state.patientName} />
                <ShowData
                  label={t("Sex")}
                  value={
                    state.sex &&
                    state.sex.charAt(0).toUpperCase() + state.sex.slice(1)
                  }
                />
              </div>
              <div className={classes.showDataContainer}>
                <ShowData
                  label={t("Date of birth")}
                  value={
                    state.birthday !== null
                      ? moment(state.birthday).format("YYYY/MM/DD")
                      : ""
                  }
                />
                <ShowData label={t("Age")} value={state.age} />
                <ShowData label={t("Occupation")} value={state.occupation} />
              </div>
            </div>
          )}
          {!externalUser && (
            <div className={classes.showDataContainer}>
              <ShowData
                label={t("Date of collection")}
                value={
                  state.dateOfCollection !== null
                    ? moment(state.dateOfCollection).format("YYYY/MM/DD")
                    : ""
                }
              />
              <ShowData
                label={t("Number of specimen")}
                value={state.numberOfSpecimen}
              />
              <ShowData
                label={t("Organ name")}
                className={classes.organNames}
                value={getOrganName(state.organ).join(", ")}
              />
            </div>
          )}
          <div>
            <ShowData
              className={classes.remarks}
              label={t("Remarks")}
              value={state.remarks}
            />
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="divide-y divide-gray-400">
            <div className="divide-x divide-gray-400 flex pb-4">
              <div className="flex-1 pr-4">
                <ControlTextField
                  disable={true}
                  control={control}
                  name="caseNumber"
                  label={t("Pathology number")}
                  placeholder={t("Pathology number")}
                  onBlur={() => {
                    return submit();
                  }}
                  defaultValue={state.caseNumber}
                  maxLength={90}
                />
                <div className={classes.pathologistSelectStyle}>
                  <ControlSelectField
                    control={control}
                    name="pathologist"
                    label={t("Pathologist")}
                    options={members.map((member: Member) => {
                      return {
                        label: member.name,
                        value: member.id
                      };
                    })}
                    onBlur={() => {
                      return submit();
                    }}
                    defaultValue={state.pathologist}
                  />
                </div>
                {!isConsult && caseDoubleCheck && doubleCheckUser && (
                  <ShowData
                    className={classes.doubleCheck}
                    label={t("Double-checker")}
                    value={doubleCheckUser.name}
                  />
                )}
              </div>
              <div className="flex-1 pl-4">
                <ControlDatePicker
                  control={control}
                  name="registrationDate"
                  label={t("Registration date")}
                  defaultValue={
                    state.registrationDate !== null
                      ? state.registrationDate
                      : state.createDateTime
                  }
                  onChange={() => {
                    return submit();
                  }}
                />

                <ControlTextField
                  control={control}
                  name="hospital"
                  label={t("Hospital")}
                  placeholder={t("Hospital / Medical department")}
                  onBlur={() => {
                    return submit();
                  }}
                  defaultValue={state.hospital}
                  maxLength={255}
                />
                <ControlTextField
                  control={control}
                  name="clinician"
                  label={t("Clinician")}
                  placeholder={t("Name")}
                  onBlur={() => {
                    return submit();
                  }}
                  defaultValue={state.clinician}
                  maxLength={45}
                />
              </div>
            </div>
            {!externalUser && (
              <div>
                <div className="flex">
                  <ControlTextField
                    id="newMedicalRecordId"
                    control={control}
                    name="newMedicalRecordId"
                    label={t("Medical record ID")}
                    placeholder={t("Medical record ID")}
                    onBlur={() => {
                      return submit();
                    }}
                    defaultValue={state.newMedicalRecordId}
                    className="py-2 w-1/5"
                    maxLength={255}
                  />
                  <ControlTextField
                    id="patientName"
                    control={control}
                    name="patientName"
                    label={t("Patient Name")}
                    placeholder={t("Name")}
                    onBlur={() => {
                      return submit();
                    }}
                    defaultValue={state.patientName}
                    className="w-3/5 py-2 mx-2"
                    maxLength={45}
                  />
                  <RadioButton
                    control={control}
                    name="sex"
                    label={t("Sex")}
                    options={[
                      [t("Male"), "male"],
                      [t("Female"), "female"]
                    ].map(sexOption => {
                      return {
                        label: sexOption[0],
                        value: sexOption[1]
                      };
                    })}
                    onChange={() => {
                      return submit();
                    }}
                    defaultValue={state.sex}
                    className="py-2"
                  />
                </div>
                <div className="flex">
                  <SelectDatePicker
                    id="birthday"
                    control={control}
                    name="birthday"
                    label={t("Date of birth")}
                    defaultValue={state.birthDay}
                    onChange={() => {
                      return submit();
                    }}
                    className="flex-1 py-2"
                  />
                  <div className={classes.input}>
                    <TextField
                      name="age"
                      label={t("Age")}
                      editValue={formState.age}
                      placeholder={"00"}
                      onBlur={() => {
                        return submit();
                      }}
                      type="number"
                      className="w-64 flex-1 py-2 mx-2"
                      error={formErrors.age}
                      onChange={(e: any) => {
                        setFormState({ ...state, age: e.target.value });
                      }}
                      maxLength={255}
                      min={0}
                    />
                  </div>
                  <ControlTextField
                    name="occupation"
                    label={t("Occupation")}
                    control={control}
                    placeholder={t("Occupation")}
                    onBlur={() => {
                      return submit();
                    }}
                    defaultValue={state.occupation}
                    className="flex-1 py-2"
                    maxLength={45}
                  />
                </div>
              </div>
            )}
            {!externalUser && (
              <div className="flex">
                <ControlDatePicker
                  control={control}
                  name="dateOfCollection"
                  label={t("Date of collection")}
                  defaultValue={state.dateOfCollection}
                  onChange={() => {
                    return submit();
                  }}
                  className="flex-1 py-2"
                />
                <ControlTextField
                  name="numberOfSpecimen"
                  label={t("Number of specimen")}
                  control={control}
                  placeholder="00"
                  onBlur={() => {
                    return submit();
                  }}
                  defaultValue={state.numberOfSpecimen}
                  className="flex-1 py-2 mx-2"
                  type="number"
                  error={formErrors.numberOfSpecimen}
                />
                <OrganField
                  label={t("Organ name")}
                  control={control}
                  onChange={() => {
                    return submit();
                  }}
                  name="organs"
                  otherOrganFieldName="organOtherText"
                  otherOrganFieldDefault={state.organOtherText}
                  defaultValue={state.organs}
                  className="flex-1 py-2"
                  watch={watch}
                  getOrganName={getOrganName}
                />
              </div>
            )}
            <div>
              <ControlTextArea
                name="remarks"
                label={t("Remarks")}
                control={control}
                placeholder={t("use space for any special information")}
                onBlur={() => {
                  return submit();
                }}
                defaultValue={state.remarks}
              />
            </div>
          </div>
          <button type="submit" ref={submitButton}></button>
        </form>
      )}
    </Container>
  );
}
