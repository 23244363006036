import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { PdfContext } from "shared-components";

import { CaseDoubleCheck, Member } from "../../../organisms/DoubleCheck";
import { CaseFormValue, RenderForm } from "../../../organisms/RenderForm";
import { ConsultReportForm } from "./ConsultReportForm";
import { FundamentalInformation } from "./FundamentalInformation";
import { Header } from "./Header";
import { ReportImageAndTags } from "./ReportImageAndTags";
import { ReportLinks } from "./ReportLinks";

interface styleProps {
  pdfLoading: boolean;
}

const useStyles = createUseStyles(() => {
  return {
    container: {
      padding: "10px 10px 20px",
      width: ({ pdfLoading }: styleProps) => {
        return pdfLoading ? 921 : "100%";
      },
      filter: ({ pdfLoading }: styleProps) => {
        return pdfLoading ? "blur(15px)" : "none";
      },
      "& .ant-radio-wrapper": {
        fontSize: "16px",
        lineHeight: "19px",
        fontFamily: "Helvetica Neue",
        color: "#1F1F1F"
      }
    }
  };
});

export interface ReportProps {
  state: CaseData;
  caseDoubleCheck: CaseDoubleCheck | null;
  setState: React.Dispatch<React.SetStateAction<CaseData>>;
  allMembers: Member[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  caseImageRef: React.MutableRefObject<CaseImages[]>;
  setCaseImagesRef: (data: any, handleFunction?: any) => Promise<void>;
  caseId: number;
  hasSidebar: boolean;
  formValues: CaseFormValue[];
  setImageView: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseData: (response: any) => void;
  publicReport?: boolean;
  getCaseTags: () => Promise<void>;
  caseTags: CaseTag[];
  tagsLoading?: boolean;
  setTagsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  imageLoading?: boolean;
  linkLoading?: boolean;
  caseLinks: CaseLink[];
  handleDeleteLink?: (linkId: number) => void;
}

const Report = ({
  state,
  caseDoubleCheck,
  setState,
  allMembers,
  setSaving,
  caseImageRef,
  setCaseImagesRef,
  caseId,
  hasSidebar,
  formValues,
  setImageView,
  setCaseData,
  publicReport,
  getCaseTags,
  tagsLoading,
  caseTags,
  setTagsLoading,
  imageLoading,
  linkLoading,
  caseLinks,
  handleDeleteLink
}: ReportProps) => {
  const { pdfLoading } = useContext(PdfContext);

  const { t } = useTranslation();

  const classes = useStyles({ pdfLoading });

  const isConsult = state.consultation;
  return (
    <div className={classes.container}>
      {pdfLoading && <span id="imageText">{t("Case Images")}</span>}
      {pdfLoading && <span id="tagText">{t("Case Tags")}</span>}
      <div id={"caseForm"}>
        <Header
          createdAt={state.createDateTime}
          completedAt={state.completedAt}
          teamName={state.teamName}
          isConsult={isConsult}
          reportNumber={state.reportNumber}
        />
        {isConsult && (
          <ConsultReportForm
            members={allMembers}
            state={state}
            caseDoubleCheck={caseDoubleCheck}
            setState={setState}
            setSaving={setSaving}
            disabledField={publicReport}
          />
        )}
        {state.consultantName &&
          state.consultHospital &&
          state.consultNumber &&
          !isConsult && (
            <ConsultReportForm
              members={allMembers}
              state={state}
              caseDoubleCheck={caseDoubleCheck}
              setState={setState}
              disabledField={true}
              isConsult={isConsult}
              setSaving={setSaving}
            />
          )}
        <FundamentalInformation
          setSaving={setSaving}
          members={allMembers}
          state={state}
          setState={setState}
          caseDoubleCheck={caseDoubleCheck}
          publicReport={publicReport}
          readOnly={!!state.confirmedAt}
        />
      </div>
      <ReportImageAndTags
        caseImageRef={caseImageRef}
        setCaseImagesRef={setCaseImagesRef}
        caseNumber={state.caseNumber}
        caseId={caseId}
        caseConfirm={!!state.confirmedAt}
        hasSidebar={hasSidebar}
        setSaving={setSaving}
        setImageView={setImageView}
        isConsult={isConsult}
        publicReport={publicReport}
        getCaseTags={getCaseTags}
        caseTags={caseTags}
        tagsLoading={tagsLoading}
        imageLoading={imageLoading}
      />
      <ReportLinks
        caseId={caseId}
        reportId={state.reportId}
        loading={linkLoading}
        caseLinks={caseLinks}
        isConsult={isConsult}
        handleDelete={handleDeleteLink}
        publicReport={publicReport}
      />
      <RenderForm
        formName={state.formName}
        reportId={state.reportId}
        formValues={formValues}
        setSaving={setSaving}
        hasSidebar={hasSidebar}
        setCaseData={setCaseData}
        isConsult={isConsult}
        publicReport={publicReport}
        readOnly={!!state.confirmedAt}
      />
    </div>
  );
};

export default Report;
