import { PictureOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Steps, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { debounce } from "shared-components";

import { caseWrapperId } from "./Case";

const useStyles = createUseStyles((theme: any) => {
  return {
    stepsContainer: {
      display: ({ hasSidebar }: any) => {
        return hasSidebar ? "none" : "flex";
      },
      flexDirection: "column",
      alignItems: "center",
      minWidth: "200px",
      padding: "30px 8px 0px 7px",
      marginLeft: 10,
      position: "absolute"
    },
    uploadImage: {
      background: theme.primary,
      color: "white",
      borderRadius: "3px",
      marginBottom: 10,
      display: "flex",
      justifyContent: "flex-start",
      height: 28,
      maxWidth: 160,
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: "center",
      "& .anticon": {
        fontSize: 16
      }
    },
    images: {
      background: theme.textwhite,
      color: theme.selectText,
      borderRadius: "3px",
      borderWidth: 2,
      borderColor: theme.selectText,
      marginBottom: 30,
      height: 28,
      maxWidth: 160,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: 5,
      paddingRight: 5,
      "& .anticon": {
        fontSize: 16
      },
      "&:hover": {
        borderColor: theme.selectText,
        color: theme.selectText
      },
      "&:focus": {
        borderColor: theme.selectText,
        color: theme.selectText
      },
      "&:active": {
        borderColor: theme.selectText,
        color: theme.selectText
      }
    },
    step: {
      marginLeft: 5,
      "& .ant-steps-item-title": {
        fontSize: "14px",
        color: `${theme.formLabelColor} !important`
      },
      "& .ant-steps-item-content": {
        width: "auto",
        marginBottom: "18px",
        minHeight: "auto"
      },
      "& .ant-steps-icon-dot": {
        left: "0px !important"
      },
      "& .ant-steps-item-icon": {
        marginRight: "14px",
        height: "10px !important",
        width: "10px !important"
      },
      "& .ant-steps-item-tail": {
        top: "16px !important",
        left: "-8px !important",
        padding: "0px !important",
        "&:after": {
          background: `${theme.formLabelColor} !important`,
          height: "31px !important"
        }
      },
      "& .ant-steps-item-wait": {
        "& .ant-steps-icon-dot": {
          left: "0px !important",
          background: "white !important",
          border: `1px solid ${theme.formLabelColor} !important`
        }
      }
    },
    divider: {
      width: "calc(100% - 20px)",
      height: 1,
      background: theme.selectText,
      marginBottom: 15
    },
    listHead: {
      fontSize: 14,
      color: theme.selectText,
      alignSelf: "flex-start",
      marginTop: 20,
      marginBottom: 10,
      marginLeft: 10
    },
    listContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    reportButton: {
      alignSelf: "center",
      height: 28,
      width: 160,
      borderRadius: 30,
      border: "1px solid",
      borderColor: theme.colorLight,
      fontSize: 14,
      marginBottom: 20,
      background: theme.colorGrey,
      textAlign: "center",
      overflow: "hidden",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      padding: "1px 5px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    greenReport: {
      background: theme.lightGreen
    }
  };
});

interface StepType {
  index: number;
  id: "top" | "image" | "findingsDiagnosis";
  title: string;
}

interface Props {
  caseImages: CaseImages[];
  handleImageModalVisibility: () => void;
  handleLinkModalVisibility: () => void;
  state: CaseData;
  hasSidebar: boolean;
  reportConfirmed: boolean;
  imageView: boolean;
  setImageView: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedReportId: React.Dispatch<React.SetStateAction<number>>;
  selectedReportId: number;
  editable: boolean;
}

const CaseSteps = ({
  caseImages,
  handleImageModalVisibility,
  handleLinkModalVisibility,
  state,
  hasSidebar,
  reportConfirmed,
  imageView,
  setImageView,
  setSelectedReportId,
  selectedReportId,
  editable
}: Props) => {
  const classes = useStyles({ hasSidebar });

  const { t } = useTranslation();

  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(0);

  const defaultTopStep = {
    index: 1,
    id: "top",
    title: t("TOP")
  };
  const [_steps, _setSteps] = useState([defaultTopStep] as StepType[]);
  const steps = useRef(_steps);
  const setSteps = (data: StepType[]) => {
    steps.current = data;
    _setSteps(data);
  };

  useEffect(() => {
    createSteps();
  }, [caseImages, state.formName]);

  const createSteps = () => {
    const newSteps = steps.current;
    if (caseImages.length !== 0) {
      if (!isStepPresent("image")) {
        const imageStep = {
          index: 2,
          id: "image",
          title: t("Image")
        } as StepType;
        newSteps.splice(1, 0, imageStep);
      }
    } else {
      newSteps.splice(1, 1);
    }
    if (state.formName === "ipfuip") {
      if (!isStepPresent("findingsDiagnosis")) {
        const findingsStep = {
          index: 3,
          id: "findingsDiagnosis",
          title: t("Diagnosis / Findings")
        } as StepType;
        newSteps.push(findingsStep);
      }
    } else if (state.formName === "general") {
      if (!isStepPresent("findingsDiagnosis")) {
        const findingsStep = {
          index: 3,
          id: "findingsDiagnosis",
          title: t("Diagnosis / Findings")
        } as StepType;
        newSteps.push(findingsStep);
      }
    } else {
      newSteps.filter((step: StepType) => {
        return step.id !== "findingsDiagnosis";
      });
    }
    newSteps.sort((step1: StepType, step2: StepType) => {
      return step1.index - step2.index;
    });
    setSteps([...newSteps]);
  };

  const getIndexOfStep = (id: string) => {
    return steps.current.findIndex((step: StepType) => {
      return step.id === id;
    });
  };

  const isStepPresent = (id: string) => {
    return steps.current.some((step: StepType) => {
      return step.id === id;
    });
  };

  const handleStepScroll = () => {
    if (checkRef("findingsDiagnosis")) {
      setCurrentStep(getIndexOfStep("findingsDiagnosis"));
      return;
    }
    if (checkRef("image")) {
      setCurrentStep(getIndexOfStep("image"));
      return;
    }
    if (checkRef("top")) {
      setCurrentStep(0);
    }
  };

  const debounceFunction = debounce((handleFunction: any) => {
    handleFunction();
    return null;
  }, 500);

  const handleScroll = () => {
    debounceFunction(handleStepScroll);
  };

  const onStepChange = (current: number) => {
    setCurrentStep(current);
    const view = document.getElementById(steps.current[current].id);

    if (view) {
      view.scrollIntoView({ behavior: "smooth" });
    }
  };

  const checkRef = (id: string) => {
    if (isStepPresent(id)) {
      const view = document.getElementById(id);
      const topView = document.getElementById("top");
      if (view && topView) {
        const rect = view.getBoundingClientRect();
        const topRect = topView.getBoundingClientRect();
        return (
          rect.top < 50 ||
          topRect.bottom <= document.documentElement.scrollHeight
        );
      }
    }
  };

  useEffect(() => {
    const mainContainer = document.getElementById(caseWrapperId);
    if (mainContainer) {
      mainContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      mainContainer?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isConsult = state.consultation;

  return (
    <div className={classes.stepsContainer}>
      {editable && (
        <Button
          className={classes.uploadImage}
          type="primary"
          block
          onClick={handleImageModalVisibility}
          disabled={reportConfirmed}
        >
          <UploadOutlined />
          {t("Upload Image")}
        </Button>
      )}
      {editable && (
        <Button
          className={classes.uploadImage}
          type="primary"
          block
          onClick={handleLinkModalVisibility}
          disabled={reportConfirmed}
        >
          <UploadOutlined />
          {t("Upload Link")}
        </Button>
      )}
      <Button
        className={classes.images}
        type="ghost"
        block
        onClick={() => {
          return setImageView(!imageView);
        }}
      >
        <PictureOutlined />
        {!imageView ? t("Images") : t("Back to report")}
      </Button>
      <div className={classes.divider} />
      {!imageView ? (
        <Steps
          progressDot
          direction="vertical"
          current={currentStep}
          className={classes.step}
          onChange={onStepChange}
        >
          {steps.current.map((step: StepType, index: number) => {
            return <Step title={step.title} key={index} />;
          })}
        </Steps>
      ) : (
        <div className={classes.listContainer}>
          <Typography className={classes.listHead}>
            {t("Attached to:")}
          </Typography>
          {state.allReports &&
            state.allReports.map((report: CaseReportList) => {
              return (
                <div
                  key={report.id}
                  onClick={() => {
                    return setSelectedReportId(report.id);
                  }}
                  className={`${classes.reportButton} ${
                    report.id === selectedReportId ? classes.greenReport : ""
                  }`}
                >
                  {(!isConsult && report.client_name
                    ? `${report.client_name} `
                    : "") + `${t("Report")} ${report.report_number}`}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export { CaseSteps };
