// ant.d と独自レイアウトを調整するshim
// とりあえずform用やけど、もう少し上の階層にあげてもいい。
import React from "react";
import { createUseStyles } from "react-jss";

let useStyles = createUseStyles((theme: any) => {
  return {
    container: (isConsult?: boolean) => {
      return {
        padding: "2px 40px",
        background: isConsult ? theme.greenBackground : theme.grayBlueBg,
        margin: "10px 0"
      };
    },
    plusHoldingContainer: {
      position: "relative"
    },
    plusIconHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 7,
      left: "-28px",
      borderRight: "2px solid #C9C9C9",
      height: 48,
      paddingRight: 14
    },
    plusIcon: {
      cursor: "pointer"
    },
    dropDown: {
      marginBottom: 12
    },
    cancel: {
      cursor: "pointer"
    },
    formTitleContainer: {
      paddingTop: "14px",
      paddingBottom: "14px"
    },
    formTitleContent: (isConsult?: boolean) => {
      return {
        margin: 0,
        padding: 0,
        color: isConsult ? theme.greenTitle : "#1A51CB",
        fontSize: "24px", // TODO: fontサイズ変更機能への対応
        lineHeight: "1em"
      };
    },
    divider: {
      boxSizing: "border-box",
      display: "flex",
      width: "100%",
      minWidth: "100%",
      margin: "10px 0",
      borderTop: "1px solid #f0f0f0"
    }
  };
});

interface Props {
  isConsult?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export function Container({ isConsult, children }: Props) {
  const classes = useStyles(isConsult);
  return <div className={classes.container}>{children}</div>;
}

export function FormTitleContainer({ isConsult, children, className }: Props) {
  const classes = useStyles(isConsult);
  return (
    <div className={`${classes.formTitleContainer} ${className}`}>
      {children}
    </div>
  );
}
export function FormTitleContent({ isConsult, children, className }: Props) {
  const classes = useStyles(isConsult);
  return <div className={classes.formTitleContent}>{children}</div>;
}
export function FormTitle({ isConsult, children, className }: Props) {
  return (
    <FormTitleContainer className={className}>
      <FormTitleContent isConsult={isConsult}>{children}</FormTitleContent>
    </FormTitleContainer>
  );
}
export function FormDivider() {
  const classes = useStyles();
  return <div className={classes.divider}></div>;
}
