import axios, { AxiosInstance } from "axios";

import { firebase } from "../firebase";
import { LocalStorageService } from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

/**
 * axios instance
 */
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" }
});

let mockAPI: AxiosInstance;

if (process.env.REACT_APP_MOCK_API_URL && process.env.REACT_APP_MOCK_API_KEY) {
  mockAPI = axios.create({
    baseURL: process.env.REACT_APP_MOCK_API_URL,
    headers: {
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_MOCK_API_KEY
    }
  });
}

// request header
API.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  response => {
    return response.data;
  },
  err => {
    return new Promise(async (resolve, reject) => {
      const originalReq = err.config;
      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config._retry
      ) {
        originalReq._retry = true;

        const user = firebase.auth().currentUser;
        if (user) {
          try {
            const idTokenResult = await user.getIdTokenResult(true);
            const idToken = idTokenResult.token;
            localStorageService.setAccessToken(idToken);

            originalReq.headers["Authorization"] = "Bearer " + idToken;
          } catch (error) {
            reject(error);
            return;
          }
          resolve(axios(originalReq));
          return;
        }
      }
      reject(err);
    });
  }
);

export { API, mockAPI };
