import { RadioChangeEvent } from "antd/lib/radio";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import { API, AuthContext, exceptionHandler, Loader } from "shared-components";

import { ComponentWrapper } from "../molecules/ComponentWrapper";
import { CaseLibraryBreadcrumb } from "./CaseLibraryBreadcrumb";
import { CaseLibraryFilter } from "./CaseLibraryFilter";
import { CaseLibraryTable } from "./CaseLibraryTable";
import { CaseMenu } from "./CaseMenu";
import { ChangeFolderNameModal } from "./ChangeFolderNameModal";
import { CreateCaseButton } from "./CreateCaseButton";
import { CreateFolderButton } from "./CreateFolderButton";
import { FolderDeleteConfirm } from "./FolderDeleteConfirm";
import styles from "./index.module.css";
import { MoveToFolderModal } from "./MoveToFolderModal";
import { Spacer } from "./Spacer";

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      padding: "30px 120px 7px 21px"
    },
    menu: { fontSize: "24px" }
  };
});

const CaseLibraryPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { defaultTeam } = useContext(AuthContext);
  const history = useHistory();
  let { id }: any = useParams();
  const [loading, setLoading] = useState<boolean>(false);

  const [breadcrumbIds, setBreadcrumbIds] = useState<BreadcrumbItem[]>([
    {
      id: 0,
      name: "Case Folder"
    }
  ]);

  const [filterValues, setFilterValue] = useState<FilterState>({
    search: "",
    from: "",
    to: "",
    target: "current"
  });

  const [visibleCreateFolder, setVisibleCreateFolder] = useState(false);
  const [visibleCreateCase, setVisibleCreateCase] = useState(false);

  const [rows, setRows] = useState<CaseLibraryTableRowData[]>([]);
  const [pagination, setPagination] = useState<PaginationData>({
    page: 1,
    pageSize: 20,
    total: 5
  });
  const [currentFolder, setCurrentFolder] = useState({
    visible: false,
    folder_id: -1,
    name: "",
    parent_folder_id: -1
  });

  const [folderToChangeName, setFolderToChangeName] = useState({
    visible: false,
    folder_id: 0,
    parent_folder_id: 0
  });
  const [folderToMove, setFolderToMove] = useState({
    visible: false,
    folder_id: 0
  });
  const [caseToMove, setCaseToMove] = useState({
    visible: false,
    case_id: 0
  });

  const handlePostCaseFolder = async (
    name: string,
    parent_folder_id: number
  ) => {
    setLoading(true);
    try {
      await API.post(`case-folders`, {
        team_id: defaultTeam,
        name: name,
        parent_folder_id: parent_folder_id
          ? parent_folder_id
          : currentFolder.folder_id
      });
      setLoading(false);
      handleGetCaseFolderChildren();
      setVisibleCreateFolder(false);
    } catch (error) {
      setLoading(false);
      setVisibleCreateFolder(false);
      exceptionHandler(error, t);
    }
  };

  const handleGetCaseFolderChildren = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(
        `case-folders/${currentFolder.folder_id}/children`,
        {
          params: {
            team_id: defaultTeam,
            page: pagination.page,
            pageSize: pagination.pageSize
          }
        }
      );
      if (
        response &&
        response.data &&
        response.data.children &&
        response.data.children.length &&
        response.data.ancestors
      ) {
        const newResponse: CaseLibraryTableRowData[] = response.data.children.map(
          (child: any, ind: number) => {
            const data = child.data;
            if (child.type === "case" && data) {
              return {
                key: ind,
                type: child.type,
                id: data.id,
                pathology_number: data.pathology_number,
                medical_record_id: data.report.medical_record_id,
                patient_name: data.report.patient_name,
                insurance_type: data.report.diagnosis_request_id
                  ? t("Consultation")
                  : t("Pathological diagnosis"),
                diagnosis_request_id: data.report.diagnosis_request_id,
                organs: data.report.organs
                  ? data.report.organs
                      .split(",")
                      .map((organ: string) => {
                        return t(
                          organ
                            .toLowerCase()
                            .charAt(0)
                            .toUpperCase() + organ.toLowerCase().slice(1)
                        );
                      })
                      .join(" ")
                  : "",
                created_at: data.report.created_at,
                confirmed_at: data.report.confirmed_at
              };
            }
            return {
              key: ind,
              type: child.type,
              id: data.id,
              parent_folder_id: response.data.folder_id,
              pathology_number: data.name,
              medical_record_id: "",
              patient_name: "",
              diagnosis_request_id: null,
              organs: "",
              created_at: data.created_at,
              confirmed_at: ""
            };
          }
        );
        newResponse && setRows(newResponse);
        newResponse && setPagination({ ...pagination, total: response.count });
      } else {
        setLoading(false);
        setRows([]);
      }
      if (
        response &&
        response.data &&
        response.data.ancestors &&
        currentFolder.folder_id
      ) {
        const newBreadCrumbItems = response.data.ancestors.concat({
          id: currentFolder.folder_id,
          name: currentFolder.name
        });
        setBreadcrumbIds(
          [
            {
              id: 0,
              name: "Case Folder"
            }
          ].concat(newBreadCrumbItems)
        );
      } else {
        setBreadcrumbIds([
          {
            id: 0,
            name: "Case Folder"
          }
        ]);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setRows([]);
      exceptionHandler(error, t);
    }
  };

  const handleDateRangeChange = (values: RangeValue<dayjs.Dayjs>) => {
    if (values) {
      const from = values[0];
      const to = values[1];
      from &&
        to &&
        setFilterValue({
          ...filterValues,
          from: from.format("YYYY-MM-DD"),
          to: to.format("YYYY-MM-DD")
        });
    } else {
      setFilterValue({
        ...filterValues,
        from: "",
        to: ""
      });
    }
  };

  const handleGetCaseFolderSearch = async (
    search: string,
    from: string,
    to: string,
    target: string
  ) => {
    if (currentFolder.folder_id < 0) {
      return;
    }
    if ((search + from + to).length === 0) {
      handleGetCaseFolderChildren();
      return;
    }
    setLoading(true);
    try {
      const response: any = await API.get(
        `case-folders/${
          target === "current" ? currentFolder.folder_id : 0
        }/search`,
        {
          params: {
            team_id: defaultTeam,
            page: pagination.page,
            pageSize: pagination.pageSize,
            from: from ? from + "" : "",
            to: to ? to + "T23:59:59" : "",
            search: search
          }
        }
      );
      if (
        response &&
        response.data &&
        response.data.matched_items &&
        response.data.matched_items.length
      ) {
        const newResponse: CaseLibraryTableRowData[] = response.data.matched_items.map(
          (child: any, ind: number) => {
            const data = child.data;
            if (child.type === "case" && data) {
              return {
                key: ind,
                type: child.type,
                id: data.id,
                pathology_number: data.pathology_number,
                medical_record_id: data.report.medical_record_id,
                patient_name: data.report.patient_name,
                insurance_type: data.report.diagnosis_request_id
                  ? t("Consultation")
                  : t("Pathological diagnosis"),
                diagnosis_request_id: data.report.diagnosis_request_id,
                organs: data.report.organs
                  ? data.report.organs
                      .split(",")
                      .map((organ: string) => {
                        return t(
                          organ
                            .toLowerCase()
                            .charAt(0)
                            .toUpperCase() + organ.toLowerCase().slice(1)
                        );
                      })
                      .join(" ")
                  : "",
                created_at: data.report.created_at,
                confirmed_at: data.report.confirmed_at
              };
            }
            return {
              key: ind,
              type: child.type,
              id: data.id,
              parent_folder_id: response.data.folder_id,
              pathology_number: data.name,
              medical_record_id: "",
              patient_name: "",
              diagnosis_request_id: null,
              organs: "",
              created_at: data.created_at,
              confirmed_at: ""
            };
          }
        );
        newResponse && setRows(newResponse);
        newResponse && setPagination({ ...pagination, total: response.count });
        setLoading(false);
      } else {
        setLoading(false);
        setRows([]);
      }
    } catch (error) {
      setLoading(false);
      setRows([]);
      exceptionHandler(error, t);
    }
  };

  const handleOnChangeFilterKeyword = (
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    event && setFilterValue({ ...filterValues, search: event.target.value });
  };

  const handleOnChangeFilterScope = (event: RadioChangeEvent) => {
    setFilterValue({
      ...filterValues,
      target: event.target.value
    });
    setPagination({ ...pagination, page: 1 });
  };

  const handleOnClearAll = () => {
    setFilterValue({
      search: "",
      from: "",
      to: "",
      target: "current"
    });
  };

  useEffect(() => {
    const idAndName = id.split("+");
    if (idAndName.length > 1) {
      id !== null &&
        setCurrentFolder({
          ...currentFolder,
          folder_id: parseInt(idAndName[0]),
          name: idAndName.slice(1).join("+")
        });
    } else {
      id !== null &&
        setCurrentFolder({
          ...currentFolder,
          folder_id: parseInt(id),
          name: ""
        });
    }
  }, [id]);

  useEffect(() => {
    if (
      defaultTeam &&
      currentFolder.folder_id >= 0 &&
      filterValues.from.length === 0 &&
      filterValues.to.length === 0 &&
      filterValues.search.length === 0
    ) {
      handleGetCaseFolderChildren();
    } else if (defaultTeam && currentFolder.folder_id >= 0) {
      handleGetCaseFolderSearch(
        filterValues.search,
        filterValues.from,
        filterValues.to,
        filterValues.target
      );
    } else {
      history.push("/case-library/0");
    }
  }, [defaultTeam, currentFolder, pagination.page, filterValues]);

  const handleOpenChangeFolderNameModal = (folder_id: number) => {
    const parent_folder_id = rows.find((row: CaseLibraryTableRowData) => {
      return row.id === folder_id;
    })?.parent_folder_id;
    setFolderToChangeName({
      visible: true,
      folder_id: folder_id,
      parent_folder_id: parent_folder_id ? parent_folder_id : 0
    });
  };

  const handleChangeFolderName = async (
    name: string,
    folder_id: number,
    parent_folder_id: number
  ) => {
    setLoading(true);
    try {
      await API.put(`case-folders/${folder_id}`, {
        name: name,
        parent_folder_id: parent_folder_id
      });
      setLoading(false);
      setFolderToChangeName({
        ...currentFolder,
        visible: false
      });
      currentFolder.folder_id === folder_id
        ? history.push(`/case-library/${folder_id}+${name}`)
        : handleGetCaseFolderChildren();
    } catch (error) {
      setLoading(false);
      setFolderToChangeName({
        ...currentFolder,
        visible: false
      });
      exceptionHandler(error, t);
    }
  };

  const handleOpenMoveFolderModal = (folder_id: number) => {
    setFolderToMove({
      visible: true,
      folder_id: folder_id
    });
  };

  const handleOpenMoveCaseModal = (case_id: number) => {
    setCaseToMove({
      visible: true,
      case_id: case_id
    });
  };

  const handleMoveCaseToFolder = async (
    case_id: number,
    parent_folder_id: number
  ) => {
    setLoading(true);
    try {
      await API.put(`cases/${case_id}/move`, {
        parent_folder_id: parent_folder_id
      });
      setLoading(false);
      handleGetCaseFolderChildren();
      setCaseToMove({ ...caseToMove, visible: false });
    } catch (error) {
      setLoading(false);
      setCaseToMove({ ...caseToMove, visible: false });
      exceptionHandler(error, t);
    }
  };

  const handleMoveFolderToFolder = async (
    folder_id: number,
    parent_folder_id: number
  ) => {
    setLoading(true);
    try {
      await API.put(`case-folders/${folder_id}`, {
        parent_folder_id: parent_folder_id
      });
      setLoading(false);
      handleGetCaseFolderChildren();
      setFolderToMove({ ...folderToMove, visible: false });
    } catch (error) {
      setLoading(false);
      setFolderToMove({ ...folderToMove, visible: false });
      exceptionHandler(error, t);
    }
  };

  const handleMoveToFolder = (id: number, name: string) => {
    id && history.push(`/case-library/${id}+${name}`);
  };

  const handleDeleteFolder = async (id: number) => {
    try {
      await API.delete(`case-folders/${id}`);
      setLoading(false);
      handleGetCaseFolderChildren();
      setVisibleCreateFolder(false);
    } catch (error) {
      setLoading(false);
      setVisibleCreateFolder(false);
      exceptionHandler(error, t);
    }
  };

  const handleDeleteCurrentFolder = async () => {
    try {
      await API.delete(`case-folders/${currentFolder.folder_id}`);
      setLoading(false);
      handleMoveToFolder(currentFolder.parent_folder_id, currentFolder.name);
      setVisibleCreateFolder(false);
    } catch (error) {
      setLoading(false);
      setVisibleCreateFolder(false);
      exceptionHandler(error, t);
    }
  };

  const breadcrumbItems = breadcrumbIds
    ? breadcrumbIds.map(
        (breadcrumbId: { id: number; name: string }, index: number) => {
          if (index + 1 === breadcrumbIds.length) {
            const actionMenuItem = [
              {
                name: "Change Name",
                onClick: () => {
                  handleOpenChangeFolderNameModal(breadcrumbId.id);
                },
                deviderUpon: false,
                startIcon: null
              },
              {
                name: "Move",
                onClick: () => {
                  handleOpenMoveFolderModal(breadcrumbId.id);
                },
                deviderUpon: false,
                startIcon: null
              },
              {
                name: "Delete",
                onClick: () => {
                  FolderDeleteConfirm(handleDeleteCurrentFolder);
                },
                deviderUpon: true,
                startIcon: null
              }
            ];
            return {
              id: breadcrumbId.id,
              name: breadcrumbId.name,
              to: `/case-library`,
              overlay: <CaseMenu visible={true} items={actionMenuItem} />,
              startIcon: ""
            };
          }
          return {
            id: breadcrumbId.id,
            name: breadcrumbId.name,
            to: `/case-library`,
            overlay: null,
            startIcon: ""
          };
        }
      )
    : [];

  return (
    <ComponentWrapper sidebar className={classes.componentWrapper}>
      <div className={styles.titleheader}>
        <ul>
          <li>
            <CaseLibraryBreadcrumb
              onClickFolder={handleMoveToFolder}
              items={breadcrumbItems}
            />
          </li>
          <li>
            <CreateFolderButton
              visibility={visibleCreateFolder}
              setVisibility={setVisibleCreateFolder}
              onSubmit={handlePostCaseFolder}
            />
          </li>
          <span style={{ float: "right", padding: "5px" }} />
          <li>
            <CreateCaseButton
              visibility={visibleCreateCase}
              setVisibility={setVisibleCreateCase}
              moveCase={handleMoveCaseToFolder}
              currentFolderId={currentFolder.folder_id}
            />
          </li>
        </ul>
      </div>
      <Spacer padding={"13px"} />
      <CaseLibraryFilter
        onChangeKeyword={handleOnChangeFilterKeyword}
        onChangeDateRange={handleDateRangeChange}
        onChageFilterScope={handleOnChangeFilterScope}
        onClearAll={handleOnClearAll}
        filterValues={filterValues}
      />
      <Spacer padding={"13px"} />
      {loading ? (
        <Loader />
      ) : (
        <CaseLibraryTable
          rows={rows}
          onClickFolder={handleMoveToFolder}
          deleteFolder={(id: number) => {
            return FolderDeleteConfirm(handleDeleteFolder, id);
          }}
          changeFolderName={handleOpenChangeFolderNameModal}
          moveFolder={handleOpenMoveFolderModal}
          moveCase={handleOpenMoveCaseModal}
          pagination={pagination}
          onChangePagination={(page: any) => {
            setPagination({ ...pagination, page: page });
          }}
        />
      )}
      <ChangeFolderNameModal
        visible={folderToChangeName.visible}
        onSubmit={handleChangeFolderName}
        folder_id={folderToChangeName.folder_id}
        parent_folder_id={folderToChangeName.parent_folder_id}
        onCancel={() => {
          setFolderToChangeName({
            ...folderToChangeName,
            visible: false
          });
        }}
      />
      <MoveToFolderModal
        visible={caseToMove.visible}
        onSubmitCase={handleMoveCaseToFolder}
        case_id={caseToMove.case_id}
        onCancel={() => {
          setCaseToMove({
            ...caseToMove,
            visible: false
          });
        }}
      />
      <MoveToFolderModal
        visible={folderToMove.visible}
        onSubmitFolder={handleMoveFolderToFolder}
        folder_id={folderToMove.folder_id}
        onCancel={() => {
          setFolderToMove({
            ...folderToMove,
            visible: false
          });
        }}
      />
    </ComponentWrapper>
  );
};

export { CaseLibraryPage };
