import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Button,
  Container,
  exceptionHandler,
  firebase,
  i18n,
  Logo,
  TextField,
  Typography,
  Wrapper
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      margin: "auto",
      marginBottom: 25,
      width: 243,
      "& div:first-child": {
        color: theme.errorYellow,
        display: "span"
      }
    },
    row: {
      marginTop: 45
    },
    buttonDiv: {
      marginTop: 29
    },
    button: {
      borderRadius: 26,
      width: 145,
      height: 28,
      background: theme.textYellow,
      color: theme.textPrimary
    },
    textField: {
      padding: "0px 6px 0px 6px",
      background: "transparent",
      "& input": {
        background: "transparent"
      },
      border: "1px solid #FFFFFF"
    },
    forgotPassword: {
      color: theme.textWhite,
      textAlign: "center"
    },
    bottomLogo: {
      position: "fixed",
      bottom: "3%",
      left: "50%",
      transform: "translate(-50%)"
    },
    text: {
      color: theme.textWhite,
      marginBottom: 0,
      letterSpacing: "0.25px",
      lineHeight: "20px"
    },
    textWrapper: {
      maxWidth: 312,
      display: "flex"
    },
    linkText: {
      color: theme.textWhite,
      marginBottom: 0,
      letterSpacing: "0.25px",
      cursor: "pointer",
      lineHeight: "20px",
      "&:hover": {
        color: theme.textWhite,
        textDecoration: "underline"
      }
    }
  };
});

const PasswordReset = () => {
  const classes = useStyles();
  const [state, setState] = useState({ email: "" });
  const [done, setDone] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleReset = async () => {
    setLoading(true);
    const auth = firebase.auth();
    const { email } = state;
    try {
      firebase.auth().languageCode = i18n.language;
      const token = await executeRecaptcha!("userPasswordReset");
      const recaptchaData: any = await API.post(`verify-recaptcha`, {
        response: token
      });
      if (
        recaptchaData &&
        recaptchaData.data &&
        recaptchaData.data.score > 0.5
      ) {
        auth
          .sendPasswordResetEmail(email)
          .then(() => {
            setDone(true);
          })
          .catch(error => {
            setLoading(false);
            exceptionHandler(error, t);
          });
      } else {
        Alert(
          "error",
          "error",
          t("Unable to pass recaptcha verification. Please try again later."),
          t
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  return (
    <Container center>
      <Wrapper>
        <Logo />
        {done ? (
          <>
            <Row gutter={8} className={classes.row}>
              <Col span={24}>
                <div className={classes.textWrapper}>
                  <Typography type="body3" className={classes.text}>
                    {t(
                      "If you have an Porous account, you’ll receive instructions for resetting your password at"
                    )}{" "}
                    {state.email}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={8} className={classes.row}>
              <Col span={24}>
                <Typography
                  center
                  type="link2"
                  href="/sign-in"
                  className={classes.linkText}
                >
                  {t("Back to top")}
                </Typography>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={8} className={classes.row}>
              <Col span={24}>
                <div className={classes.textWrapper}>
                  <Typography type="body3" className={classes.text}>
                    {t(
                      "Enter the email associated with your account, we’ll send you instructions to reset your password."
                    )}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row gutter={8} className={classes.row}>
              <Col span={24}>
                <Form onFinish={handleReset}>
                  <div className={classes.formItem}>
                    <Form.Item
                      id="email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: t("Not a valid email")
                        },
                        {
                          required: true,
                          message: t("Required")
                        },
                        {
                          max: 60,
                          message: t("Maximum limit has exceeded")
                        }
                      ]}
                    >
                      <TextField
                        name="email"
                        className={classes.textField}
                        placeholder={t("Email")}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className={classes.buttonDiv}>
                    <Form.Item>
                      <Button
                        size="small"
                        htmlType="submit"
                        loading={loading}
                        type="primary"
                        className={classes.button}
                      >
                        {t("Submit")}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Wrapper>
      <div className={classes.bottomLogo}>
        <Logo logo="bonbon" />
      </div>
    </Container>
  );
};

export { PasswordReset };
