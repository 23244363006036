import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  imageSrc?: any;
  title: string;
  disabled?: boolean;
  className?: string;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center"
    },
    image: {
      height: "12px"
    },
    text: {
      marginLeft: 10,
      fontSize: 18,
      lineHeight: "21px",
      color: ({ disabled }: Props) => {
        return disabled ? theme.colorDisabled : theme.textBlack;
      },
      fontWeight: ({ selected }: Props) => {
        return selected ? "500" : "300";
      },
      textDecoration: "none"
    },
    icon: {
      fill: ({ disabled }: Props) => {
        return disabled ? theme.colorDisabled : theme.textBlue;
      }
    }
  };
});

const FilterLink: React.FC<Props> = props => {
  const classes = useStyles(props);
  return (
    <div
      className={`${classes.container} ${props.className}`}
      onClick={props.onClick}
    >
      {props.imageSrc && <props.imageSrc className={classes.icon} />}
      <span className={classes.text}>{props.title}</span>
    </div>
  );
};

export { FilterLink };
