import { Typography } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { createUseStyles } from "react-jss";
import { SelectDateComponent } from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    label: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    },
    fieldWrapper: {
      maxWidth: 251
    }
  };
});

export default function({
  name,
  label,
  control,
  defaultValue,
  onChange,
  className,
  disable
}: DatePickerProps) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Typography.Text className={classes.label}>{label}</Typography.Text>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange: rendererOnChange, value }) => {
          return (
            <SelectDateComponent
              disabled={disable}
              className={classes.fieldWrapper}
              value={value}
              onChange={value => {
                rendererOnChange(value?.format());
                onChange(value?.format());
              }}
            />
          );
        }}
      />
    </div>
  );
}
