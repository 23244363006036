import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import { API, exceptionHandler, Loader } from "shared-components";

import { ComponentWrapper } from "../../../molecules/ComponentWrapper";
import { CaseDoubleCheck } from "../../../organisms/DoubleCheck";
import { CaseFormValue } from "../../../organisms/RenderForm";
import { caseTagMap } from "../../../utils";
import Report from "./Report";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      background: theme.background,
      paddingTop: 100,
      paddingBottom: 100,
      alignItems: "center"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    wrapper: {
      display: "flex",
      background: theme.textWhite,
      marginLeft: 125,
      width: "calc(100% - 250px)",
      minWidth: "550px",
      flexDirection: "column",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      "& .ant-radio-wrapper": {
        fontSize: "16px",
        lineHeight: "19px",
        fontFamily: "Helvetica Neue",
        color: "#1F1F1F"
      }
    }
  };
});

export const PublicReport = ({ errorBoundary }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { reportCode }: any = useParams();

  const [loading, setLoading] = useState(true as boolean);
  const [state, setState] = useState({} as CaseData);
  const [formValues, setFormValues] = useState([] as CaseFormValue[]);
  const [caseDoubleCheck, setCaseDoubleCheck] = useState(
    null as CaseDoubleCheck | null
  );
  const [caseImages, setCaseImages] = useState([] as CaseImages[]);
  const caseImageRef = useRef(caseImages);

  const [tagsLoading, setTagsLoading] = useState(false as boolean);
  const [caseTags, setCaseTags] = useState([] as CaseTag[]);

  const [linkLoading, setLinkLoading] = useState(false as boolean);
  const [caseLinks, _setCaseLinks] = useState([] as CaseLink[]);
  const caseLinksRef = useRef(caseLinks);
  const setCaseLinks = (data: CaseLink[]) => {
    caseLinksRef.current = data;
    _setCaseLinks(data);
  };

  useEffect(() => {
    if (reportCode) {
      getCase();
    }
  }, [reportCode]);

  const getCase = async (reportID?: number) => {
    setLoading(true);
    try {
      const response = await API.get(`cases/${reportCode}`, {
        params: {
          public: true
        }
      });
      setCaseData(response);
    } catch (error) {
      exceptionHandler(error, t);
      errorBoundary(error);
    }
    setLoading(false);
  };

  const setCaseData = (response: any) => {
    if (!response || !response.data) {
      return;
    }
    const { report, ...caseData } = response.data;
    const { form_values, double_check, case_images, ...reportData } = report;

    setState({
      id: caseData.id,
      reportId: reportData.id,
      reportNumber: report.report_number,
      createDateTime: caseData.created_at,
      caseNumber: caseData.pathology_number,
      patientName: reportData.patient_name,
      birthday: reportData.birthday,
      age: reportData.age,
      sex: reportData.sex,
      occupation: reportData.occupation,
      formName: reportData.form_name,
      pathologist: reportData.pathologist_user_id,
      completedAt: reportData.confirmed_at,
      myBookmark: caseData.user_mark,
      marked: caseData.marked,
      teamName: reportData.team_name,
      teamId: caseData.team_id,
      hospital: reportData.hospital,
      clinician: reportData.clinician,
      remarks: reportData.remarks,
      numberOfSpecimen: reportData.number_of_specimen,
      newMedicalRecordId: reportData.medical_record_id,
      registrationDate: reportData.registration_date,
      dateOfCollection: reportData.date_of_collection,
      organs: reportData.organs,
      organOtherText: reportData.organ_other_text,
      confirmedAt: reportData.confirmed_at,
      allReports: caseData.all_reports,
      consultant: reportData.consult_user_id.String,
      consultHospital: reportData.consult_hospital,
      consultNumber: reportData.consult_number,
      consultantDoubleCheckName: reportData.consultant_double_check_name,
      diagnosisRequestId: reportData.diagnosis_request_id,
      consultation: reportData.consultation,
      pathologistName: reportData.pathologist_name,
      consultantName: reportData.consultant_name
    });

    // setSelectedReportId(reportData.id);

    if (form_values.length > 0) {
      const formValues = form_values.map((value: any) => {
        return {
          reportID: value.report_id,
          name: value.name,
          value: value.value
        };
      });
      setFormValues([...formValues]);
    } else {
      setFormValues([]);
    }

    if (
      double_check &&
      !double_check.confirmed_at &&
      !double_check.change_requested_at
    ) {
      setDoubleCheckData(double_check);
    } else {
      setDoubleCheckData(null);
    }

    if (case_images && case_images.length > 0) {
      const caseImages = case_images.map((value: any) => {
        return {
          id: value.id,
          url: value.thumbnail,
          name: value.name,
          dataUrl: "",
          organ: value.organ,
          part: value.part,
          staining: value.staining,
          slide_id: value.slide_id,
          Image: {
            Format: value.format,
            Overlap: value.overlap,
            Size: {
              Height: value.height,
              Width: value.width
            },
            TileSize: value.tile_size,
            Url: value.image_url
          }
        };
      });
      setCaseImagesRef([...caseImages]);
    } else {
      setCaseImagesRef([]);
    }
  };

  const setDoubleCheckData = (value: any) => {
    if (value === null) {
      setCaseDoubleCheck(null);
      return;
    }
    setCaseDoubleCheck({
      reportId: value.report_id,
      completedAt: value.confirmed_at,
      changeRequestedAt: value.change_requested_at,
      id: value.id,
      userId: value.user_id
    });
  };

  const setCaseImagesRef = async (data: any, handleFunction?: any) => {
    caseImageRef.current = data;
    setCaseImages(data);
    for (const item of data) {
      let url;
      if (item.url && !item.dataUrl) {
        url = await API.get("/getDataURL", {
          params: {
            url: item.url
          }
        });
        item.dataUrl = url.data;
      }
    }
    if (handleFunction) {
      handleFunction();
    }
    setCaseImages(data);
  };

  const currentMember = {
    ProfileImage: "",
    color: "",
    created_at: "",
    email: "",
    id: state.pathologist,
    language: "",
    name: state.pathologistName,
    updated_at: "",
    team_user_id: 0
  };

  const getCaseTags = async () => {
    setTagsLoading(true);
    try {
      const tagsResponse = await API.get(`reports/${state.reportId}/tags`, {
        params: {
          public: "true"
        }
      });
      if (tagsResponse && tagsResponse.data && tagsResponse.data.length) {
        const caseTagData: CaseTag[] = [];

        tagsResponse.data.map(async (tag: any, index: number) => {
          const imageDataUrl = await API.get("/getDataURL", {
            params: {
              url: tag.image_url
            }
          });
          tag.imageDataUrl = imageDataUrl.data;
          caseTagData.push(caseTagMap(tag));
          if (index === tagsResponse.data.length - 1) {
            setCaseTags([...caseTags, ...caseTagData]);
          }
        });
      } else {
        setCaseTags([]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setTagsLoading(false);
  };

  const getCaseLinks = async () => {
    setLinkLoading(true);
    try {
      const linksResponse = await API.get(`reports/${state.reportId}/links`, {
        params: {
          public: "true"
        }
      });
      if (linksResponse && linksResponse.data && linksResponse.data.length) {
        const caseLinkData: CaseLink[] = [];

        linksResponse.data.map(async (link: any, index: number) => {
          caseLinkData.push(caseLinkMap(link));
          if (index === linksResponse.data.length - 1) {
            setCaseLinks([...caseLinkData]);
          }
        });
      } else {
        setCaseLinks([]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLinkLoading(false);
  };
  const caseLinkMap = (link: any) => {
    return {
      id: link.id,
      url: link.url,
      name: link.name
    };
  };

  useEffect(() => {
    if (state.reportId) {
      getCaseTags();
      getCaseLinks();
    }
  }, [state.reportId]);

  if (loading) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <ComponentWrapper className={classes.container}>
      <div className={classes.wrapper}>
        <Report
          state={state}
          hasSidebar={false}
          caseDoubleCheck={caseDoubleCheck}
          setState={setState}
          caseImageRef={caseImageRef}
          setCaseImagesRef={setCaseImagesRef}
          caseId={state.id}
          formValues={formValues}
          allMembers={[currentMember]}
          setSaving={() => {
            return null;
          }}
          setCaseData={setCaseData}
          setImageView={() => {
            return null;
          }}
          publicReport
          caseTags={caseTags}
          tagsLoading={tagsLoading}
          setTagsLoading={setTagsLoading}
          getCaseTags={getCaseTags}
          linkLoading={linkLoading}
          caseLinks={caseLinks}
        />
      </div>
    </ComponentWrapper>
  );
};
