import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  exceptionHandler,
  PdfContext,
  SelectComponent
} from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import { findingOptions } from "./formOptions";
import { FormTitle } from "./Layout";

let useStyles = createUseStyles((theme: any) => {
  return {
    container: (isConsult: boolean) => {
      return {
        padding: "8px 45px 35px 55px",
        background: isConsult ? theme.greenBackground : theme.grayBlueBg
      };
    },
    plusHoldingContainer: {
      position: "relative"
    },
    plusIconHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 7,
      left: "-28px",
      borderRight: "2px solid #C9C9C9",
      height: 48,
      paddingRight: 14
    },
    plusIcon: {
      cursor: "pointer"
    },
    dropDown: {
      marginBottom: 12
    },
    cancel: {
      cursor: "pointer"
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    selectWrapper: {
      maxWidth: 200
    },
    select: {
      "& .ant-select": {
        marginBottom: 0
      },
      "& .ant-select-selector": {
        height: "29px !important",
        background: "none !important",
        borderColor: `${theme.selectText} !important`
      },
      " & .ant-select-arrow": {
        color: `${theme.selectText} !important`
      }
    },
    showData: {
      marginBottom: 15
    }
  };
});

interface Option {
  [key: string]: string;
}

interface Props {
  reportId: number;
  formValues: Option;
  handleChange: (name: string, value: string) => any;
  hasSidebar: boolean;
  setCaseData: (response: any) => void;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}

const Findings = ({
  reportId,
  formValues,
  handleChange,
  hasSidebar,
  setCaseData,
  setSaving,
  isConsult,
  publicReport,
  readOnly
}: Props) => {
  const { pdfLoading } = useContext(PdfContext);
  const classes = useStyles(isConsult);
  const { t } = useTranslation();

  const [accentuationCount, setAccentuation] = useState(false as boolean);
  const [airwayCount, setAirway] = useState([] as string[]);

  const increaseAccentuation = () => {
    setAccentuation(true);
  };

  const decreaseAccentuation = () => {
    setAccentuation(false);
    handleChange("accentuation_2", "");
  };

  const increaseAirway = () => {
    if (airwayCount.length === 2) {
      return;
    }
    if (airwayCount.includes("airway_disease_2")) {
      if (!airwayCount.includes("airway_disease_3"))
        airwayCount.push("airway_disease_3");
    } else {
      airwayCount.push("airway_disease_2");
    }
    setAirway([...airwayCount]);
  };

  const decreaseAirway = (name: string) => {
    let index = airwayCount.indexOf(name);
    if (index !== -1) airwayCount.splice(index, 1);
    setAirway([...airwayCount]);
    handleChange(name, "");
  };

  useEffect(() => {
    const field: string[] = [];
    [2, 3].forEach(item => {
      if (formValues[`airway_disease_${item}`]) {
        if (!airwayCount.includes(`airway_disease_${item}`)) {
          field.push(`airway_disease_${item}`);
          setAirway([...airwayCount, ...field]);
        }
      }
    });
    if (formValues.accentuation_2) {
      setAccentuation(true);
    }
  }, [formValues]);

  const handleFormSelect = async (name: string, value: string) => {
    setSaving(true);
    try {
      const response = await API.post("/form-name-update", {
        report_id: reportId,
        form_name: value
      });
      setCaseData(response);
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  const findLabel = (field: any, value: string) => {
    const options: any = findingOptions;
    const returnVal = options[field].filter((item: any) => {
      return item.value === value;
    });
    return returnVal[0] ? returnVal[0].label : "";
  };

  const readOnlyView = readOnly || publicReport || hasSidebar;

  return (
    <div className={classes.container} id="findings">
      <div className={classes.titleContainer}>
        <FormTitle isConsult={isConsult}>{t("Findings")}</FormTitle>
        {!publicReport && (
          <SelectComponent
            wrapperClassName={classes.selectWrapper}
            className={classes.select}
            options={[
              {
                label: t("IPF/UIP"),
                value: "ipfuip"
              },
              {
                label: t("General"),
                value: "general"
              },
              {
                label: t("Colorectal"),
                value: "colorectal"
              }
            ]}
            name="form_name"
            placeholder={t("Select Form")}
            defaultValue="ipfuip"
            onChange={handleFormSelect}
            size="small"
            id="caseFormSelect"
          />
        )}
      </div>
      {readOnlyView ? (
        <>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Quality of Specimen")}
                value={findLabel(
                  "quality_of_specimen",
                  formValues.quality_of_specimen
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Dense fibrosis")}
                value={findLabel("dense_fibrosis", formValues.dense_fibrosis)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Loose fibrosis")}
                value={findLabel("loose_fibrosis", formValues.loose_fibrosis)}
              />
            </Col>
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Architectural destruction")}
                value={findLabel(
                  "architectural_destruction",
                  formValues.architectural_destruction
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Honeycombing")}
                value={findLabel("honeycombing", formValues.honeycombing)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Fibroblastic focus")}
                value={findLabel(
                  "fibroblastic_focus",
                  formValues.fibroblastic_focus
                )}
              />
            </Col>
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Distribution")}
                value={findLabel("distribution", formValues.distribution)}
              />
            </Col>
            {formValues.distribution_2 && (
              <Col lg={8} md={12} sm={24} xs={24}>
                <br />
                <ShowData
                  className={classes.showData}
                  value={findLabel("distribution", formValues.distribution_2)}
                  label={""}
                />
              </Col>
            )}
            {formValues.distribution_3 && (
              <Col lg={8} md={12} sm={24} xs={24}>
                <br />
                <ShowData
                  className={classes.showData}
                  label={""}
                  value={findLabel("distribution", formValues.distribution_3)}
                />
              </Col>
            )}
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Accentuation")}
                value={findLabel("accentuation", formValues.accentuation)}
              />
            </Col>
            {accentuationCount && (
              <Col lg={8} md={12} sm={24} xs={24} data-testid={"accentuation"}>
                <br />
                <ShowData
                  className={classes.showData}
                  label={""}
                  value={findLabel("accentuation", formValues.accentuation_2)}
                />
              </Col>
            )}
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Completely normal lung")}
                value={findLabel(
                  "completely_normal_lung",
                  formValues.completely_normal_lung
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Fibroelastosis")}
                value={findLabel("fibroelastosis", formValues.fibroelastosis)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Organizing pneumonia")}
                value={findLabel(
                  "organizing_pneumonia",
                  formValues.organizing_pneumonia
                )}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Lymphoid infiltration")}
                value={findLabel(
                  "lymphoid_infiltration",
                  formValues.lymphoid_infiltration
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Emphysema")}
                value={findLabel("emphysema", formValues.emphysema)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Airspace macrophages")}
                value={findLabel(
                  "airspace_macrophages",
                  formValues.airspace_macrophages
                )}
              />
            </Col>
          </Row>

          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Airway disease")}
                value={findLabel("airway_disease", formValues.airway_disease)}
              />
            </Col>
            {airwayCount.map((item: string, key: number) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  sm={24}
                  xs={24}
                  key={key}
                  data-testid={item}
                >
                  <br />
                  <ShowData
                    className={classes.showData}
                    label={""}
                    value={findLabel("airway_disease", formValues[item])}
                  />
                </Col>
              );
            })}
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Granuloma")}
                value={findLabel("granuloma", formValues.granuloma)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Giant cells/cholesterol cleft")}
                value={findLabel("giant_cells", formValues.giant_cells)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Exudate")}
                value={findLabel("exudate", formValues.exudate)}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Lymphoid follicle")}
                value={findLabel(
                  "lymphoid_follicle",
                  formValues.lymphoid_follicle
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Other inflammatory cells")}
                value={findLabel(
                  "other_inflammatory_cells",
                  formValues.other_inflammatory_cells
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                className={classes.showData}
                label={t("Vascular abnormality")}
                value={findLabel(
                  "vascular_abnormality",
                  formValues.vascular_abnormality
                )}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Pneumocytes")}
                value={findLabel("Pneumocytes", formValues.Pneumocytes)}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Marked Deposition")}
                value={findLabel(
                  "marked_deposition",
                  formValues.marked_deposition
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Smooth muscle hyperplasia")}
                value={findLabel(
                  "smooth_muscle_hyperplasia",
                  formValues.smooth_muscle_hyperplasia
                )}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                size="middle"
                className={classes.dropDown}
                options={findingOptions["quality_of_specimen"]}
                label={t("Quality of Specimen")}
                name="quality_of_specimen"
                placeholder={findingOptions["quality_of_specimen"][0].label}
                value={formValues.quality_of_specimen}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["dense_fibrosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("Dense fibrosis")}
                name="dense_fibrosis"
                placeholder={findingOptions["dense_fibrosis"][0].label}
                value={formValues.dense_fibrosis}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["loose_fibrosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("Loose fibrosis")}
                name="loose_fibrosis"
                placeholder={findingOptions["loose_fibrosis"][0].label}
                value={formValues.loose_fibrosis}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["architectural_destruction"]}
                size="middle"
                className={classes.dropDown}
                label={t("Architectural destruction")}
                name="architectural_destruction"
                placeholder={
                  findingOptions["architectural_destruction"][0].label
                }
                value={formValues.architectural_destruction}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["honeycombing"]}
                size="middle"
                className={classes.dropDown}
                label={t("Honeycombing")}
                name="honeycombing"
                placeholder={findingOptions["honeycombing"][0].label}
                value={formValues.honeycombing}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["fibroblastic_focus"]}
                size="middle"
                className={classes.dropDown}
                label={t("Fibroblastic focus")}
                name="fibroblastic_focus"
                placeholder={findingOptions["fibroblastic_focus"][0].label}
                value={formValues.fibroblastic_focus}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["distribution"]}
                size="middle"
                className={classes.dropDown}
                label={t("Distribution")}
                name="distribution"
                placeholder={findingOptions["distribution"][0].label}
                value={formValues.distribution}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <br />
              <SelectComponent
                options={findingOptions["distribution"]}
                size="middle"
                className={classes.dropDown}
                name="distribution_2"
                placeholder={findingOptions["distribution"][0].label}
                value={formValues.distribution_2}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <br />
              <SelectComponent
                options={findingOptions["distribution"]}
                size="middle"
                className={classes.dropDown}
                name="distribution_3"
                placeholder={findingOptions["distribution"][0].label}
                value={formValues.distribution_3}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                size="middle"
                className={classes.dropDown}
                options={findingOptions["accentuation"]}
                label={t("Accentuation")}
                name="accentuation"
                placeholder={findingOptions["accentuation"][0].label}
                value={formValues.accentuation}
                onChange={handleChange}
                max={2}
                allowClear={true}
              />
            </Col>
            {accentuationCount && (
              <Col lg={8} md={12} sm={24} xs={24} data-testid={"accentuation"}>
                <span
                  className={classes.cancel}
                  data-testid={"accentuationCross"}
                  onClick={decreaseAccentuation}
                >
                  {!pdfLoading ? "x" : <br />}
                </span>
                <SelectComponent
                  size="middle"
                  className={classes.dropDown}
                  options={findingOptions["accentuation_2"]}
                  name="accentuation_2"
                  placeholder={findingOptions["accentuation_2"][0].label}
                  value={formValues.accentuation_2}
                  onChange={handleChange}
                  allowClear={true}
                />
              </Col>
            )}
            {!pdfLoading && (
              <div className={classes.plusIconHolder}>
                <PlusCircleOutlined
                  width="17px"
                  height="17px"
                  className={classes.plusIcon}
                  data-testid={"accentuationPlus"}
                  onClick={increaseAccentuation}
                />
              </div>
            )}
          </Row>
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["completely_normal_lung"]}
                size="middle"
                className={classes.dropDown}
                label={t("Completely normal lung")}
                name="completely_normal_lung"
                placeholder={findingOptions["completely_normal_lung"][0].label}
                value={formValues.completely_normal_lung}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["fibroelastosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("Fibroelastosis")}
                name="fibroelastosis"
                placeholder={findingOptions["fibroelastosis"][0].label}
                value={formValues.fibroelastosis}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["organizing_pneumonia"]}
                size="middle"
                className={classes.dropDown}
                label={t("Organizing pneumonia")}
                name="organizing_pneumonia"
                placeholder={findingOptions["organizing_pneumonia"][0].label}
                value={formValues.organizing_pneumonia}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["lymphoid_infiltration"]}
                size="middle"
                className={classes.dropDown}
                label={t("Lymphoid infiltration")}
                name="lymphoid_infiltration"
                placeholder={findingOptions["lymphoid_infiltration"][0].label}
                value={formValues.lymphoid_infiltration}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["emphysema"]}
                size="middle"
                className={classes.dropDown}
                label={t("Emphysema")}
                name="emphysema"
                placeholder={findingOptions["emphysema"][0].label}
                value={formValues.emphysema}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["airspace_macrophages"]}
                size="middle"
                className={classes.dropDown}
                label={t("Airspace macrophages")}
                name="airspace_macrophages"
                placeholder={findingOptions["airspace_macrophages"][0].label}
                value={formValues.airspace_macrophages}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>

          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["airway_disease"]}
                size="middle"
                className={classes.dropDown}
                label={t("Airway disease")}
                name="airway_disease"
                placeholder={findingOptions["airway_disease"][0].label}
                value={formValues.airway_disease}
                onChange={handleChange}
                max={3}
                allowClear={true}
              />
            </Col>
            {airwayCount.map((item: string, key: number) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  sm={24}
                  xs={24}
                  key={key}
                  data-testid={item}
                >
                  <span
                    className={classes.cancel}
                    data-testId={"airwayCross"}
                    onClick={() => {
                      return decreaseAirway(item);
                    }}
                  >
                    {!pdfLoading ? "x" : <br />}
                  </span>
                  <SelectComponent
                    options={findingOptions["airway_disease"]}
                    size="middle"
                    className={classes.dropDown}
                    name={item}
                    placeholder={findingOptions["airway_disease"][0].label}
                    value={formValues[item]}
                    onChange={handleChange}
                    allowClear={true}
                  />
                </Col>
              );
            })}
            {!pdfLoading && (
              <div className={classes.plusIconHolder}>
                <PlusCircleOutlined
                  width="17px"
                  height="17px"
                  className={classes.plusIcon}
                  data-testid={"airwayPlus"}
                  onClick={increaseAirway}
                />
              </div>
            )}
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["granuloma"]}
                size="middle"
                className={classes.dropDown}
                label={t("Granuloma")}
                name="granuloma"
                placeholder={findingOptions["granuloma"][0].label}
                value={formValues.granuloma}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["giant_cells"]}
                size="middle"
                className={classes.dropDown}
                label={t("Giant cells/cholesterol cleft")}
                name="giant_cells"
                placeholder={findingOptions["giant_cells"][0].label}
                value={formValues.giant_cells}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["exudate"]}
                size="middle"
                className={classes.dropDown}
                label={t("Exudate")}
                name="exudate"
                placeholder={findingOptions["exudate"][0].label}
                value={formValues.exudate}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["lymphoid_follicle"]}
                size="middle"
                className={classes.dropDown}
                label={t("Lymphoid follicle")}
                name="lymphoid_follicle"
                placeholder={findingOptions["lymphoid_follicle"][0].label}
                value={formValues.lymphoid_follicle}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["other_inflammatory_cells"]}
                size="middle"
                className={classes.dropDown}
                label={t("Other inflammatory cells")}
                name="other_inflammatory_cells"
                placeholder={
                  findingOptions["other_inflammatory_cells"][0].label
                }
                value={formValues.other_inflammatory_cells}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["vascular_abnormality"]}
                size="middle"
                className={classes.dropDown}
                label={t("Vascular abnormality")}
                name="vascular_abnormality"
                placeholder={findingOptions["vascular_abnormality"][0].label}
                value={formValues.vascular_abnormality}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>

          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["Pneumocytes"]}
                size="middle"
                className={classes.dropDown}
                label={t("Pneumocytes")}
                name="Pneumocytes"
                placeholder={findingOptions["Pneumocytes"][0].label}
                value={formValues.Pneumocytes}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["marked_deposition"]}
                size="middle"
                className={classes.dropDown}
                label={t("Marked Deposition")}
                name="marked_deposition"
                placeholder={findingOptions["marked_deposition"][0].label}
                value={formValues.marked_deposition}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={findingOptions["smooth_muscle_hyperplasia"]}
                size="middle"
                className={classes.dropDown}
                label={t("Smooth muscle hyperplasia")}
                name="smooth_muscle_hyperplasia"
                placeholder={
                  findingOptions["smooth_muscle_hyperplasia"][0].label
                }
                value={formValues.smooth_muscle_hyperplasia}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export { Findings };
