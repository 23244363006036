import React, { useState } from "react";

export type PdfContextProps = {
  pdfLoading: boolean;
  setPdfLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PdfContext = React.createContext({} as PdfContextProps);

export const PdfContextProvider = ({ children }: any) => {
  const [pdfLoading, setPdfLoading] = useState(false as boolean);

  return (
    <PdfContext.Provider
      value={{
        pdfLoading,
        setPdfLoading
      }}
    >
      {children}
    </PdfContext.Provider>
  );
};
