import { Col, Form, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, TextField, Typography } from "shared-components";

interface FormItems {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (FormItems: FormItems) => void;
  error?: string;
  loading?: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      width: 243,
      "& div:first-child": {
        color: theme.yellow5
      },
      "& > :first-child": {
        marginBottom: 15
      }
    },
    row: {
      marginTop: 51
    },
    buttonDiv: {
      marginTop: 51,
      "& > :first-child": {
        marginBottom: 40
      }
    },
    button: {
      borderRadius: 26,
      width: 145,
      height: 28,
      background: theme.yellow5,
      color: theme.textPrimary,
      "&:focus": {
        background: theme.yellow5,
        color: theme.textPrimary
      }
    },
    textField: {
      "& input": {
        background: "transparent"
      },
      border: "1px solid #FFFFFF"
    },
    forgotPassword: {
      color: theme.textWhite,
      textAlign: "center",
      cursor: "pointer",
      "&:hover": {
        color: theme.textWhite,
        textDecoration: "underline"
      }
    }
  };
});

const SignInComponent: React.FC<Props> = props => {
  const classes = useStyles();
  const [state, setState] = useState({ email: "", password: "" });

  const { t } = useTranslation();

  const handleSignIn = () => {
    props.onSubmit({ ...state });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  return (
    <Row gutter={8} className={classes.row}>
      <Col span={24}>
        <Form onFinish={handleSignIn}>
          <div className={classes.formItem}>
            <Form.Item
              id="email"
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("Not a valid email")
                },
                {
                  required: true,
                  message: t("Required")
                },
                {
                  max: 60,
                  message: t("Max Length Reached")
                }
              ]}
            >
              <TextField
                name="email"
                className={classes.textField}
                placeholder={t("Mail address")}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={classes.formItem}>
            <Form.Item
              id="password"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Required")
                },
                {
                  min: 8,
                  message: t("Must be at least 8 characters")
                }
              ]}
            >
              <TextField
                name="password"
                className={classes.textField}
                placeholder={t("Password")}
                type="password"
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className={classes.buttonDiv}>
            <Form.Item>
              <Button
                size="small"
                type="primary"
                htmlType="submit"
                className={classes.button}
                loading={props.loading}
              >
                {t("Sign In")}
              </Button>
            </Form.Item>
          </div>
        </Form>
        <Typography
          type="link1"
          href="/forgot-password"
          className={classes.forgotPassword}
        >
          {t("Forgot Password?")}
        </Typography>
      </Col>
    </Row>
  );
};

export { SignInComponent };
