import OpenSeadragon from "openseadragon";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Layer, Rect, Stage } from "react-konva";
import {
  API,
  exceptionHandler,
  pointerIcon,
  POROUSAPI
} from "shared-components";

import { AnnotationModal } from "../../molecules/AnnotationModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    viewer: {
      height: "100%",
      width: "100%",
      background: theme.gray9,
      position: "relative",
      "& .openseadragon-canvas": {
        cursor: ({ pointer }: any) => {
          return pointer ? `url(${pointerIcon}),default` : "unset";
        }
      },
      "& .tag-button": {
        display: "flex !important",
        paddingLeft: "5px",
        paddingRight: "5px"
      },
      "& .tag-dropdown-menu": {
        minWidth: "125px !important"
      }
    }
  };
});

const OpenSlideViewer = ({
  caseId,
  selectedImage,
  reportId,
  manifest,
  tagVocabulary,
  observationOptions,
  pointer
}: any) => {
  const { t } = useTranslation();
  const [openseadragonInstance, setOpenseadragonInstance] = useState(
    null as any
  );
  const viewerRef = useRef(openseadragonInstance);
  const setViewerRef = async (data: any) => {
    viewerRef.current = data;
    setOpenseadragonInstance(data);
  };

  const classes = useStyles({ pointer });
  const [showSketch, setShowSketch] = useState(false);
  const [point, setPoint] = useState({ x: 0, y: 0, scale: 1 });
  const [scale, setScale] = useState(1.0);
  const [rotate, setRotate] = useState(0);

  const [currentAnnotation, setCurrentAnnotation] = useState(null as any);

  const [annotations, setAnnotations] = useState([] as any);
  const [newAnnotation, setNewAnnotation] = useState([] as any);

  useEffect(() => {
    document.body.onkeydown = function(e) {
      setShowSketch(e.shiftKey);
    };
    document.body.onkeyup = function() {
      setShowSketch(false);
    };
  }, []);

  const loadAnnotations = async () => {
    try {
      const response = await API.get(
        `reports/${reportId}/tags/${selectedImage.id}`,
        {
          params: {
            associated: selectedImage.associated
          }
        }
      );
      if (response.data) {
        const annotations = [] as any;

        response.data.forEach((annotation: any, index: number) => {
          const value = JSON.parse(annotation.roi);
          const [
            x2,
            y2,
            width,
            height,
            x,
            y,
            rotate
          ] = value.target.selector.value.split(":")[1].split(",");
          value.x = x || 0;
          value.y = y || 0;
          value.x2 = x2;
          value.y2 = y2;
          value.width = width;
          value.height = height;
          value.scale = 1.0;
          value.rotate = rotate || 0;
          value.original = annotation;
          value.key = index;
          annotations.push(value);
        });

        return annotations;
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const createThumbnail = (slideId: string, xywh: number[], theta: number) => {
    return POROUSAPI.post(`/slides/${slideId}/crop`, {
      height: parseInt(xywh[3] + ""),
      width: parseInt(xywh[2] + ""),
      theta: theta || 0,
      x: parseInt(xywh[0] + ""),
      y: parseInt(xywh[1] + ""),
      name: selectedImage.associated
    });
  };

  const handleCreateAnnotation = async (
    annotation: any,
    tag: any,
    comment: any
  ) => {
    try {
      const width = annotation.width / annotation.scale;
      const height = annotation.height / annotation.scale;
      const x2 = annotation.x2 / annotation.scale;
      const y2 = annotation.y2 / annotation.scale;
      const angle = 6.28318530718 - (annotation.rotate * Math.PI) / 180.0;

      const thumbnail = await createThumbnail(
        selectedImage.slide_id,
        [x2, y2, width, height],
        angle
      );

      const roi = {
        type: "Annotation",
        body: [
          {
            type: "TextualBody",
            purpose: "tagging",
            value: tag ? tag.join(" / ") : ""
          },
          {
            type: "TextualBody",
            purpose: "commenting",
            value: comment || ""
          }
        ],
        target: {
          selector: {
            type: "FragmentSelector",
            conformsTo: "http://www.w3.org/TR/media-frags/",
            value: `xywh=pixel:${x2},${y2},${width},${height},0,0,${annotation.rotate},${annotation.scale}`
          }
        },
        "@context": "http://www.w3.org/ns/anno.jsonld",
        id: ""
      };

      const result = await API.post(`cases/${caseId}/tags`, {
        report_id: reportId,
        comment: comment || "",
        disease: tag ? tag[0] || "" : "",
        observation: tag ? tag[1] || "" : "",
        significance: tag ? tag[2] || "" : "",
        case_image_id: selectedImage.id,
        associated_image_name: selectedImage.associated,
        roi: JSON.stringify(roi),
        image_url: thumbnail.data.url
      });
      if (result && result.data) {
        const newAnnotations = [...annotations];
        newAnnotations[annotation.key].original = result.data;
        setAnnotations(newAnnotations);
      }
      setCurrentAnnotation(null);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleUpdateAnnotation = async (
    annotation: any,
    tag: any,
    comment: any
  ) => {
    try {
      const roi = {
        type: "Annotation",
        body: [
          {
            type: "TextualBody",
            purpose: "tagging",
            value: tag ? tag.join(" / ") : ""
          },
          {
            type: "TextualBody",
            purpose: "commenting",
            value: comment || ""
          }
        ],
        target: {
          selector: {
            type: "FragmentSelector",
            conformsTo: "http://www.w3.org/TR/media-frags/",
            value: `xywh=pixel:${annotation.x2},${annotation.y2},${annotation.width},${annotation.height},${annotation.scale},${annotation.rotate}`
          }
        },
        "@context": "http://www.w3.org/ns/anno.jsonld",
        id: ""
      };

      await API.put(`tags/${annotation.original.id}`, {
        ...annotation.original,
        report_id: reportId,
        comment: comment || "",
        disease: tag ? tag[0] || "" : "",
        observation: tag ? tag[1] || "" : "",
        significance: tag ? tag[2] || "" : "",
        roi: JSON.stringify(roi)
      });

      const newAnnotations = [...annotations];
      newAnnotations[annotation.key] = { ...annotation, ...roi };
      setAnnotations(newAnnotations);
      setCurrentAnnotation(null);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleDeleteAnnotation = async (deleteAnnotation: any) => {
    try {
      const id = deleteAnnotation.original.id;
      await API.delete(`tags/${id}`);
      setCurrentAnnotation(null);
      const newAnnotations = annotations.filter((annotation: any) => {
        return !annotation.original || annotation.original.id !== id;
      });
      setAnnotations(newAnnotations);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  useEffect(() => {
    if (manifest) {
      openseadragonInstance && openseadragonInstance.destroy();
      InitOpenseadragon();
    }
    return () => {
      openseadragonInstance && openseadragonInstance.destroy();
    };
  }, [manifest]);

  const handleZoomIn = () => {
    const zoom = viewerRef.current.viewport.getZoom(true);
    if (zoom < 10) {
      viewerRef.current.viewport.zoomTo(zoom * 2);
    }
  };

  const handleZoomOut = () => {
    const zoom = viewerRef.current.viewport.getZoom(true);
    if (zoom > 0.5) {
      viewerRef.current.viewport.zoomTo(zoom / 2);
    }
  };

  const setToCenter = (viewportPoint: any) => {
    viewerRef.current.viewport.panTo(viewportPoint);
  };

  const InitOpenseadragon = async () => {
    const openSeadragon: any = OpenSeadragon({
      id: "openSeaDragon",
      prefixUrl: "/openseadragon-images/",
      animationTime: 0.5,
      blendTime: 0.1,
      constrainDuringPan: true,
      maxZoomPixelRatio: 2,
      minZoomLevel: 0.5,
      visibilityRatio: 0.5,
      zoomPerScroll: 2,
      zoomPerClick: 1,
      showZoomControl: false,
      showNavigator: true,
      showRotationControl: true,
      rotationIncrement: 15
    });
    openSeadragon.addHandler("rotate", (viewer: any) => {
      setRotate(viewer.degrees || 0);
    });

    const onChangeViewport = () => {
      const tiledImage = openSeadragon.world.getItemAt(0);
      const boundsRect = openSeadragon.viewport.getBoundsNoRotate(true);
      const viewportOriginX = boundsRect.x;
      const viewportOriginY = boundsRect.y;
      const viewportWidth = boundsRect.width;
      const viewportHeight = boundsRect.height;
      const element = openSeadragon.container;
      const getViewerContainerSize = new OpenSeadragon.Point(
        element.clientWidth,
        element.clientHeight
      );

      const x =
        ((openSeadragon.viewport.getBoundsNoRotate(true).x - viewportOriginX) /
          viewportWidth) *
        getViewerContainerSize.x;
      const xPixel =
        ((x - viewportOriginX) / viewportWidth) * getViewerContainerSize.x;

      const y =
        ((openSeadragon.viewport.getBoundsNoRotate(true).y - viewportOriginY) /
          viewportHeight) *
        getViewerContainerSize.y;
      const yPixel =
        ((y - viewportOriginY) / viewportHeight) * getViewerContainerSize.y;

      const _zoomFactor =
        getViewerContainerSize.x /
        (viewportWidth * tiledImage.source.dimensions.x);

      setPoint({
        x: xPixel,
        y: yPixel,
        scale: _zoomFactor
      });
      setScale(_zoomFactor);
    };

    openSeadragon.addHandler("open", onChangeViewport);
    openSeadragon.addHandler("viewport-change", onChangeViewport);
    openSeadragon.addHandler("resize", onChangeViewport);
    openSeadragon.addHandler("full-page", onChangeViewport);
    openSeadragon.addHandler("full-screen", onChangeViewport);

    let zoomIn = new OpenSeadragon.Button({
      tooltip: "Zoom In",
      srcRest: "/openseadragon-images/zoomin_rest.png",
      srcGroup: "/openseadragon-images/zoomin_grouphover.png",
      srcHover: "/openseadragon-images/zoomin_hover.png",
      srcDown: "/openseadragon-images/zoomin_pressed.png",
      onClick: handleZoomIn
    });

    let zoomOut = new OpenSeadragon.Button({
      tooltip: "Zoom Out",
      srcRest: "/openseadragon-images/zoomout_rest.png",
      srcGroup: "/openseadragon-images/zoomout_grouphover.png",
      srcHover: "/openseadragon-images/zoomout_hover.png",
      srcDown: "/openseadragon-images/zoomout_pressed.png",
      onClick: handleZoomOut
    });

    openSeadragon.buttons.buttons.push(zoomIn);
    openSeadragon.buttons.element.appendChild(zoomIn.element);
    openSeadragon.buttons.buttons.push(zoomOut);
    openSeadragon.buttons.element.appendChild(zoomOut.element);

    openSeadragon.open({
      Image: {
        ...manifest,
        xmlns: "http://schemas.microsoft.com/deepzoom/2008"
      }
    });

    const data = await loadAnnotations();
    if (data) {
      setAnnotations(data);
    } else {
      setAnnotations([]);
    }

    openSeadragon.addHandler("canvas-double-click", (event: any) => {
      const webPoint = event.position;
      const viewportPoint = openSeadragon.viewport.pointFromPixel(webPoint);
      setToCenter(viewportPoint);
    });

    setViewerRef(openSeadragon);
  };

  const handleMouseDown = (event: any) => {
    if (newAnnotation.length === 0) {
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([{ x, y, width: 0, height: 0, key: "0", isNew: true }]);
    }
  };

  const handleMouseUp = (event: any) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      const adjustedPosition = rotatePoints(
        sx,
        sy,
        center.x,
        center.y,
        -rotate
      );

      const annotationToAdd = {
        x: sx,
        y: sy,
        x2: adjustedPosition.x - point.x,
        y2: adjustedPosition.y - point.y,
        rotate: rotate,
        width: x - sx,
        height: y - sy,
        key: annotations.length,
        scale: scale
      };
      if (annotationToAdd.width > 5 && annotationToAdd.height > 5) {
        annotations.push(annotationToAdd);
        setCurrentAnnotation(annotations[annotations.length - 1]);
        setAnnotations(annotations);
      }
      setNewAnnotation([]);
    }
  };

  const handleMouseMove = (event: any) => {
    if (newAnnotation.length === 1) {
      const sx = newAnnotation[0].x;
      const sy = newAnnotation[0].y;
      const { x, y } = event.target.getStage().getPointerPosition();
      setNewAnnotation([
        {
          x: sx,
          y: sy,
          width: x - sx,
          height: y - sy,
          key: "0",
          isNew: true
        }
      ]);
    }
  };

  const annotationsToDraw = [...annotations, ...newAnnotation];
  let center = { x: 0, y: 0 };
  if (viewerRef.current) {
    center = viewerRef.current.viewport.viewportToViewerElementCoordinates(
      viewerRef.current.viewport.getCenter(true)
    );
  }

  const getCoordinates = (value: any) => {
    const x = value.isNew
      ? value.x
      : point.x + (value.x2 * scale) / value.scale;

    const y = value.isNew
      ? value.y
      : point.y + (value.y2 * scale) / value.scale;

    return rotatePoints(x, y, center.x, center.y, value.isNew ? 0 : rotate);
  };

  const onAnnotationClick = (event: any) => {
    const newAnnotations = [...annotations];
    const target = newAnnotations[event.target.index];
    if (target) {
      setCurrentAnnotation(target);
    }
  };

  const onAnnotationOver = (event: any) => {
    const newAnnotations = [...annotations];
    const target = newAnnotations[event.target.index];
    if (target) {
      target.color = "rgba(255, 167, 78, 0.5)";
      setAnnotations(newAnnotations);
    }
  };

  const onAnnotationOut = (event: any) => {
    const newAnnotations = [...annotations];
    const target = newAnnotations[event.target.index];
    if (target) {
      target.color = "";
      setAnnotations(newAnnotations);
    }
  };

  const closeModal = () => {
    if (
      currentAnnotation &&
      typeof currentAnnotation.original === "undefined"
    ) {
      const newAnnotations = annotations.filter((annotation: any) => {
        return annotation.key !== currentAnnotation.key;
      });
      setAnnotations(newAnnotations);
    }
    setCurrentAnnotation(null);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative"
      }}
    >
      <div id="openSeaDragon" className={classes.viewer} />

      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 1,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          pointerEvents: showSketch ? "auto" : "none"
        }}
      >
        <Stage
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          width={5000}
          height={5000}
        >
          <Layer>
            {annotationsToDraw.map((value, index) => {
              const points = getCoordinates(value);

              return (
                <Rect
                  key={index}
                  rotation={
                    value.isNew
                      ? 0
                      : value.rotate || 0
                      ? rotate - value.rotate
                      : rotate
                  }
                  x={points.x}
                  y={points.y}
                  width={
                    value.isNew
                      ? value.width
                      : (value.width * scale) / value.scale
                  }
                  height={
                    value.isNew
                      ? value.height
                      : (value.height * scale) / value.scale
                  }
                  fill="transparent"
                  strokeWidth={3}
                  stroke={value.color || "rgba(0, 0, 0, 0.5)"}
                  onClick={onAnnotationClick}
                  onMouseOver={onAnnotationOver}
                  onMouseOut={onAnnotationOut}
                />
              );
            })}
          </Layer>
        </Stage>
      </div>

      <AnnotationModal
        annotation={currentAnnotation}
        onCancel={closeModal}
        tagVocabulary={tagVocabulary}
        observationOptions={observationOptions}
        handleUpdateAnnotation={handleUpdateAnnotation}
        handleCreateAnnotation={handleCreateAnnotation}
        handleDeleteAnnotation={handleDeleteAnnotation}
      />
    </div>
  );
};

const rotatePoints = (
  pointX: number,
  pointY: number,
  originX: number,
  originY: number,
  angle: number
) => {
  angle = (angle * Math.PI) / 180.0;
  return {
    x:
      Math.cos(angle) * (pointX - originX) -
      Math.sin(angle) * (pointY - originY) +
      originX,
    y:
      Math.sin(angle) * (pointX - originX) +
      Math.cos(angle) * (pointY - originY) +
      originY
  };
};

export { OpenSlideViewer };
