import { FolderOpenFilled, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { CaseMenu } from "../CaseMenu";
import { CaseLibraryTableCell } from "./CaseLibraryTableCell";

interface Props {
  rows: CaseLibraryTableRowData[];
  onClickFolder: (id: number, name: string) => void;
  deleteFolder: (id: number) => void;
  changeFolderName: (id: number) => void;
  moveFolder: (id: number) => void;
  moveCase: (id: number) => void;
  pagination: PaginationData;
  onChangePagination: (page: number, pageSize?: number | undefined) => void;
}

const CaseLibraryTable = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const handleChangeSelectedRows = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleChangeSelectedRows
  };

  const onRow = (record: CaseLibraryTableRowData) => {
    return {
      onDoubleClick: () => {
        if (record.type === "case") {
          history.push(
            `/cases/${record.id}${
              record.diagnosis_request_id
                ? "?diagnosisRequestId=" + record.diagnosis_request_id
                : ""
            }`
          );
        } else if (record.type === "folder") {
          props.onClickFolder(record.id, record.pathology_number);
        }
      }
    };
  };

  const columns = [
    {
      title: t("Case Number/Name"),
      dataIndex: "pathology_number",
      width: 250,
      render: (text: string, record: CaseLibraryTableRowData) => {
        if (record.type === "case") {
          return <CaseLibraryTableCell text={text} />;
        }
        return (
          <CaseLibraryTableCell text={text} startIcon={<FolderOpenFilled />} />
        );
      }
    },
    {
      title: t("Medical record ID"),
      dataIndex: "medical_record_id",
      width: 180,
      render: (text: string) => {
        return <CaseLibraryTableCell text={text} />;
      }
    },
    {
      title: t("Patient Name"),
      dataIndex: "patient_name",
      width: 180,
      render: (text: string) => {
        return <CaseLibraryTableCell text={text} />;
      }
    },
    {
      title: t("Insurance type"),
      dataIndex: "insurance_type",
      width: 180
    },
    {
      title: t("Organ name"),
      dataIndex: "organs",
      width: 180,
      render: (text: string) => {
        return <CaseLibraryTableCell text={text} />;
      }
    },
    {
      title: t("Creation Date"),
      dataIndex: "created_at",
      width: 120,
      render: (text: string) => {
        return text && text.replace("T", " ").replace("Z", "");
      }
    },
    {
      title: t("Complete Date"),
      dataIndex: "confirmed_at",
      width: 120,
      render: (text: string) => {
        return text && text.replace("T", " ").replace("Z", "");
      }
    },
    {
      title: "",
      dataIndex: "id",
      width: 10,
      render: (text: string, record: CaseLibraryTableRowData) => {
        const actionMenuItem =
          record.type === "case"
            ? [
                {
                  name: "Move",
                  onClick: (
                    e:
                      | React.MouseEvent<HTMLElement>
                      | React.KeyboardEvent<HTMLElement>
                  ) => {
                    props.moveCase(record.id);
                    e.stopPropagation();
                  },
                  deviderUpon: false,
                  startIcon: null
                }
              ]
            : [
                {
                  name: "Change Name",
                  onClick: (
                    e:
                      | React.MouseEvent<HTMLElement>
                      | React.KeyboardEvent<HTMLElement>
                  ) => {
                    props.changeFolderName(record.id);
                    e.stopPropagation();
                  },
                  deviderUpon: false,
                  startIcon: null
                },
                {
                  name: "Move",
                  onClick: (
                    e:
                      | React.MouseEvent<HTMLElement>
                      | React.KeyboardEvent<HTMLElement>
                  ) => {
                    props.moveFolder(record.id);
                    e.stopPropagation();
                  },
                  deviderUpon: false,
                  startIcon: null
                },
                {
                  name: "Delete",
                  onClick: (
                    e:
                      | React.MouseEvent<HTMLElement>
                      | React.KeyboardEvent<HTMLElement>
                  ) => {
                    e.stopPropagation();
                    props.deleteFolder(record.id);
                  },
                  deviderUpon: true,
                  startIcon: null
                }
              ];
        const overlay = <CaseMenu visible={true} items={actionMenuItem} />;
        return (
          <Dropdown overlay={overlay}>
            <MoreOutlined />
          </Dropdown>
        );
      }
    }
  ];
  return (
    <div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={props.rows}
        onRow={onRow}
        pagination={{
          ...props.pagination,
          current: props.pagination.page,
          onChange: page => {
            return props.onChangePagination(page);
          }
        }}
      />
    </div>
  );
};

export { CaseLibraryTable };
