import { Button } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import {
  AuthContext,
  Avatar,
  BorderBoxWrapper,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    text: {
      marginTop: 17,
      marginBottom: 58,
      color: theme.colorTextGrey
    },
    links: {
      color: theme.colorTextGrey,
      textDecoration: "none",
      textAlign: "center",
      marginBottom: 27
    },
    linkWrapper: {
      marginTop: 56
    },
    avatar: {
      display: "flex",
      justifyContent: "center"
    },
    containerBottom: {
      position: "absolute",
      bottom: "0px",
      borderTop: `1px solid ${theme.colorGrey}`,
      width: "100%",
      padding: "17px 0px",
      textAlign: "center"
    },
    mainContainer: {
      position: "relative",
      height: 560
    },
    backButton: {
      background: theme.primary,
      color: "white",
      padding: 6,
      fontSize: "14px",
      lineHeight: "20px",
      minWidth: "160px",
      borderRadius: "3px"
    }
  };
});

const AccountSetting = () => {
  const classes = useStyles();
  const { profile, externalUser } = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <ComponentWrapper>
      <BorderBoxWrapper heading={t("Account Settings")}>
        <div className={classes.mainContainer}>
          <div className={classes.avatar}>
            <Avatar
              name={profile.name || t("Not Set")}
              photo={profile.ProfileImage}
              color={profile.color}
            />
          </div>

          <Typography
            fontSize="18px"
            center
            className={classes.text}
            lineHeight="27px"
          >
            {profile.name || t("Not Set")}
          </Typography>

          <div className={classes.linkWrapper}>
            <Typography
              type="link1"
              href="/edit-profile"
              className={classes.links}
              center
            >
              {t("Edit Profile")}
            </Typography>

            <Typography
              type="link1"
              href="/change-email"
              className={classes.links}
              center
            >
              {t("Change Mail Address")}
            </Typography>

            <Typography
              type="link1"
              href="/change-password"
              className={classes.links}
              center
            >
              {t("Change Password")}
            </Typography>
          </div>
          {!externalUser && (
            <div className={classes.containerBottom}>
              <Button
                type="primary"
                disabled={profile && profile.teams && profile.teams.length == 0}
                onClick={() => {
                  return history.push("/cases");
                }}
                className={classes.backButton}
              >
                {t("Back to Team")}
              </Button>
            </div>
          )}
        </div>
      </BorderBoxWrapper>
    </ComponentWrapper>
  );
};

export { AccountSetting };
