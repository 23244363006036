import { CaretDownFilled, FolderOpenFilled } from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Typography } from "shared-components";

import styles from "./index.module.css";

interface CaseLibraryBreadcrumbItem {
  id: number;
  name: string;
  to: string;
  overlay: React.ReactElement<any, string> | null;
  startIcon: React.ReactNode;
}

interface Props {
  items: CaseLibraryBreadcrumbItem[];
  onClickFolder: (id: number, name: string) => void;
}

const NUMBER_OF_VISIBLE_BREADCRUMB = 2;

const CaseLibraryBreadcrumb = (props: Props) => {
  const { t } = useTranslation();

  const handleOnClick = (item: CaseLibraryBreadcrumbItem) => {
    props.onClickFolder && props.onClickFolder(item.id, item.name);
  };

  return props.items && props.items.length > 0 ? (
    <div aria-label="Breadcrumb" className={styles.breadcrumb}>
      <ul className={styles.breadcrumbUl}>
        {props.items.map((item: CaseLibraryBreadcrumbItem, i: number) => {
          if (item.name === "Case Folder") {
            return (
              <li key={item.id} className={styles.breadcrumbLi}>
                <FolderOpenFilled />
                <Link
                  to="/case-library"
                  onClick={() => {
                    handleOnClick(item);
                  }}
                >
                  {t("Case Folder")}
                </Link>
              </li>
            );
          }
          if (i < props.items.length - (NUMBER_OF_VISIBLE_BREADCRUMB + 1)) {
            return null;
          }
          if (i == props.items.length - (NUMBER_OF_VISIBLE_BREADCRUMB + 1)) {
            return (
              <li key={item.id} className={styles.breadcrumbLi}>
                ...
              </li>
            );
          }
          return (
            <li key={item.id} className={styles.breadcrumbLi}>
              {item.startIcon}
              {item.overlay ? (
                <Dropdown overlay={item.overlay}>
                  <div className={styles.menu}>
                    <Typography
                      type="h4"
                      onClick={() => {
                        handleOnClick(item);
                      }}
                    >
                      {t(item.name)}
                    </Typography>
                    <CaretDownFilled />
                  </div>
                </Dropdown>
              ) : (
                <div
                  onClick={() => {
                    handleOnClick(item);
                  }}
                  className={styles.menu}
                >
                  <Typography type="h4">{t(item.name)}</Typography>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export { CaseLibraryBreadcrumb };
