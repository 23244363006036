import { Form } from "antd";
import React, { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  API,
  AuthContext,
  Button,
  debounceFunction,
  exceptionHandler,
  FilePlusIcon,
  Modal,
  TextField,
  Typography
} from "shared-components";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      marginTop: "-2% !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-content": {
        marginTop: "1%"
      },

      "& .ant-modal-body": {
        minWidth: 850,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-drag": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 25,
        justifyContent: "center",
        height: 135,
        width: 514,
        textAlign: "center",
        background: theme.textWhite
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      },
      "& .ant-upload-drag-container": {
        marginTop: -5,

        "& article": {
          textAlign: "center"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    logo: {
      "& svg": {
        height: 25,
        width: 34,
        marginRight: 15
      }
    },
    modal: {
      maxWidth: "100%"
    },
    modalButton: {
      textAlign: "center"
    },

    button: {
      height: 36,
      width: 160,
      fontSize: 14
    },
    draggerComponent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    draggerSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 514,
      border: "1px dashed #d9d9d9",
      marginTop: 23,
      height: 135
    },
    middleText: {
      marginTop: 20,
      marginBottom: 22,
      "& article": {
        textAlign: "center"
      }
    },
    text: {
      color: theme.primary,
      textAlign: "center",
      letterSpacing: "1.08px",
      marginBottom: 4,
      "& article": {
        textAlign: "center"
      }
    },
    textBlue: {
      color: theme.primary
    },
    textGreen: {
      color: theme.textGreen
    },
    inputCaseNo: {
      width: "fit-content"
    }
  };
});

interface Props {
  visible: boolean;
  moveCase?: (case_id: number, parent_folder_id: number) => void;
  currentFolderId?: number;
  onCancel: () => void;
}
const CreateCaseInFolderModal = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);
  const { defaultTeam, externalUser } = useContext(AuthContext);
  const [state, setState] = useState({ caseId: "" });

  const handleSubmit = async (folder: any) => {
    let folderName;

    if (folder) {
      folderName = folder[0].path;

      if (folderName.includes("/")) {
        folderName = folderName.split("/");
        if (folderName[0] === "") {
          folderName = folderName[1];
        } else {
          folderName = folderName[0];
        }
      } else {
        Alert("error", "error", t("Please upload a folder."), t);
        return;
      }
    }

    setLoading(true);

    const { caseId } = state;
    try {
      const response = await API.post(`/cases`, {
        team_id: defaultTeam,
        pathology_number: folderName ? folderName : caseId,
        form_name: externalUser ? "ipfuip" : "general"
      });
      setLoading(false);
      if (response.data && response.data.id) {
        props.currentFolderId &&
          props.moveCase &&
          props.moveCase(response.data.id, props.currentFolderId);
        history.push({
          pathname: `/cases/${response.data.id}`,
          state: { folder: folder }
        });
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleModalField = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const handleFolderUpload = async (folder: any) => {
    debounceFunction(() => {
      return handleSubmit(folder);
    });
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={props.onCancel}
      width="850px"
      mask={false}
    >
      <div className={classes.header}>
        <div className={classes.logo}>
          <FilePlusIcon />
        </div>
        <Typography type="title">{t("Create New")}</Typography>
      </div>

      <Dropzone
        onDrop={folder => {
          handleFolderUpload(folder);
        }}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()} style={{ outline: "none" }}>
              <section className={classes.draggerSection}>
                <input
                  {...getInputProps()}
                  directory=""
                  webkitdirectory=""
                  type="file"
                  multiple
                />
                <div className={classes.draggerComponent}>
                  <Typography type="body1" className={classes.textBlue}>
                    {t("Drop Folder")}
                  </Typography>
                  <Typography type="body4" className={classes.textGreen}>
                    {t(
                      "Folder name will be included in the pathology control number"
                    )}
                  </Typography>
                </div>
              </section>
            </div>
          );
        }}
      </Dropzone>

      <div className={classes.middleText}>
        <Typography>{t("OR")}</Typography>
      </div>

      <Form
        form={form}
        className={classes.modal}
        onFinish={() => {
          return handleSubmit(null);
        }}
      >
        <Typography type="body2" className={classes.text}>
          {t("Enter the pathological control number")}
        </Typography>
        <Form.Item
          id="name"
          name="name"
          rules={[
            {
              required: true,
              message: t("Required")
            }
          ]}
        >
          <div className={classes.inputCaseNo}>
            <TextField
              autoComplete="off"
              name="caseId"
              placeholder={t("Case Number")}
              onChange={handleModalField}
              maxLength={90}
            />
          </div>
        </Form.Item>
        <Form.Item className={classes.modalButton}>
          <Button
            className={classes.button}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            data-testid="create-button"
          >
            {t("Create")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { CreateCaseInFolderModal };
