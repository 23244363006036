import { TagFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  CheckboxComponent,
  exceptionHandler,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    detailsWrapper: {
      height: 186,
      display: "flex"
    },
    tag: {
      color: theme.lightBg,
      marginTop: 4
    },
    tagBackground: {
      display: "flex",
      backgroundColor: theme.darkBackground,
      borderRadius: 3,
      padding: "2px 6px",
      height: 24,
      marginBottom: 4
    },
    circle: {
      marginTop: 4,
      marginRight: 10
    },
    tagDetails: {
      color: theme.textWhite,
      padding: 0,
      marginLeft: 8,
      fontSize: 14,
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    comment: {
      fontWeight: 800
    },
    commentWrapper: {
      maxHeight: 85,
      minHeight: 30,
      display: "-webkit-box",
      "-webkit-line-clamp": 5,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    pdfCommentWrapper: {
      wordBreak: "break-word"
    },
    wrapper: {
      maxWidth: "webkit-fill-available"
    },
    imageDiv: {
      backgroundPosition: "center",
      border: "1px solid #ccc",
      borderRadius: 5,
      height: 186,
      width: 186,
      minHeight: 135,
      minWidth: 135,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat"
    },
    column: {
      minHeight: 186
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 130
    },
    caseImageText: {
      display: "inline-block",
      fontSize: 14,
      marginLeft: 0,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    checkContainer: {
      display: "flex"
    },
    textContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      flex: 1
    },
    tagsContainer: {
      display: "flex",
      marginTop: 5,
      marginBottom: 5
    }
  };
});

interface Props {
  pdfLoading?: boolean;
  tag: CaseTag;
  reportTags: CaseTag[];
  selectedReportId: number;
  caseImages: CaseImages[];
}

export const CaseTag = ({
  pdfLoading,
  tag,
  reportTags,
  selectedReportId,
  caseImages
}: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false as boolean);

  useEffect(() => {
    if (reportTags && tag.id) {
      const newChecked = reportTags.some((reportTag: CaseTag) => {
        return reportTag.id === tag.id;
      });
      setChecked(newChecked);
    }
  }, [reportTags, tag]);

  const handleTagsCheckBox = async (tag: CaseTag, event: any) => {
    const checkedValue = event.target.checked;
    try {
      setChecked(checkedValue);
      await API.post(`/reports/${selectedReportId}/tags/${tag.id}`, {
        checked: checkedValue
      });
    } catch (error) {
      setChecked(!checkedValue);
      exceptionHandler(error, t);
    }
  };

  return (
    <div className={classes.detailsWrapper}>
      <div
        className={classes.imageDiv}
        style={{
          backgroundImage: `url(${
            pdfLoading ? tag.caseImageDataURL : tag.caseImageURL
          })`
        }}
      />

      <div className={classes.textContainer}>
        <Row>
          <Col span={24} className={classes.checkContainer}>
            <CheckboxComponent
              checked={checked}
              onClick={(event: any) => {
                return handleTagsCheckBox(tag, event);
              }}
            />
            <Typography className={classes.caseImageText}>
              {t("Add to report")}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={classes.checkContainer}>
            <Typography className={classes.caseImageText}>
              {`from ${caseImages &&
                caseImages.find((image: CaseImages) => {
                  return image.id === tag.caseImageId;
                })?.name}`}
            </Typography>
          </Col>
        </Row>
        <div className={classes.tagsContainer}>
          {tag.disease && (
            <div className={classes.tagBackground}>
              <TagFilled rotate={275} className={classes.tag} />
              <Typography className={classes.tagDetails}>
                {tag.observation ? `${t(tag.observation)}` : ""}
                {tag.significance ? ` / ${t(tag.significance)}` : ""}
              </Typography>
            </div>
          )}
        </div>
        <Row>
          <Col
            span={24}
            className={`${
              pdfLoading ? classes.pdfCommentWrapper : classes.commentWrapper
            }`}
          >
            <Typography type="body3" className={classes.comment}>
              {tag.comment}
            </Typography>
          </Col>
        </Row>
      </div>
    </div>
  );
};
