import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Typography } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      fontSize: 24,
      marginBottom: 10
    }
  };
});

function DateLabel({
  className,
  name,
  value
}: {
  className?: string;
  name: string;
  value: string | null;
}) {
  return (
    <div className={`flex ${className}`}>
      <div className="mr-4">{name}</div>
      <div>{value ? moment(value).format("YYYY/MM/DD HH:mm") : "--"}</div>
    </div>
  );
}
export function Header({
  createdAt,
  completedAt,
  teamName,
  isConsult,
  reportNumber
}: {
  createdAt: string;
  completedAt: string | null;
  teamName: string;
  isConsult?: boolean;
  reportNumber: number;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className="flex pr-4">
        <div>{/* TODO: 診断報告書n を表示*/}</div>
        <div className="ml-auto flex">
          {teamName !== "" && <div className="mr-4"> {teamName} </div>}
          <DateLabel name={t("Created")} value={createdAt} className="mr-4" />
          <DateLabel
            name={t("Completed")}
            value={completedAt}
            className="text-indigo-600"
          />
        </div>
      </div>
      <Typography className={classes.title}>
        {isConsult
          ? t("Consultation")
          : `${t("Diagnosis report")} ${reportNumber}`}
      </Typography>
    </>
  );
}
