import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  AuthContext,
  Button,
  DoubleTickIcon,
  Typography
} from "shared-components";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      paddingLeft: 24
    },
    container: {
      width: "100%",
      padding: "40px 50px 20px 50px"
    },
    lowerContainer: {
      width: "100%",
      padding: "40px 50px 20px 50px",
      backgroundColor: theme.formBgColor
    },
    button: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginBottom: 10,
      width: "100%",
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative"
    },
    icon: {
      position: "absolute",
      top: "25%",
      right: "-5px"
    },
    doubleTick: {
      marginRight: 5
    },
    member: {
      borderRadius: 3,
      marginBottom: 10,
      height: 30,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer"
      }
    },
    memberName: {
      margin: "0 15px",
      fontSize: 16,
      fontColor: theme.textPrimary
    },
    divider: {
      borderColor: theme.border
    },
    listContainer: {
      marginTop: 15
    },
    buttonLabel: {
      color: theme.formLabelColor,
      fontSize: 14,
      marginBottom: 10
    }
  };
});

export interface Member {
  ProfileImage: string;
  color: string;
  created_at: string;
  email: string;
  id: string;
  language: string;
  name: string;
  updated_at: string;
  team_user_id: number;
}

export interface CaseDoubleCheck {
  reportId: number;
  completedAt: string | null;
  changeRequestedAt: string | null;
  id: number;
  userId: string;
}

export interface DoubleCheckProps {
  caseDoubleCheck: CaseDoubleCheck | null;
  setDoubleCheckUser: React.Dispatch<React.SetStateAction<Member | null>>;
  setConfirmRequest: React.Dispatch<React.SetStateAction<boolean>>;
  setDoubleCheckReturn: any;
  setDoubleCheckConfirm: any;
  teamId: number;
  confirm?: boolean;
  pathologistId?: string;
  consultantID?: string;
  members: Member[];
}

interface Props extends DoubleCheckProps {
  onClose: (event?: any, clickedOn?: string | undefined) => void;
}

const DoubleCheck = ({
  setDoubleCheckUser,
  setConfirmRequest,
  setDoubleCheckReturn,
  setDoubleCheckConfirm,
  caseDoubleCheck,
  onClose,
  confirm,
  pathologistId,
  consultantID,
  members
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uid } = useContext(AuthContext);

  const [buttonup, setButtonup] = useState(false as boolean);
  const [userSelect, setUserSelect] = useState(null as Member | null);

  useEffect(() => {
    if (members.length > 0 && caseDoubleCheck) {
      if (!caseDoubleCheck.completedAt && !caseDoubleCheck.changeRequestedAt) {
        const currentUser = members.find(member => {
          return member.id === caseDoubleCheck.userId;
        });
        if (currentUser) {
          setUserSelect(currentUser);
        }
      } else {
        setUserSelect(null);
      }
    }
  }, [members, caseDoubleCheck]);

  return (
    <div className={classes.wrapper}>
      {caseDoubleCheck && userSelect ? (
        userSelect.id === uid ? (
          <div>
            <Typography className={classes.buttonLabel}>
              {t("Change Request")}
            </Typography>
            <Button
              size="middle"
              className={classes.button}
              onClick={() => {
                setDoubleCheckReturn();
                onClose();
              }}
            >
              {t("Send back")}
            </Button>
            <Divider className={classes.divider} />
            <Typography className={classes.buttonLabel}>{t("Done")}</Typography>
            <Button
              size="middle"
              className={classes.button}
              onClick={() => {
                setDoubleCheckConfirm(true);
                onClose();
              }}
            >
              {t("Confirm")}
            </Button>
          </div>
        ) : (
          <div>
            <Typography type="body3">
              {t("The following member is in the process of double-checking.")}
            </Typography>
            <div
              className={classes.member}
              style={{ backgroundColor: userSelect.color }}
            >
              <Typography type="body3" className={classes.memberName}>
                {userSelect.name || userSelect.email}
              </Typography>
            </div>
          </div>
        )
      ) : confirm ? (
        <div>
          <Typography type="body3">
            {t("This case has been confirmed.")}
          </Typography>
        </div>
      ) : pathologistId === uid || consultantID === uid ? (
        <>
          <Typography type="body3" className={classes.buttonLabel}>
            {t("Double Check")}
          </Typography>
          <Button
            size="middle"
            className={classes.button}
            onClick={() => {
              return setButtonup(!buttonup);
            }}
          >
            <div className={classes.buttonContainer}>
              <DoubleTickIcon className={classes.doubleTick} />
              {t("Request a double check")}
              {buttonup ? (
                <CaretUpOutlined className={classes.icon} />
              ) : (
                <CaretDownOutlined className={classes.icon} />
              )}
            </div>
          </Button>
          <br />
          <div className={classes.listContainer}>
            {buttonup &&
              members.length > 0 &&
              members.map((member: Member) => {
                return (
                  <div
                    key={member.id}
                    className={classes.member}
                    style={{ backgroundColor: member.color }}
                    onClick={() => {
                      setButtonup(!buttonup);
                      setDoubleCheckUser(member);
                      onClose();
                    }}
                  >
                    <Typography type="body3" className={classes.memberName}>
                      {member.name || member.email}
                    </Typography>
                  </div>
                );
              })}
          </div>
          <Divider className={classes.divider} />
          <Typography type="body3" className={classes.buttonLabel}>
            {t("Complete")}
          </Typography>
          <Button
            size="middle"
            className={classes.button}
            onClick={() => {
              setConfirmRequest(true);
              onClose();
            }}
          >
            {t("To Confirmation Screen")}
          </Button>
        </>
      ) : (
        <div>
          <Typography type="body3">
            {t("You are not authorized to double check and confirm the case.")}
          </Typography>
        </div>
      )}
    </div>
  );
};

export { DoubleCheck };
