import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Button,
  exceptionHandler,
  keyValuePair,
  Loader
} from "shared-components";

import { statusValue } from "./actionValueMap";

const useStyles = createUseStyles((theme: any) => {
  return {
    buttonMargin: {
      marginRight: 50,
      marginLeft: 50,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    casesTable: {
      marginTop: 15
    },
    remove: {
      width: 75,
      textAlign: "center",
      cursor: "pointer"
    },
    logContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: 50
    },
    activityLog: {
      display: "flex",
      flexDirection: "row",
      marginTop: "15px !important"
    },
    actionButtonContainer: {
      marginTop: 20,
      display: "flex",
      width: "100%",
      justifyContent: "center"
    },
    estimatingButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "5%"
    }
  };
});

interface Props {
  sender: boolean;
  form: DiagnosisRequestFormData;
  errors: keyValuePair;
  setErrors: React.Dispatch<React.SetStateAction<keyValuePair>>;
  cases: DiagnosisCasesData[];
  setForm: React.Dispatch<React.SetStateAction<DiagnosisRequestFormData>>;
  setRequestAction: React.Dispatch<React.SetStateAction<RequestAction>>;
  setActivities: React.Dispatch<React.SetStateAction<DiagnosisActivity[]>>;
  requestAction: RequestAction;
  activities: DiagnosisActivity[];
}

export const RequestActions = ({
  sender,
  form,
  setErrors,
  cases,
  requestAction,
  setRequestAction,
  setForm,
  setActivities,
  activities
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { status } = form;

  const [loading, setLoading] = useState(false as boolean);

  const canQuote =
    activities &&
    activities.length > 0 &&
    !["send_quote", "return_request"].includes(activities[0].action);

  const handleConfirm = async (params: {
    action: string;
    comment?: string;
  }) => {
    setRequestAction({ ...requestAction, open: false });
    setLoading(true);

    let data: any = {};

    if (params.action === "request_quote" || params.action === "edit_request") {
      const valid = validateSender();
      if (!valid) {
        setLoading(false);
        return;
      }
    }

    if (params.action === "send_quote") {
      const valid = validateReceiver();
      if (!valid) {
        setLoading(false);
        return;
      }
    }

    if (
      ["request_quote", "send_quote", "edit_request"].includes(params.action)
    ) {
      data = {
        case_ids: cases.map((caseData: DiagnosisCasesData) => {
          return caseData.id;
        }),
        action: params.action,
        team_client_id: form.team_client_id,
        request_type: form.request_type,
        request_body: form.request_body,
        price: form.price,
        due_date: form.due_date,
        comment: params.comment
      };
    } else {
      data = {
        action: params.action,
        comment: params.comment
      };
    }

    try {
      const response = await API.post(`requests/${form.id}/actions`, data);
      if (response && response.data) {
        const { activities, ...form } = response.data;
        setForm({ ...form });
        setActivities(activities);
        if (params.action === "request_quote") {
          Alert("success", "success", t("Estimate request sent."), t);
        } else if (params.action === "send_quote") {
          Alert("success", "success", t("Estimate sent."), t);
        } else if (params.action === "edit_request") {
          Alert("success", "success", t("Request set to edit mode."), t);
        } else if (params.action === "order") {
          Alert("success", "success", t("Request ordered."), t);
        } else if (params.action === "return_request") {
          Alert("success", "success", t("Estimate returned."), t);
        } else if (params.action === "request_check") {
          Alert("success", "success", t("Request check sent."), t);
        } else if (params.action === "return_check") {
          Alert("success", "success", t("Request check returned."), t);
        } else if (params.action === "complete_check") {
          Alert("success", "success", t("Check completed."), t);
        }
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const validateSender = () => {
    let newErrors = {};

    if (!form.request_type) {
      newErrors = { ...newErrors, request_type: "Required" };
    }
    if (!form.team_client_id) {
      newErrors = { ...newErrors, team_client_id: "Required" };
    }
    if (!form.request_body) {
      newErrors = { ...newErrors, request_body: "Required" };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const validateReceiver = () => {
    let newErrors = {};

    if (!form.price) {
      newErrors = { ...newErrors, price: "Required" };
    }
    if (form.price && !(form.price > 0)) {
      newErrors = {
        ...newErrors,
        price: "Please enter value that is not negative and zero"
      };
    }
    if (!form.due_date) {
      newErrors = { ...newErrors, due_date: "Required" };
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }
    return true;
  };

  const requestActions = {
    edit_request: {
      open: true,
      action: "edit_request",
      handleConfirm
    },
    order: {
      open: true,
      action: "order",
      handleConfirm
    },
    return_request: {
      open: true,
      action: "return_request",
      handleConfirm
    },
    send_quote: {
      open: true,
      action: "send_quote",
      handleConfirm
    },
    request_check: {
      open: true,
      action: "request_check",
      handleConfirm
    },
    return_check: {
      open: true,
      action: "return_check",
      handleConfirm
    },
    complete_check: {
      open: true,
      action: "complete_check",
      handleConfirm
    }
  };

  if (loading)
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );

  return (
    <div className={classes.actionButtonContainer}>
      {sender && status === statusValue.Editing && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            return handleConfirm({
              action: "request_quote"
            });
          }}
          className={classes.buttonMargin}
        >
          {t("Request Quote")}{" "}
        </Button>
      )}
      {(status === statusValue.Estimating ||
        status === statusValue.EstimateReturned ||
        status === statusValue.Ordering) &&
        (sender ? (
          <div className={classes.estimatingButtons}>
            {form.price && (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  return setRequestAction(requestActions.order);
                }}
                className={classes.buttonMargin}
              >
                {t("Order")}{" "}
              </Button>
            )}
            <Button
              type="primary"
              size="small"
              onClick={() => {
                return setRequestAction(requestActions.edit_request);
              }}
              className={classes.buttonMargin}
            >
              {t("Edit")}{" "}
            </Button>
          </div>
        ) : (
          canQuote && (
            <div className={classes.estimatingButtons}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  return setRequestAction(requestActions.return_request);
                }}
                className={classes.buttonMargin}
              >
                {t("Return request")}{" "}
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  const valid = validateReceiver();
                  if (!valid) {
                    return;
                  }
                  return setRequestAction(requestActions.send_quote);
                }}
                className={classes.buttonMargin}
              >
                {t("Send quote")}{" "}
              </Button>
            </div>
          )
        ))}

      {status === statusValue.Working && !sender && (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            return setRequestAction(requestActions.request_check);
          }}
          className={classes.buttonMargin}
        >
          {t("Request Check")}{" "}
        </Button>
      )}

      {status === statusValue.Checking && sender && (
        <div className={classes.estimatingButtons}>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              return setRequestAction(requestActions.complete_check);
            }}
            className={classes.buttonMargin}
          >
            {t("Complete check")}{" "}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              return setRequestAction(requestActions.return_check);
            }}
            className={classes.buttonMargin}
          >
            {t("Return check")}{" "}
          </Button>
        </div>
      )}
    </div>
  );
};
