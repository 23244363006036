import { Layout } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router";
import { AccountMenu, Logo, NotificationComponent } from "shared-components";
import { API, AuthContext, exceptionHandler } from "shared-components/src";

const useStyles = createUseStyles((theme: any) => {
  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.background,
      borderBottom: "1px solid #E6E6E6",
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      position: "fixed",
      maxHeight: 40,
      zIndex: "1000"
    },
    logoBlock: {
      display: "flex",
      whiteSpace: "nowrap"
    },
    teamName: {
      marginLeft: 15
    }
  };
});

const HeaderWrapper = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { defaultTeam, defaultTeamName, externalUser } = useContext(
    AuthContext
  );

  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState("");

  const { Header } = Layout;

  const publicLink = location.pathname.includes("/public-report");
  useEffect(() => {
    return () => {
      const pdfUploading = localStorage.getItem("pdfUploading");
      if (pdfUploading) {
        localStorage.removeItem("pdfUploading");
      }
    };
  }, []);

  useEffect(() => {
    defaultTeam && getTeamLogo();
  }, [defaultTeam]);

  const getTeamLogo = async () => {
    // Upload
    setLoading(true);

    try {
      const response = await API.get(`teams/${defaultTeam}/team-logo`);
      response.data && setLogo(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <Header className={classes.header}>
      <div className={classes.logoBlock}>
        {!loading && logo ? (
          <Logo
            textSize="small"
            color="black"
            src={logo}
            onClick={() => {
              // if (externalUser) {
              //   return;
              // }
              return history.push("/cases");
            }}
          />
        ) : (
          <Logo
            textSize="small"
            color="black"
            onClick={() => {
              // if (externalUser) {
              //   return;
              // }
              return history.push("/cases");
            }}
          />
        )}
        {defaultTeamName !== "" && !publicLink && (
          <div className={classes.teamName}>{defaultTeamName}</div>
        )}
      </div>
      {!externalUser && !publicLink && <NotificationComponent />}
      {!publicLink && <AccountMenu />}
    </Header>
  );
};

export { HeaderWrapper };
