import React from "react";
import { createUseStyles } from "react-jss";

import { Circle } from "../Circle";

interface Props {
  onClick?: (event?: any) => void;
  clickable?: boolean;
  color?: any;
  name?: any;
  photo?: any;
  className?: any;
  size?: "40" | "25" | any;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    circle: {
      borderRadius: "50%",
      cursor: ({ clickable }: Props) => {
        return clickable ? "pointer" : "default";
      },
      background: ({ color }: Props) => {
        return color;
      },
      height: ({ size }: Props) => {
        return `${size}px`;
      },
      width: ({ size }: Props) => {
        return `${size}px`;
      }
    },
    profilePicWrapper: {
      textAlign: "center"
    },
    image: {
      height: "40px",
      width: "40px",
      margin: "auto",
      borderRadius: "50%",
      border: `1px solid ${theme.borderColor}`
    }
  };
});

const Avatar: React.FC<Props> = props => {
  const { photo = "", color = "#cccccc", name = "", size = "40" } = props;
  const classes = useStyles(props);

  if (photo) {
    return (
      <div className={classes.profilePicWrapper}>
        <img
          className={`${classes.image} ${props.className}`}
          src={photo}
          alt={name}
        />
      </div>
    );
  }
  return <Circle color={color} size={size} />;
};
export { Avatar };
