export const theme = {
  primary: "#1A51CB",
  secondary: "",
  success: "",
  error: "#FF0000",
  errorLight: "#FFCE00",

  brandFont: "Noto Sans JP",

  blue6: "#1890FF",
  yellow5: "#FFEC3D",
  bgColor: "linear-gradient(180deg, #1359F5 0%, #0E3589 100%)",
  formBgColor: "#C8D3EC",
  textPrimary: "#1F1F1F",
  textWhite: "#FFFFFF",
  textRed: "#FF0000",
  textYellow: "#FFCE00",
  textGreen: "#6AE68C",
  textBlue: "#1A51CB",
  textDarkGrey: "#707070",
  darkBlueButton: "#1A51CB",
  textDark: "#989D9F",
  textBlack: "#2E2E2E",
  gray9: "#262626",
  gray8: "#595959",
  gray7: "#5A5A5A",
  gray6: "#6A6A6A",
  gray5: "#AFAFAF",
  gray4: "#E8E8E8",
  gray3: "#F0F2F5",
  colorDisabled: "#989D9F",
  borderColor: "#D9D9D9",
  red6: "#F5222D",
  lightBg: "#ffffff",
  formLabelColor: "#707070",
  border: "#707070",
  grayBlueBg: "#F0F8FD",
  colorLight: "#C9C9C9",
  background: "#F5F5F5",
  darkBackground: "#2e2e2e",
  colorGrey: "#E6E6E6",
  colorTextGrey: "#2E2E2E",
  formBackground: "#F0F8FD",
  borderActive: "#D39CF0",
  lightGrey: "#f1f1f1",
  lightBlue: "#1EDAEA",
  backgroundPink: "#FCD3D3",
  textLightBlack: "#272727",
  scrollColor: "#c1c1c1",
  imageBorder: "#F29696",
  selectText: "#6A6A6A",
  lightGreen: "#6AE68C",
  greenBackground: "#EDFFF2",
  greenTitle: "#6AE68C",
  royalBlue: "#3371c9"
};

export const defaultTheme = {
  name: "DEFAULT",
  variables: {
    ...theme
  }
};
