import { BellFilled } from "@ant-design/icons";
import { Badge, Divider, Dropdown } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { clearInterval, setInterval } from "timers";

import { AuthContext, Circle, Typography } from "../../../../src";
import { API } from "../../../api/api";
import { notificationTypes } from "../../../constants/notificationTypes";
import { exceptionHandler } from "../../../ErrorHandler";

const useStyles = createUseStyles((theme: any) => {
  return {
    notificationIconWrapper: {
      display: "flex",
      position: "relative",
      marginRight: 25,
      marginLeft: "auto",
      cursor: "pointer",
      "& svg": {
        height: 18.5,
        width: 15.5
      },
      "& sup": {
        marginTop: 4,
        marginRight: 7,
        minWidth: 10,
        minHeight: 10,
        borderRadius: "50%",
        right: -6,
        display: "flex",
        alignItems: "center",
        background: "#ff0000",
        boxShadow: "none",
        position: "cover"
      }
    },
    dropdownContent: {
      position: "absolute",
      backgroundColor: "white",
      boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.180392)",
      borderRadius: "10px",
      zIndex: 1,
      top: "-10px",
      right: 0,
      minWidth: 320,
      height: 560,
      overflowX: "auto"
    },
    notificationList: {
      height: 530,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: 10
      }
    },
    notificationWrapperTitle: {
      background: theme.textBlue,
      padding: "3px 0px",
      textAlign: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      color: "white",
      fontSize: 14,
      marginBottom: 2
    },
    notificationDivider: {
      margin: "0 !important",
      color: "#707070 !important",
      fontSize: 14
    },
    itemWrapper: {
      fontSize: 12,
      lineHeight: "14px",
      color: "#1F1F1F",
      display: "flex",
      alignItems: "center",
      padding: "9px 15px 9px 15px",
      "&:last-child": {
        "& p": {
          paddingBottom: 16
        }
      },
      "&:nth-child(2)": {
        paddingTop: 5
      },
      "&:hover": {
        background: theme.background
      }
    },
    notificationTimeWrapper: {
      display: "flex",
      flexDirection: "column"
    },
    notify: {
      fontSize: 16,
      margin: "5px 0px 5px 15px"
    },
    error: {
      marginTop: 30,
      textAlign: "center"
    }
  };
});

interface Notifications {
  date: string;
  notifications: Notification[];
}

export interface Pagination {
  page: number;
  pageSize: number;
}

interface Notification {
  id: number;
  team_id: number;
  sender_team_id: number;
  from: string;
  to: string;
  type: string;
  data: number;
  link: string;
  created_at: string;
  from_username: string;
  team_name: string;
  sender_team_name: string;
}

interface Props {}

const NotificationComponent: React.FC<Props> = () => {
  const classes = useStyles();
  const basePagination = {
    page: 1,
    pageSize: 10
  };

  const [notifications, setNotifications] = useState([] as Notifications[]);
  const [list, setList] = useState([] as Notification[]);
  const [pagination, setPagination] = useState(basePagination as Pagination);
  const [scrollTo, setScrollTo] = useState("" as string);
  const { defaultTeam, uid, profile, setProfile } = useContext(AuthContext);
  const { t } = useTranslation();

  const [_notification, _setNotificaiton] = useState(false as boolean);
  const notificationVisible = useRef(_notification);
  const setNotificationVisible = (data: boolean) => {
    notificationVisible.current = data;
    _setNotificaiton(data);
  };

  useEffect(() => {
    getNotifications(pagination);
  }, [defaultTeam, uid]);

  const getNotifications = async (
    pagination: Pagination,
    interval?: boolean
  ) => {
    if (!uid || !defaultTeam) {
      return;
    }
    if (notificationVisible.current && interval) {
      return;
    }

    try {
      const response: any = await API.get(`/users/${uid}/notifications`, {
        params: pagination
      });
      let newNotificaiton: Notification[];

      if (response.data && response.data.length) {
        if (pagination.page === 1) {
          newNotificaiton = response.data;
        } else {
          newNotificaiton = [...list, ...response.data];
        }
        if (newNotificaiton) {
          const lastNotification = newNotificaiton[newNotificaiton.length - 1];
          const newScrollTo =
            newNotificaiton.length < response.count
              ? `${lastNotification.id}`
              : "";
          setScrollTo(newScrollTo);
        }

        newNotificaiton =
          newNotificaiton.filter((notification: Notification) => {
            return notification.team_id === defaultTeam;
          }) || [];

        sortByDate([...newNotificaiton]);
        setList([...newNotificaiton]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const sortByDate = (response: Notification[]) => {
    const groups = response.reduce((groups: any, notification: any) => {
      const date = notification.created_at.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(notification);
      return groups;
    }, {});

    const groupByDate = Object.keys(groups).map(date => {
      return {
        date,
        notifications: groups[date]
      };
    });
    setNotifications(groupByDate);
  };

  const scrolled = async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (
      e &&
      e.currentTarget &&
      e.currentTarget.scrollTop ===
        e.currentTarget.scrollHeight - e.currentTarget.offsetHeight &&
      scrollTo
    ) {
      const newPagination = {
        ...pagination,
        page: pagination.page + 1
      };
      if (newPagination.page <= 10) {
        setPagination(newPagination);
        getNotifications(newPagination);
      }
    }
  };

  const handleNotificationClick = async (visible: boolean) => {
    setNotificationVisible(visible);
    if (visible) {
      const formData = new FormData();
      formData.append("notification", "true");
      try {
        const response = await API.put(`users/${uid}`, formData);
        setProfile(response.data);
      } catch (error) {
        exceptionHandler(error, t);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      try {
        getNotifications(basePagination, true);
      } catch (error) {
        exceptionHandler(error, t);
      }
    }, 12000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const menu = (
    <div className={classes.dropdownContent}>
      {notifications.length === 0 ? (
        <p className={classes.error}>{t("No Notifications")}</p>
      ) : (
        <>
          <div className={classes.notificationWrapperTitle}>
            <span>{t("Notifications")}</span>
          </div>
          <div className={classes.notificationList} onScroll={scrolled}>
            {notifications.map((data: Notifications, index: number) => {
              return (
                <div key={index}>
                  <Divider className={classes.notificationDivider}>
                    {moment(data.date).isSame(moment(), "day")
                      ? t("Today")
                      : data.date}
                  </Divider>

                  {data.notifications.map((notification: Notification) => {
                    const color = "white";
                    return (
                      <Link
                        to={notification.link}
                        id={`${notification.id}`}
                        className={classes.itemWrapper}
                        key={notification.id}
                      >
                        <div className={classes.notificationTimeWrapper}>
                          <Circle color={color} size="8" />

                          <Typography
                            type="h5"
                            className={classes.notificationDivider}
                          >
                            {moment(notification.created_at).format("HH:mm")}
                          </Typography>
                        </div>
                        <div className={classes.notify}>
                          <Typography type="body2">
                            {notification.team_name}
                          </Typography>
                          <Typography type="body2">
                            {[
                              "double-check-request",
                              "double-check-complete",
                              "double-check-return",
                              "new_reply"
                            ].includes(notification.type)
                              ? `${notification.from_username} ${t(
                                  notificationTypes[notification.type]
                                )}`
                              : t(notificationTypes[notification.type], {
                                  data: `${notification.data}`.padStart(6, "0"),
                                  senderTeamName: notification.sender_team_name
                                })}
                          </Typography>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={handleNotificationClick}
    >
      <div className={classes.notificationIconWrapper}>
        <Badge
          dot={
            profile &&
            list &&
            list.length &&
            profile.notification_read_at < list[0].created_at
              ? true
              : false
          }
        >
          <BellFilled />
        </Badge>
      </div>
    </Dropdown>
  );
};

export { NotificationComponent };
