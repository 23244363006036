import React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { CaseCard } from "shared-components";

interface Props {
  handleMyBookmark: any;
  handleTeamBookmark: any;
  patient: any;
  handleDelete: any;
  handleAssignment: any;
  onClick: any;
}

interface DropResult {
  id: string;
}

interface DragItem {
  type: string;
}

export const DraggableCard: React.FC<Props> = ({
  patient,
  handleMyBookmark,
  handleTeamBookmark,
  handleDelete,
  handleAssignment,
  onClick
}) => {
  const item = { type: "case-card" };
  const [{ opacity }, drag] = useDrag({
    item,
    end(item: DragItem | undefined, monitor: DragSourceMonitor) {
      const dropResult: DropResult = monitor.getDropResult();
      if (item && dropResult) {
        localStorage.setItem("pathologist_id", dropResult.id);
        handleAssignment();
      }
    },
    collect: (monitor: any) => {
      return {
        opacity: monitor.isDragging() ? 0.4 : 1
      };
    }
  });

  return (
    <div ref={drag} style={{ opacity, overflow: "hidden" }}>
      <CaseCard
        onClick={onClick}
        draggable
        patientInfo={patient}
        handleMyBookmark={handleMyBookmark}
        handleTeamBookmark={handleTeamBookmark}
        handleDelete={handleDelete}
      />
    </div>
  );
};
