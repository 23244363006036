export const caseTagMap = (tag: any) => {
  return {
    id: tag.id,
    caseImageId: tag.case_image_id,
    caseImageURL: tag.image_url,
    checked: tag.checked,
    comment: tag.comment,
    significance: tag.significance,
    disease: tag.disease,
    observation: tag.observation,
    taggingUserId: tag.user_id,
    caseImageDataURL: tag.imageDataUrl
  };
};
