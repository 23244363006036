import "antd/dist/antd.less";
import "./tailwind.css";
import "./App.css";

import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  AuthProvider,
  Forbidden,
  initSentry,
  NotFound,
  PdfContextProvider,
  RECAPTCHA_SITE_KEY,
  theme
} from "shared-components";

import { EmailConfirmation } from "./components/pages/Auth/Emailconfirmation";
import { PasswordReset } from "./components/pages/Auth/ForgotPassword";
import { SignIn } from "./components/pages/Auth/SignIn";
import { SignUp } from "./components/pages/Auth/SignUp";
import { CaseExternal } from "./components/pages/Cases/CaseExternal";
import { Home } from "./Home";
import { PrivateRoute } from "./PrivateRoute";
import { RestrictedRoute } from "./RestrictedRoute";

initSentry();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <PdfContextProvider>
          <Switch>
            <RestrictedRoute
              exact
              path="/email-confirmation"
              component={EmailConfirmation}
            />
            <Route
              exact
              path="/forgot-password"
              component={() => {
                return (
                  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                    <PasswordReset />
                  </GoogleReCaptchaProvider>
                );
              }}
            />
            <RestrictedRoute
              exact
              path="/sign-in"
              component={(props: any) => {
                return (
                  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                    <SignIn state={props.location.state} />
                  </GoogleReCaptchaProvider>
                );
              }}
            />
            <PrivateRoute exact path="/403" component={Forbidden} />
            <PrivateRoute
              exact
              path="/external-case"
              component={CaseExternal}
            />
            <RestrictedRoute
              exact
              path="/sign-up"
              component={() => {
                return (
                  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                    <SignUp />
                  </GoogleReCaptchaProvider>
                );
              }}
            />
            <Route path="/" component={Home} />
            <Route path="*" component={NotFound} />
          </Switch>
        </PdfContextProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);
