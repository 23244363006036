import { FileProtectOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { AuthContext } from "../../../AuthProvider";
import {
  CompleteTickIcon,
  DeleteConfirm,
  DoubleTickIcon,
  OptionsIcon
} from "../../../index";
import { DeleteIcon } from "../../assets";
import { Circle } from "../Circle";

interface Props {
  patientInfo: CaseListData;
  handleMyBookmark?: any;
  handleTeamBookmark?: any;
  handleDelete?: any;
  draggable?: boolean;
  onClick?: any;
  className?: any;
  noMargin?: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    casetab: {
      "& .ant-col-5": {
        display: "inherit"
      },
      "&  .ant-col-19": {
        display: "inherit"
      },
      cursor: ({ draggable }: Props) => {
        return draggable ? "move" : "unset";
      },
      boxShadow: "0px 0px 5px #00000026",
      background: ({ patientInfo }: Props) => {
        return patientInfo.is_marked
          ? "linear-gradient(to bottom right,transparent 51%,#FFCE00 0) bottom right/40px 40px no-repeat,white"
          : "white";
      },
      "&:hover": {
        cursor: "pointer",
        boxShadow: `0px 0px 5px 0px ${theme.primary}`,
        transition: " ease-in-out, width .35s ease-in-out"
      },
      padding: "10px 6px 14px 10px",
      marginBottom: 18,
      borderRadius: 6,
      marginLeft: ({ noMargin }: Props) => {
        return noMargin ? "0px" : "18px";
      },
      "@media(min-width: 1300px)": {
        width: 320
      },
      position: "relative",
      overflow: "hidden",
      border: "1px solid #E6E6E6"
    },
    title: {
      fontSize: 18,
      lineHeight: "27px",
      letterSpacing: "0.44px",
      color: theme.formLabelColor,
      marginBottom: "8px",
      fontFamily: "Noto Sans JP",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginRight: 20
    },
    dot: {
      marginBottom: 10
    },
    icon: {
      marginBottom: 10,
      "& path": {
        fill: theme.primary
      }
    },
    changeRequestedIcon: {
      marginBottom: 10,
      "& path": {
        fill: theme.error
      }
    },
    overlap: {
      display: "grid",
      marginTop: "10px"
    },
    label: {
      color: theme.colorLight,
      fontSize: "14px",
      lineHeight: "20px",
      display: "block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontFamily: "Noto Sans JP",
      marginBottom: 1
    },
    titleId: {
      fontSize: 20,
      lineHeight: "26px",
      color: theme.formLabelColor,
      width: 142,
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginBottom: 11,
      paddingRight: 10,
      fontFamily: "Roboto"
    },
    content: {
      color: theme.textDarkGrey,
      fontSize: "14px",
      lineHeight: "19px",
      paddingLeft: "13px",
      fontFamily: "Roboto",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    grid: {
      display: "inline-grid",
      position: "absolute"
    },
    dropdown: {
      position: "absolute",
      right: 6,
      top: 6,
      color: theme.textBlack,
      padding: "5px 11px"
    },
    fixCase: {
      position: "absolute",
      top: 100,
      left: 0
    },
    deleteIcon: {
      marginRight: "12px !important",
      fontSize: "15px !important",
      color: theme.textBlack
    },
    contentDiv: {
      marginLeft: "26px"
    },
    menu: {
      boxShadow: "1px 1px 7px rgba(0, 0, 0, 0.160784)",
      minWidth: "150px",
      borderRadius: 5,
      border: "1px solid #E6E6E6",
      position: "relative",
      left: "150px",
      marginTop: "12px"
    },
    idDiv: {
      marginTop: 8
    },
    privateBookmark: {
      width: "26px",
      height: "0",
      borderBottom: `7px solid ${theme.textYellow}`,
      borderTop: `7px solid ${theme.textYellow}`,
      borderRight: "4px solid transparent",
      position: "absolute",
      left: "0px",
      bottom: "42px"
    },
    teamBookmark: {
      width: "26px",
      height: "0",
      borderBottom: `7px solid ${theme.textGreen}`,
      borderTop: `7px solid ${theme.textGreen}`,
      borderRight: "4px solid transparent",
      position: "absolute",
      left: "0px",
      bottom: "19px"
    },
    myBookmarkMenuIcon: {
      width: "17px",
      height: "0",
      borderBottom: `4px solid ${theme.textYellow}`,
      borderTop: `4px solid ${theme.textYellow}`,
      borderRight: "4px solid transparent",
      transform: "rotate(-50deg)",
      margin: {
        right: 7,
        top: 5
      }
    },
    teamBookmarkMenuIcon: {
      width: "17px",
      height: "0",
      borderBottom: `4px solid ${theme.textGreen}`,
      borderTop: `4px solid ${theme.textGreen}`,
      borderRight: "4px solid transparent",
      transform: "rotate(-50deg)",
      margin: {
        right: 7,
        top: 5
      }
    },
    menuIcon: {
      display: "flex"
    },
    notoSansFont: {
      fontFamily: "Noto Sans JP"
    }
  };
});

const CaseCard: React.FC<Props> = ({
  patientInfo,
  handleMyBookmark,
  handleTeamBookmark,
  handleDelete,
  draggable,
  onClick,
  className,
  noMargin
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ noMargin, patientInfo, draggable });
  const { defaultTeam } = useContext(AuthContext);

  const circleColor = patientInfo.is_new
    ? "#6AE68C"
    : patientInfo.is_old
    ? "#FF0000"
    : "white";
  const onDelete = () => {
    DeleteConfirm(handleDelete);
  };

  const menu = (
    <Menu className={classes.menu}>
      <Menu.Item onClick={handleMyBookmark}>
        <div className={classes.menuIcon}>
          <div className={classes.myBookmarkMenuIcon} />
          {patientInfo.my_bookmark
            ? t("Remove from My Bookmark")
            : t("My Bookmark")}
        </div>
      </Menu.Item>

      <Menu.Item onClick={handleTeamBookmark}>
        <div className={classes.menuIcon}>
          <div className={classes.teamBookmarkMenuIcon} />
          {patientInfo.marked
            ? t("Remove from Team Bookmark")
            : t("Team Bookmark")}
        </div>
      </Menu.Item>
      {(patientInfo.team_id === defaultTeam ||
        patientInfo.team_id === undefined) && (
        <Menu.Item onClick={onDelete}>
          <DeleteIcon className={classes.deleteIcon} />
          {t("Delete")}
        </Menu.Item>
      )}
    </Menu>
  );

  let requiredOrgansString = "";
  if (patientInfo.organ !== undefined) {
    const makeOrgansArray = patientInfo.organ.split(",");
    const requiredOrgansArray = makeOrgansArray.map(organ => {
      return t(`${organ.substring(organ.indexOf("-") + 1)}`);
    });
    requiredOrgansString = requiredOrgansArray.toString();
  }
  return (
    <div className={`${classes.casetab} ${className}`}>
      <Dropdown overlay={menu} trigger={["click"]}>
        <span>
          <OptionsIcon className={classes.dropdown} />
        </span>
      </Dropdown>
      <div onClick={onClick}>
        {patientInfo.my_bookmark && <div className={classes.privateBookmark} />}

        {patientInfo.marked && <div className={classes.teamBookmark} />}

        <div className={classes.grid}>
          <div className={classes.dot}>
            <Circle size="10" color={circleColor} border={patientInfo.tbd} />
          </div>
          {patientInfo.completed && (
            <CompleteTickIcon className={classes.icon} />
          )}

          {patientInfo.double_check === "doubleCheck" && (
            <DoubleTickIcon className={classes.icon} />
          )}
          {patientInfo.double_check === "changeRequested" && (
            <DoubleTickIcon className={classes.changeRequestedIcon} />
          )}

          {patientInfo.fix_case && (
            <FileProtectOutlined className={classes.fixCase} />
          )}
        </div>
        <div className={classes.contentDiv}>
          <span className={classes.title}>
            {patientInfo.name ? patientInfo.name : "N/A"}
          </span>
          <Row className={classes.idDiv}>
            <Col span={12}>
              <span className={classes.label}>
                {t("Pathology Control No.")}
              </span>
              <span className={classes.titleId}>
                {patientInfo.pathology_number}
              </span>
            </Col>
            <Col span={12}>
              <span className={classes.label}>ID</span>
              <span className={classes.titleId}>
                {patientInfo.medicalRecordId || "-"}
              </span>
            </Col>

            <Col span={5}>
              <span className={classes.label}>{t("Organ")}</span>
            </Col>
            <Col span={19}>
              <span className={`${classes.content} ${classes.notoSansFont}`}>
                {patientInfo.organ ? requiredOrgansString : "-"}
              </span>
            </Col>

            <Col span={5}>
              <span className={classes.label}>{t("Created")}</span>
            </Col>
            <Col span={19}>
              <span className={classes.content}>
                {moment(patientInfo.created_at).format("YYYY/MM/DD HH:mm")}
              </span>
            </Col>
            <Col span={5}>
              <span className={classes.label}>{t("Updated")}</span>
            </Col>
            <Col span={19}>
              <span className={classes.content}>
                {moment(patientInfo.updated_at).format("YYYY/MM/DD HH:mm")}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export { CaseCard };
