import { Modal as ModalComponent } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  visible: boolean;
  children: any;
  onCancel: any;
  className?: any;
  width?: string;
  mask?: boolean;
  centered?: boolean;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    modal: {
      "& .ant-modal-body": {
        marginTop: "9%",
        left: 215,
        maxWidth: 850,
        height: 480,
        textAlign: "-webkit-center"
      },
      "& .ant-modal-footer": {
        display: "none"
      },
      "& .ant-modal-content": {
        borderRadius: 9,
        boxShadow: "0px 0px 10px #00000033"
      }
    }
  };
});

const Modal = (props: Props) => {
  const classes = useStyles();
  const { mask = true } = props;

  return (
    <div>
      <ModalComponent
        onCancel={props.onCancel}
        className={`${classes.modal} ${props.className}`}
        mask={mask}
        visible={props.visible}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        width={props.width || "40%"}
        centered={props.centered}
      >
        {props.children}{" "}
      </ModalComponent>
    </div>
  );
};
export { Modal };
