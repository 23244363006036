import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  exceptionHandler,
  SelectComponent,
  TextArea
} from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import { CaseFormValue } from "../organisms/RenderForm";
import { Container, FormDivider, FormTitle } from "./Layout";
import { useCaseFormValue } from "./useCaseFormValue";

const useStyles = createUseStyles((theme: any) => {
  return {
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    selectWrapper: {
      maxWidth: 200
    },
    select: {
      "& .ant-select": {
        marginBottom: 0
      },
      "& .ant-select-selector": {
        height: "29px !important",
        background: "none !important",
        borderColor: `${theme.selectText} !important`
      },
      " & .ant-select-arrow": {
        color: `${theme.selectText} !important`
      }
    },
    showData: {}
  };
});

export function General({
  reportId,
  formValues,
  setSaving,
  setCaseData,
  isConsult,
  publicReport,
  readOnly
}: {
  reportId: string;
  formValues: CaseFormValue[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseData: (response: any) => void;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}) {
  const [diagnosis, setDiagnosis, commitDiagnosis] = useCaseFormValue(
    reportId,
    "diagnosis",
    formValues
  );
  const [findings, setFindings, commitFindings] = useCaseFormValue(
    reportId,
    "findings",
    formValues
  );
  const [remarks, setRemarks, commitRemarks] = useCaseFormValue(
    reportId,
    "remarks",
    formValues
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const disabled = publicReport;

  const handleFormSelect = async (name: string, value: string) => {
    setSaving(true);
    try {
      const response = await API.post("/form-name-update", {
        report_id: parseInt(reportId),
        form_name: value
      });
      setCaseData(response);
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };
  return (
    <div id="findingsDiagnosis">
      <Container isConsult={isConsult}>
        <div className={classes.titleContainer}>
          <FormTitle isConsult={isConsult}>
            {t("Diagnosis / Findings")}
          </FormTitle>
          {!publicReport && (
            <SelectComponent
              wrapperClassName={classes.selectWrapper}
              className={classes.select}
              options={[
                {
                  label: t("IPF/UIP"),
                  value: "ipfuip"
                },
                {
                  label: t("General"),
                  value: "general"
                },
                {
                  label: t("Colorectal"),
                  value: "colorectal"
                }
              ]}
              name="form_name"
              placeholder={t("Select Form")}
              defaultValue="general"
              onChange={handleFormSelect}
              size="small"
              id="caseFormSelect"
            />
          )}
        </div>
        {readOnly ? (
          <>
            <ShowData
              className={classes.showData}
              value={diagnosis || "-"}
              label={t("Pathological findings")}
            />
            <FormDivider />

            <ShowData
              className={classes.showData}
              value={findings || "-"}
              label={t("Pathological diagnosis")}
            />
            <FormDivider />
            <ShowData
              className={classes.showData}
              value={remarks || "-"}
              label={t("Remarks")}
            />
          </>
        ) : (
          <>
            <TextArea
              label={t("Pathological findings")}
              placeholder={t("Placeholder of pathological findings")}
              name="diagnosis"
              value={diagnosis}
              onChange={(_, value) => {
                return setDiagnosis(value);
              }}
              onBlur={async () => {
                if (publicReport) {
                  return;
                }
                setSaving(true);
                await commitDiagnosis();
                setSaving(false);
              }}
              rows={10}
              maxLength={1024}
              disabled={disabled}
            />
            <FormDivider />
            <TextArea
              label={t("Pathological diagnosis")}
              placeholder={t("Placeholder of pathological diagnosis")}
              name="findings"
              value={findings}
              onChange={(_, value) => {
                return setFindings(value);
              }}
              onBlur={async () => {
                if (publicReport) {
                  return;
                }
                setSaving(true);
                await commitFindings();
                setSaving(false);
              }}
              rows={5}
              maxLength={1024}
              disabled={disabled}
            />
            <FormDivider />
            <TextArea
              label={t("Remarks")}
              placeholder={t("Placeholder of remarks")}
              name="remarks"
              value={remarks}
              onChange={(_, value) => {
                return setRemarks(value);
              }}
              onBlur={async () => {
                if (publicReport) {
                  return;
                }
                setSaving(true);
                await commitRemarks();
                setSaving(false);
              }}
              rows={5}
              maxLength={1024}
              disabled={disabled}
            />
          </>
        )}
      </Container>
    </div>
  );
}
