const LocalStorageService = (function() {
  let service: any;

  const getService = () => {
    if (!service) {
      service = {
        getService: getService,
        setAccessToken: setAccessToken,
        setRefreshToken: setRefreshToken,
        getAccessToken: getAccessToken,
        getRefreshToken: getRefreshToken,
        clearToken: clearToken
      };
      return service;
    }
    return service;
  };

  const setAccessToken = (value: string) => {
    localStorage.setItem(`accessToken`, value);
  };

  const setRefreshToken = (value: string) => {
    localStorage.setItem(`refreshToken`, value);
  };

  const getAccessToken = () => {
    return localStorage.getItem(`accessToken`);
  };

  const getRefreshToken = () => {
    return localStorage.getItem(`refreshToken`);
  };

  const clearToken = () => {
    localStorage.removeItem(`accessToken`);
    localStorage.removeItem(`refreshToken`);
  };

  return {
    getService: getService,
    setAccessToken: setAccessToken,
    setRefreshToken: setRefreshToken,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    clearToken: clearToken
  };
})();

export { LocalStorageService };
