import { Divider, Form, Input, Select, Switch } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, Modal, Typography } from "shared-components";

import { SharingSettingTable } from "./SharingSettingTable";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      marginTop: "-2% !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-content": {
        marginTop: "1%"
      },

      "& .ant-modal-body": {
        minWidth: 600,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px"
      },
      "& .ant-upload-drag": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 25,
        justifyContent: "center",
        height: 135,
        width: 514,
        textAlign: "center",
        background: theme.textWhite
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      },
      "& .ant-upload-drag-container": {
        marginTop: -5,

        "& article": {
          textAlign: "center"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    logo: {
      "& svg": {
        height: 25,
        width: 34,
        marginRight: 15
      }
    },
    modal: {
      width: "100%",
      padding: "0px 15px"
    },
    modalButton: {
      textAlign: "center"
    },

    button: {
      height: 36,
      width: 160,
      fontSize: 14
    },
    draggerComponent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    draggerSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 514,
      border: "1px dashed #d9d9d9",
      marginTop: 23,
      height: 135
    },
    middleText: {
      marginTop: 20,
      marginBottom: 22,
      "& article": {
        textAlign: "center"
      }
    },
    text: {
      color: theme.primary,
      textAlign: "center",
      letterSpacing: "1.08px",
      marginBottom: 4,
      "& article": {
        textAlign: "center"
      }
    },
    textBlue: {
      color: theme.primary
    },
    textGreen: {
      color: theme.textGreen
    },
    inputCaseNo: {
      width: "fit-content"
    }
  };
});

interface Props {
  visible: boolean;
  onSubmit: (newPermission: PermissionRequestData) => void;
  onCancel: () => void;
  onChangePermission: (name: string, permission: string) => void;
  onDeletePermission: (id_email: string, registered: boolean) => void;
  onResendInvitation: (email: string) => void;
  onTogglePublicURL: (value: boolean) => void;
  permissions: ReportPermissionData[];
  publicURLData: PutlicURLData;
}
const SharingSettingModal = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);

  const [newPermission, setNewPermission] = useState<PermissionRequestData>({
    email: "",
    permission: "reviewer"
  });

  const handleModalField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setNewPermission(newPermission => {
      return { ...newPermission, [name]: value };
    });
  };

  const handlePermissionChange = (permission: PermissionType) => {
    setNewPermission(newPermission => {
      return { ...newPermission, permission: permission };
    });
  };

  const handleAddPermission = async () => {
    setLoading(true);
    try {
      await props.onSubmit(newPermission);
      setNewPermission({
        email: "",
        permission: "reviewer"
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={props.onCancel}
      mask={false}
    >
      <div className={classes.header}>
        <Typography type="body1">{t("Report Sharing Setting")}</Typography>
      </div>
      <span style={{ padding: "8px" }}></span>
      <div style={{ width: "100%", textAlign: "left", padding: "0px 15px" }}>
        <Typography type="body2">{t("Input email address to send")}</Typography>
      </div>
      <span style={{ padding: "8px" }}></span>
      <Form
        className={classes.modal}
        layout="vertical"
        onFinish={() => {
          return handleAddPermission();
        }}
      >
        <ul style={{ display: "flex", textAlign: "left" }}>
          <Form.Item
            id="email"
            name="email"
            label={t("Email")}
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <li>
              <div className={classes.inputCaseNo}>
                <Input
                  style={{ width: "250px" }}
                  value={newPermission.email}
                  autoComplete="off"
                  name="email"
                  placeholder={t("")}
                  onChange={handleModalField}
                  maxLength={90}
                />
              </div>
            </li>
          </Form.Item>
          <span style={{ padding: "10px" }} />
          <Form.Item
            id="permission"
            name="permission"
            label={t("Permission")}
            rules={[
              {
                required: false,
                message: t("Required")
              }
            ]}
          >
            <li>
              <div className={classes.inputCaseNo}>
                <Select
                  value={newPermission.permission}
                  style={{ width: "250px" }}
                  onChange={handlePermissionChange}
                >
                  <Select.Option value="owner">{t("Owner")}</Select.Option>
                  <Select.Option value="editor">{t("Editor")}</Select.Option>
                  <Select.Option value="reviewer">
                    {t("Commentable Reviewer")}
                  </Select.Option>
                </Select>
              </div>
            </li>
          </Form.Item>
        </ul>
        {newPermission.email && newPermission.email.length > 0 && (
          <ul style={{ display: "flex", justifyContent: "center" }}>
            <li>
              <Form.Item className={classes.modalButton}>
                <Button
                  className={classes.button}
                  size="large"
                  type="default"
                  htmlType="button"
                  onClick={props.onCancel}
                  loading={loading}
                  data-testid="create-button"
                >
                  {t("Cancel")}
                </Button>
              </Form.Item>
            </li>
            <span style={{ padding: "10px" }} />
            <li>
              <Form.Item className={classes.modalButton}>
                <Button
                  className={classes.button}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  data-testid="create-button"
                >
                  {t("Add")}
                </Button>
              </Form.Item>
            </li>
          </ul>
        )}
      </Form>
      {!newPermission.email && newPermission.email.length === 0 && (
        <div style={{ width: "100%", textAlign: "left", padding: "0px 15px" }}>
          <Divider />
          <div>
            <Typography type="body2">{t("Edit Sharing Settings")}</Typography>
          </div>
          <span style={{ padding: "8px" }}></span>
          <SharingSettingTable
            rows={props.permissions}
            onChangePermission={props.onChangePermission}
            onDeletePermission={props.onDeletePermission}
            onResendInvitation={props.onResendInvitation}
          />
          <Divider />
          <div>
            <Typography type="body2">{t("Create Public URL")}</Typography>
          </div>
          <span style={{ padding: "8px" }} />
          <div>
            <Switch
              checked={props.publicURLData.active}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(value: boolean) => {
                props.onTogglePublicURL(value);
              }}
            />
          </div>
          <span style={{ padding: "8px" }} />
          {props.publicURLData.active && (
            <div>
              <a
                href={`${window.location.origin}/public-report/${props.publicURLData.url}`}
              >{`${window.location.origin}/public-report/${props.publicURLData.url}`}</a>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export { SharingSettingModal };
