import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, API, APIV2, exceptionHandler } from "shared-components";

import { SharingSettingModal } from "./SharingSettingModal";

interface Props {
  report_id: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  publicLink?: string;
  permissions: ReportPermissionData[];
  onChangePermission: (newPermissions: ReportPermissionData[]) => void;
}

const SharingSettings = (props: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPublicURLData({
      active: props.publicLink ? true : false,
      url: props.publicLink ? props.publicLink : ""
    });
  }, [props.publicLink]);

  const [publicURLData, setPublicURLData] = useState<PutlicURLData>({
    active: false,
    url: "bon-bon.co.jp"
  });

  const inviteNewUser = async (newPermission: PermissionRequestData) => {
    try {
      await API.post(
        `reports/${props.report_id}/unregistered-personal-permissions`,
        {
          email: newPermission.email,
          level: newPermission.permission,
          send_invitation: true,
          invitation_language: "ja"
        }
      );
      Alert("success", "success", t(`Invitation email was sent.`), t);
      await props.onChangePermission(
        props.permissions.concat({
          subject_type: "unregistered_user",
          subject: { email: newPermission.email },
          level: newPermission.permission,
          name: "",
          image_url: "",
          occupation: ""
        })
      );
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const resendInvitation = async (email: string) => {
    try {
      await API.post(
        `reports/${props.report_id}/unregistered-personal-permissions/resend`,
        {
          email: email,
          invitation_language: "ja"
        }
      );
      Alert("success", "success", t(`Invitation email was sent.`), t);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const createPermission = async (newPermission: PermissionRequestData) => {
    try {
      const newPermissionWithProfile = await getUserProfileByEmail(
        newPermission
      );
      if (newPermissionWithProfile) {
        const newPermissions = props.permissions.concat(
          newPermissionWithProfile
        );
        await props.onChangePermission(newPermissions);
      } else {
        inviteNewUser(newPermission);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const deletePermission = async (id_email: string, registered: boolean) => {
    try {
      if (registered) {
        const newPermissions = props.permissions.filter(
          (permission: ReportPermissionData) => {
            return permission.subject.id !== id_email;
          }
        );
        await props.onChangePermission(newPermissions);
      } else {
        await API.delete(
          `reports/${props.report_id}/unregistered-personal-permissions`,
          {
            params: { email: id_email }
          }
        );
        const newPermissions = props.permissions.filter(
          (permission: ReportPermissionData) => {
            return permission.subject.email !== id_email;
          }
        );
        await props.onChangePermission(newPermissions);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const getUserProfileByEmail = async (request: PermissionRequestData) => {
    try {
      const response: any = await APIV2.get(
        `users/${request.email}/publicProfile`,
        { params: { by: "email" } }
      );
      return {
        subject_type: "user",
        subject: { id: response && response.user_id ? response.user_id : "" },
        level: request.permission,
        name:
          response && response.display_name
            ? response.display_name
            : response.user_id
            ? response.user_id
            : "",
        image_url: response ? response.image_url : "",
        occupation: response ? response.occupation : ""
      };
    } catch (error) {
      // exceptionHandler(error, t);
      return null;
    }
  };

  const handleChangePermissions = (name: string, value: string) => {
    const newPermissions = props.permissions.map(
      (data: ReportPermissionData) => {
        if (data.name === name) {
          return {
            ...data,
            name: name,
            level: value
          };
        }
        return data;
      }
    );
    props.onChangePermission(newPermissions);
  };

  const createURLHandler = async () => {
    try {
      const response: any = await API.post(
        `reports/${props.report_id}/public-link`,
        {
          confirm: true,
          personal: false
        }
      );
      if (response && response.data) {
        setPublicURLData({ active: true, url: response.data.public_link });
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const removeURLHandler = async () => {
    try {
      const response: any = await API.post(
        `reports/${props.report_id}/public-link`,
        {
          confirm: false
        }
      );
      if (response && response.data) {
        setPublicURLData({ active: false, url: "" });
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <SharingSettingModal
      visible={props.visible}
      onCancel={() => {
        props.setVisible(false);
      }}
      onSubmit={createPermission}
      onChangePermission={handleChangePermissions}
      onDeletePermission={deletePermission}
      onResendInvitation={resendInvitation}
      onTogglePublicURL={(value: boolean) => {
        value ? createURLHandler() : removeURLHandler();
      }}
      permissions={props.permissions}
      publicURLData={publicURLData}
    />
  );
};

export { SharingSettings };
