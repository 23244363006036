import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

interface Props {
  checked?: boolean;
  onClick: any;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      position: "relative",
      height: 20,
      width: 20
    },
    checkbox: {
      // visibility: "hidden",
      height: "20px",
      width: "20px",
      zIndex: 10
    },
    geekmark: ({ value }: { value?: boolean }) => {
      return {
        pointerEvents: "none",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "20px",
        width: "20px",
        border: "1px solid",
        borderColor: theme.colorLight,
        borderRadius: 2,
        backgroundColor: value ? theme.lightGreen : "white",
        "&:hover": {
          cursor: "pointer"
        }
      };
    },
    tickmark: {
      position: "absolute",
      pointerEvents: "none",
      left: "7px",
      bottom: "5px",
      width: "6px",
      height: "12px",
      border: "solid white",
      borderWidth: "0px 3px 3px 0px",
      webkitTransform: "rotate(45deg)",
      msTransform: "rotate(45deg)",
      transform: "rotate(45deg)"
    }
  };
});

export const CheckboxComponent = ({ checked, onClick }: Props) => {
  const [value, setValue] = useState(false as boolean | undefined);

  const classes = useStyles({ value });

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  const handleClick = (event: any) => {
    setValue(event.target.checked);
    onClick(event);
  };

  return (
    <div className={classes.container}>
      <Checkbox
        className={classes.checkbox}
        onClick={handleClick}
        checked={value}
      />
      <span className={classes.geekmark} />
      <span className={classes.tickmark} />
    </div>
  );
};
