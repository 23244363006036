import { Cascader, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Button, Modal, TextArea } from "shared-components";

const useStyles = createUseStyles(() => {
  return {
    modalContainer: {
      marginTop: "-2% !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-content": {
        marginTop: "1%"
      },
      "& .ant-modal-body": {
        minWidth: 450,
        display: "flex",
        flexDirection: "column"
      }
    },
    button: {
      margin: "0 auto",
      height: 36,
      width: 160,
      fontSize: 14
    },
    removeButton: {
      margin: "50px auto 0",
      height: 26,
      width: 120,
      fontSize: 12
    }
  };
});

interface Props {
  annotation: any;
  onCancel: any;
  tagVocabulary: any;
  observationOptions: any;
  handleUpdateAnnotation: any;
  handleCreateAnnotation: any;
  handleDeleteAnnotation: any;
}
const AnnotationModal = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);
  const [comment, setComment] = useState("");
  const [tag, setTag] = useState([]);

  useEffect(() => {
    getTag(props.annotation);
    getComment(props.annotation);
  }, [props.annotation]);

  const getTag = (annotation: any) => {
    if (!annotation || !annotation.body) {
      setTag([]);
      return;
    }
    const tag = annotation.body.find((data: any) => {
      return data.purpose === "tagging";
    });
    if (tag && tag.value) {
      setTag(tag.value.split(" / "));
    } else {
      setTag([]);
    }
  };

  const getComment = (annotation: any) => {
    if (!annotation || !annotation.body) {
      setComment("");
      return;
    }
    const comment = annotation.body.find((data: any) => {
      return data.purpose === "commenting";
    });
    if (comment && comment.value) {
      setComment(comment.value);
    } else {
      setComment("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (props.annotation.original) {
      await props.handleUpdateAnnotation(props.annotation, tag, comment);
    } else {
      await props.handleCreateAnnotation(props.annotation, tag, comment);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await props.handleDeleteAnnotation(props.annotation);
    setLoading(false);
  };

  const displayRender = (label: any) => {
    return label.join(" / ") !== " / " ? label.join(" / ") : "";
  };

  const handleTagChange = (event: any) => {
    setTag(event);
  };

  let options: any = [];
  if (props.tagVocabulary) {
    options = [...props.tagVocabulary] || [];
    options[0].children = [
      {
        value: "Others",
        label: t("Others"),
        children: props.observationOptions || []
      }
    ];
  }

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.annotation !== null}
      onCancel={props.onCancel}
      width="850px"
      mask={false}
    >
      <TextArea
        placeholder={t("Comment")}
        name="comment"
        value={comment}
        defaultValue={""}
        onChange={(_, value) => {
          return setComment(value);
        }}
        rows={10}
        maxLength={3000}
      />

      <Form.Item>
        <Cascader
          options={options}
          expandTrigger="hover"
          displayRender={displayRender}
          onChange={handleTagChange}
          value={tag}
        />
      </Form.Item>
      <Button
        className={classes.button}
        size="large"
        type="primary"
        htmlType="button"
        loading={loading}
        data-testid="create-button"
        onClick={handleSubmit}
      >
        {t("Submit")}
      </Button>

      {props.annotation && props.annotation.original && (
        <Button
          className={classes.removeButton}
          size="small"
          htmlType="button"
          loading={loading}
          data-testid="create-button"
          onClick={handleDelete}
        >
          {t("Delete")}
        </Button>
      )}
    </Modal>
  );
};

export { AnnotationModal };
