import React from "react";
import { useTranslation } from "react-i18next";

import { OrganGroup } from "./data";

export function OrganCheckboxes({
  column = 1,
  organ,
  className,
  onChange,
  selectedOrgans
}: {
  organ: OrganGroup;
  column?: number;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
  selectedOrgans: string[];
}) {
  const { t } = useTranslation();
  const gridClass = column === 1 ? "w-full" : `w-1/${column}`;
  return (
    <div className={`flex p-2 ${className}`}>
      <div className="border rounded p-2 relative border-indigo-500 pt-4 w-full">
        <div
          className="title absolute text-indigo-500 px-4 bg-white"
          style={{ top: "-12px" }}
        >
          <span>{t(organ.name)}</span>
        </div>
        <div className="forms flex flex-wrap">
          {organ.organs.map(o => {
            const checked = selectedOrgans && selectedOrgans.includes(o.value);
            const checkedClass = checked ? "bg-indigo-100" : "";
            return (
              <div
                key={`organ-checkbox-${organ.name}-${o.value}`}
                className={gridClass}
              >
                <label
                  htmlFor={`organ-checkbox-${organ.name}-${o.value}`}
                  className={`flex items-center pl-1 ${checkedClass}`}
                  style={{ marginTop: 2, marginBottom: 2 }}
                >
                  <input
                    type="checkbox"
                    name=""
                    id={`organ-checkbox-${organ.name}-${o.value}`}
                    value={o.value}
                    className="mr-2"
                    onChange={onChange}
                    checked={checked}
                  />
                  <span>{t(o.name)}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
