import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  exceptionHandler,
  SelectComponent,
  TextField
} from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import { FormTitle } from "../../../forms/Layout";
import { CaseDoubleCheck, Member } from "../../../organisms/DoubleCheck";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      width: "100%",
      marginBottom: "-30px"
    },
    formContainer: ({ disabledField }: { disabledField?: boolean }) => {
      return {
        width: "100%",
        display: "flex",
        margin: "0px !important",
        padding: disabledField ? "15px 20px" : "15px 15px",
        backgroundColor: disabledField
          ? theme.formBackground
          : theme.greenBackground
      };
    },
    textField: {
      marginBottom: 25
    },
    title: {
      fontSize: 24,
      marginBottom: 10
    },
    formTitle: {
      width: "100%",
      padding: "0px 20px 14px"
    }
  };
});

interface Props {
  members: Member[];
  state: CaseData;
  caseDoubleCheck: CaseDoubleCheck | null;
  setState: React.Dispatch<React.SetStateAction<CaseData>>;
  disabledField?: boolean;
  isConsult?: boolean;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConsultReportForm = ({
  members,
  state,
  caseDoubleCheck,
  setState,
  disabledField,
  isConsult,
  setSaving
}: Props) => {
  const classes = useStyles({ disabledField });
  const { t } = useTranslation();

  const [form, setForm] = useState({} as ConsultForm);
  const [doubleCheckUser, setDoubleCheckUser] = useState("" as string);

  useEffect(() => {
    if (
      caseDoubleCheck &&
      caseDoubleCheck.userId &&
      members &&
      members.length > 0
    ) {
      const doubleCheckMember = members.find((member: Member) => {
        return member.id === caseDoubleCheck.userId;
      });
      if (doubleCheckMember) {
        setDoubleCheckUser(doubleCheckMember.name || doubleCheckMember.email);
      }
    }
  }, [caseDoubleCheck, members]);

  useEffect(() => {
    mapStateToForm();
  }, [state]);

  const mapStateToForm = () => {
    if (
      form.consultHospital === state.consultHospital &&
      form.consultNumber === state.consultNumber &&
      state.consultant === form.consultant
    ) {
      return;
    }
    setForm({
      consultant: state.consultant,
      consultHospital: state.consultHospital,
      consultNumber: state.consultNumber,
      consultantName: state.consultantName
    });
    if (state.completedAt) {
      setDoubleCheckUser(state.consultantDoubleCheckName);
    }
  };

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    if (name) {
      setForm({
        ...form,
        [name]: type === "number" ? parseInt(`${value}`) : value
      });
    }
  };

  const handleUpdate = async () => {
    setSaving(true);
    try {
      await API.put(`/reports/${state.reportId}/consultation`, {
        consult_user_id: form.consultant,
        consult_number: form.consultNumber,
        consult_hospital: form.consultHospital
      });
      setState({ ...state, ...form });
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  const findLabel = (value: string) => {
    let options: any = [];
    members.forEach((member: Member) => {
      options.push({
        label: member.name || member.email,
        value: member.id
      });
    });

    const returnVal = options.filter((item: any) => {
      return item.value === value;
    });
    return returnVal[0] ? returnVal[0].label : "";
  };

  const confirmed = state.confirmedAt;

  return (
    <div className={classes.container}>
      <Row className={classes.formContainer} gutter={40}>
        {disabledField && (
          <FormTitle isConsult={isConsult} className={classes.formTitle}>
            <div className="flex flex-wrap">
              <div className="mr-4">{t("Consultation Information")}</div>
            </div>
          </FormTitle>
        )}
        {confirmed ? (
          <>
            <Col xs={12}>
              <ShowData label={t("Consult code")} value={form.consultNumber} />
              <ShowData
                label={t("Consultant")}
                value={
                  disabledField
                    ? form.consultantName
                    : findLabel(form.consultant)
                }
              />
            </Col>
            <Col xs={12}>
              <ShowData
                label={t("Consult hospital name")}
                value={form.consultHospital}
              />
              <ShowData label={t("Double checker")} value={doubleCheckUser} />
            </Col>
          </>
        ) : (
          <>
            <Col xs={12}>
              <TextField
                label={t("Consult code")}
                name="consultNumber"
                wraperClass={classes.textField}
                onChange={handleChange}
                editValue={form.consultNumber}
                onBlur={handleUpdate}
                disabled={disabledField}
              />
              {disabledField ? (
                <TextField
                  label={t("Consultant")}
                  wraperClass={classes.textField}
                  editValue={state.consultantName}
                  disabled
                />
              ) : (
                <SelectComponent
                  label={t("Consultant")}
                  name="consultant"
                  options={members.map((member: Member) => {
                    return {
                      label: member.name || member.email,
                      value: member.id
                    };
                  })}
                  className={classes.textField}
                  onChange={(name: string, value: any) => {
                    return handleChange({ target: { name, value } });
                  }}
                  value={form.consultant}
                  size="middle"
                  onBlur={handleUpdate}
                  disabled={disabledField}
                />
              )}
            </Col>
            <Col xs={12}>
              <TextField
                label={t("Consult hospital name")}
                name="consultHospital"
                wraperClass={classes.textField}
                onChange={handleChange}
                editValue={form.consultHospital}
                onBlur={handleUpdate}
                disabled={disabledField}
              />
              <TextField
                label={t("Double checker")}
                name="doubleChecker"
                wraperClass={classes.textField}
                onChange={handleChange}
                editValue={doubleCheckUser}
                disabled={true}
              />
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
