import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared-components";

import { CreateCaseInFolderModal } from "./CreateCaseInFolderModal";
import styles from "./index.module.css";

interface Props {
  visibility: boolean;
  moveCase?: (case_id: number, parent_folder_id: number) => void;
  currentFolderId?: number;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateCaseButton = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.folderbuttonWrapper}>
      <Button
        onClick={() => {
          props.setVisibility(true);
        }}
        className={styles.folderbutton}
        type="primary"
      >
        <p>{t("Create New Case")}</p>
      </Button>
      <CreateCaseInFolderModal
        visible={props.visibility}
        moveCase={props.moveCase}
        currentFolderId={props.currentFolderId}
        onCancel={() => {
          props.setVisibility(false);
        }}
      />
    </div>
  );
};

export { CreateCaseButton };
