import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      marginBottom: 20
    },
    labelStyle: {
      fontSize: "12px",
      lineHeight: "14px",
      marginBottom: 4,
      color: theme.gray6
    },
    valueStyle: {
      fontSize: "16px",
      lineHeight: "19px",
      whiteSpace: "break-spaces",
      color: theme.gray6
    },
    subLabelStyle: {
      fontSize: "12px",
      marginBottom: 4,
      lineHeight: "14px",
      color: theme.textDarkGrey
    }
  };
});

interface Props {
  label: string;
  value: string | number;
  subLabel?: string;
  className?: string;
}

export const ShowData = ({ label, value, className, subLabel }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.labelStyle}>{label}</div>
      {subLabel && <div className={classes.subLabelStyle}>{subLabel}</div>}
      <div className={classes.valueStyle}>{value ? t(`${value}`) : "-"}</div>
    </div>
  );
};
