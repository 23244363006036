import React, { useContext } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Redirect, Switch } from "react-router-dom";
import { AuthContext } from "shared-components";

import { CaseLibraryPage } from "./components/case-library/";
import { SideBar } from "./components/molecules/SideBar";
import { Assignment } from "./components/pages/Assignment/Assignment";
import { Case } from "./components/pages/Cases/Case";
import { CaseList } from "./components/pages/Cases/CaseList";
import { Clients } from "./components/pages/Client/client";
import { CreateEditRequest } from "./components/pages/DiagnosisRequest/CreateEditRequest";
import { DiagnosisList } from "./components/pages/DiagnosisRequest/DiagnosisList";
import { Member } from "./components/pages/Member/Member";
import { Settings } from "./components/pages/SettingsPage/Settings";
import { PrivateRoute } from "./PrivateRoute";

const HomeWithSiderBar = () => {
  const { authenticatedOwner, externalUser } = useContext(AuthContext);

  return (
    <React.Fragment>
      <SideBar />
      <Switch>
        <PrivateRoute exact path="/cases/:id" component={Case} />
        <PrivateRoute exact path="/cases" component={CaseList} />
        <PrivateRoute
          exact
          path="/case-library/:id"
          component={CaseLibraryPage}
        />
        <Redirect to="/case-library/0" from="/case-library" />
        {authenticatedOwner && (
          <PrivateRoute exact path="/members" component={Member} />
        )}
        {!externalUser && (
          <PrivateRoute
            exact
            path="/assignment"
            component={() => {
              return (
                <DndProvider backend={HTML5Backend}>
                  <Assignment />
                </DndProvider>
              );
            }}
          />
        )}
        {!externalUser && (
          <PrivateRoute exact path="/clients" component={Clients} />
        )}
        {!externalUser && (
          <PrivateRoute
            exact
            path="/diagnosis/:id"
            component={CreateEditRequest}
          />
        )}
        {!externalUser && (
          <PrivateRoute exact path="/diagnosis" component={DiagnosisList} />
        )}
        <PrivateRoute exact path="/settings" component={Settings} />
        <Redirect to="/cases" from="/" />
      </Switch>
    </React.Fragment>
  );
};

export { HomeWithSiderBar };
