import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router-dom";
import {
  API,
  AuthContext,
  exceptionHandler,
  // PdfContext,
  Typography
} from "shared-components";

import { CaseDoubleCheck, Member } from "../../organisms/DoubleCheck";
import { RightSidebar } from "../../organisms/RightSidebar";
// import { caseToPdf } from "../../utils";

interface Props {
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  userTeamId: number;
  caseDoubleCheck: CaseDoubleCheck | null;
  setDoubleCheckData: (value: any) => void;
  state: CaseData;
  setState: React.Dispatch<React.SetStateAction<CaseData>>;
  allMembers: Member[];
  setCaseData: (response: any) => void;
  setHasSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  permissions: ReportPermissionData[];
  onChangePermission: (newPermissions: ReportPermissionData[]) => void;
  editable: boolean;
  isInCaseTeam: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    button: {
      fontWeight: 300,
      margin: "auto",
      display: "block",
      marginBottom: 40
    },

    bottomContainer: {
      position: "fixed",
      display: "flex",
      alignItems: "center",
      paddingLeft: 145,
      width: "-webkit-fill-available",
      bottom: 0,
      left: 0,
      height: 70,
      backgroundColor: theme.background
    },
    moz: {
      width: "-moz-available"
    },
    blueButton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 30,
      minWidth: 160,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    emptybutton: {
      height: "28px",
      padding: "2px 10px",
      borderRadius: "3px",
      marginRight: 20,
      minWidth: 160,
      backgroundColor: theme.textWhite,
      color: theme.textPrimary,
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textPrimary
      }
    },
    buttonWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end"
    },
    member: {
      borderRadius: 3,
      height: 24,
      position: "absolute",
      minWidth: 200
    },
    memberName: {
      margin: "0 5px",
      fontSize: 16,
      fontColor: theme.textPrimary
    }
  };
});

const CaseConfirmation = ({
  setSaving,
  userTeamId,
  caseDoubleCheck,
  setDoubleCheckData,
  state,
  allMembers,
  setCaseData,
  setHasSidebar,
  setState,
  permissions,
  onChangePermission,
  editable,
  isInCaseTeam
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { externalUser } = useContext(AuthContext);
  const isConsult = state.consultation;
  const { id: caseId, caseNumber, reportId, clientTeamId, age } = state;
  const [doubleCheckUser, setDoubleCheckUser] = useState(null as Member | null);
  const [confirmRequest, setConfirmRequest] = useState(false as boolean);
  const [doubleCheckConfirm, setDoubleCheckConfirm] = useState(
    false as boolean
  );

  const handleDoubleCheck = async () => {
    if (doubleCheckUser && doubleCheckUser.id) {
      setSaving(true);
      try {
        const response = await API.post("/double-check/request", {
          report_id: reportId,
          user_id: doubleCheckUser.id,
          client_team_id: isConsult ? clientTeamId : 0
        });
        setDoubleCheckUser(null);
        setDoubleCheckData(response.data);
      } catch (error) {
        exceptionHandler(error, t);
      }
      setSaving(false);
    }
  };

  const handleConfirm = async () => {
    setSaving(true);
    try {
      const response = await API.put(`reports/${reportId}`, {
        pathology_number: caseNumber,
        age: Number(age),
        confirm: true
      });
      setConfirmRequest(false);
      setCaseData(response);
      history.go(0);
    } catch (error) {
      setConfirmRequest(false);
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  const handleConfirmDoubleCheck = async () => {
    if (caseDoubleCheck?.id) {
      setSaving(true);
      try {
        const response = await API.post(
          `/double-check/confirm/${caseDoubleCheck?.id}`,
          { client_team_id: isConsult ? clientTeamId : 0 }
        );
        setDoubleCheckData(response.data);
        history.go(0);
      } catch (error) {
        exceptionHandler(error, t);
      }
      setDoubleCheckConfirm(false);
      setSaving(false);
    }
  };

  const handeReturnDoubleCheck = async () => {
    if (caseDoubleCheck?.id) {
      setSaving(true);
      try {
        const response = await API.post(
          `/double-check/return/${caseDoubleCheck?.id}`,
          { client_team_id: isConsult ? clientTeamId : 0 }
        );
        setDoubleCheckData(response.data);
        history.go(0);
      } catch (error) {
        exceptionHandler(error, t);
      }
      setSaving(false);
    }
  };

  useEffect(() => {
    if (caseId && location.pathname) {
      if (
        doubleCheckUser ||
        confirmRequest ||
        doubleCheckConfirm ||
        externalUser
      ) {
        history.replace({
          pathname: `/cases/${caseId}`,
          search: location.search,
          state: { clear: true }
        });
        if (!externalUser) {
          setHasSidebar(true);
        }
      } else {
        history.replace({
          pathname: `/cases/${caseId}`,
          search: location.search,
          state: { clear: false }
        });
        setHasSidebar(false);
      }
    }
  }, [
    doubleCheckUser,
    confirmRequest,
    doubleCheckConfirm,
    caseId,
    externalUser,
    location.pathname
  ]);

  const handlePublicLinkChange = (value: string) => {
    const newState = { ...state, publicLink: value };
    setState(newState);
  };

  return (
    <>
      {(doubleCheckUser ||
        confirmRequest ||
        doubleCheckConfirm ||
        externalUser) && (
        <div className={`${classes.bottomContainer} ${classes.moz}`}>
          {externalUser && !state.confirmedAt && (
            <div className={classes.buttonWrapper}>
              <Button
                size="middle"
                className={classes.blueButton}
                onClick={handleConfirm}
              >
                {isConsult ? t("Complete consultation") : t("Confirm Report")}
              </Button>
            </div>
          )}
          {doubleCheckUser && doubleCheckUser.id && (
            <>
              <div
                className={classes.member}
                style={{ backgroundColor: doubleCheckUser.color }}
              >
                <Typography className={classes.memberName}>
                  {doubleCheckUser.name || doubleCheckUser.email}
                </Typography>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  size="middle"
                  className={classes.emptybutton}
                  onClick={() => {
                    setDoubleCheckUser(null);
                  }}
                >
                  {t("Back to Edit")}
                </Button>

                <Button
                  size="middle"
                  className={classes.blueButton}
                  onClick={handleDoubleCheck}
                >
                  {t("Send")}
                </Button>
              </div>
            </>
          )}
          {confirmRequest && (
            <div className={classes.buttonWrapper}>
              <Button
                size="middle"
                className={classes.emptybutton}
                onClick={() => {
                  setConfirmRequest(false);
                }}
              >
                {t("Back to Edit")}
              </Button>

              <Button
                size="middle"
                className={classes.blueButton}
                onClick={handleConfirm}
              >
                {isConsult ? t("Complete consultation") : t("Confirm")}
              </Button>
            </div>
          )}
          {doubleCheckConfirm && (
            <div className={classes.buttonWrapper}>
              <Button
                size="middle"
                className={classes.emptybutton}
                onClick={() => {
                  setDoubleCheckConfirm(false);
                }}
              >
                {t("Back to Edit")}
              </Button>

              <Button
                size="middle"
                className={classes.blueButton}
                onClick={handleConfirmDoubleCheck}
              >
                {t("Confirm")}
              </Button>
            </div>
          )}
        </div>
      )}
      <RightSidebar
        clear={!!(doubleCheckUser || confirmRequest || doubleCheckConfirm)}
        isConsult={isConsult}
        doubleCheckProps={{
          members: allMembers,
          caseDoubleCheck,
          setDoubleCheckUser,
          teamId: userTeamId,
          setConfirmRequest,
          setDoubleCheckReturn: handeReturnDoubleCheck,
          setDoubleCheckConfirm,
          pathologistId: state.pathologist,
          consultantID: state.consultant,
          confirm: !!state.completedAt
        }}
        caseId={state.id}
        reportId={reportId}
        publicLink={state.publicLink}
        handlePublicLinkChange={handlePublicLinkChange}
        caseNumber={state.caseNumber}
        completedAt={state.confirmedAt}
        myBookmark={state.myBookmark}
        teamBookmark={state.marked}
        permissions={permissions}
        onChangePermission={onChangePermission}
        editable={editable}
        isInCaseTeam={isInCaseTeam}
      />
    </>
  );
};

export { CaseConfirmation };
