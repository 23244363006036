import Icon from "@ant-design/icons";
import React from "react";

import { ReactComponent as assignment } from "./assignment.svg";
import { ReactComponent as book } from "./book.svg";
import { ReactComponent as CancelInvitation } from "./cancel.svg";
import { ReactComponent as caseList } from "./caseList.svg";
import { ReactComponent as completeTick } from "./completeTick.svg";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as doubleTick } from "./doubleTick.svg";
import { ReactComponent as filePlus } from "./filePlus.svg";
import { ReactComponent as flag } from "./flag.svg";
import { ReactComponent as form } from "./form.svg";
import { ReactComponent as teamBookmark } from "./greenBookmarkFilled.svg";
import { ReactComponent as OptionIcon } from "./option_button.svg";
import { ReactComponent as userBookmark } from "./orangeBookmarkFilled.svg";
import { ReactComponent as paperPlane } from "./paperPlane.svg";
import pointerIcon from "./pointer.svg";
import { ReactComponent as ResendInvitation } from "./resend.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as team } from "./team.svg";
import { ReactComponent as tick } from "./tick.svg";
import { ReactComponent as user } from "./user.svg";

export { pointerIcon };

export { ReactComponent as BonBon } from "./bonbon.svg";
export { ReactComponent as LogoWithoutName } from "./logo.svg";
export { ReactComponent as LogoWithName } from "./logoWithName.svg";
export { ReactComponent as Pen } from "./pen.svg";

export const UserBookmarkIcon = (props: any) => {
  return <Icon component={userBookmark} {...props} />;
};

export const TeamBookmarkIcon = (props: any) => {
  return <Icon component={teamBookmark} {...props} />;
};

export const DoubleTickIcon = (props: any) => {
  return <Icon component={doubleTick} {...props} />;
};

export const UserIcon = (props: any) => {
  return <Icon component={user} {...props} />;
};

export const CompleteTickIcon = (props: any) => {
  return <Icon component={completeTick} {...props} />;
};

export const TeamIcon = (props: any) => {
  return <Icon component={team} {...props} />;
};

export const FlagIcon = (props: any) => {
  return <Icon component={flag} {...props} />;
};

export const CaseListIcon = (props: any) => {
  return <Icon component={caseList} {...props} />;
};

export const AssignmentIcon = (props: any) => {
  return <Icon component={assignment} {...props} />;
};

export const FilePlusIcon = (props: any) => {
  return <Icon component={filePlus} {...props} />;
};

export const SearchIcon = (props: any) => {
  return <Icon component={search} {...props} />;
};

export const FormIcon = (props: any) => {
  return <Icon component={form} {...props} />;
};

export const BookIcon = (props: any) => {
  return <Icon component={book} {...props} />;
};

export const TickIcon = (props: any) => {
  return <Icon component={tick} {...props} />;
};
export const PaperPlaneIcon = (props: any) => {
  return <Icon component={paperPlane} {...props} />;
};

export const DeleteIcon = (props: any) => {
  return <Icon component={deleteIcon} {...props} />;
};

export const OptionsIcon = (props: any) => {
  return <Icon component={OptionIcon} {...props} />;
};

export const CancelInvitationIcon = (props: any) => {
  return <Icon component={CancelInvitation} {...props} />;
};

export const ResendInvitationIcon = (props: any) => {
  return <Icon component={ResendInvitation} {...props} />;
};
