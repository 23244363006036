import { Radio, Typography } from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import { createUseStyles } from "react-jss";

let useStyles = createUseStyles((theme: any) => {
  return {
    label: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    }
  };
});

export default function({
  name,
  label,
  control,
  onChange,
  options,
  defaultValue,
  className,
  disable
}: RadioProps) {
  const classes = useStyles();
  return (
    <div className={className}>
      <Typography.Text className={classes.label}>{label}</Typography.Text>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange: rendererOnChange, value, name }) => {
          return (
            <Radio.Group
              onChange={event => {
                rendererOnChange(event.target.value);
                onChange(event);
              }}
              value={value}
              name={name}
              disabled={disable}
            >
              {options.map(option => {
                return (
                  <Radio
                    key={`${name}_option_${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          );
        }}
      />
    </div>
  );
}
