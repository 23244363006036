import { Col, Row, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  exceptionHandler,
  keyValuePair,
  POROUSAPI
} from "shared-components";
import { LoaderComponent } from "shared-components/src/components/atoms/Loader/index.stories";

import { CaseImage } from "../../molecules/CaseImage";
import { CaseLink } from "../../molecules/CaseLink";
import { CaseTag } from "../../molecules/CaseTag";
import { caseTagMap } from "../../utils";

const useStyles = createUseStyles((theme: any) => {
  return {
    imageContainer: {
      flex: 1,
      background: theme.background,
      width: "100%"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    tagsection: {
      marginTop: "40px !important"
    },
    noImage: {
      display: "flex",
      background: theme.background,
      fontSize: 24,
      color: theme.selectText,
      textAlign: "left"
    },
    images: {
      width: "100%"
    }
  };
});

interface Props {
  hasSidebar: boolean;
  caseId: number;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  selectedReportId: number;
  setProgressCaseImagesRef: (data: any) => Promise<void>;
  caseImageRef: React.MutableRefObject<CaseImages[]>;
}

export const CaseImages = ({
  caseId,
  setSaving,
  selectedReportId,
  setProgressCaseImagesRef,
  caseImageRef
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true as boolean);
  const [tags, setTags] = useState([] as CaseTag[]);
  const [links, setLinks] = useState([] as CaseLink[]);

  const [reportImages, setReportImages] = useState([] as CaseImages[]);
  const [reportTags, setReportTags] = useState([] as CaseTag[]);
  const [reportLinks, setReportLinks] = useState([] as CaseLink[]);

  const [images, setImages] = useState([] as CaseImages[]);
  const imagesRef = useRef(images);
  const setImagesRef = async (data: any) => {
    imagesRef.current = data;
    setImages(data);
  };

  const [_dataLoading, _setDataLoading] = useState([] as number[]);
  const dataLoading = useRef(_dataLoading);
  const setDataLoading = async (data: any) => {
    dataLoading.current = data;
    _setDataLoading(data);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    initialLoad();
    return () => {
      if (source) {
        // source.cancel();
      }
    };
  }, [caseId, caseImageRef.current]);

  useEffect(() => {
    reportLoad();
  }, [selectedReportId, caseImageRef.current]);

  useEffect(() => {
    setProgressCaseImagesRef(imagesRef.current);
    initialLoadImages();
  }, [imagesRef.current]);

  const initialLoad = async () => {
    if (!caseId) {
      return;
    }
    setLoading(true);
    try {
      const imagesResponse = await API.get(`cases/${caseId}/uploads`);
      if (imagesResponse && imagesResponse.data) {
        const newImages: CaseImages[] = [];
        imagesResponse.data.forEach((resItem: any) => {
          let idExists = false;
          images.forEach(item => {
            if (item.id === resItem.id) {
              idExists = true;
              newImages.push(item);
            }
          });
          if (!idExists) {
            newImages.push(resItem);
          }
        });
        setImagesRef([...newImages]);
      }

      const tagsResponse = await API.get(`cases/${caseId}/tags`);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.length) {
        const caseTagData: CaseTag[] = [];
        tagsResponse.data.map(async (tag: any, index: number) => {
          caseTagData.push(caseTagMap(tag));
          if (index === tagsResponse.data.length - 1) {
            setTags([...caseTagData]);
          }
        });
      }

      const linksResponse = await API.get(`cases/${caseId}/links`);
      if (linksResponse && linksResponse.data && linksResponse.data.length) {
        const caseLinkData: CaseLink[] = [];
        linksResponse.data.map(async (link: any, index: number) => {
          caseLinkData.push({
            id: link.id,
            name: link.name,
            url: link.url
          });
          if (index === linksResponse.data.length - 1) {
            setLinks([...caseLinkData]);
          }
        });
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLoading(false);
  };

  const reportLoad = async () => {
    if (!selectedReportId) {
      return;
    }
    try {
      const imagesResponse = await API.get(
        `reports/${selectedReportId}/uploads`
      );
      if (imagesResponse && imagesResponse.data) {
        setReportImages(imagesResponse.data);
      } else {
        setReportImages([]);
      }

      const tagsResponse = await API.get(`reports/${selectedReportId}/tags`);
      if (tagsResponse && tagsResponse.data) {
        const caseTagData: CaseTag[] = [];
        tagsResponse.data.map(async (tag: any, index: number) => {
          caseTagData.push(caseTagMap(tag));
          if (index === tagsResponse.data.length - 1) {
            setReportTags([...caseTagData]);
          }
        });
      } else {
        setReportTags([]);
      }

      const linksResponse = await API.get(`reports/${selectedReportId}/links`);
      if (
        linksResponse &&
        linksResponse.data &&
        linksResponse.data.length > 0
      ) {
        const caseLinkData: CaseLink[] = [];
        linksResponse.data.map(async (link: any, index: number) => {
          caseLinkData.push({
            id: link.id,
            name: link.name,
            url: link.url
          });
          if (index === linksResponse.data.length - 1) {
            setReportLinks([...caseLinkData]);
          }
        });
      } else {
        setReportLinks([]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleChangeImage = (imageId: number, change: keyValuePair) => {
    const newImages = images.map((image: CaseImages) => {
      if (image.id === imageId) {
        return {
          ...image,
          ...change
        };
      }
      return image;
    });
    setImagesRef([...newImages]);
  };

  const handleDelete = (imageId: number) => {
    const newImages = images.filter((image: CaseImages) => {
      return image.id !== imageId;
    });
    setImagesRef([...newImages]);
  };

  const handleDeleteLink = (linkId: number) => {
    const newLinks = links.filter((link: CaseLink) => {
      return link.id !== linkId;
    });
    setLinks([...newLinks]);
  };

  const createThumbnail = (slideId: string, height: number, width: number) => {
    try {
      return POROUSAPI.post(
        `/slides/${slideId}/crop`,
        {
          height: height,
          width: width,
          theta: 0,
          x: 0,
          y: 0
        },
        {
          cancelToken: source.token
        }
      );
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const checkImage = async (
    apiImageResp: CaseImages,
    porousApiImageResp: any
  ) => {
    if (apiImageResp.id && porousApiImageResp.data) {
      if (
        porousApiImageResp.data.state === "waiting" ||
        porousApiImageResp.data.state === "processing"
      ) {
        const newImages = imagesRef.current.map((img: CaseImages) => {
          if (img.id === apiImageResp.id) {
            return {
              ...img,
              imageStatus: porousApiImageResp.data.state
            };
          }
          return img;
        });
        setImagesRef([...newImages]);
        setTimeout(() => {
          getCaseImage(apiImageResp);
        }, 1000);
      }
      if (porousApiImageResp.data.state === "failed") {
        Alert("error", "error", "Failed to upload Image", t);
        try {
          await API.delete(`uploads/${apiImageResp.id}`);
          const newImages = images.filter((image: CaseImages) => {
            return image.id !== apiImageResp.id;
          });
          setImagesRef([...newImages]);
        } catch (error) {
          exceptionHandler(error, t);
        }
        return;
      }
      if (
        porousApiImageResp.data.state === "done" &&
        apiImageResp.thumbnail === ""
      ) {
        const thumbnail: any = await createThumbnail(
          apiImageResp.slide_id,
          porousApiImageResp.data.height,
          porousApiImageResp.data.width
        );
        try {
          await API.put(`uploads/${apiImageResp.id}`, {
            case_id: caseId,
            format: porousApiImageResp.data.format,
            overlap: porousApiImageResp.data.overlap,
            height: porousApiImageResp.data.height,
            width: porousApiImageResp.data.width,
            tile_size: porousApiImageResp.data.tileSize,
            thumbnail: thumbnail.data.url
          });

          const newImages = imagesRef.current.map((image: CaseImages) => {
            if (image.id === apiImageResp.id) {
              return {
                ...image,
                thumbnail: thumbnail.data.url,
                imageStatus: porousApiImageResp.data.state,
                Image: {
                  Format: porousApiImageResp.data.format,
                  Overlap: porousApiImageResp.data.overlap,
                  Size: {
                    Height: porousApiImageResp.data.height,
                    Width: porousApiImageResp.data.width
                  },
                  TileSize: porousApiImageResp.data.tileSize,
                  Url: image.Image.Url
                }
              };
            }
            return image;
          });
          setImagesRef([...newImages]);
        } catch (error) {
          exceptionHandler(error, t);
        }
      }
    }
  };

  const initialLoadImages = () => {
    if (imagesRef.current && imagesRef.current.length > 0) {
      imagesRef.current.map((image: CaseImages) => {
        if (!dataLoading.current.includes(image.id)) {
          setDataLoading([...dataLoading.current, image.id]);
          getCaseImage(image);
          return null;
        }
      });
    }
  };

  const getCaseImage = async (image: CaseImages) => {
    try {
      if (image.thumbnail === "") {
        const porousApiImageResponse: any = await POROUSAPI.get(
          `slides/${image.slide_id}`,
          {
            cancelToken: source.token
          }
        );
        checkImage(image, porousApiImageResponse);
      } else {
        const newImages = imagesRef.current.map((currentImage: CaseImages) => {
          if (image.id === currentImage.id) {
            return {
              ...currentImage,
              imageStatus: "done"
            };
          }
          return currentImage;
        });
        setImagesRef([...newImages]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <div className={classes.imageContainer}>
      <Row gutter={[30, 30]} className={classes.images}>
        {images &&
          images.length > 0 &&
          images.map((image: CaseImages, index: number) => {
            return (
              <Col lg={8} md={8} sm={8} xs={8} key={index}>
                <CaseImage
                  imageUrl={`${image.thumbnail}` || ""}
                  imageName={image.name}
                  imageStatus={image.imageStatus}
                  onClick={() => {
                    return null;
                  }}
                  slideId={image.slide_id}
                  imageId={image.id}
                  caseId={caseId}
                  setSaving={setSaving}
                  onDelete={handleDelete}
                  state={{
                    organ: image.organ,
                    part: image.part,
                    staining: image.staining
                  }}
                  selectedReportId={selectedReportId}
                  handleChangeImage={handleChangeImage}
                  reportImages={reportImages}
                />
              </Col>
            );
          })}
        {loading && (
          <Col lg={24} md={24} sm={24} xs={24}>
            <div className={classes.loader}>
              <LoaderComponent />
            </div>
          </Col>
        )}
      </Row>
      {!loading && (!images || images.length === 0) && (
        <Typography className={classes.noImage}>
          {" "}
          {t("No image is registered.")}
        </Typography>
      )}

      <Row gutter={[40, 16]} className={classes.tagsection}>
        {!loading &&
          tags.map((tag: CaseTag, index: number) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} key={index}>
                <CaseTag
                  caseImages={images}
                  tag={tag}
                  reportTags={reportTags}
                  selectedReportId={selectedReportId}
                />
              </Col>
            );
          })}
      </Row>

      <Row gutter={[40, 16]} className={classes.tagsection}>
        {!loading &&
          links.map((link: CaseLink, index: number) => {
            return (
              <Col lg={12} md={12} sm={12} xs={12} key={index}>
                <CaseLink
                  linkId={link.id}
                  caseId={caseId}
                  url={link.url}
                  linkName={link.name}
                  reportLinks={reportLinks}
                  selectedReportId={selectedReportId}
                  onDelete={handleDeleteLink}
                />
              </Col>
            );
          })}
      </Row>
    </div>
  );
};
