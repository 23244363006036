import { DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  CheckboxComponent,
  DeleteConfirm,
  exceptionHandler
} from "shared-components/src";

interface Props {
  linkId: number;
  caseId: number;
  url: string;
  onDelete?: (linkId: number) => void;
  linkName: string;
  reportLinks?: CaseLink[];
  selectedReportId?: number;
  caseConfirm?: boolean;
  publicReport?: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      border: `1px solid ${theme.colorLight}`,
      borderRadius: "5px",
      marginBottom: 15,
      cursor: "pointer"
    },
    iconContainer: {
      float: "right"
    },
    icon: {
      "&:first-child": {
        marginRight: 17
      },
      "& svg": {
        fontSize: 16
      },
      cursor: "pointer"
    },
    selectionContainer: {
      background: theme.colorGrey,
      padding: "5px 7px",
      borderTop: `1px solid ${theme.colorLight}`,
      "& .ant-col": {
        "&:first-child": {
          "& .ant-select": {
            borderLeft: `1px solid ${theme.colorLight} !important`,
            borderRight: `1px solid ${theme.background}  !important`
          }
        }
      }
    },
    cardBody: {
      width: "100%",
      borderRadius: 5,
      boxShadow: "0px 0px 5px #00000026",
      "&:hover": {
        cursor: "pointer",
        boxShadow: `0px 0px 5px 0px ${theme.primary}`,
        transition: " ease-in-out, width .35s ease-in-out"
      }
    },
    checkbox: {},
    caseLinkText: {
      display: "inline-block",
      fontSize: 14,
      marginLeft: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },
    checkBoxContainer: {
      height: 40,
      display: "flex",
      alignItems: "center"
    }
  };
});

const CaseLink: React.FC<Props> = props => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { reportLinks, selectedReportId } = props;

  const [checked, setChecked] = useState(false as boolean);

  const disabled = props.caseConfirm || props.publicReport;

  const { Meta } = Card;

  useEffect(() => {
    if (reportLinks && props.linkId) {
      const checked = reportLinks.some((reportLink: CaseLink) => {
        return reportLink.id === props.linkId;
      });
      setChecked(checked);
    }
  }, [props.linkId, props.url, reportLinks, selectedReportId]);

  const handleLinkCheckBox = async (linkId: number, event: any) => {
    const checkValue = event.target.checked;
    try {
      setChecked(checkValue);
      await API.post(`/reports/${selectedReportId}/links/${linkId}`, {
        checked: checkValue
      });
    } catch (error) {
      setChecked(!checkValue);
      exceptionHandler(error, t);
    }
  };

  const handleDelete = () => {
    if (disabled) {
      return;
    }
    DeleteConfirm(() => {
      return handleCaseLinkDelete();
    });
  };

  const handleCaseLinkDelete = async () => {
    try {
      await API.delete(`/links/${props.linkId}`);
      props.onDelete && props.onDelete(props.linkId);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <div className={classes.componentWrapper}>
      <div className={classes.selectionContainer}>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Card className={classes.cardBody} bordered={true}>
              <a href={props.url} target="_blank" rel="noopener noreferrer">
                <Meta
                  title={`${t(props.linkName)}`}
                  description={`${t(props.url)}`}
                />
              </a>
              <div className={classes.iconContainer}>
                <Tooltip placement="bottom" title={t("Delete")}>
                  <DeleteOutlined
                    className={classes.icon}
                    onClick={() => {
                      return handleDelete();
                    }}
                  />
                </Tooltip>
              </div>
            </Card>
          </Col>
        </Row>
        {selectedReportId && (
          <div className={classes.checkBoxContainer}>
            <CheckboxComponent
              onClick={(event: any) => {
                return handleLinkCheckBox(props.linkId, event);
              }}
              checked={checked}
            />
            <Typography className={classes.caseLinkText}>
              {t("Add to report")}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export { CaseLink };
