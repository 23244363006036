import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { wait } from "../setTimeout";

export const caseToPdf = async (
  { setPdfLoading }: any,
  filename: string,
  pdfUploading?: boolean,
  includePersonalInfo?: boolean
) => {
  const formRef: any = document.getElementById("caseForm");
  const findingRef: any = document.getElementById("findings");
  const colorectalRef: any = document.getElementById("colorectal");
  const diagnosisRef: any = document.getElementById("diagnosis");
  const casesImages: any = document.getElementById("caseImages");
  const tags: any = document.getElementById("caseTags");
  const generalRef: any = document.getElementById("findingsDiagnosis");
  setPdfLoading(true);
  const pdf = new jsPDF("p", "px", "a4", 0.1);
  const pageHeight = pdf.internal.pageSize.height;
  await wait(500);
  const imageWidth = 400;
  const formCanvas = await html2canvas(formRef, {
    ignoreElements: element => {
      if (pdfUploading) {
        if (includePersonalInfo) {
          return false;
        }
        return (
          "newMedicalRecordId" === element.id ||
          "patientName" === element.id ||
          "birthday" === element.id ||
          "ant-picker-suffix" === element.className
        );
      }
      return false;
    }
  });
  const imgData = formCanvas.toDataURL("image/png", 0.2);
  pdf.addImage(
    imgData,
    "PNG",
    20,
    20,
    imageWidth,
    (imageWidth * formCanvas.height) / formCanvas.width,
    undefined,
    "FAST"
  );

  if (casesImages !== null) {
    const imagesCanvas = await html2canvas(casesImages, {
      ignoreElements: element => {
        return "iconContainer" === element.id;
      }
    });
    const caseImageHeight =
      (imageWidth * imagesCanvas.height) / imagesCanvas.width;
    const onePageHeight = 485;
    const totalPagesForImage = Math.ceil(caseImageHeight / onePageHeight);
    const caseImgData = imagesCanvas.toDataURL("image/png", 0.2);

    pdf.addPage();

    pdf.addImage(caseImgData, "PNG", 20, 40, imageWidth, caseImageHeight);
    pdf.setDrawColor(255, 255, 255);
    pdf.setFillColor(255, 255, 255);
    pdf.rect(0, onePageHeight + 35, 500, pageHeight, "F");

    if (totalPagesForImage !== 1) {
      for (let i = 1; i < totalPagesForImage; i++) {
        pdf.addPage();
        pdf.addImage(
          caseImgData,
          "PNG",
          20,
          i * -onePageHeight + 30,
          imageWidth,
          caseImageHeight,
          undefined,
          "FAST"
        );
        pdf.setDrawColor(255, 255, 255);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, 500, 25, "F");
        pdf.setDrawColor(255, 255, 255);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, onePageHeight + 25, 500, pageHeight, "F");
      }
    }

    if (tags !== null) {
      const tagsCanvas = await html2canvas(tags);
      const caseTagHeight = (imageWidth * tagsCanvas.height) / tagsCanvas.width;
      const tagsPageHeight = 550;
      const totalPagesForImage = Math.ceil(caseTagHeight / tagsPageHeight);
      const caseTagData = tagsCanvas.toDataURL("image/png", 0.2);

      pdf.addPage();

      pdf.addImage(caseTagData, "PNG", 20, 40, imageWidth, caseTagHeight);
      pdf.setDrawColor(255, 255, 255);
      pdf.setFillColor(255, 255, 255);
      pdf.rect(0, tagsPageHeight + 35, 500, pageHeight, "F");

      if (totalPagesForImage !== 1) {
        for (let i = 1; i < totalPagesForImage; i++) {
          pdf.addPage();
          pdf.addImage(
            caseTagData,
            "PNG",
            20,
            i * -tagsPageHeight + 30,
            imageWidth,
            caseTagHeight,
            undefined,
            "FAST"
          );
          pdf.setDrawColor(255, 255, 255);
          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, 0, 500, 25, "F");
          pdf.setDrawColor(255, 255, 255);
          pdf.setFillColor(255, 255, 255);
          pdf.rect(0, tagsPageHeight + 25, 500, pageHeight, "F");
        }
      }
    }
  }

  if (findingRef !== null) {
    const findingsCanvas = await html2canvas(findingRef, {
      ignoreElements: element => {
        return "caseFormSelect" === element.id;
      }
    });
    const findingsImgData = findingsCanvas.toDataURL("image/png", 0.2);
    pdf.addPage();
    pdf.addImage(
      findingsImgData,
      "PNG",
      20,
      20,
      imageWidth,
      (imageWidth * findingsCanvas.height) / findingsCanvas.width,
      undefined,
      "FAST"
    );

    const diagnosisCanvas = await html2canvas(diagnosisRef);
    const diagnosisImgData = diagnosisCanvas.toDataURL("image/png", 0.2);
    pdf.addPage();
    pdf.addImage(
      diagnosisImgData,
      "PNG",
      20,
      20,
      imageWidth,
      (imageWidth * diagnosisCanvas.height) / diagnosisCanvas.width,
      undefined,
      "FAST"
    );
  } else if (colorectalRef !== null) {
    const colorectalCanvas = await html2canvas(colorectalRef, {
      ignoreElements: element => {
        return (
          "caseFormSelect" === element.id || "colorectalFormEdit" === element.id
        );
      }
    });
    const colorectalCanvasHeight =
      (imageWidth * colorectalCanvas.height) / colorectalCanvas.width;
    const colorectalImgData = colorectalCanvas.toDataURL("image/png", 0.2);
    pdf.addPage();
    pdf.addImage(
      colorectalImgData,
      "PNG",
      20,
      20,
      imageWidth,
      colorectalCanvasHeight,
      undefined,
      "FAST"
    );
  } else {
    const generalCanvas = await html2canvas(generalRef, {
      ignoreElements: element => {
        return "caseFormSelect" === element.id;
      }
    });
    const generalImgData = generalCanvas.toDataURL("image/png", 0.2);
    pdf.addPage();
    pdf.addImage(
      generalImgData,
      "PNG",
      20,
      20,
      imageWidth,
      (imageWidth * generalCanvas.height) / generalCanvas.width,
      undefined,
      "FAST"
    );
  }

  if (pdfUploading) {
    const pdfOutput = await pdf.output("blob");
    setPdfLoading(false);
    return pdfOutput;
  }

  pdf.save(filename);
  setPdfLoading(false);
};
