import { CloseOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { i18n, Loader, Typography } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      position: "fixed",
      bottom: "25px",
      right: "85px",
      border: "1px solid #E6E6E6",
      width: "395px",
      borderRadius: "6px",
      background: theme.lightBg,
      color: theme.gray7,
      zIndex: 10000
    },
    header: {
      padding: 20,
      paddingBottom: 11,
      paddingTop: 11,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 20
    },
    imageList: {
      height: 133,
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    imageInfo: {
      padding: 20,
      paddingTop: 9,
      paddingBottom: 9,
      display: "flex",
      justifyContent: "space-between",
      fontSize: 16,
      alignItems: "center"
    },
    closeButton: {
      cursor: "pointer",
      fontSize: 15
    },
    progress: {
      "& .ant-progress-text": {
        display: "none"
      },
      "& .ant-progress-circle-trail": {
        stroke: "#e8e8e8"
      }
    },
    loader: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    laterLoader: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

interface Props {
  imageUploadingShow?: boolean;
  setImageUploadingShow: React.Dispatch<React.SetStateAction<boolean>>;
  uploadedImages: React.MutableRefObject<any[]>;
  intermediateUpload: any;
  setUploadedImagesRef: React.Dispatch<any>;
  loadingFilesToUpload: boolean;
}
const UploadProgress: React.FC<Props> = props => {
  const classes = useStyles();
  const [imageList, setImageList] = useState([] as any);
  const [visible, setVisible] = useState(false as boolean);

  useEffect(() => {
    if (props.intermediateUpload) {
      // if (props.uploadedImages.current.length !== imageList.length)
      setImageList(props.uploadedImages.current);
    }

    if (props.uploadedImages.current.length > 0 && !visible) {
      setVisible(true);
    }
  }, [props.intermediateUpload]);

  useEffect(() => {
    if (!visible) {
      setImageList([]);
      props.setUploadedImagesRef([]);
    }
  }, [visible]);

  if ((!props.imageUploadingShow && !visible) || imageList.length <= 0) {
    return <></>;
  }
  return (
    <div className={classes.modalContainer}>
      <div className={classes.header}>
        <Typography fontSize={"18px"}>
          {i18n.language === "ja"
            ? `${imageList.length} 個のファイルをアップロード`
            : `Uploading ${imageList.length} items `}
        </Typography>
        <CloseOutlined
          onClick={() => {
            props.setImageUploadingShow(false);
            setVisible(false);
          }}
          className={classes.closeButton}
        />
      </div>
      <div className={classes.imageList}>
        {imageList.map((item: any, key: number) => {
          return (
            <div key={key}>
              <hr />
              <div className={classes.imageInfo}>
                <div>{item.fileName || item.name}</div>
                <Progress
                  className={classes.progress}
                  strokeColor={"#485faf"}
                  strokeWidth={15}
                  width={18}
                  type="circle"
                  percent={item.percent ? item.percent : 0}
                />
              </div>
            </div>
          );
        })}
        {props.loadingFilesToUpload && (
          <div className={classes.laterLoader}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export { UploadProgress };
