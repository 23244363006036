import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Button,
  debounceFunction,
  FilePlusIcon,
  Modal,
  TextField,
  Typography
} from "shared-components";

import { Spacer } from "../../Spacer";

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      marginTop: "-2% !important",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-content": {
        marginTop: "1%"
      },

      "& .ant-modal-body": {
        minWidth: 850,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-drag": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 25,
        justifyContent: "center",
        height: 135,
        width: 514,
        textAlign: "center",
        background: theme.textWhite
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      },
      "& .ant-upload-drag-container": {
        marginTop: -5,

        "& article": {
          textAlign: "center"
        }
      }
    },
    header: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    logo: {
      "& svg": {
        height: 25,
        width: 34,
        marginRight: 15
      }
    },
    modal: {
      maxWidth: "100%"
    },
    modalButton: {
      textAlign: "center"
    },

    button: {
      height: 36,
      width: 160,
      fontSize: 14
    },
    draggerComponent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    draggerSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      width: 514,
      border: "1px dashed #d9d9d9",
      marginTop: 23,
      height: 135
    },
    middleText: {
      marginTop: 20,
      marginBottom: 22,
      "& article": {
        textAlign: "center"
      }
    },
    text: {
      color: theme.primary,
      textAlign: "center",
      letterSpacing: "1.08px",
      marginBottom: 4,
      "& article": {
        textAlign: "center"
      }
    },
    textBlue: {
      color: theme.primary
    },
    textGreen: {
      color: theme.textGreen
    },
    inputCaseNo: {
      width: "fit-content"
    }
  };
});

interface Props {
  visible: boolean;
  onSubmit: (name: string, parent_folder_id: number) => void;
  onCancel: () => void;
}
const CreateFolderModal = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false as boolean);

  const [state, setState] = useState({
    name: "",
    parent_folder_id: 0
  });

  const handleModalField = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const handleFolderUpload = async () => {
    setLoading(true);
    try {
      debounceFunction(() => {
        return props.onSubmit(state.name, state.parent_folder_id);
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      className={classes.modalContainer}
      visible={props.visible}
      onCancel={props.onCancel}
      width="850px"
      mask={false}
    >
      <div className={classes.header}>
        <div className={classes.logo}>
          <FilePlusIcon />
        </div>
        <Typography type="title">{t("Create New Folder")}</Typography>
      </div>
      <Spacer padding={"16px"} />
      <Form
        className={classes.modal}
        onFinish={() => {
          return handleFolderUpload();
        }}
      >
        <Typography type="body2" className={classes.text}>
          {t("Enter the folder name")}
        </Typography>
        <Form.Item
          id="name"
          name="name"
          rules={[
            {
              required: true,
              message: t("Required")
            }
          ]}
        >
          <div className={classes.inputCaseNo}>
            <TextField
              autoComplete="off"
              name="name"
              placeholder={t("Case Folder Name")}
              onChange={handleModalField}
              maxLength={90}
            />
          </div>
        </Form.Item>
        <Form.Item className={classes.modalButton}>
          <Button
            className={classes.button}
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            data-testid="create-button"
          >
            {t("Create")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { CreateFolderModal };
