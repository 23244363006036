import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  BorderBoxWrapper,
  Button,
  ErrorMessages,
  exceptionHandler,
  firebase,
  TextField
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      marginBottom: 20,
      maxWidth: "256px",
      margin: "auto",
      "& label": {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.gray9,
        fontWeight: 300,
        marginBottom: 2
      },

      "& .ant-form-item-explain": {
        fontWeight: 300
      },
      "& > :first-child": {
        padding: 0
      }
    },

    fullWidth: {
      maxWidth: "100%"
    },
    button: {
      fontWeight: 300,
      margin: "auto",
      display: "block",
      marginBottom: 40
    },
    "@media screen and (max-width: 600px)": {
      formItem: {
        width: "auto",
        padding: {
          left: 30,
          right: 30
        }
      }
    }
  };
});

const ChangePassword = () => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  const success = () => {
    Alert("success", "success", t("Your password has been changed."), t);
    history.push("/account-settings");
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (state.newPassword.length < 8) {
      Alert("error", "error", t("Password should be minimum 8 characters"), t);
    } else {
      return Object.keys(errors).length === 0;
    }
  };

  const handlePasswordChange = async () => {
    const { currentPassword, newPassword } = state;
    setLoading(true);
    var user = firebase.auth().currentUser;
    var email: string = firebase.auth().currentUser!.email!;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );

    if (!validateForm()) {
      setLoading(false);
      return;
    }
    try {
      await user!.reauthenticateWithCredential(credential);
      await user!.updatePassword(newPassword);
      success();
      form.resetFields();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = ErrorMessages[error.code];
      setFormError("currentPassword", errorMessage);
      exceptionHandler(error, t);
    }
  };

  const setFormError = (name: string, error: string) => {
    form.setFields([
      {
        name: name,
        errors: [error]
      }
    ]);
  };

  return (
    <ComponentWrapper>
      <BorderBoxWrapper heading={t("Change Password")}>
        <Form
          form={form}
          scrollToFirstError
          onFinish={handlePasswordChange}
          layout="vertical"
        >
          <Form.Item
            id="currentPassword"
            label={t("Current Password")}
            required={false}
            name="currentPassword"
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              }
            ]}
          >
            <TextField
              name="currentPassword"
              className={classes.fullWidth}
              type="password"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            id="newPassword"
            label={t("New Password")}
            name="newPassword"
            required={false}
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              },
              {
                min: 8,
                message: t("Password should be minimum 8 characters")
              }
            ]}
          >
            <TextField
              name="newPassword"
              type="password"
              className={classes.fullWidth}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            required={false}
            label={t("Confirm Password")}
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              },
              ({ getFieldValue }: any) => {
                return {
                  validator(rule: any, value: string) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(t("Password did not matched"));
                  }
                };
              }
            ]}
          >
            <TextField
              name="confirmPassword"
              type="password"
              className={classes.fullWidth}
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className={classes.button}
              loading={loading}
              htmlType="submit"
              size="middle"
            >
              {t("Change")}
            </Button>
          </Form.Item>
        </Form>
      </BorderBoxWrapper>
    </ComponentWrapper>
  );
};

export { ChangePassword };
