import { Input, Typography } from "antd";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import {
  digestiveOrgan,
  endocrine,
  femaleOrgan,
  hematopoieticOrgan,
  locomotorOrgans,
  mouthNose,
  respiratorySyste,
  skinMammaryGland,
  urinaryOrgan
} from "./organs/data";
import { OrganCheckboxes } from "./organs/organ-checkboxes";

let useStyles = createUseStyles((theme: any) => {
  return {
    label: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    },
    text: {
      border: `1px solid ${theme.borderColor}`,
      borderRadius: 2,
      maxHeight: 32,
      background: "white",
      "& .ant-input-suffix": {
        fontSize: 12,
        color: theme.textLightBlack
      }
    }
  };
});

export default function({
  onChange,
  control,
  name,
  otherOrganFieldName,
  otherOrganFieldDefault,
  label,
  defaultValue,
  className,
  watch,
  disable,
  getOrganName
}: {
  label: string;
  onChange: () => any;
  control: Control;
  name: string;
  defaultValue: string;
  otherOrganFieldName: string;
  otherOrganFieldDefault: string;
  className?: string;
  watch: any;
  disable?: boolean;
  getOrganName?: (value: string) => string[];
}) {
  const classes = useStyles();
  const [showOrganModal, setShowOrganModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Typography.Text className={classes.label}>{label}</Typography.Text>
      <Input
        disabled={disable}
        className={classes.text}
        onClick={() => {
          if (disable) {
            return;
          }
          return setShowOrganModal(true);
        }}
        value={getOrganName!(defaultValue)}
      />
      <div
        className={`inset-0 fixed flex justify-center items-center ${
          showOrganModal ? "" : "hidden"
        }`}
        style={{ zIndex: 9999 }}
      >
        <div className="absolute w-full h-full bg-gray-900 opacity-50" />
        <div className="bg-white container z-0">
          <p className="text-xs text-gray-600 px-6 pt-2 mb-2">
            {t("Please check the items applicable.")}
          </p>
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange, value, name }) => {
              return (
                <OrganFields onChange={onChange} value={value} name={name} />
              );
            }}
          />
          <div className="flex px-6 py-4 items-center">
            <div className="mr-3 text-indigo-500">{t("Others")}</div>
            <div className="flex-1 mr-3">
              <Controller
                control={control}
                name={otherOrganFieldName}
                defaultValue={otherOrganFieldDefault}
                render={({ onChange, value, name }) => {
                  return (
                    <Input
                      className={classes.text}
                      value={value}
                      name={name}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="ml-auto">
              <button
                className="px-4 py-1 bg-indigo-500 text-white"
                onClick={() => {
                  onChange();
                  setShowOrganModal(false);
                }}
              >
                {t("Done")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OrganFields({
  onChange,
  value: selectedOrgans
}: {
  onChange: (...event: any[]) => void;
  value: any;
  name: string;
}) {
  function fieldOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value: changeValue } = event.target;
    const normalizeValue = selectedOrgans.split(",").filter((organ: any) => {
      return organ !== "";
    });
    if (checked) {
      if (normalizeValue.length > 0) {
        onChange([normalizeValue, changeValue].join(","));
      } else {
        onChange(changeValue);
      }
    } else {
      onChange(
        normalizeValue
          .filter((organ: any) => {
            return `${organ}` !== changeValue;
          })
          .join(",")
      );
    }
  }
  return (
    <div className="px-4">
      <div className="flex items-stretch">
        <OrganCheckboxes
          organ={digestiveOrgan}
          column={2}
          className="w-2/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={femaleOrgan}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={urinaryOrgan}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={mouthNose}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
      </div>
      <div className="flex">
        <OrganCheckboxes
          organ={skinMammaryGland}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={locomotorOrgans}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={respiratorySyste}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={hematopoieticOrgan}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
        <OrganCheckboxes
          organ={endocrine}
          className="w-1/5"
          onChange={fieldOnChange}
          selectedOrgans={selectedOrgans}
        />
      </div>
    </div>
  );
}
