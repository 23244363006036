import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Alert,
  API,
  APIV2,
  AuthContext,
  DeleteConfirm,
  exceptionHandler,
  Loader,
  PdfContext,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";
import { SwitchTeamModal } from "../../molecules/SwitchTeamModal";
import { UploadImageModal } from "../../molecules/UploadImageModal";
import { UploadLinkModal } from "../../molecules/UploadLinkModal";
import { UploadProgress } from "../../molecules/UploadProgress";
import { CaseDoubleCheck, Member } from "../../organisms/DoubleCheck";
import { ImageViewer } from "../../organisms/ImageViewer";
import { CaseFormValue } from "../../organisms/RenderForm";
import { caseTagMap } from "../../utils";
import { CaseConfirmation } from "./CaseConfirmation";
import { CaseImages } from "./CaseImages";
import { CaseSteps } from "./CaseSteps";
import ReportWrapper from "./Report/ReportWrapper";

export const caseWrapperId = "case-component-wrapper";

interface styleProps {
  pdfLoading: boolean;
  hasSidebar: boolean;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      background: theme.background
    },
    mainContainer: {
      display: "flex",
      background: theme.background
    },
    fullWidth: {
      maxWidth: "100%",
      display: "block",
      border: "none",
      width: "100%"
    },
    saving: {
      position: "absolute",
      top: 50,
      right: 115,
      padding: "0px 8px",
      zIndex: 100,
      borderRadius: "3px",
      backgroundColor: theme.backgroundPink
    },
    teamName: {
      position: "absolute",
      top: 60,
      left: 275
    },
    pdfLoader: {
      position: "absolute",
      left: "50%",
      top: "50%",
      zIndex: 2
    },
    psuedoContainer: {
      display: "flex",
      background: ({ pdfLoading }: styleProps) => {
        return pdfLoading ? theme.grayBlueBg : theme.textWhite;
      },
      margin: ({ hasSidebar }: styleProps) => {
        return hasSidebar ? "32px 200px 91px 145px" : "30px 200px 60px 250px";
      },
      width: "calc(100% - 250px)",
      minWidth: "550px",
      flexDirection: "column",
      height: "100%",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      filter: ({ pdfLoading }: styleProps) => {
        return pdfLoading ? "blur(15px)" : "none";
      },
      "& .ant-radio-wrapper": {
        fontSize: "16px",
        lineHeight: "19px",
        fontFamily: "Helvetica Neue",
        color: "#1F1F1F"
      }
    }
  };
});

export const Case = ({ errorBoundary }: any) => {
  const { t } = useTranslation();
  const {
    defaultTeam,
    profile: { teams: allTeams },
    uid
  } = useContext(AuthContext);
  const { pdfLoading } = useContext(PdfContext);
  const history = useHistory();
  let { id }: any = useParams();
  const caseId = parseInt(id);

  const location = useLocation();
  const by = new URLSearchParams(location.search).get("by");

  const [imageView, setImageView] = useState(false as boolean);
  const [selectedReportId, setSelectedReportId] = useState(0 as number);

  const [caseImages, setCaseImages] = useState([] as CaseImages[]);
  const caseImageRef = useRef(caseImages);
  const setCaseImagesRef = async (data: any, handleFunction?: any) => {
    caseImageRef.current = data;
    setCaseImages(data);
    for (const item of data) {
      let url;
      if (item.url && !item.dataUrl) {
        url = await API.get("/getDataURL", {
          params: {
            url: item.url
          }
        });

        if (url.data === "data:image/png;base64,") {
          let times = 3;
          for (let i = 0; i < times; i++) {
            url = await API.get("/getDataURL", {
              params: {
                url: item.url
              }
            });
            if (url.data !== "data:image/png;base64,") {
              break;
            }
          }
        }
        item.dataUrl = url.data;
        item.imageStatus = "done";
      }
    }
    if (handleFunction) {
      handleFunction();
    }
    setCaseImages(data);
  };
  const [progressCaseImages, setProgressCaseImages] = useState(
    [] as CaseImages[]
  );
  const imagesRef = useRef(progressCaseImages);
  const setProgressCaseImagesRef = async (data: any) => {
    imagesRef.current = data;
    setProgressCaseImages(data);
  };
  const [allMembers, setAllMembers] = useState([] as Member[]);
  const [imageModalVisibility, setImageModalVisibility] = useState(false);
  const [linkModalVisibility, setLinkModalVisibility] = useState(false);

  const [state, setState] = useState({} as CaseData);

  const [formValues, setFormValues] = useState([] as CaseFormValue[]);

  const [caseDoubleCheck, setCaseDoubleCheck] = useState(
    null as CaseDoubleCheck | null
  );

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false as boolean);
  const [hasSidebar, setHasSidebar] = useState(false as boolean);
  const [imageUploadingShow, setImageUploadingShow] = useState(
    false as boolean
  );
  const [imageUploadingList, setImageUploadingList] = useState([] as any);

  const classes = useStyles({ pdfLoading, hasSidebar, imageView });

  const [permissions, setPermissions] = useState<ReportPermissionData[]>([]);
  const [editable, setEditable] = useState<boolean>(false);
  const [isImageViewerVisible, setImageViewerVisibility] = useState(false);
  const [selectedImageID, setSelectedImageID] = useState("" as string);
  const [imageLoading, setImageLoading] = useState(false as boolean);
  const [tagsLoading, setTagsLoading] = useState(false as boolean);
  const [caseTags, _setCaseTags] = useState([] as CaseTag[]);
  const caseTagsRef = useRef(caseTags);
  const setCaseTags = (data: CaseTag[]) => {
    caseTagsRef.current = data;
    _setCaseTags(data);
  };

  const [linkLoading, setLinkLoading] = useState(false as boolean);
  const [caseLinks, _setCaseLinks] = useState([] as CaseLink[]);
  const caseLinksRef = useRef(caseLinks);
  const setCaseLinks = (data: CaseLink[]) => {
    caseLinksRef.current = data;
    _setCaseLinks(data);
  };

  const [loadingFilesToUpload, setLoadingFilesToUpload] = useState(
    true as boolean
  );
  const [intermediateUpload, setIntermediateUpload] = useState([] as any[]);
  const uploadedImagesRef = useRef(intermediateUpload);
  const setUploadedImagesRef = (data: any[]) => {
    uploadedImagesRef.current = data;
    setIntermediateUpload(data);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const reportId = urlParams.get("reportId");
    if (reportId) {
      getCase(Number(reportId));
    } else {
      getCase();
    }
    state.teamId && getMembers();
  }, [caseId, defaultTeam, state.teamId]);

  useEffect(() => {
    if (state.reportId) {
      getCaseTagsData(state.reportId);
      window.addEventListener("focus", e => {
        getCaseTagsData(state.reportId);
      });

      getCaseLinks();
      getPermissions(state.reportId);
    }
  }, [state.reportId]);

  useEffect(() => {
    judgeEditability(permissions);
    state.teamId && getMembers();
  }, [permissions]);

  useEffect(() => {
    if (!imageView && !loading) {
      state.reportId && getCase(state.reportId);
    }
  }, [imageView]);

  useEffect(() => {
    if (by) {
      setSelectedImageID(by);
      setImageViewerVisibility(true);
    }
  }, [by]);

  const judgeEditability = (newPermissionData: any) => {
    const myPermission = newPermissionData.filter(
      (permission: ReportPermissionData) => {
        return (
          permission.subject &&
          permission.subject.id &&
          permission.subject.id === uid
        );
      }
    );
    defaultTeam && state.teamId && state.teamId === defaultTeam
      ? setEditable(true)
      : myPermission.length > 0 &&
        setEditable(["owner", "editor"].includes(myPermission[0].level));
    defaultTeam &&
      state.allReports &&
      state.allReports.filter((data: any) => {
        return data.client_team_id === defaultTeam;
      }).length > 0 &&
      setEditable(true);
  };

  const getUserProfile = async (item: ReportPermissionItem) => {
    try {
      if (item.subject_type === "user") {
        const response: any = await APIV2.get(
          `users/${item.subject.id}/publicProfile`
        );
        return {
          ...item,
          name:
            response && response.display_name
              ? response.display_name
              : item.subject.id,
          image_url: response ? response.image_url : "",
          occupation: response ? response.occupation : ""
        };
      }
      return {
        ...item,
        name: item.subject.email,
        image_url: "",
        occupation: ""
      };
    } catch (error) {
      exceptionHandler(error, t);
      return {
        ...item,
        name: "",
        image_url: "",
        occupation: ""
      };
    }
  };

  const setPermissionWithName = async (items: ReportPermissionItem[]) => {
    const newPermissionData = await Promise.all(items.map(getUserProfile));
    newPermissionData && setPermissions(newPermissionData);
  };
  const getPermissions = async (reportId: number) => {
    try {
      const response: ReportPermissionResponse = await API.get(
        `reports/${reportId}/permissions`
      );
      if (response && response.data && response.data.entries) {
        setPermissionWithName(response.data.entries);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const updatePermissions = async (newPermissions: ReportPermissionData[]) => {
    try {
      const personal_permissions = newPermissions
        .filter((data: ReportPermissionData) => {
          return data.subject_type === "user";
        })
        .map((data: ReportPermissionData) => {
          return {
            user_id: data.subject.id,
            level: data.level
          };
        });
      await API.put(`reports/${state.reportId}/permissions`, {
        personal_permissions: personal_permissions
      });
      setPermissionWithName(newPermissions);
      Alert("success", "success", t("Permission has been updated."), t);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const [switchTeam, setSwitchTeam] = useState({
    open: false,
    teams: []
  } as SwitchTeamValue);

  const getCaseTagsData = (id: number) => {
    if (id !== undefined) getCaseTags();
  };

  const getCase = async (reportID?: number) => {
    setLoading(true);
    try {
      const diagnosisRequestId =
        location &&
        location.search &&
        location.search.includes("?diagnosisRequestId=") &&
        location.search.split("=")[1];
      const response = await API.get(`cases/${id}`, {
        params: {
          report_id: reportID,
          diagnosis_request_id: diagnosisRequestId || undefined
        }
      });
      setCaseData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error && error.response && error.response.status === 406) {
        const { message } = error.response.data.error;
        const caseTeamIds = message.split(":");
        const teamNames = allTeams.filter((team: any) => {
          return caseTeamIds.includes(team.id.toString());
        });
        setSwitchTeam({ open: true, teams: teamNames });
        return;
      }
      exceptionHandler(error, t);
      errorBoundary(error);
    }
  };

  const setTeamMembersAndGuestMembers = (teamMembers: Member[]) => {
    const guestMembers = permissions.map((permission: ReportPermissionData) => {
      return {
        ProfileImage: permission.image_url,
        color: "#e2e8f0",
        created_at: "",
        email: "",
        id: permission.subject.id ? permission.subject.id : "",
        language: "",
        name: permission.name,
        updated_at: "",
        team_user_id: state.teamId
      };
    });
    setAllMembers(teamMembers.concat(guestMembers));
  };

  const getMembers = async () => {
    try {
      const memberResponse = await API.get(`members`, {
        params: {
          teamId:
            state.clientTeamId &&
            state.clientTeamId.toString() === defaultTeam.toString()
              ? state.clientTeamId
              : state.teamId
        }
      });
      setTeamMembersAndGuestMembers(memberResponse.data);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const setCaseData = (response: any) => {
    if (!response || !response.data) {
      return;
    }
    const { report, ...caseData } = response.data;
    const { form_values, double_check, case_images, ...reportData } = report;
    setState({
      id: caseData.id,
      reportId: reportData.id,
      reportNumber: report.report_number,
      createDateTime: caseData.created_at,
      caseNumber: caseData.pathology_number,
      patientName: reportData.patient_name,
      birthday: reportData.birthday,
      age: reportData.age,
      sex: reportData.sex,
      occupation: reportData.occupation,
      formName: reportData.form_name,
      pathologist: reportData.pathologist_user_id,
      completedAt: reportData.confirmed_at,
      myBookmark: caseData.user_mark,
      marked: caseData.marked,
      teamName: reportData.team_name,
      teamId: caseData.team_id,
      hospital: reportData.hospital,
      clinician: reportData.clinician,
      remarks: reportData.remarks,
      numberOfSpecimen: reportData.number_of_specimen,
      newMedicalRecordId: reportData.medical_record_id,
      registrationDate: reportData.registration_date,
      dateOfCollection: reportData.date_of_collection,
      organs: reportData.organs,
      organOtherText: reportData.organ_other_text,
      confirmedAt: reportData.confirmed_at,
      allReports: caseData.all_reports,
      consultant: reportData.consult_user_id.String,
      consultHospital: reportData.consult_hospital,
      consultNumber: reportData.consult_number,
      consultantDoubleCheckName: reportData.consultant_double_check_name,
      diagnosisRequestId: reportData.diagnosis_request_id,
      consultation: reportData.consultation,
      pathologistName:
        reportData.pathologist_name !== ""
          ? reportData.pathologist_name
          : state.pathologistName,
      consultantName: reportData.consultant_name,
      publicLink: reportData.public_link,
      clientTeamId: reportData.client_team_id
    });

    setSelectedReportId(reportData.id);

    if (form_values.length > 0) {
      const formValues = form_values.map((value: any) => {
        return {
          reportID: value.report_id,
          name: value.name,
          value: value.value
        };
      });
      setFormValues([...formValues]);
    } else {
      setFormValues([]);
    }

    if (
      double_check &&
      !double_check.confirmed_at &&
      !double_check.change_requested_at
    ) {
      setDoubleCheckData(double_check);
    } else {
      setDoubleCheckData(null);
    }

    if (case_images && case_images.length > 0) {
      const caseImages = case_images.map((value: any) => {
        return {
          id: value.id,
          url: value.thumbnail,
          name: value.name,
          dataUrl: "",
          organ: value.organ,
          part: value.part,
          staining: value.staining,
          slide_id: value.slide_id,
          Image: {
            Format: value.format,
            Overlap: value.overlap,
            Size: {
              Height: value.height,
              Width: value.width
            },
            TileSize: value.tile_size,
            Url: value.image_url
          },
          associatedImages: value.associated_images
        };
      });
      setCaseImagesRef([...caseImages]);
    } else {
      setCaseImagesRef([]);
    }
  };

  const setDoubleCheckData = (value: any) => {
    if (value === null) {
      setCaseDoubleCheck(null);
      return;
    }
    setCaseDoubleCheck({
      reportId: value.report_id,
      completedAt: value.confirmed_at,
      changeRequestedAt: value.change_requested_at,
      id: value.id,
      userId: value.user_id
    });
  };

  const handleImageModalVisibility = () => {
    setImageModalVisibility(!imageModalVisibility);
  };

  const handleLinkModalVisibility = () => {
    setLinkModalVisibility(!linkModalVisibility);
  };

  const getCaseTags = async () => {
    setTagsLoading(true);
    try {
      const tagsResponse = await API.get(`reports/${state.reportId}/tags`);
      if (tagsResponse && tagsResponse.data && tagsResponse.data.length) {
        const caseTagData: CaseTag[] = [];
        for (const tag of tagsResponse.data) {
          const imageDataUrl = await API.get("/getDataURL", {
            params: {
              url: tag.image_url
            }
          });
          tag.imageDataUrl = imageDataUrl.data;
          caseTagData.push(caseTagMap(tag));
        }
        setCaseTags([...caseTagData]);
      } else {
        setCaseTags([]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setTagsLoading(false);
  };

  const updateCaseTags = async (tag: any, remove?: boolean) => {
    const caseTags = caseTagsRef.current;
    const currentTag = caseTags.find((caseTag: CaseTag) => {
      return caseTag.id === tag.id;
    });

    let newCaseTags = [] as any;
    if (currentTag) {
      if (remove) {
        newCaseTags = caseTags.filter((caseTag: CaseTag) => {
          return caseTag.id !== tag.id;
        });
      } else {
        newCaseTags = caseTags.map((caseTag: CaseTag) => {
          if (caseTag.id === currentTag.id) {
            tag.imageDataUrl = caseTag.caseImageDataURL;
            return caseTagMap(tag);
          }
          return caseTag;
        });
      }
    } else {
      setTagsLoading(true);
      const imageDataUrl = await API.get("/getDataURL", {
        params: {
          url: tag.image_url
        }
      });
      tag.imageDataUrl = imageDataUrl.data;
      newCaseTags = [...caseTags, caseTagMap(tag)];
    }
    setCaseTags([...newCaseTags]);
    setTagsLoading(false);
  };

  const getCaseLinks = async () => {
    setLinkLoading(true);
    try {
      const linksResponse = await API.get(`reports/${state.reportId}/links`);
      if (linksResponse && linksResponse.data && linksResponse.data.length) {
        const caseLinkData: CaseLink[] = [];

        linksResponse.data.map(async (link: any, index: number) => {
          caseLinkData.push(caseLinkMap(link));
          if (index === linksResponse.data.length - 1) {
            setCaseLinks([...caseLinkData]);
          }
        });
      } else {
        setCaseLinks([]);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
    setLinkLoading(false);
  };
  const caseLinkMap = (link: any) => {
    return {
      id: link.id,
      url: link.url,
      name: link.name
    };
  };

  const handleCaseLinkDelete = async (linkId: number) => {
    try {
      await API.post(`/reports/${state.reportId}/links/${linkId}`, {
        checked: false
      });
      const newLink = caseLinks.filter((link: CaseLink) => {
        return link.id !== linkId;
      });
      setCaseLinks(newLink);
      getCaseLinks();
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleDeleteLink = (linkId: number) => {
    DeleteConfirm(() => {
      return handleCaseLinkDelete(linkId);
    });
  };

  const onSwitchTeamCancel = () => {
    history.push("/");
  };

  return (
    <>
      {isImageViewerVisible ? (
        <ImageViewer
          caseId={state.caseNumber}
          dbId={caseId}
          reportId={state.reportId}
          caseConfirm={!!state.confirmedAt}
          selectedImage={selectedImageID}
          visible
          images={caseImageRef.current.filter(image => {
            return image.url !== "";
          })}
          setLoading={setImageLoading}
          updateCaseTags={updateCaseTags}
          onCancel={() => {
            window.close();
          }}
          setTagsLoading={setTagsLoading}
        />
      ) : (
        <ComponentWrapper
          sidebar
          contentId={caseWrapperId}
          className={classes.componentWrapper}
        >
          <div className={classes.mainContainer} id="top">
            <CaseSteps
              handleImageModalVisibility={handleImageModalVisibility}
              handleLinkModalVisibility={handleLinkModalVisibility}
              state={state}
              caseImages={caseImages}
              hasSidebar={hasSidebar}
              reportConfirmed={state.completedAt !== null}
              imageView={imageView}
              setImageView={setImageView}
              setSelectedReportId={setSelectedReportId}
              selectedReportId={selectedReportId}
              editable={editable}
            />
            {saving && (
              <div className={classes.saving}>
                <Typography fontSize="12px">{t("Saving...")}</Typography>
              </div>
            )}
            {pdfLoading && (
              <div className={classes.pdfLoader}>
                {" "}
                <Loader />{" "}
              </div>
            )}
            <div className={classes.psuedoContainer}>
              {!imageView ? (
                <ReportWrapper
                  {...{
                    loading,
                    state,
                    caseDoubleCheck,
                    setState,
                    allMembers,
                    setSaving,
                    caseImageRef,
                    setCaseImagesRef,
                    caseId,
                    hasSidebar,
                    formValues,
                    getCase,
                    setImageView,
                    setCaseData,
                    getCaseTags,
                    caseTags,
                    tagsLoading,
                    setTagsLoading,
                    imageLoading,
                    imageUploadingList,
                    setImageUploadingList,
                    linkLoading,
                    caseLinks,
                    handleDeleteLink,
                    editable
                  }}
                />
              ) : (
                <CaseImages
                  {...{
                    caseId,
                    hasSidebar,
                    setSaving,
                    selectedReportId,
                    setProgressCaseImagesRef: setProgressCaseImagesRef,
                    setCaseImagesRef: setCaseImagesRef,
                    caseImageRef: caseImageRef
                  }}
                />
              )}
            </div>

            {!imageView && (
              <CaseConfirmation
                setSaving={setSaving}
                userTeamId={defaultTeam}
                caseDoubleCheck={caseDoubleCheck}
                setDoubleCheckData={setDoubleCheckData}
                state={state}
                setState={setState}
                allMembers={allMembers}
                setCaseData={setCaseData}
                setHasSidebar={setHasSidebar}
                permissions={permissions}
                onChangePermission={updatePermissions}
                editable={editable}
                isInCaseTeam={state.teamId === defaultTeam}
              />
            )}
          </div>
          <UploadImageModal
            caseId={caseId}
            reportId={state.reportId}
            images={caseImageRef}
            visible={imageModalVisibility}
            setCaseImagesRef={setCaseImagesRef}
            setImageModalVisibility={setImageModalVisibility}
            teamId={state.teamId}
            setImageUploadingShow={setImageUploadingShow}
            setImageUploadingList={setImageUploadingList}
            setUploadedImagesRef={setUploadedImagesRef}
            uploadedImages={uploadedImagesRef}
            setLoadingFilesToUpload={setLoadingFilesToUpload}
          />
          <UploadLinkModal
            caseId={caseId}
            teamId={state.teamId}
            reportId={state.reportId}
            visible={linkModalVisibility}
            setLinkModalVisibility={setLinkModalVisibility}
            linkLoading={linkLoading}
            setLinkLoading={setLinkLoading}
            getCaseLinks={getCaseLinks}
          />
          <UploadProgress
            imageUploadingShow={imageUploadingShow}
            setImageUploadingShow={setImageUploadingShow}
            intermediateUpload={intermediateUpload}
            setUploadedImagesRef={setUploadedImagesRef}
            uploadedImages={uploadedImagesRef}
            loadingFilesToUpload={loadingFilesToUpload}
          />
          <SwitchTeamModal
            visible={switchTeam.open}
            caseTeams={switchTeam.teams}
            onCancel={onSwitchTeamCancel}
          />
        </ComponentWrapper>
      )}
    </>
  );
};
