import { CopyFilled } from "@ant-design/icons";
import React from "react";
import { useDrop } from "react-dnd";
import { createUseStyles } from "react-jss";
import { Avatar, Typography } from "shared-components";

interface StyleProps {
  isActive: boolean;
  color: string;
}
const useStyles = createUseStyles((theme: any) => {
  return {
    memberBoxTop: {
      border: ({ isActive }: StyleProps) => {
        return isActive
          ? `2px dashed ${theme.border}`
          : `1px solid ${theme.border}`;
      },
      position: "relative",
      background: theme.lightBg,
      bottom: "-2px",
      height: 9,
      width: 45
    },
    memberBox: {
      border: ({ isActive }: StyleProps) => {
        return isActive
          ? `2px dashed ${theme.border}`
          : `1px solid ${theme.border}`;
      },
      borderRadius: 3,
      borderTopLeftRadius: 0,
      padding: 10,
      paddingBottom: 0,
      minWidth: 155,
      height: 60,
      marginRight: 10
    },
    memberInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: 7
    },
    memberTasks: {
      display: "flex",
      alignItems: "center"
    },
    memberName: {
      fontFamily: "HeiseiKakuGoStd",
      fontSize: "14px",
      lineHeight: "16px",
      color: theme.textPrimary
    },
    memberIcon: {
      color: theme.border,
      paddingRight: 5
    }
  };
});
interface Props {
  id: string;
  name: string;
  color: string;
  count: number;
}
export const MemberBox: React.FC<Props> = ({ id, name, color, count }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "case-card",
    drop: () => {
      return {
        id
      };
    },
    collect: (monitor: any) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
    }
  });
  const isActive = canDrop && isOver;

  const classes = useStyles({ isActive, color });

  return (
    <div ref={drop}>
      <div className={classes.memberBoxTop} />
      <div className={classes.memberBox}>
        <Typography className={classes.memberName}>{name}</Typography>
        <div className={classes.memberInfo}>
          <Avatar size={12} color={color} />
          <div className={classes.memberTasks}>
            <CopyFilled className={classes.memberIcon} />
            {count}
          </div>
        </div>
      </div>
    </div>
  );
};
