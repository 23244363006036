import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Col, DatePicker, Radio, Row, Table } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import {
  API,
  AuthContext,
  Button,
  debounceFunction,
  DeleteConfirm,
  exceptionHandler,
  keyValuePair,
  Loader,
  SelectComponent,
  TextArea,
  TextField,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";
import { SwitchTeamModal } from "../../molecules/SwitchTeamModal";
import { requestActions, statusValue } from "./actionValueMap";
import { RequestActionModal } from "./RequestActionModal";
import { RequestActions } from "./RequestActions";
import { SelectCaseModal } from "./SelectCaseModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      margin: "50px 100px"
    },
    header: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    leftIcon: {
      fontSize: "20px",
      marginRight: "10px"
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: 50
    },
    formItem: {
      marginBottom: 30
    },
    formLabel: {
      marginBottom: 13
    },
    select: {
      minWidth: 325,
      "& .ant-select-selector": {
        height: "32px !important"
      }
    },
    formText: {
      maxWidth: 325,
      minWidth: 325
    },
    datePicker: {
      minWidth: 325,
      "& span": {
        display: "flex"
      }
    },
    casesContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 30
    },
    casesHeader: {
      display: "flex",
      justifyContent: "space-between"
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    buttonMargin: {
      marginRight: 50,
      marginLeft: 50,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    casesTable: {
      marginTop: 15
    },
    remove: {
      width: 75,
      textAlign: "center",
      cursor: "pointer"
    },
    logContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      marginTop: 50
    },
    activityLog: {
      display: "flex",
      flexDirection: "row",
      marginTop: "15px !important"
    },
    actionButtonContainer: {
      marginTop: 50,
      display: "flex",
      width: "100%",
      justifyContent: "center"
    },
    estimatingButtons: {
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    error: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 12,
      color: theme.error,
      fontWeight: 300,
      display: "flex",
      lineHeight: "20px"
    },
    seeAll: {
      marginTop: 30,
      fontSize: 14,
      color: theme.primary,
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    },
    logComment: {
      fontSize: 16,
      wordBreak: "break-all"
    }
  };
});

interface ParamTypes {
  id: string;
}

export const CreateEditRequest = ({ errorBoundary }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    defaultTeam,
    defaultTeamName,
    profile: { teams: allTeams }
  } = useContext(AuthContext);

  const { id: diagnosisRequestId } = useParams<ParamTypes>();

  const [loading, setLoading] = useState(true as boolean);
  const [form, setForm] = useState({} as DiagnosisRequestFormData);
  const [clients, setClients] = useState([] as TeamClientData[]);

  const [cases, setCases] = useState([] as DiagnosisCasesData[]);
  const [activities, setActivities] = useState([] as DiagnosisActivity[]);
  const [allActivities, setAllActivities] = useState(false as boolean);

  const [selectCaseModal, setSelectCaseModal] = useState(false as boolean);
  const [requestAction, setRequestAction] = useState({
    open: false,
    action: "",
    handleConfirm: null
  } as RequestAction);

  const [senderEdit, setSenderEdit] = useState(false as boolean);
  const [recieverEdit, setReceiverEdit] = useState(false as boolean);
  const [senderCaseEdit, setSenderCaseEdit] = useState(false as boolean);

  const [switchTeam, setSwitchTeam] = useState({
    open: false,
    teams: []
  } as SwitchTeamValue);

  useEffect(() => {
    if (form && form.id && defaultTeam) {
      if ([1, 2, 4].includes(form.status)) {
        if (form.team_id === defaultTeam) {
          if (form.status === 1 || form.status === 4) {
            setSenderEdit(true);
            if (form.status !== 4) {
              setSenderCaseEdit(true);
            }
          } else {
            setSenderEdit(false);
          }
        } else {
          setSenderEdit(false);
          if (
            activities &&
            activities.length > 0 &&
            !["send_quote", "return_request"].includes(activities[0].action)
          ) {
            setReceiverEdit(true);
          } else {
            setReceiverEdit(false);
          }
        }
      } else {
        setSenderEdit(false);

        setReceiverEdit(false);
      }
    }
  }, [form, defaultTeam, activities]);

  const handleFormChange = async (event: any) => {
    const { name, value } = event.target;

    if (name) {
      const oldForm = form;
      const newForm = {
        ...oldForm,
        [name]: name === "price" ? parseInt(value) : value
      };
      setForm(newForm);
      if (name === "request_type" && value === "general") {
        setCases([]);
      }
      setErrors({});
      debounceFunction(() => {
        return updateDiagnosisRequest(newForm, oldForm);
      });
    }
  };

  const handleDeleteCase = async (id: number) => {
    const oldCases = cases;
    const newCases = cases.filter((caseData: DiagnosisCasesData) => {
      return caseData.id !== id;
    });

    if (form.status === statusValue.Editing) {
      DeleteConfirm(async () => {
        try {
          await API.put(`requests/${form.id}`, {
            team_client_id: form.team_client_id || undefined,
            request_type: form.request_type || undefined,
            request_body: form.request_body || undefined,
            price: form.price || undefined,
            due_date: form.due_date || undefined,
            case_ids:
              newCases && newCases.length > 0
                ? newCases.map((caseData: DiagnosisCasesData) => {
                    return caseData.id;
                  })
                : undefined
          });
          setCases(newCases);
        } catch (error) {
          setCases(oldCases);
          exceptionHandler(error, t);
        }
      });
    } else {
      setCases(newCases);
    }
  };

  const updateDiagnosisRequest = async (
    form: DiagnosisRequestFormData,
    oldForm: DiagnosisRequestFormData
  ) => {
    if (form.status !== statusValue.Editing) {
      return;
    }
    try {
      await API.put(`requests/${form.id}`, {
        team_client_id: form.team_client_id || undefined,
        request_type: form.request_type || undefined,
        request_body: form.request_body || undefined,
        price: form.price || undefined,
        due_date: form.due_date || undefined,
        case_ids:
          form.request_type === "general"
            ? []
            : cases && cases.length > 0
            ? cases.map((caseData: DiagnosisCasesData) => {
                return caseData.id;
              })
            : undefined
      });
    } catch (error) {
      setForm(oldForm);
      exceptionHandler(error, t);
    }
  };

  const [sender, setSender] = useState(true as boolean);

  useEffect(() => {
    loadClients();
  }, [defaultTeam]);

  useEffect(() => {
    loadDiagnosisData();
  }, [diagnosisRequestId]);

  const loadClients = async () => {
    try {
      const response: any = await API.get(`clients`);
      if (!response.data) {
        setClients([]);
        return;
      }
      setClients(response.data);
    } catch (error) {
      setClients([]);
      exceptionHandler(error, t);
    }
  };

  const loadDiagnosisData = async () => {
    if (!diagnosisRequestId || diagnosisRequestId === "add") {
      return;
    }
    setLoading(true);
    try {
      const response = await API.get(`/requests/${diagnosisRequestId}`);

      if (response.data) {
        const { cases, activities, ...form } = response.data;
        setForm({ ...form });
        if (cases) {
          setCases(cases);
        } else {
          setCases([]);
        }
        if (activities) {
          setActivities(activities);
        } else {
          setActivities([]);
        }
        if (form.team_id === defaultTeam) {
          setSender(true);
        } else {
          setSender(false);
        }
      }
    } catch (err) {
      setLoading(false);
      if (err && err.response && err.response.status === 406) {
        const { message } = err.response.data.error;
        const caseTeamIds = message.split(":");
        const teamNames = allTeams.filter((team: any) => {
          return caseTeamIds.includes(team.id.toString());
        });
        setSwitchTeam({ open: true, teams: teamNames });
        return;
      }
      exceptionHandler(err, t);
      errorBoundary(err);
    }
    setLoading(false);
  };

  const onSwitchTeamCancel = () => {
    history.push("/request");
  };

  const basicColumns = [
    {
      title: t("Medical record ID"),
      dataIndex: "medical_record_id",
      key: "medical_record_id"
    },
    {
      title: t("Pathology number"),
      dataIndex: "pathology_number",
      key: "pathology_number"
    },
    {
      title: t("Pathologist"),
      dataIndex: "pathologist",
      key: "pathologist"
    },
    {
      title: t("Organs"),
      dataIndex: "organs",
      key: "organs",
      render: (value: string) => {
        const makeOrgansArray = value.split(",");
        const requiredOrgansArray = makeOrgansArray.map(organ => {
          return t(`${organ.substring(organ.indexOf("-") + 1)}`);
        });
        return value ? requiredOrgansArray.toString() : value;
      }
    }
  ];
  const receivedColumns = [
    {
      title: t("Consult number"),
      dataIndex: "consult_number",
      key: "consult_number"
    },
    {
      title: t("Consult pathologist"),
      dataIndex: "consult_pathologist",
      key: "consult_pathologist"
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (text: string, data: DiagnosisCasesData) => {
        if (data.status) {
          return t("complete");
        }
        return t("incomplete");
      }
    }
  ];

  const actionColumn = {
    title: "",
    key: "action",
    render: (text: string, data: DiagnosisCasesData) => {
      return (
        <DeleteOutlined
          onClick={() => {
            return handleDeleteCase(data.id);
          }}
        />
      );
    }
  };

  const statusColumn =
    form.status < statusValue.Working
      ? [...basicColumns]
      : [...basicColumns, ...receivedColumns];

  const columns =
    sender && senderEdit && senderCaseEdit
      ? [...statusColumn, actionColumn]
      : statusColumn;

  const [errors, setErrors] = useState({} as keyValuePair);

  const disabledToDate = (current: Moment) => {
    return current && current < moment().subtract(1, "days");
  };

  const handleSeeAll = async () => {
    try {
      setAllActivities(true);
      const response = await API.get(`/requests/${form.id}/activities`);
      if (response.data) {
        setActivities(response.data);
      }
    } catch (error) {
      setAllActivities(false);
      exceptionHandler(error, t);
    }
  };

  const getOptions = () => {
    if (sender) {
      return clients.map((client: TeamClientData) => {
        return {
          value: client.id,
          label: client.team_name
        };
      });
    }
    return [{ value: form.team_client_id, label: defaultTeamName }];
  };

  return (
    <ComponentWrapper>
      <div className={classes.container}>
        <div className={classes.header}>
          <ArrowLeftOutlined
            className={classes.leftIcon}
            onClick={() => {
              return history.push("/diagnosis");
            }}
          />
          <Typography fontSize="24px">{t("Diagnosis request")}</Typography>
        </div>
        <div className="clearfix" />
        {loading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : (
          <>
            <div className={classes.formContainer}>
              {!sender && (
                <>
                  <div className={classes.formItem}>
                    <Typography fontSize="16px" className={classes.formLabel}>
                      {t("From2")}
                    </Typography>
                    <TextField
                      name="sender_team_name"
                      error={errors.from}
                      disabled={!senderEdit}
                      editValue={form.sender_team_name || undefined}
                      className={classes.formText}
                      onChange={handleFormChange}
                    />
                  </div>
                  <div className={classes.formItem}>
                    <Typography fontSize="16px" className={classes.formLabel}>
                      {t("Transaction ID")}
                    </Typography>
                    <TextField
                      name="transaction_id"
                      error={errors.from}
                      disabled={!senderEdit}
                      editValue={
                        form.transaction_id?.toString().padStart(6, "0") ||
                        undefined
                      }
                      className={classes.formText}
                      onChange={handleFormChange}
                    />
                  </div>
                </>
              )}
              <div className={classes.formItem}>
                <Typography fontSize="16px" className={classes.formLabel}>
                  {t("Request type")}
                </Typography>
                <Radio.Group
                  name="request_type"
                  onChange={handleFormChange}
                  value={form.request_type}
                  disabled={!senderEdit}
                >
                  <Radio value="pathology">{t("Pathology")}</Radio>
                  <Radio value="general">{t("General")}</Radio>
                </Radio.Group>
                {errors.request_type && (
                  <p className={classes.error}>{t(`${errors.request_type}`)}</p>
                )}
              </div>
              <div className={classes.formItem}>
                <Typography fontSize="16px" className={classes.formLabel}>
                  {t("To")}
                </Typography>
                <SelectComponent
                  name="team_client_id"
                  disabled={!senderEdit}
                  className={classes.select}
                  error={errors.team_client_id}
                  options={getOptions()}
                  onChange={(name, value) => {
                    return handleFormChange({
                      target: {
                        name,
                        value
                      }
                    });
                  }}
                  value={form.team_client_id}
                />
              </div>
              <div className={classes.formItem}>
                <Typography fontSize="16px" className={classes.formLabel}>
                  {t("Price")}
                </Typography>
                <TextField
                  name="price"
                  type="number"
                  error={errors.price}
                  disabled={!recieverEdit}
                  editValue={form.price || undefined}
                  className={classes.formText}
                  onChange={handleFormChange}
                />
              </div>
              <div className={classes.formItem}>
                <Typography fontSize="16px" className={classes.formLabel}>
                  {t("Due date")}
                </Typography>
                <DatePicker
                  disabled={!recieverEdit}
                  className={classes.datePicker}
                  name="due_date"
                  disabledDate={disabledToDate}
                  onChange={value => {
                    return handleFormChange({
                      target: { name: "due_date", value: value?.toJSON() }
                    });
                  }}
                  value={form.due_date ? moment(form.due_date) : null}
                  placeholder=""
                />
                {errors.due_date && (
                  <p className={classes.error}>{t(`${errors.due_date}`)}</p>
                )}
              </div>
              <div className={classes.formItem}>
                <Typography fontSize="16px" className={classes.formLabel}>
                  {t("Message")}
                </Typography>
                <TextArea
                  name="request_body"
                  rows={5}
                  error={errors.request_body}
                  disabled={!senderEdit}
                  inputClass={classes.formText}
                  value={form.request_body}
                  onChange={(name, value) => {
                    return handleFormChange({
                      target: {
                        name,
                        value
                      }
                    });
                  }}
                />
              </div>
            </div>
            {form.request_type !== "general" && (
              <div className={classes.casesContainer}>
                <div className={classes.casesHeader}>
                  <Typography fontSize="16px">{t("Request Cases")}</Typography>
                  {senderEdit && senderCaseEdit && (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        return setSelectCaseModal(true);
                      }}
                      className={classes.createButton}
                    >
                      {t("Select cases")}{" "}
                    </Button>
                  )}
                </div>
                <Table
                  className={classes.casesTable}
                  columns={columns}
                  dataSource={cases}
                  rowKey="id"
                  pagination={false}
                />
              </div>
            )}

            <RequestActions
              {...{
                sender,
                form,
                errors,
                setErrors,
                cases,
                setForm,
                setRequestAction,
                activities,
                setActivities,
                requestAction
              }}
            />
            {activities && activities.length > 0 && (
              <div className={classes.logContainer}>
                <Typography fontSize="16px">{t("Activity log")}</Typography>

                {activities.map((log: DiagnosisActivity, index: number) => {
                  return (
                    <Row className={classes.activityLog} key={index}>
                      <Col span={4}>
                        <Typography fontSize="16px">
                          {moment(log.updated_at).format("YYYY-MM-DD HH:mm")}
                        </Typography>
                      </Col>

                      <Col span={4}>
                        <Typography fontSize="16px">
                          {t(requestActions[log.action])}
                        </Typography>
                      </Col>

                      {log.team_name !== "" && (
                        <Col span={4}>
                          <Typography fontSize="16px">
                            {log.team_name}
                          </Typography>
                        </Col>
                      )}

                      {log.user_name !== "" && (
                        <Col span={4}>
                          <Typography fontSize="16px">
                            {log.user_name}
                          </Typography>
                        </Col>
                      )}

                      <Col span={log.team_name !== "" ? 8 : 16}>
                        <Typography className={classes.logComment}>
                          {log.comment}
                        </Typography>
                      </Col>
                    </Row>
                  );
                })}
                {activities.length > 9 && !allActivities && (
                  <div onClick={handleSeeAll}>
                    <Typography className={classes.seeAll}>
                      {t("See all logs...")}
                    </Typography>
                  </div>
                )}
              </div>
            )}
            <SelectCaseModal
              open={selectCaseModal}
              onClose={() => {
                return setSelectCaseModal(false);
              }}
              cases={cases}
              setCases={setCases}
              form={form}
            />
            <RequestActionModal
              requestAction={requestAction}
              setRequestAction={setRequestAction}
            />
            <SwitchTeamModal
              visible={switchTeam.open}
              caseTeams={switchTeam.teams}
              onCancel={onSwitchTeamCancel}
            />
          </>
        )}
      </div>
    </ComponentWrapper>
  );
};
