import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  children: React.ReactNode;
  className?: any;
  sidebar?: boolean;
  onScroll?: any;
  contentId?: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    componentWrapper: {
      marginTop: 40,
      background: "white",
      "& .ant-layout-content": {
        height: "100%",
        overflowY: "auto"
      }
    }
  };
});

const ComponentWrapper: React.FC<Props> = props => {
  const classes = useStyles();

  const { Content } = Layout;

  return (
    <Layout className={classes.componentWrapper}>
      <Content
        id={props.contentId}
        className={props.className}
        onScroll={props.onScroll}
      >
        {props.children}
      </Content>
    </Layout>
  );
};

export { ComponentWrapper };
