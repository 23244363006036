import { Menu } from "antd";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface CaseMenuItem {
  name: string;
  onClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  deviderUpon: boolean;
  startIcon: React.ReactNode;
}

interface MenuClickEvent {
  key: string;
  keyPath: string[];
  item: React.ReactInstance;
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

interface Props {
  items: CaseMenuItem[];
  visible: boolean;
}

const CaseMenu = (props: Props) => {
  const { t } = useTranslation();

  const handleOnClick = (event: MenuClickEvent) => {
    const targetItem = props.items.find((item: CaseMenuItem) => {
      return item.name === event.key;
    });

    targetItem && targetItem.onClick(event.domEvent);
  };

  return props.items && props.items.length > 0 ? (
    <Menu mode={"vertical"} selectable={false} onClick={handleOnClick}>
      {props.items.map((item: CaseMenuItem) => {
        return (
          <Fragment key={item.name}>
            {item.deviderUpon && <Menu.Divider />}
            <Menu.Item key={item.name} icon={item.startIcon}>
              {t(item.name)}
            </Menu.Item>
          </Fragment>
        );
      })}
    </Menu>
  ) : null;
};

export { CaseMenu };
