import React from "react";
import { Controller } from "react-hook-form";
import { SelectComponent } from "shared-components";

export default function({
  name,
  label,
  control,
  onBlur,
  options,
  defaultValue,
  disable
}: SelectProps) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value, name }) => {
        return (
          <SelectComponent
            disabled={disable}
            options={options}
            size="middle"
            label={label}
            name={name}
            onChange={(_, value) => {
              return onChange(value);
            }}
            onBlur={onBlur}
            value={value}
          />
        );
      }}
    />
  );
}
