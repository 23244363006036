import { DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tooltip } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { AuthContext, Loader, PdfContext, Typography } from "shared-components";

import { FormTitle } from "../../../forms/Layout";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: (isConsult: boolean) => {
      return {
        padding: "19px 40px 25px 22px",
        background: isConsult ? theme.greenBackground : theme.formBackground
      };
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20
    },
    title: {
      padding: 0
    },
    mainContainer: {
      // display: "flex"
      // background: ({ pdfLoading }: any) => {
      //   return pdfLoading && theme.grayBlueBg;
      // }
    },
    iconContainer: {
      float: "right"
    },
    icon: {
      verticalAlign: "top",
      "& svg": {
        fontSize: 14,
        textAlign: "right"
      },
      cursor: "pointer"
    },
    cardBody: {
      width: "100%",
      borderRadius: 5,
      boxShadow: "0px 0px 5px #00000026",
      "&:hover": {
        cursor: "pointer",
        boxShadow: `0px 0px 5px 0px ${theme.primary}`,
        transition: " ease-in-out, width .35s ease-in-out"
      }
    },
    cardTitle: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: "14px",
      textOverflow: "ellipsis",
      width: "100%"
    },
    cardDescription: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontSize: "12px",
      textOverflow: "ellipsis",
      width: "100%"
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 130
    },
    linkMessage: {
      minHeight: 100,
      paddingTop: 20,
      display: "flex",
      justifyContent: "center",
      fontSize: 16,
      color: theme.selectText
    }
  };
});

interface Props {
  caseId: number;
  reportId: number;
  loading?: boolean;
  caseLinks: CaseLink[];
  handleDelete?: (linkId: number) => void;
  publicReport?: boolean;
  isConsult?: boolean;
}

const ReportLinks = ({
  caseId,
  reportId,
  loading,
  caseLinks,
  handleDelete,
  publicReport,
  isConsult
}: Props) => {
  const { t } = useTranslation();

  const { pdfLoading } = useContext(PdfContext);
  const { externalUser } = useContext(AuthContext);
  const classes = useStyles(isConsult);

  const { Meta } = Card;

  return (
    <>
      {(caseLinks.length !== 0 || loading) && (
        <div
          className={pdfLoading ? classes.container : classes.container}
          id="caseLinks"
        >
          <div className={classes.titleContainer}>
            <FormTitle isConsult={isConsult} className={classes.title}>
              {externalUser ? t("Slide") : t("Link")}
            </FormTitle>
          </div>
          {caseLinks.length !== 0 || loading ? (
            <div className={classes.mainContainer} id="caseLinks">
              <Row gutter={[16, 16]}>
                {caseLinks.map((link: CaseLink, index: any) => {
                  return (
                    <Col lg={8} md={12} sm={12} xs={24} key={index}>
                      <Card className={classes.cardBody} bordered={true}>
                        {!publicReport && handleDelete && (
                          <div className={classes.iconContainer}>
                            <Tooltip placement="bottom" title={t("Delete")}>
                              <DeleteOutlined
                                className={classes.icon}
                                onClick={() => {
                                  return handleDelete(link.id);
                                }}
                              />
                            </Tooltip>
                          </div>
                        )}
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip
                            placement="bottom"
                            title={link.name + "\n" + link.url}
                          >
                            <Meta
                              title={
                                <Typography
                                  className={classes.cardTitle}
                                >{`${link.name}`}</Typography>
                              }
                              description={
                                <Typography
                                  className={classes.cardDescription}
                                >{`${link.url}`}</Typography>
                              }
                            />
                          </Tooltip>
                        </a>
                      </Card>
                    </Col>
                  );
                })}
                {loading && (
                  <Col
                    xs={24}
                    sm={24}
                    lg={24}
                    md={24}
                    xl={12}
                    xxl={pdfLoading ? 12 : 8}
                    span={8}
                    className={classes.loading}
                  >
                    <Loader />
                  </Col>
                )}
              </Row>
            </div>
          ) : (
            <div className={classes.linkMessage}>
              {t("Please check links to be pasted from the image/link list.")}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { ReportLinks };
