import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { API, exceptionHandler, SelectComponent } from "shared-components";

import { FormTitle } from "./Layout";

let useStyles = createUseStyles((theme: any) => {
  return {
    container: (isConsult: boolean) => {
      return {
        padding: "8px 40px 15px",
        marginBottom: "5px",
        background: isConsult ? theme.greenBackground : theme.grayBlueBg
      };
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    select: {
      maxWidth: 200,
      "& .ant-select": {
        marginBottom: 0
      },
      "& .ant-select-selector": {
        height: "29px !important",
        background: "none !important",
        borderColor: `${theme.selectText} !important`
      },
      " & .ant-select-arrow": {
        color: `${theme.selectText} !important`
      }
    }
  };
});

interface Props {
  reportId: number;
  setCaseData: (response: any) => void;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  isConsult?: boolean;
}

const SelectFinding = ({
  reportId,
  setCaseData,
  setSaving,
  isConsult
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleFormSelect = async (name: string, value: string) => {
    setSaving(true);
    try {
      const response = await API.post("/form-name-update", {
        report_id: reportId,
        form_name: value
      });
      setCaseData(response);
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <FormTitle isConsult={isConsult}>{t("Findings")}</FormTitle>
        <SelectComponent
          wrapperClassName={classes.select}
          options={[
            {
              label: t("IPF/UIP"),
              value: "ipfuip"
            },
            {
              label: t("General"),
              value: "general"
            },
            {
              label: t("Colorectal"),
              value: "colorectal"
            }
          ]}
          name="form_name"
          placeholder={t("Select Form")}
          onChange={handleFormSelect}
          size="small"
          id="caseFormSelect"
        />
      </div>
    </div>
  );
};

export { SelectFinding };
