import React from "react";
import { Controller } from "react-hook-form";
import { TextArea } from "shared-components";

export function tailwind({
  label,
  register,
  required,
  onBlur,
  name
}: ReactHookFormInputProps) {
  return (
    <div className={`flex flex-col my-3`}>
      <label htmlFor="" className="text-gray-700">
        {label}
      </label>
      <input
        name={name}
        type="text"
        ref={register({ required })}
        className="appearance-none border rounded py-1 px-3"
        onBlur={onBlur}
      />
    </div>
  );
}

export default function({
  name,
  label,
  control,
  onBlur,
  defaultValue,
  placeholder,
  disable
}: InputProps) {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, value, name }) => {
          return (
            <TextArea
              disabled={disable}
              name={name}
              label={label}
              onChange={(_, value) => {
                return onChange(value);
              }}
              onBlur={onBlur}
              value={value}
              placeholder={placeholder}
              rows={5}
            />
          );
        }}
      />
    </div>
  );
}
