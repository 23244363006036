// import { createUseStyles } from "react-jss";
import { DeleteOutlined, MailOutlined } from "@ant-design/icons";
import { Select, Table, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { TableCell } from "./TableCell";

interface Props {
  rows: ReportPermissionData[];
  onChangePermission: (name: string, permission: string) => void;
  onDeletePermission: (id_email: string, registered: boolean) => void;
  onResendInvitation: (email: string) => void;
}

const SharingSettingTable = (props: Props) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Name"),
      dataIndex: "name",
      width: "40%",
      sorter: (a: ReportPermissionData, b: ReportPermissionData) => {
        return a.name.localeCompare(b.name);
      },
      render: (text: string, record: any) => {
        if (record.subject_type === "user") {
          return <TableCell text={text} />;
        }
        return <TableCell text={`(${t("Inviting")}) ${text}`} />;
      }
    },
    {
      title: t("Permission"),
      dataIndex: "level",
      ellipsis: true,
      width: "40%",
      render: (text: string, record: any) => {
        if (record.subject_type === "user") {
          return (
            <Select
              value={text}
              style={{ width: "100%" }}
              onChange={(value: string) => {
                props.onChangePermission(record.name, value);
              }}
            >
              <Select.Option value="owner">{t("Owner")}</Select.Option>
              <Select.Option value="editor">{t("Editor")}</Select.Option>
              <Select.Option value="reviewer">
                {t("Commentable Reviewer")}
              </Select.Option>
            </Select>
          );
        }
        return <TableCell text={t(text)} />;
      }
    },
    {
      title: t("Action"),
      dataIndex: "subject",
      width: "20%",
      render: (text: { id?: string; email?: string }, record: any) => {
        if (record.subject_type === "user") {
          return (
            <Tooltip title={t("Delete")}>
              <DeleteOutlined
                onClick={() => {
                  return text.id && props.onDeletePermission(text.id, true);
                }}
              />
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={t("Delete")}>
              <DeleteOutlined
                onClick={() => {
                  return (
                    text.email && props.onDeletePermission(text.email, false)
                  );
                }}
              />
            </Tooltip>
            <span style={{ paddingLeft: "20px" }} />
            <Tooltip title={t("Resend Invitation")}>
              <MailOutlined
                onClick={() => {
                  return text.email && props.onResendInvitation(text.email);
                }}
              />
            </Tooltip>
          </>
        );
      }
    }
  ];

  return (
    <div style={{ width: "552px", maxHeight: "300px", overflowY: "scroll" }}>
      <Table
        columns={columns}
        dataSource={props.rows}
        pagination={false}
        rowKey={"name"}
      />
    </div>
  );
};

export { SharingSettingTable };
