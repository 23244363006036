import { Card, Form, Radio, Tooltip, Upload } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  API,
  AuthContext,
  BorderBoxWrapper,
  Button,
  CancelInvitationIcon,
  Circle,
  DividerComponent,
  exceptionHandler,
  Loader,
  Modal,
  ResendInvitationIcon,
  TextField,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";

interface Members {
  color?: string;
  name: string;
  email: string;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    card: {
      borderRadius: 3,
      height: 33,
      "& > div": {
        padding: "7px 0px 0px 10px"
      },
      marginBottom: 10
    },
    invitedCard: {
      borderRadius: 3,
      width: "50%",
      height: 33,
      "& > div": {
        padding: "7px 0px 0px 10px"
      },
      backgroundColor: theme.gray4,
      marginBottom: 10
    },
    title: {
      marginBottom: 6,
      fontSize: 14
    },
    divider: {
      border: `1px solid ${theme.gray4}`
    },
    borderBox: {
      "& > div": {
        padding: 0
      }
    },
    wrapper: {
      padding: "21px 0px 18px 30px",
      overflow: "hidden",
      "&:hover": {
        "overflow-y": "auto"
      },
      "&::-webkit-scrollbar": {
        width: 5
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#c1c1c1",
        borderRadius: "10px"
      }
    },
    inviteUserWrapperHeight: {
      height: 373
    },
    teamCodeWrapperHeight: {
      height: 128
    },
    teamLogoWrapperHeight: {
      height: 96
    },
    buttonWrapper: {
      textAlign: "center",
      marginLeft: 20,
      marginBottom: 16
    },
    uploadWrapper: {
      marginLeft: 20,
      marginBottom: 16,
      "& .ant-upload-list-item-thumbnail": {
        "& .ant-upload-list-item-image": {
          objectFit: "contain"
        }
      }
    },
    modal: {
      maxWidth: "60%"
    },
    modalButton: {
      textAlign: "right"
    },
    text: {
      maxWidth: "100%"
    },
    left: {
      float: "left"
    },
    inline: {
      display: "flex",
      alignItems: "center",
      marginBottom: 4
    },
    member: {
      padding: 6
    },
    button: {
      backgroundColor: theme.darkBlueButton,
      width: 160,
      height: 28,
      padding: 0,
      borderRadius: 3
    },
    modalBox: {
      height: 480,
      maxwidth: 850,
      marginTop: 5,
      "& .ant-modal-body": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }
    },
    teamCode: {
      color: "#5A5A5A",
      "& span": {
        marginLeft: 20,
        fontSize: 22,
        color: "#2E2E2E"
      }
    },
    teamLogo: {
      color: "#5A5A5A",
      "& span": {
        marginLeft: 20,
        fontSize: 22,
        color: "#2E2E2E"
      }
    },
    firstDesc: {
      marginTop: 18
    },
    radiobutton: {
      fontSize: "16px !important",
      letterSpacing: 0,
      lineHeight: "19px",
      marginRight: 30,
      color: theme.textLightBlack,
      "& .ant-radio-checked": {
        "& :after": {
          background: "#000000"
        }
      }
    },
    labeltext: {
      color: theme.textBlue,
      fontSize: "12px",
      letterSpacing: 0,
      lineHeight: "14px"
    },
    nameLabel: {
      marginBottom: 7
    },
    emailLabel: {
      marginBottom: 3
    },
    radioGroup: {
      display: "flex"
    },
    formItem: {
      marginBottom: 19
    },
    invitationIconWrapper: {
      marginLeft: 10,
      cursor: "pointer",
      marginTop: 5
    },
    invitationResendIcon: {
      "& > svg": {
        height: 25,
        width: 25
      }
    },
    invitationCancelIcon: {
      marginLeft: 5,
      "& > svg": {
        height: 25,
        width: 25
      }
    }
  };
});

const Member = () => {
  const { defaultTeam, invitationCode } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [members, setMembers] = useState([] as Members[]);
  const [state, setState] = useState({
    email: "",
    name: "",
    language: "japanese"
  });
  const [loading, setLoading] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [response, setResponse] = useState({} as any);
  const [form] = Form.useForm();
  const [invited, setInvited] = useState([] as Members[]);
  const [imageList, setImageList] = useState([] as any);

  useEffect(() => {
    getMembers();
    getTeamLogo();
  }, [response, defaultTeam]);

  const getMembers = async () => {
    try {
      const response = await API.get(`members`, {
        params: { teamId: defaultTeam }
      });
      if (!response.data) {
        response.data = [];
      }
      const sortedData = response.data.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      setMembers(sortedData);

      const invited = await API.get(`/members/invited-users`);
      const sortedInvited = invited.data.sort((a: any, b: any) => {
        if (a.name < b.name) return -1;
        return a.name > b.name ? 1 : 0;
      });
      setInvited([...sortedInvited]);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(state => {
      return { ...state, [name]: value };
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { name, email, language } = state;
      const response = await API.post(`members/invite-users`, {
        name,
        email,
        language
      });
      setResponse(response);
      setLoading(false);
      form.resetFields();
      setVisibility(false);
      Alert("success", "success", t("Member has been invited."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const toggleModal = () => {
    setVisibility(!visibility);
  };

  const handleCopyButtonClick = () => {
    try {
      navigator.clipboard.writeText(invitationCode);
      Alert("success", "success", t("Team code copied successfully."), t);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleResendInvitation = async (email: string) => {
    try {
      const response: any = await API.post(`invite-users/resend-invitation`, {
        email
      });
      if (response) {
        if (
          response.data &&
          response.data.code &&
          response.data.code === "info"
        ) {
          Alert(
            "",
            "info",
            t(
              "Less than 10 minutes have passed since the last resend. Please resend after a while."
            ),
            t
          );
        } else {
          Alert("success", "success", t("Invitation email is sent"), t);
        }
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCancelInvitation = async (email: string) => {
    try {
      await API.post(`invite-users/cancel-invitation`, {
        email
      });
      getMembers();
      Alert("success", "success", t("Invitation is cancelled"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeTeamLogo = async (info: any) => {
    if (info.file.status === "uploading") {
      const newImageObj = info.file.originFileObj;
      // Upload
      setLoading(true);
      const formData = new FormData();
      formData.append("name", newImageObj.name);
      formData.append("team_logo", newImageObj);

      try {
        const response = await API.put(
          `teams/${defaultTeam}/team-logo`,
          formData
        );
        setImageList([
          {
            uid: "1",
            name: newImageObj.name,
            originFileObj: newImageObj,
            url: response.data
          }
        ]);
        setLoading(false);
        Alert("success", "success", t("Your team-logo has been updated."), t);
        history.go(0);
      } catch (error) {
        setLoading(false);
        setImageList([]);
        exceptionHandler(error, t);
      }
    }
  };

  const handlePreviewTeamLogo = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => {
          return resolve(reader.result);
        };
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow && imgWindow.document.write(image.outerHTML);
  };

  const getTeamLogo = async () => {
    // Upload
    setLoading(true);

    try {
      const response = await API.get(`teams/${defaultTeam}/team-logo`);
      response.data &&
        setImageList([
          {
            uid: "1",
            name: defaultTeam.toString(),
            url: response.data,
            status: "done"
          }
        ]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleRemoveTeamLogo = async (file: any) => {
    // Upload
    setLoading(true);

    try {
      await API.delete(`teams/${defaultTeam}/team-logo`);
      setImageList([]);
      setLoading(false);
      Alert("success", "success", t("Your team-logo has been deleted."), t);
      history.go(0);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <ComponentWrapper sidebar>
      <BorderBoxWrapper heading={t("Member")} className={classes.borderBox}>
        <div
          className={`${classes.wrapper} ${classes.inviteUserWrapperHeight}`}
        >
          {members.map((member: Members, index: number) => {
            return (
              <div className={classes.inline} key={index}>
                <div className={classes.left}>
                  <Circle size="25" color={member.color} />
                </div>
                <Card key={index} bordered={false} className={classes.card}>
                  <Typography type="boldBody" className={classes.member}>
                    {" "}
                    {member.name || member.email}
                  </Typography>
                </Card>
              </div>
            );
          })}
          {invited.map((member: Members, index: number) => {
            return (
              <div className={classes.inline} key={index}>
                <div className={classes.left}>
                  <Circle border size="25" color={"transparent"} />
                </div>
                <Card key={index} bordered={false} className={classes.card}>
                  <Typography type="boldBody" className={classes.member}>
                    {" "}
                    {member.name}
                  </Typography>
                </Card>
                <div className={classes.invitationIconWrapper}>
                  <Tooltip placement="bottom" title={t("Resend Invitation")}>
                    <ResendInvitationIcon
                      className={classes.invitationResendIcon}
                      onClick={() => {
                        return handleResendInvitation(member.email);
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="bottom" title={t("Cancel Invitation")}>
                    <CancelInvitationIcon
                      className={classes.invitationCancelIcon}
                      onClick={() => {
                        return handleCancelInvitation(member.email);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <DividerComponent className={classes.divider} />
          <div className={classes.buttonWrapper}>
            <Button
              type="primary"
              size="large"
              onClick={toggleModal}
              className={classes.button}
            >
              {t("Invite")}{" "}
            </Button>
          </div>
        </div>
      </BorderBoxWrapper>
      <BorderBoxWrapper headingSpacing="0px" className={classes.borderBox}>
        <div className={`${classes.wrapper} ${classes.teamCodeWrapperHeight}`}>
          <Typography fontSize={"20px"} className={classes.teamCode}>
            {t("Team Code")} <span>{invitationCode}</span>
          </Typography>
          <Typography fontSize={"14px"} className={classes.firstDesc}>
            ※{t("This is a code to be used for requests and consultations.")}
          </Typography>
          <Typography fontSize={"14px"}>
            ※
            {t(
              "If you have not yet communicated with the other team, provide them the code."
            )}
          </Typography>
          <Typography fontSize={"14px"}>
            ※
            {t(
              "Once the code is entered, it will be added to their contact list."
            )}
          </Typography>
        </div>
        <DividerComponent className={classes.divider} />
        <div className={classes.buttonWrapper}>
          <Button
            type="primary"
            size="large"
            onClick={handleCopyButtonClick}
            className={classes.button}
          >
            {t("Copy")}{" "}
          </Button>
        </div>
      </BorderBoxWrapper>
      <BorderBoxWrapper headingSpacing="0px" className={classes.borderBox}>
        <div className={`${classes.wrapper} ${classes.teamLogoWrapperHeight}`}>
          <Typography fontSize={"20px"} className={classes.teamLogo}>
            {t("Team Logo")}
          </Typography>
          <Typography fontSize={"14px"} className={classes.firstDesc}>
            ※
            {t(
              "You can set your own team logo on page header instead of the Porous logo."
            )}
          </Typography>
        </div>
        <div className={classes.uploadWrapper}>
          {loading ? (
            <Loader />
          ) : (
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              accept=".png, .jpg, .jpeg, .svg"
              fileList={imageList}
              onChange={handleChangeTeamLogo}
              onPreview={handlePreviewTeamLogo}
              onRemove={handleRemoveTeamLogo}
              // className={classes.uploadWrapper}
            >
              {imageList.length < 1 && "+ Upload"}
            </Upload>
          )}
        </div>
      </BorderBoxWrapper>
      <Modal
        onCancel={toggleModal}
        visible={visibility}
        mask={false}
        className={classes.modalBox}
        centered
      >
        <Form form={form} className={classes.modal} onFinish={handleSubmit}>
          <Form.Item
            id="name"
            name="name"
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              },
              {
                max: 60,
                message: t("Maximum input limit is 60 characters")
              }
            ]}
          >
            <div>
              <Typography
                className={`${classes.labeltext} ${classes.nameLabel}`}
              >
                {t("Name")}
              </Typography>
              <TextField
                autoComplete="off"
                name="name"
                onChange={handleChange}
                maxLength={60}
              />
            </div>
          </Form.Item>
          <Form.Item
            id="email"
            name="email"
            className={classes.formItem}
            rules={[
              {
                type: "email",
                message: t("Not a valid email")
              },
              {
                required: true,
                message: t("Required")
              },
              {
                max: 60,
                message: t("Maximum input limit is 60 characters")
              }
            ]}
          >
            <div>
              <Typography
                className={`${classes.labeltext} ${classes.emailLabel}`}
              >
                {t("Email")}
              </Typography>
              <TextField
                autoComplete="off"
                name="email"
                onChange={handleChange}
              />
            </div>
          </Form.Item>
          <Form.Item
            colon={false}
            id="language"
            required={true}
            name="language"
          >
            <div>
              <Typography
                className={`${classes.labeltext} ${classes.emailLabel}`}
              >
                {t("Language of invitation mail")}
              </Typography>
              <Radio.Group
                name="language"
                value={state.language}
                className={classes.radioGroup}
                onChange={handleChange}
              >
                <Radio value={"japanese"} className={classes.radiobutton}>
                  {t("Japanese")}
                </Radio>
                <Radio value={"english"} className={classes.radiobutton}>
                  {t("English")}
                </Radio>
              </Radio.Group>
            </div>
          </Form.Item>
          <Form.Item className={classes.modalButton}>
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.button}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t("Submit")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </ComponentWrapper>
  );
};

export { Member };
