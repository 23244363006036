type OptionField = { label: string; value: string };

export interface TargetPartOptions {
  primaryTumor: OptionField[];
  lymphNode: OptionField[];
  metastasis: OptionField[];
  histopathological?: OptionField[];
  stage: OptionField[];
}

const targetPartStage = [
  { label: "p0", value: "p0" },
  { label: "pⅠ", value: "pⅠ" },
  { label: "pⅡa", value: "pⅡa" },
  { label: "pⅡb", value: "pⅡb" },
  { label: "pⅡc", value: "pⅡc" },
  { label: "pⅢa", value: "pⅢa" },
  { label: "pⅢb", value: "pⅢb" },
  { label: "pⅢc", value: "pⅢc" },
  { label: "pⅣa", value: "pⅣa" },
  { label: "pⅣb", value: "pⅣb" },
  { label: "pⅣc", value: "pⅣc" },
  { label: "Unclassifiable", value: "Unclassifiable" }
];

export const commonTargetPartOptions = {
  primaryTumor: [
    { label: "pTX", value: "pTX" },
    { label: "pT0", value: "pT0" },
    { label: "pTis", value: "pTis" },
    { label: "pT1a", value: "pT1a" },
    { label: "pT1b", value: "pT1b" },
    { label: "pT2", value: "pT2" },
    { label: "pT3", value: "pT3" },
    { label: "pT4a", value: "pT4a" },
    { label: "pT4b", value: "pT4b" }
  ],
  lymphNode: [
    { label: "pNX", value: "pNX" },
    { label: "pN0", value: "pN0" },
    { label: "pN1a", value: "pN1a" },
    { label: "pN1b", value: "pN1b" },
    { label: "pN2a", value: "pN2a" },
    { label: "pN2b", value: "pN2b" },
    { label: "pN3", value: "pN3" }
  ],
  metastasis: [
    { label: "pM0", value: "pM0" },
    { label: "pM1a", value: "pM1a" },
    { label: "pM1b", value: "pM1b" },
    { label: "pM1c1", value: "pM1c1" },
    { label: "pM1c2", value: "pM1c2" }
  ],
  stage: targetPartStage
};

export const appendixOptions = {
  primaryTumor: [
    { label: "pTX", value: "pTX" },
    { label: "pT0", value: "pT0" },
    { label: "pTis", value: "pTis" },
    { label: "pTis (LAMN)", value: "pTis(LAMN)" },
    { label: "pT1", value: "pT1" },
    { label: "pT2", value: "pT2" },
    { label: "pT3", value: "pT3" },
    { label: "pT4a", value: "pT4a" },
    { label: "pT4b", value: "pT4b" }
  ],
  lymphNode: [
    { label: "pNX", value: "pNX" },
    { label: "pN0", value: "pN0" },
    { label: "pN1a", value: "pN1a" },
    { label: "pN1b", value: "pN1b" },
    { label: "pN1c", value: "pN1c" },
    { label: "pN2", value: "pN2" }
  ],
  metastasis: [
    { label: "pM0", value: "pM0" },
    { label: "pM1a", value: "pM1a" },
    { label: "pM1b", value: "pM1b" },
    { label: "pM1c", value: "pM1c" }
  ],
  histopathological: [
    { label: "pGX", value: "pGX" },
    { label: "pG1", value: "pG1" },
    { label: "pG2", value: "pG2" },
    { label: "pG3", value: "pG3" }
  ],
  stage: targetPartStage
};

export const occupationPrimaryTumorOptions = [
  { label: "C", value: "C" },
  { label: "A", value: "A" },
  { label: "T", value: "T" },
  { label: "S", value: "S" },
  { label: "RS", value: "RS" },
  { label: "RS-Ant", value: "RSAnt" },
  { label: "RS-Post", value: "RSPost" },
  { label: "RS-Lt", value: "RSLt" },
  { label: "RS-Rt", value: "RSRt" },
  { label: "Ra-Ant", value: "RaAnt" },
  { label: "Ra-Post", value: "RaPost" },
  { label: "Ra-Lt", value: "RaLt" },
  { label: "Ra-Rt", value: "RaRt" },
  { label: "Rb-Ant", value: "RbAnt" },
  { label: "Rb-Post", value: "RbPost" },
  { label: "Rb-Lt", value: "RbLt" },
  { label: "Rb-Rt", value: "RbRt" },
  { label: "V", value: "V" },
  { label: "P-Ant", value: "PAnt" },
  { label: "P-Post", value: "PPost" },
  { label: "P-Lt", value: "PLt" },
  { label: "P-Rt", value: "PRt" },
  { label: "E", value: "E" }
];
