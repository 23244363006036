import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Alert, API, exceptionHandler } from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    mainContainer: {
      display: "flex",
      justifyContent: "flex-start",
      background: theme.background,
      width: "100%",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        height: "3px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightBg
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: "10px"
      }
    },
    tabs: {
      "&:hover": {
        cursor: "pointer"
      },
      "&.selected": {
        backgroundColor: theme.textWhite
      },
      display: "block",
      overflow: "unset",
      fontSize: "14px",
      maxWidth: "250px",
      minHeight: "33px",
      alignItems: "center",
      padding: "5px 15px",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
      textAlign: "center",
      justifyContent: "center"
    },
    addIcon: {
      display: "flex",
      minWidth: 40,
      minHeight: 33,
      justifyContent: "center",
      alignItems: "center",
      fontSize: 14,
      "&:hover": {
        cursor: "pointer"
      }
    }
  };
});

interface Props {
  allReports: CaseReportList[];
  caseId: number;
  reportId: number;
  confirmedAt: string;
  getCase: (reportID?: number | undefined) => Promise<void>;
  setRequest: React.Dispatch<React.SetStateAction<boolean>>;
  isConsult: boolean;
  diagnosisRequestId?: number;
  editable: boolean;
}

export const ReportNavigation = ({
  allReports,
  caseId,
  confirmedAt,
  reportId,
  getCase,
  setRequest,
  isConsult,
  diagnosisRequestId,
  editable
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selected, setSelected] = useState(0 as number);

  useEffect(() => {
    if (reportId && allReports && !selected) {
      const latestReport = allReports.find((report: CaseReportList) => {
        return report.id === reportId;
      });
      if (latestReport) {
        setSelected(latestReport.id);
      }
    }
  }, [reportId, allReports]);

  const changeTab = async (value: number) => {
    setSelected(value);
    if (value) {
      getCase(value);
      setRequest(false);
    } else {
      setRequest(true);
    }
  };

  const addReport = async () => {
    try {
      if (confirmedAt === null) {
        Alert("error", "error", "Report is not confirmed.", t);
        return;
      }
      const response: any = await API.post(`reports`, {
        case_id: caseId,
        diagnosis_request_id: diagnosisRequestId
      });
      getCase(response.data.id);
      changeTab(response.data.id);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  return (
    <div className={classes.mainContainer}>
      {!isConsult && (
        <div
          className={`${classes.tabs} ${!selected ? "selected" : ""}`}
          onClick={() => {
            return changeTab(0);
          }}
        >
          <span>{t("Requests")}</span>
        </div>
      )}
      {allReports &&
        allReports.length > 0 &&
        allReports.map((report: CaseReportList) => {
          return (
            <div
              key={report.id}
              className={`${classes.tabs} ${
                selected === report.id ? "selected" : ""
              }`}
              onClick={() => {
                return changeTab(report.id);
              }}
            >
              {(report.client_name && !isConsult
                ? `{${report.client_name}}`
                : "") + `${t("Report")} ${report.report_number}`}
            </div>
          );
        })}
      {editable && (
        <div className={classes.addIcon} onClick={addReport}>
          +
        </div>
      )}
    </div>
  );
};
