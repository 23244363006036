import { Col, Empty, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation } from "react-router";
import {
  Alert,
  API,
  AuthContext,
  CaseCard,
  debounceFunction,
  exceptionHandler,
  Loader
} from "shared-components";

import { allOrgans } from "../../form-items/organs/data";
import { ComponentWrapper } from "../../molecules/ComponentWrapper";
import { checkForDoubleCheck } from "../../utils";
import { FilterComponent } from "./FilterComponent";

const useStyles = createUseStyles((theme: any) => {
  return {
    filters: {
      margin: "20px 10px",
      display: "flow-root",
      AlignItems: "center"
    },
    links: {
      marginRight: 30,
      float: "left"
    },
    searchWrapper: {
      marginBottom: 20
    },
    mainWrapper: {
      display: "flex",
      overflow: "hidden",
      position: "relative",
      height: "100%"
    },
    rowWrapper: {
      padding: "40px 44px 52px 20px",
      overflowY: "auto"
    },
    createButton: {
      float: "right",
      right: "1%",
      width: 124,
      height: 31,
      "& > button": {
        padding: "3px 4px 3px 4px"
      }
    },
    modal: {
      maxWidth: "100%"
    },
    modalButton: {
      textAlign: "center"
    },
    button: {
      borderRadius: 0
    },
    text: {
      maxWidth: "100%",
      fontSize: 16
    },
    selectedLink: {
      marginRight: 30,
      float: "left",
      "& span": {
        color: theme.textBlue
      }
    },
    datepicker: {
      background: "transparent",
      border: "none",
      padding: 0,
      marginLeft: "14px",
      "& input": {
        display: "none"
      },
      cursor: "pointer",
      "& svg": {
        height: 16,
        width: 16,
        color: theme.primary,
        margin: 5
      }
    },
    searchTagged: {
      marginLeft: 15,
      "& svg": {
        height: 20,
        width: 20,
        margin: 5
      }
    },
    centerAlign: {
      display: "flex",
      justifyContent: "center"
    },
    "@media screen and (max-width: 991px)": {
      centerAlign: {
        marginLeft: "14%"
      }
    },
    "@media screen and (max-width: 767px)": {
      centerAlign: {
        marginLeft: "3%"
      }
    },
    emptyContainer: {
      display: "inline-block",
      width: "100%",
      marginTop: 30
    },
    filterRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    componentWrapper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column"
    },
    row: {
      "@media(min-width: 1300px)": {
        width: 1014,
        margin: "auto"
      }
    }
  };
});

const CaseList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [cases, setCases] = useState([] as CaseListData[]);
  const [count, setCount] = useState(0 as number);
  const { defaultTeam, uid, profile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true as boolean);
  const [openCaseModal, setOpenCaseModal] = useState(false as boolean);
  const location = useLocation();
  const [lastTab, setLastTab] = useState("" as string);
  const [filterChanged, setFilterChanged] = useState(false as boolean);

  const userSettings: any = {};
  for (const setting of profile.user_settings || []) {
    userSettings[setting.name] = setting.value;
  }

  const [filters, setFilters] = useState({
    type: userSettings.case_tab,
    from: userSettings.case_from_date,
    to: userSettings.case_to_date,
    search: userSettings.case_keyword as string,
    userBookmark: userSettings.case_my_mark,
    marked: userSettings.case_team_mark,
    page: 1,
    pageSize: 30,
    orderBy: userSettings.case_sort
  } as CaseFilters);

  useEffect(() => {
    if (defaultTeam) {
      debounceFunction(() => {
        return getCases(filters);
      });
    } else {
      setLoading(false);
    }
  }, [defaultTeam, filters]);

  const getCases = async (filters: CaseFilters) => {
    setLoading(true);
    try {
      let keyword = "";
      if (filters && filters.search) {
        keyword = filters.search;
        allOrgans.forEach(organ => {
          keyword = keyword.replace(t(organ.name), organ.name);
        });
      }

      const response: any = await API.get(`cases`, {
        params: {
          teamId: defaultTeam,
          unassigned: false,
          incharge: (filters && filters.type === "incharge") || undefined,
          double_check:
            (filters && filters.type === "double_check") || undefined,
          complete: (filters && filters.type === "complete") || undefined,
          from: (filters && filters.from) || undefined,
          to: (filters && filters.to) || undefined,
          search: keyword || undefined,
          marked: (filters && filters.marked) || undefined,
          user_bookmark: (filters && filters.userBookmark) || undefined,
          page: filters.page,
          pageSize: filters.pageSize,
          orderBy: filters.orderBy
        }
      });
      setLoading(false);
      if (!response.data) {
        setCases([]);
        setCount(0);
        return;
      }

      const newCases = response.data.map((data: any) => {
        const dateDifference = moment(moment()).diff(
          data.report.created_at,
          "day"
        );

        const { report } = data;

        return {
          id: data.id,
          name: report.patient_name,
          organ: report.organs,
          created_at: report.created_at,
          updated_at: report.updated_at,
          pathology_number: data.pathology_number,
          completed: report.confirmed_at !== null,
          double_check: checkForDoubleCheck(report.double_check),
          is_new: dateDifference < 1,
          is_old: dateDifference >= 7,
          tbd: dateDifference < 7 && dateDifference >= 1,
          marked: data.marked,
          my_bookmark: data.user_mark,
          medicalRecordId: report.medical_record_id,
          diagnosisRequestId: report.diagnosis_request_id,
          team_id: data.team_id
        };
      });

      setCases([...newCases]);
      setCount(response.count);
    } catch (error) {
      setLoading(false);
      setCases([]);
      exceptionHandler(error, t);
    }
  };

  const handleMyBookmark = async (index: number, id: number) => {
    try {
      await API.post("/toggle-user-marks", {
        case_id: id,
        user_id: uid
      });
      const newCases = [...cases];
      newCases[index].my_bookmark = !newCases[index].my_bookmark;
      setCases(newCases);
      if (newCases[index].my_bookmark) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamBookmark = async (index: number, id: number) => {
    try {
      const newCases = [...cases];
      await API.post("/toggle-team-marks", {
        case_id: id,
        team_id: defaultTeam
      });
      newCases[index].marked = !newCases[index].marked;
      setCases(newCases);
      if (newCases[index].marked) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await API.delete(`cases/${id}`);
      await getCases(filters);
      setLoading(false);
      Alert("success", "success", t("Case is deleted successfully"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    if (name === "type") {
      localStorage.setItem("selectedTabName", value as string);
      setLastTab(value as string);
    }
    if (
      value &&
      !(
        value === "incharge" ||
        value === "double_check" ||
        value === "complete" ||
        value === "all"
      )
    ) {
      setFilterChanged(true);
    } else {
      setFilterChanged(false);
    }
    const newFilters = { ...filters, [name]: value };
    if (name !== "page") {
      newFilters.page = 1;
    }
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setLoading(true);
    const newFilters = {
      ...filters,
      from: "",
      to: "",
      search: "",
      page: 1,
      pageSize: 30
    };
    setFilters(newFilters);
  };

  useEffect(() => {
    if (location.search === "?openCaseModal") {
      setOpenCaseModal(true);
    }
    const selectedTab = localStorage.getItem("selectedTabName");
    if (selectedTab) {
      handleFilterChange("type", selectedTab);
      setLastTab(selectedTab);
    }
  }, []);

  return (
    <ComponentWrapper sidebar className={classes.componentWrapper}>
      <FilterComponent
        handleFilterChange={handleFilterChange}
        clearFilters={clearFilters}
        filters={filters}
        count={count}
        openCaseModal={openCaseModal}
        lastTab={lastTab}
      />
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : filterChanged && cases.length === 0 ? (
        <Empty
          description={t(
            "There are no cases that match your search conditions."
          )}
          className={classes.emptyContainer}
        />
      ) : cases.length === 0 ? (
        <Empty description={t("No Cases")} className={classes.emptyContainer} />
      ) : (
        <div className={classes.rowWrapper}>
          <Row className={classes.row}>
            {cases.map((patient: CaseListData, index: number) => {
              return (
                <Col
                  lg={12}
                  md={18}
                  sm={24}
                  xs={24}
                  xl={8}
                  key={index}
                  className={classes.centerAlign}
                >
                  <CaseCard
                    onClick={() => {
                      return history.push(
                        `/cases/${patient.id}${
                          patient.diagnosisRequestId
                            ? "?diagnosisRequestId=" +
                              patient.diagnosisRequestId
                            : ""
                        }`
                      );
                    }}
                    patientInfo={patient}
                    handleMyBookmark={() => {
                      handleMyBookmark(index, patient.id);
                    }}
                    handleTeamBookmark={() => {
                      handleTeamBookmark(index, patient.id);
                    }}
                    handleDelete={() => {
                      handleDelete(patient.id);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </ComponentWrapper>
  );
};

export { CaseList };
