import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shared-components";

import { CreateFolderModal } from "./CreateFolderModal";
import styles from "./index.module.css";

interface Props {
  onSubmit: (name: string, parent_folder_id: number) => void;
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateFolderButton = (props: Props) => {
  const { t } = useTranslation();
  //   const [visibility, setVisibility] = useState(false);
  return (
    <div className={styles.folderbuttonWrapper}>
      <Button
        onClick={() => {
          props.setVisibility(true);
        }}
        className={styles.folderbutton}
        type="primary"
      >
        <p>{t("Create New Folder")}</p>
      </Button>
      <CreateFolderModal
        visible={props.visibility}
        onCancel={() => {
          props.setVisibility(false);
        }}
        onSubmit={props.onSubmit}
      />
    </div>
  );
};

export { CreateFolderButton };
