import { Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  Button,
  exceptionHandler,
  SelectComponent,
  TextArea,
  Typography
} from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import { CaseFormValue } from "../organisms/RenderForm";
import { Colorectal } from ".";
import { FormTitle } from "./Layout";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      marginTop: 9,
      background: theme.lightBg,
      "& span": {
        fontFamily: theme.brandFont
      }
    },
    container: (isConsult: boolean) => {
      return {
        padding: "8px 45px 35px 25px",
        background: isConsult ? theme.greenBackground : theme.grayBlueBg
      };
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    selectWrapper: {
      maxWidth: 200
    },
    select: {
      "& .ant-select": {
        marginBottom: 0
      },
      "& .ant-select-selector": {
        height: "29px !important",
        background: "none !important",
        borderColor: `${theme.selectText} !important`
      },
      " & .ant-select-arrow": {
        color: `${theme.selectText} !important`
      }
    },
    formTitle: {
      fontSize: 17,
      color: theme.textBlue,
      marginBottom: 15
    },
    subtitleContainer: {
      display: "flex",
      marginBottom: 25,
      alignItems: "center"
    },
    subtitle: {
      fontSize: 17,
      color: theme.textBlue,
      marginRight: 20
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    modalBox: {
      width: "900px !important",
      "& .ant-modal-content": {
        height: "100%",
        "& .ant-modal-body": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }
      }
    },
    formValue: {
      fontSize: 16,
      marginBottom: 15
    },
    divider: {
      borderTop: `1px solid ${theme.colorLight}`
    }
  };
});

interface Props {
  reportId: number;
  formValues: CaseFormValue[];
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseData: (response: any) => void;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}

export const ColorectalView = ({
  reportId,
  formValues,
  setSaving,
  setCaseData,
  isConsult,
  publicReport,
  readOnly
}: Props) => {
  const classes = useStyles(isConsult);
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false as boolean);

  const [form, setForm] = useState({} as Option);

  const handleFormSelect = async (name: string, value: string) => {
    setSaving(true);
    try {
      const response = await API.post("/form-name-update", {
        report_id: reportId,
        form_name: value
      });
      setCaseData(response);
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  useEffect(() => {
    const form = {} as Option;
    formValues.map((value: CaseFormValue) => {
      form[value.name] = value.value;
      return null;
    });
    setForm({ ...form });
  }, [formValues]);

  const clinicalInformation: string[] = [
    "clinical_diagnosis",
    "resection",
    "resection_others",
    "previous_treatment",
    "previous_treatment_yes",
    "stage_ct",
    "stage_cn",
    "stage_cm",
    "stage_cstage"
  ];

  const primaryTumor: string[] = [
    "tumor_count",
    "primary_tumor_number",
    "occupation_primary_tumor",
    "resection_distance",
    "orthogonal_diameter",
    "primary_tumor_orthogonal",
    "primary_tumor_height",
    "tumor_diameter",
    "intestinal_tract_diameter",
    "morphological_type",
    "superficial_type",
    "primary_tumor_others"
  ];

  const histologicType: string[] = ["benign", "malignant", "histologic_others"];

  const stagingFirst: string[] = ["pT", "pN", "pM", "stage"];
  const stagingSecond: string[] = ["target_part", "t", "n", "m", "g", "stage2"];

  const staging: string[] = [...stagingFirst, ...stagingSecond];

  const resectionStump: string[] = [
    "HM",
    "HM0_text",
    "VM",
    "VM0_text",
    "cancer_persistence"
  ];

  const histologicalDescription: string[] = [
    "lymphatic",
    "vein",
    "nerve",
    "IFN",
    "budding",
    "liver_metasis",
    "peritoneal_metasis",
    "lung_metasis",
    "ascites",
    "Ly",
    "histological_description_others"
  ];

  const handleBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (publicReport) {
      return;
    }
    setSaving(true);
    const formUpdateValues = {
      report_id: reportId,
      name: e.target.name,
      value: e.target.value
    };
    try {
      await API.post("/form-value", formUpdateValues);
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
  };

  return (
    <div className={classes.wrapper} id="colorectal">
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <FormTitle isConsult={isConsult}>
            {t("Diagnosis / Findings")}
          </FormTitle>
          {!publicReport && (
            <SelectComponent
              labelFontSize={12}
              wrapperClassName={classes.selectWrapper}
              className={classes.select}
              options={[
                {
                  label: t("IPF/UIP"),
                  value: "ipfuip"
                },
                {
                  label: t("General"),
                  value: "general"
                },
                {
                  label: t("Colorectal"),
                  value: "colorectal"
                }
              ]}
              name="form_name"
              placeholder={t("Select Form")}
              defaultValue="colorectal"
              onChange={handleFormSelect}
              size="small"
              id="caseFormSelect"
            />
          )}
        </div>

        <div className={classes.subtitleContainer}>
          <Typography className={classes.subtitle}>
            {t("Colorectal (Endoscopic resection)")}
          </Typography>
          {!publicReport && !readOnly && (
            <Button
              id="colorectalFormEdit"
              type="primary"
              size="small"
              onClick={() => {
                setModalOpen(true);
              }}
              className={classes.createButton}
            >
              {t("Edit form")}{" "}
            </Button>
          )}
        </div>

        <Typography className={classes.formTitle}>
          {t("①Clinical information")}
        </Typography>
        <Typography className={classes.formValue}>
          {clinicalInformation
            .map((key: string) => {
              if (
                !form[key] ||
                (key === "previous_treatment" && form[key] === "yes") ||
                (key === "resection" && form[key] === "Others")
              ) {
                return "";
              }
              return t(form[key]);
            })
            .filter((val: string) => {
              return val;
            })
            .join("/") || "-"}
        </Typography>
        <Typography className={classes.formTitle}>
          {t("②Primary tumor")}
        </Typography>
        {primaryTumor
          .map(key => {
            return t(form[key]);
          })
          .join("") ? (
          <p className={classes.formValue}>
            {`${
              form.tumor_count === "multiple"
                ? t("Multiple") +
                  `${
                    form.primary_tumor_number !== undefined
                      ? " (" + form.primary_tumor_number + ")"
                      : ""
                  }`
                : (form.tumor_count !== undefined &&
                    t(`${form.tumor_count}`)) ||
                  ""
            }${
              form.occupation_primary_tumor
                ? " / " + form.occupation_primary_tumor + " / "
                : ""
            } ${(t("Excision end") + " " + form.resection_distance !==
              undefined &&
              form.resection_distance) ||
              "0"}mm / ${form.orthogonal_diameter ||
              "0"} × ${form.primary_tumor_orthogonal ||
              "0"} × ${form.primary_tumor_height || "0"} mm / ${t(
              "Intestinal circumference"
            )} ${
              form.tumor_diameter && form.intestinal_tract_diameter
                ? (
                    (Number(form.tumor_diameter) /
                      Number(form.intestinal_tract_diameter)) *
                    100
                  ).toFixed(0)
                : 0
            } % ${
              form.morphological_type
                ? "/ " + t(`${form.morphological_type}`)
                : ""
            }${form.superficial_type ? "/ " + form.superficial_type : ""}`}{" "}
            {form.primary_tumor_others ? (
              <>
                {" "}
                <br /> {form.primary_tumor_others}{" "}
              </>
            ) : (
              ""
            )}
          </p>
        ) : (
          <p className={classes.formValue}>-</p>
        )}

        <Typography className={classes.formTitle}>
          {t("③Histologic type")}
        </Typography>
        {histologicType
          .map(key => {
            return form[key];
          })
          .join("") ? (
          <Typography className={classes.formValue}>
            {`${
              form.benign
                ? form.benign
                    .split(",")
                    .map((benign: string) => {
                      if (!benign) {
                        return "";
                      }
                      return t(benign);
                    })
                    .filter((benign: string) => {
                      return benign;
                    })
                    .join(" / ") + " / "
                : ""
            }${
              form.malignant
                ? form.malignant
                    .split(",")
                    .map((malignant: string) => {
                      if (!malignant) {
                        return "";
                      }
                      return t(malignant);
                    })
                    .filter((malignant: string) => {
                      return malignant;
                    })
                    .join(" / ")
                : ""
            }`}
            {form.histologic_others ? (
              <>
                {" "}
                <br /> {form.histologic_others}{" "}
              </>
            ) : (
              ""
            )}
          </Typography>
        ) : (
          <p className={classes.formValue}>-</p>
        )}

        <Typography className={classes.formTitle}>{t("④Staging")}</Typography>
        {staging
          .map(key => {
            return form[key];
          })
          .join("") ? (
          <p className={classes.formValue}>
            {`
            ${
              stagingFirst
                .map(key => {
                  return form[key] || "";
                })
                .join("")
                ? t(
                    "Classification of Colorectal Carcinoma Carcinoma 9th Edition"
                  ) +
                  ": " +
                  stagingFirst
                    .map((key: string) => {
                      if (!form[key]) {
                        return "";
                      }
                      return t(form[key]);
                    })
                    .filter((val: string) => {
                      return val;
                    })
                    .join(" / ")
                : ""
            }`}
            {stagingSecond
              .map(key => {
                return form[key] || "";
              })
              .join("") ? (
              <>
                {(form["pT"] !== undefined || form["pN"] != undefined) && (
                  <br />
                )}
                {`${t("UICC / TNM Classification 8th Edition") +
                  ": " +
                  stagingSecond
                    .map((key: string) => {
                      if (!form[key]) {
                        return "";
                      }
                      return t(form[key]);
                    })
                    .filter((val: string) => {
                      return val;
                    })
                    .join(" / ")}`}{" "}
              </>
            ) : (
              ""
            )}
          </p>
        ) : (
          <p className={classes.formValue}>-</p>
        )}

        <Typography className={classes.formTitle}>
          {t("⑤Resection stump/Cancer persistence")}
        </Typography>

        <Typography className={classes.formValue}>
          {resectionStump
            .map((key: string) => {
              if (
                !form[key] ||
                (key === "HM" && form[key] === "HM0") ||
                (key === "VM" && form[key] === "VM0")
              ) {
                return "";
              }

              let pre = "";
              if (key === "HM") {
                pre = t("Horizontal stump");
              } else if (key === "HM0_text") {
                pre = `${t("Horizontal stump")} HM0`;
              } else if (key === "VM") {
                pre = t("Perpendicular stump");
              } else if (key === "VM0_text") {
                pre = `${t("Perpendicular stump")} VM0`;
              } else if (key === "cancer_persistence") {
                pre = t("Cancer persistence");
              }

              return `${pre} ${form[key]}`;
            })
            .filter((val: string) => {
              return val;
            })
            .join(" / ") || "-"}
        </Typography>

        <Typography className={classes.formTitle}>
          {t("⑥Histological description")}
        </Typography>
        {histologicalDescription
          .map(key => {
            return form[key];
          })
          .join("") ? (
          <Typography className={classes.formValue}>
            {histologicalDescription
              .map((key: string) => {
                if (!form[key] || key === "histological_description_others") {
                  return "";
                }
                return form[key];
              })
              .filter((value: string) => {
                return value;
              })
              .join(" / ")}
            {form.histological_description_others ? (
              <>
                {" "}
                <br /> {form.histological_description_others}{" "}
              </>
            ) : (
              ""
            )}
          </Typography>
        ) : (
          <p className={classes.formValue}>-</p>
        )}

        <Divider className={classes.divider} />
        {readOnly ? (
          <>
            <ShowData
              label={t("Diagnosis")}
              value={form.colorectal_diagnosis}
            />
            <Divider className={classes.divider} />
            <ShowData label={t("Remarks")} value={form.colorectal_remarks} />
          </>
        ) : (
          <>
            <TextArea
              name="colorectal_diagnosis"
              label={t("Diagnosis")}
              onChange={(_, value) => {
                return setForm({ ...form, colorectal_diagnosis: value });
              }}
              onBlur={handleBlur}
              value={form.colorectal_diagnosis}
              placeholder={""}
              rows={5}
              disabled={publicReport}
            />

            <Divider className={classes.divider} />

            <TextArea
              name="colorectal_remarks"
              label={t("Remarks")}
              onChange={(_, value) => {
                return setForm({ ...form, colorectal_remarks: value });
              }}
              onBlur={handleBlur}
              value={form.colorectal_remarks}
              placeholder={""}
              rows={5}
              disabled={publicReport}
            />
          </>
        )}
      </div>
      <Modal
        onCancel={() => {
          return setModalOpen(false);
        }}
        visible={modalOpen}
        mask={false}
        footer={null}
        className={classes.modalBox}
      >
        <Colorectal
          isConsult={isConsult}
          reportId={reportId}
          formValue={form}
          setFormValue={setForm}
          setSaving={setSaving}
          setCaseData={setCaseData}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </div>
  );
};
