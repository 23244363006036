import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import dayjs from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import React from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "./DatePicker";
import styles from "./index.module.css";

interface Props {
  filterValues: FilterState;
  onChangeKeyword: (
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ) => void;
  onChangeDateRange: (values: RangeValue<dayjs.Dayjs>) => void;
  onChageFilterScope: (event: RadioChangeEvent) => void;
  onClearAll: () => void;
}

const CaseLibraryFilter = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filter}>
      <ul className={styles.filterUl}>
        <li className={styles.filterLi}>
          <DatePicker.RangePicker
            placeholder={[t("From Complete Date"), t("To Complete Date")]}
            allowClear
            value={[
              props.filterValues.from
                ? dayjs(props.filterValues.from, "YYYY-MM-DD")
                : null,
              props.filterValues.to
                ? dayjs(props.filterValues.to, "YYYY-MM-DD")
                : null
            ]}
            format={"YYYY-MM-DD"}
            onChange={props.onChangeDateRange}
          />
        </li>
        <li className={styles.filterLi}>
          <Input
            value={props.filterValues.search}
            placeholder={t("Lung Biopsy A Hospital, ...")}
            suffix={<SearchOutlined />}
            onChange={props.onChangeKeyword}
            allowClear
          />
        </li>
        <li className={styles.filterLi}>
          {(
            props.filterValues.search +
            props.filterValues.from +
            props.filterValues.to
          ).length > 0 && <CloseOutlined onClick={props.onClearAll} />}
        </li>
        <li className={styles.filterLi}>
          {(
            props.filterValues.search +
            props.filterValues.from +
            props.filterValues.to
          ).length > 0 && (
            <Radio.Group
              onChange={props.onChageFilterScope}
              value={props.filterValues.target}
            >
              <Radio value={"current"}>{t("Under Current Folder")}</Radio>
              <Radio value={"root"}>{t("All Folder")}</Radio>
            </Radio.Group>
          )}
        </li>
      </ul>
    </div>
  );
};

export { CaseLibraryFilter };
