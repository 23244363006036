import { Tooltip, Typography } from "antd";
import React from "react";

interface Props {
  text: string;
  startIcon?: object;
}

const CaseLibraryTableCell = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center"
      }}
    >
      {props.startIcon && props.startIcon}
      <Tooltip placement="topLeft" title={props.text}>
        <Typography
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%"
          }}
        >
          {props.text}
        </Typography>
      </Tooltip>
    </div>
  );
};

export { CaseLibraryTableCell };
