import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Button,
  FilePlusIcon,
  FlagIcon,
  SelectComponent,
  TeamBookmarkIcon,
  Typography,
  UserBookmarkIcon
} from "shared-components";

import { CreateCaseModal } from "../../molecules/CreateCaseModal";
import { Filter } from "./Assignment";
import { MemberBox } from "./MemberBox";

const useStyles = createUseStyles((theme: any) => {
  return {
    filters: {
      margin: "20px 10px",
      display: "flex",
      AlignItems: "center"
    },
    memberWrapper: {
      margin: {
        top: 17,
        left: 46,
        right: 40,
        bottom: 9
      },
      display: "flex"
    },
    members: {
      "&::-webkit-scrollbar": {
        height: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: "10px"
      },
      display: "flex",
      paddingBottom: 5,
      alignItems: "center",
      overflowX: "auto",
      whiteSpace: "nowrap",
      marginLeft: 50,
      marginBottom: 10
    },
    error: {
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "18px",
      color: theme.textRed,
      fontFamily: "Roboto"
    },
    flagWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      "& .ant-btn": {
        background: "none !important"
      }
    },
    flagWrapperEnd: {
      marginRight: 40
    },
    pagination: {
      display: "inline-block",
      margin: 5,
      marginRight: 21
    },
    icon: {
      height: 20,
      width: 15
    },
    paginationButton: {
      padding: 5,
      marginLeft: 5,
      borderRadius: "50%",
      border: "none",
      "&:hover": {
        backgroundColor: "none"
      }
    },
    icons: {
      fontSize: 16
    },
    flagIcon: {
      fontSize: 30,
      color: "transparent",
      marginRight: 14,
      marginLeft: 52
    },
    selectOrder: {
      margin: 0,
      width: 90,
      border: "none",
      fontSize: 14,
      "& .ant-select-selector": {
        border: "none !important"
      }
    },
    wrapperSelect: {
      width: "auto",
      marginLeft: 30
    },
    createButton: {
      marginLeft: "auto",
      borderRadius: 16,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      boxShadow: " 0px 3px 6px #00000029",
      display: "flex",
      alignItems: "center",
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    memberTitle: {
      color: theme.formLabelColor,
      paddingTop: 12
    },
    flagContainer: {
      "&:hover": {
        cursor: "pointer"
      }
    },
    flagIconYellow: {
      fontSize: 30,
      color: theme.textYellow
    },
    flagIconGreen: {
      fontSize: 30,
      color: theme.textGreen
    }
  };
});

interface Props {
  count: number;
  members?: any;
  handleFilterChange: (name: string, value: string | number | boolean) => void;
  filters: Filter;
}

const FilterComponent = ({
  count,
  members,
  handleFilterChange,
  filters
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [visibility, setVisibility] = useState(false);

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  return (
    <React.Fragment>
      <div className={classes.memberWrapper}>
        <Typography
          fontSize="14px"
          lineHeight="20px"
          className={classes.memberTitle}
        >
          {t("Member")}
        </Typography>
        <Button
          icon={<FilePlusIcon className={classes.icon} />}
          onClick={toggleVisibility}
          className={classes.createButton}
        >
          {t("Create New")}
        </Button>
      </div>
      <div className={classes.members}>
        {members &&
          members.map((member: any, index: number) => {
            return (
              <div key={index}>
                <MemberBox
                  id={member.id}
                  name={member.name}
                  count={member.assigned_cases_count}
                  color={member.color}
                />
              </div>
            );
          })}
      </div>
      <Row justify="end">
        <Col lg={6} md={12} className={classes.flagWrapper}>
          <Typography fontSize="14px" className={classes.pagination}>{`${
            count > 0 ? (filters.page - 1) * filters.pageSize + 1 : 0
          } - ${
            filters.pageSize * filters.page >= count
              ? count
              : filters.pageSize * filters.page
          } of ${count}`}</Typography>
          <Button
            onClick={() => {
              return handleFilterChange("page", filters.page - 1);
            }}
            disabled={filters.page === 1}
            className={classes.paginationButton}
            shape="circle"
            icon={<LeftOutlined className={classes.icons} />}
          />
          <Button
            onClick={() => {
              return handleFilterChange("page", filters.page + 1);
            }}
            disabled={filters.pageSize * filters.page >= count}
            className={classes.paginationButton}
            shape="circle"
            icon={<RightOutlined className={classes.icons} />}
          />
        </Col>
        <Col
          lg={5}
          md={12}
          className={`${classes.flagWrapper} ${classes.flagWrapperEnd}`}
        >
          <div
            className={classes.flagContainer}
            onClick={() => {
              return handleFilterChange("userBookmark", !filters.userBookmark);
            }}
          >
            {filters.userBookmark ? (
              <UserBookmarkIcon className={`${classes.flagIconYellow}`} />
            ) : (
              <FlagIcon className={classes.flagIconYellow} />
            )}
          </div>
          <div
            className={classes.flagContainer}
            onClick={() => {
              return handleFilterChange("marked", !filters.marked);
            }}
          >
            {filters.marked ? (
              <TeamBookmarkIcon className={`${classes.flagIconGreen}`} />
            ) : (
              <FlagIcon className={classes.flagIconGreen} />
            )}
          </div>
          <SelectComponent
            className={classes.selectOrder}
            wrapperClassName={classes.wrapperSelect}
            name="sortOrder"
            size="middle"
            allowClear
            defaultValue="desc"
            options={[
              {
                label: t("ascending"),
                value: "asc"
              },
              {
                label: t("descending"),
                value: "desc"
              }
            ]}
            placeholder={t("Select Order")}
            onChange={(event: any, value: any) => {
              return handleFilterChange("orderBy", value);
            }}
          />
        </Col>
      </Row>
      <CreateCaseModal visible={visibility} onCancel={toggleVisibility} />
    </React.Fragment>
  );
};

export { FilterComponent };
