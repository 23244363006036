import { Divider, Upload } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import {
  API,
  Button,
  exceptionHandler,
  Modal,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      "& .ant-modal-body": {
        padding: "33px 0px 0px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-drag": {
        marginTop: 33,
        marginBottom: 6,
        height: 135,
        width: 514,
        background: theme.textWhite,
        borderRadius: 3,
        borderColor: theme.formLabelColor
      },
      "& article": {
        textAlign: "center"
      }
    },
    button: {
      height: 28,
      width: 160,
      fontSize: 14,
      lineHeight: "20px",
      borderRadius: 3,
      marginTop: 11
    },
    title: {
      fontSize: 27,
      lineHeight: "39px",
      letterSpacing: "1.62px",
      color: theme.formLabelColor
    },
    dropText: {
      color: theme.primary,
      fontSize: 18,
      lineHeight: "27px"
    },
    orText: {
      textAlign: "center",
      fontSize: 18,
      lineHeight: "27px",
      color: theme.textBlack
    },
    fileType: {
      textAlign: "center",
      fontSize: 14,
      lineHeight: "27px",
      letterSpacing: "0.84px",
      color: theme.gray7,
      marginBottom: 56.5
    },
    upload: {
      "& .ant-upload-text-icon": {
        display: "inline-block"
      },
      "& span": {
        display: "inline"
      }
    },
    divider: {
      margin: "0px !important",
      borderColor: theme.colorGrey
    },
    imagesContainer: {
      display: "flex",
      margin: "15.5px 15px",
      flexWrap: "wrap",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: "10px"
      }
    },
    imageTag: {
      fontSize: 10,
      lineHeight: "22px",
      letterSpacing: "0.6px",
      color: theme.colorTextGrey,
      padding: "5px 9px 5px 10px",
      background: theme.background,
      borderRadius: 3,
      marginRight: 5
    }
  };
});

interface Props {
  visibility: boolean;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  getRequests: any;
}

const UploadRequestFormModal = ({
  visibility,
  setVisibility,
  getRequests
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let { id }: any = useParams();
  const caseId = parseInt(id);
  const { Dragger } = Upload;
  const [uploading, setUploading] = useState(false);
  const [fileName, setFileName] = useState("");

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleImageUpload = async (info: any) => {
    setUploading(true);
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("file", info.file.originFileObj);
      formData.append("case_id", caseId.toString());
      try {
        const response = await API.post(`request-forms`, formData);
        if (response && response.data) {
          setFileName(response.data.file_name);
        }
        getRequests({ page: 1, pageSize: 1 });
        setUploading(false);
      } catch (error) {
        setUploading(false);
        exceptionHandler(error, t);
      }
    }
  };

  return (
    <Modal
      centered
      className={classes.modalContainer}
      visible={visibility}
      onCancel={setVisibility}
      width="850px"
      mask={false}
    >
      <Typography className={classes.title}>
        {t("Upload Request Form")}
      </Typography>

      <Dragger
        customRequest={dummyRequest}
        showUploadList={false}
        onChange={handleImageUpload}
        disabled={uploading}
        accept=".pdf,.jpg,.png"
      >
        <Typography className={classes.dropText}>{t("Drop File")}</Typography>
      </Dragger>

      <Typography className={classes.orText}>{t("OR")}</Typography>

      <Upload
        accept=".pdf,.jpg,.png"
        className={classes.upload}
        onChange={handleImageUpload}
        customRequest={dummyRequest}
        showUploadList={false}
        disabled={uploading}
      >
        <Button
          className={classes.button}
          size="small"
          type="primary"
          loading={uploading}
        >
          {t("Select File")}
        </Button>
        <Typography className={classes.fileType}>
          {t("※PDF/JPEG/PNG")}
        </Typography>
      </Upload>

      <Divider className={classes.divider} />

      <div className={classes.imagesContainer}>
        <span className={classes.imageTag}>{fileName}</span>
      </div>
    </Modal>
  );
};

export { UploadRequestFormModal };
