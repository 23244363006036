import React from "react";
import { createUseStyles } from "react-jss";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)"

    }
  };
});
interface Props {
  children?: any;
  classname?: any;
}
const Wrapper: React.FC<Props> = props => {
  const classes = useStyles();
  return (
    <div className={`${props.classname} ${classes.wrapper}`}>
      {props.children}
    </div>
  );
};
export { Wrapper };
