import { Divider } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  dashed?: boolean;
  orientation?: "left" | "right";
  children?: any;
  className?: any;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    divider: {
      border: `1px solid ${theme.gray4}`,
      margin: 0
    }
  };
});

const DividerComponent: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <div>
      <Divider
        orientation={props.orientation}
        dashed={props.dashed}
        className={`${classes.divider} ${props.className}`}
        plain
      >
        {props.children}
      </Divider>
    </div>
  );
};

export { DividerComponent };
