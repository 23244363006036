import {
  DownloadOutlined,
  // GlobalOutlined,
  MessageOutlined,
  SendOutlined,
  ShareAltOutlined
} from "@ant-design/icons";
import { Divider, Drawer } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import {
  Alert,
  API,
  AuthContext,
  // BookIcon,
  exceptionHandler,
  FilePlusIcon,
  FlagIcon,
  SearchIcon,
  TeamBookmarkIcon,
  theme,
  TickIcon,
  Typography,
  UserBookmarkIcon
} from "shared-components";

import { Comment } from "./Comment";
import { DoubleCheck, DoubleCheckProps } from "./DoubleCheck";
import { Export } from "./Export";
import { PublicLink } from "./PublicLink";
import { SeeCase } from "./SeeCase";
import { SendPDF } from "./SendPDF";
import { SharingSettings } from "./SharingSettings";

const useStyles = createUseStyles((theme: any) => {
  return {
    "@keyframes slide-out-right": {
      from: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)",
        opacity: 1
      },
      to: {
        webkitTransform: "translateX(1000px)",
        transform: "translateX(1000px)",
        opacity: 0
      }
    },
    "@keyframes slide-in-right": {
      from: {
        webkitTransform: "translateX(100px)",
        transform: "translateX(100px)",
        opacity: 0
      },
      to: {
        webkitTransform: "translateX(0)",
        transform: "translateX(0)",
        opacity: 1
      }
    },
    rightSidebarWrapper: {
      position: "fixed",
      top: 40,
      right: 15,
      zIndex: "1000",
      backgroundColor: theme.background,
      height: "unset"
    },
    rightSidebarWrapperOut: {
      display: "none",
      animation:
        "$slide-out-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    },
    rightSidebarWrapperIn: {
      animation:
        "$slide-in-right 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both"
    },
    rightSidebar: {
      display: "flex",
      width: "200px",
      flexDirection: "column",
      minHeight: "-webkit-fill-available"
    },
    rightSidebarIconsWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      paddingLeft: "10px",
      cursor: "pointer",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.1411764705882353) !important"
      }
    },
    rightSidebarIcon: {
      margin: "5px 0px",
      cursor: "pointer",
      borderRadius: "50%",
      color: theme.primary,
      padding: 10,
      fontSize: 21
    },
    bookmarkIcon: {
      margin: "5px 0px",
      cursor: "pointer",
      borderRadius: "50%",
      color: theme.black,
      padding: 10,
      fontSize: 21
    },
    file: {
      fontSize: 26,
      margin: "10px 0px 0px 0px"
    },
    dividerWrapper: {
      width: "200px"
    },
    divider: {
      height: 2,
      margin: "16px 0",
      background: "#DBDCDC"
    },
    drawerBody: {
      background: theme.background
    },
    drawer: {
      "& .ant-drawer-content-wrapper": {
        boxShadow: "-2px 0px 1px -1px #00000029 !important",
        minWidth: "320px !important",
        width: "auto !important"
      },
      "& *": {
        transition: "none"
      },
      "& .ant-drawer-body": {
        borderRight: "none !important",
        marginBottom: 45,
        "&::-webkit-scrollbar": {
          width: 5
        },
        "&::-webkit-scrollbar-track": {
          background: theme.lightGrey
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#c1c1c1",
          borderRadius: "10px"
        }
      }
    },
    disabledIcon: {
      "& svg": {
        fill: "#bbbbbb"
      }
    },
    flagIconGreen: {
      fontSize: 30,
      color: theme.textGreen,
      padding: 5
    },
    flagIconYellow: {
      fontSize: 30,
      color: theme.textYellow,
      padding: 5
    }
  };
});

interface IDrawerContent {
  identifier: string;
}

interface Props {
  doubleCheckProps: DoubleCheckProps;
  caseId: number;
  reportId: number;
  caseNumber: string;
  myBookmark: boolean;
  teamBookmark: boolean;
  clear?: boolean;
  isConsult?: boolean;
  completedAt: string;
  publicLink?: string;
  handlePublicLinkChange: (value: string) => void;
  permissions: ReportPermissionData[];
  onChangePermission: (newPermissions: ReportPermissionData[]) => void;
  isInCaseTeam: boolean;
  editable: boolean;
}

const RightSidebar = ({
  clear,
  doubleCheckProps,
  caseId,
  reportId,
  caseNumber,
  myBookmark,
  teamBookmark,
  isConsult,
  completedAt,
  publicLink,
  handlePublicLinkChange,
  permissions,
  onChangePermission,
  isInCaseTeam,
  editable
}: Props) => {
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = useState(false as boolean);
  const [uBookmark, setMyBookmark] = useState(false as boolean);
  const [tBookmark, setTeamBookmark] = useState(false as boolean);
  const { defaultTeam } = useContext(AuthContext);
  const { uid, externalUser } = useContext(AuthContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [commentNotification, setCommentNotification] = useState(
    false as boolean
  );
  const [rightSidebarMenuSelected, setRightSidebarMenuSelected] = useState(
    "0" as string
  );
  const [sharingSettingVisible, setSharingSettingVisible] = useState<boolean>(
    false
  );
  const toggleDrawer = (event?: any, clickedOn?: string) => {
    if (clickedOn) {
      setRightSidebarMenuSelected(clickedOn);
      if (clickedOn === rightSidebarMenuSelected) {
        setShowDrawer(false);
        setRightSidebarMenuSelected("0");
      } else {
        setShowDrawer(true);
      }
    } else {
      setShowDrawer(false);
      setRightSidebarMenuSelected("0");
    }
  };

  useEffect(() => {
    if (location && location.search && location.search.includes("commentId=")) {
      toggleDrawer(undefined, "8");
    }
  }, []);

  const toggleVisibility = () => {
    window.open("/cases?openCaseModal");
  };

  useEffect(() => {
    if (myBookmark) {
      setMyBookmark(true);
    }
    if (teamBookmark) {
      setTeamBookmark(true);
    }
  }, [myBookmark, teamBookmark]);

  function zeroPadding(num: number, length: number) {
    return ("0000000000" + num).slice(-length);
  }
  const DrawerContent = (props: IDrawerContent) => {
    switch (props.identifier) {
      case "2": {
        return <SeeCase />;
      }
      case "3": {
        return <SendPDF onClose={toggleDrawer} pathologyNumber={caseNumber} />;
      }
      case "4": {
        let filename = "";
        const now = new Date();
        const month = zeroPadding(now.getMonth() + 1, 2);
        const day = zeroPadding(now.getDate(), 2);
        filename = doubleCheckProps.confirm
          ? filename + completedAt.split("T")[0] + "_completed_"
          : filename +
            now.getFullYear() +
            "-" +
            month +
            "-" +
            day +
            "_editing_";
        filename = filename + caseNumber;
        filename = filename.endsWith("_")
          ? filename.slice(0, -1) + ".pdf"
          : filename + ".pdf";
        return <Export onClose={toggleDrawer} filename={filename} />;
      }
      case "5": {
        return (
          <PublicLink
            reportId={reportId}
            publicLink={publicLink}
            handlePublicLinkChange={handlePublicLinkChange}
          />
        );
      }
      case "7": {
        return <DoubleCheck {...doubleCheckProps} onClose={toggleDrawer} />;
      }
      case "8": {
        return (
          <Comment
            commentNotification={commentNotification}
            setCommentNotification={setCommentNotification}
            reportId={reportId}
          />
        );
      }
      default: {
        return <p>Custom Content Block</p>;
      }
    }
  };

  const handleMyBookmark = async () => {
    try {
      const response: any = await API.post("/toggle-user-marks", {
        case_id: caseId,
        user_id: uid
      });
      if (response.data) {
        setMyBookmark(true);
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        setMyBookmark(false);
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamBookmark = async () => {
    try {
      const response: any = await API.post("/toggle-team-marks", {
        case_id: caseId,
        team_id: defaultTeam
      });
      if (response.data) {
        setTeamBookmark(true);
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        setTeamBookmark(false);
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  return (
    <>
      <div
        className={`${classes.rightSidebarWrapper} ${
          clear ? classes.rightSidebarWrapperOut : classes.rightSidebarWrapperIn
        }`}
      >
        <div className={classes.rightSidebar}>
          {!isConsult && editable && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={toggleVisibility}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <FilePlusIcon
                    className={`${classes.rightSidebarIcon} ${classes.file}`}
                    name="1"
                    style={{
                      background:
                        rightSidebarMenuSelected === "1"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">{t("Create New")}</Typography>
                </li>
              </ul>
            </div>
          )}
          {editable && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={(event: any) => {
                return toggleDrawer(event, "2");
              }}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <SearchIcon
                    className={classes.rightSidebarIcon}
                    name="2"
                    style={{
                      background:
                        rightSidebarMenuSelected === "2"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">{t("See case")}</Typography>
                </li>
              </ul>
            </div>
          )}
          {editable && (
            <div className={classes.dividerWrapper}>
              <Divider className={classes.divider} />
            </div>
          )}
          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={(event: any) => {
              return toggleDrawer(event, "3");
            }}
          >
            <ul style={{ display: "inline-block" }}>
              <li style={{ display: "inline-block" }}>
                <SendOutlined
                  className={classes.rightSidebarIcon}
                  name="3"
                  style={{
                    background:
                      rightSidebarMenuSelected === "3"
                        ? "rgba(0, 0, 0, 0.1411764705882353)"
                        : "none"
                  }}
                />
              </li>
              <li style={{ display: "inline-block" }}>
                <Typography type="body2">{t("Send PDF")}</Typography>
              </li>
            </ul>
          </div>
          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={(event: any) => {
              return toggleDrawer(event, "4");
            }}
          >
            <ul style={{ display: "inline-block" }}>
              <li style={{ display: "inline-block" }}>
                <DownloadOutlined
                  className={classes.rightSidebarIcon}
                  name="4"
                  style={{
                    background:
                      rightSidebarMenuSelected === "4"
                        ? "rgba(0, 0, 0, 0.1411764705882353)"
                        : "none"
                  }}
                />
              </li>
              <li style={{ display: "inline-block" }}>
                <Typography type="body2">{t("Download")}</Typography>
              </li>
            </ul>
          </div>
          {/* {editable && (
            <div className={classes.rightSidebarIconsWrapper}>
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <GlobalOutlined
                    className={classes.rightSidebarIcon}
                    name="5"
                    style={{
                      background:
                        rightSidebarMenuSelected === "5"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                    onClick={(event: any) => {
                      return toggleDrawer(event, "5");
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">{t("Public URL")}</Typography>
                </li>
              </ul>
            </div>
          )} */}
          {editable && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={(event: any) => {
                // return toggleDrawer(event, "6");
                return setSharingSettingVisible(true);
              }}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <ShareAltOutlined
                    className={classes.rightSidebarIcon}
                    name="6"
                    style={{
                      background:
                        rightSidebarMenuSelected === "6"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">
                    {t("Report Sharing Setting")}
                  </Typography>
                </li>
              </ul>
            </div>
          )}
          {!externalUser && editable && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={(event: any) => {
                return toggleDrawer(event, "7");
              }}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <TickIcon
                    name="7"
                    className={classes.rightSidebarIcon}
                    style={{
                      background:
                        rightSidebarMenuSelected === "7"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">
                    {t("Completed / Doublecheck")}
                  </Typography>
                </li>
              </ul>
            </div>
          )}
          <div className={classes.dividerWrapper}>
            <Divider className={classes.divider} />
          </div>
          {!isConsult && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={event => {
                return toggleDrawer(event, "8");
              }}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  <MessageOutlined
                    className={classes.rightSidebarIcon}
                    name="8"
                    style={{
                      background:
                        rightSidebarMenuSelected === "8"
                          ? "rgba(0, 0, 0, 0.1411764705882353)"
                          : "none"
                    }}
                  />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body2">{t("Comment")}</Typography>
                </li>
              </ul>
            </div>
          )}
          <div
            className={classes.rightSidebarIconsWrapper}
            onClick={() => {
              return handleMyBookmark();
            }}
          >
            <ul style={{ display: "inline-block" }}>
              <li style={{ display: "inline-block" }}>
                {uBookmark ? (
                  <UserBookmarkIcon
                    className={`${classes.bookmarkIcon} ${classes.flagIconYellow}`}
                  />
                ) : (
                  <FlagIcon
                    className={`${classes.bookmarkIcon} ${classes.flagIconYellow} ${classes.disabledIcon}`}
                  />
                )}
              </li>
              <li style={{ display: "inline-block" }}>
                <Typography type="body3">{t("My Bookmark")}</Typography>
              </li>
            </ul>
          </div>
          {isInCaseTeam && (
            <div
              className={classes.rightSidebarIconsWrapper}
              onClick={() => {
                return handleTeamBookmark();
              }}
            >
              <ul style={{ display: "inline-block" }}>
                <li style={{ display: "inline-block" }}>
                  {tBookmark ? (
                    <TeamBookmarkIcon
                      className={`${classes.bookmarkIcon} ${classes.flagIconGreen}`}
                    />
                  ) : (
                    <FlagIcon
                      className={`${classes.bookmarkIcon} ${classes.flagIconGreen} ${classes.disabledIcon}`}
                    />
                  )}
                </li>
                <li style={{ display: "inline-block" }}>
                  <Typography type="body3">{t("Team Bookmark")}</Typography>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <div>
        <Drawer
          placement="right"
          closable={false}
          onClose={toggleDrawer}
          visible={showDrawer}
          className={classes.drawer}
          getContainer={false}
          maskStyle={{
            backgroundColor: "transparent"
          }}
          style={{
            marginRight: showDrawer ? 200 : 0,
            marginTop: 40
          }}
          bodyStyle={{
            background: theme.background,
            borderRight: "1px solid #dbdcdc",
            boxShadow: "0px 3px 6px #00000029 !important",
            padding: "12px 16px 0 0"
          }}
        >
          <DrawerContent identifier={rightSidebarMenuSelected} />
        </Drawer>
        <SharingSettings
          report_id={reportId}
          visible={sharingSettingVisible}
          setVisible={setSharingSettingVisible}
          publicLink={publicLink}
          permissions={permissions}
          onChangePermission={onChangePermission}
        />
      </div>
    </>
  );
};

export { RightSidebar };
