import { Form } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  BorderBoxWrapper,
  Button,
  exceptionHandler,
  firebase,
  i18n,
  TextField,
  Typography
} from "shared-components";
import { API } from "shared-components/src/api/api";
import validator from "validator";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    formItem: {
      marginBottom: 20,
      maxWidth: "256px",
      margin: "auto",
      "& label": {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.gray9,
        fontWeight: 300,
        padding: 0,
        marginBottom: 2
      },
      "& > :first-child": {
        padding: 0
      },

      "& .ant-form-item-explain": {
        fontWeight: 300
      }
    },

    fullWidth: {
      maxWidth: "100%"
    },
    "@media screen and (max-width: 600px)": {
      formItem: {
        width: "auto",
        padding: {
          left: 30,
          right: 30
        }
      }
    },
    button: {
      fontWeight: 300,
      margin: "auto",
      display: "block",
      marginBottom: 40
    },
    forgotPassword: {
      color: theme.textBlue,
      cursor: "pointer",
      fontWeight: 300,
      marginBottom: 40,
      "&:hover": {
        color: theme.textBlue,
        textDecoration: "underline"
      }
    }
  };
});

const ChangeEmail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [state, setState] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const uid = firebase.auth().currentUser!.uid;
  const [readOnly, setReadOnly] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setState(s => {
      return { ...s, [name]: value };
    });
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};

    if (!validator.isEmail(state.email)) {
      Alert("error", "error", t("Not a valid email"), t);
    } else {
      return Object.keys(errors).length === 0;
    }
  };

  const handleEmailChange = async () => {
    setLoading(true);
    const { email, password } = state;
    const firebaseUser = firebase.auth().currentUser;
    const oldEmail: string = firebase.auth().currentUser!.email!;

    const credential = firebase.auth.EmailAuthProvider.credential(
      oldEmail,
      password
    );
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    try {
      firebase.auth().languageCode = i18n.language;
      await firebaseUser!.reauthenticateWithCredential(credential);

      const response = await API.put(`users/${uid}/update-email`, {
        email: email
      });
      const reCredential = firebase.auth.EmailAuthProvider.credential(
        response.data.email,
        password
      );
      window.sessionStorage.removeItem("email_verification_resend_count");
      await firebaseUser!.reauthenticateWithCredential(reCredential);

      setLoading(false);
      Alert("success", "success", t("Your email address has been changed."), t);
      history.push("/account-settings");
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  return (
    <ComponentWrapper>
      <BorderBoxWrapper heading={t("Change Email")}>
        <Form onFinish={handleEmailChange} layout="vertical" scrollToFirstError>
          <Form.Item
            id="email"
            required={false}
            name="email"
            label={t("Mail address")}
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              },
              {
                type: "email",
                message: t("Not a valid email")
              },
              {
                max: 60,
                message: t("Max Length Reached")
              },
              () => {
                return {
                  validator(rule: any, value: string) {
                    if (value === firebase.auth().currentUser!.email) {
                      return Promise.reject(
                        t("Enter email other than current email")
                      );
                    }
                    return Promise.resolve();
                  }
                };
              }
            ]}
          >
            <TextField
              name="email"
              className={classes.fullWidth}
              onChange={handleChange}
              onFocus={() => {
                return setReadOnly(false);
              }}
              readOnly={readOnly}
            />
          </Form.Item>

          <Form.Item
            id="password"
            required={false}
            name="password"
            label={t("Password")}
            className={classes.formItem}
            rules={[
              {
                required: true,
                message: t("Required")
              },
              {
                min: 8,
                message: t("Must be at least 8 characters")
              }
            ]}
          >
            <TextField
              name="password"
              type="password"
              className={classes.fullWidth}
              onChange={handleChange}
              onFocus={() => {
                return setReadOnly(false);
              }}
              readOnly={readOnly}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="middle"
              loading={loading}
              className={classes.button}
            >
              {t("Change")}
            </Button>
          </Form.Item>
        </Form>

        <Typography
          type="link1"
          href="/forgot-password"
          center
          className={classes.forgotPassword}
        >
          {t("Forgot Password?")}
        </Typography>
      </BorderBoxWrapper>
    </ComponentWrapper>
  );
};

export { ChangeEmail };
