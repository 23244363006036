import { Input } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { PdfContext } from "../../../PdfContext";
import { Typography } from "../Typography";

export interface Props {
  placeholder?: string;
  autoComplete?: string;
  type?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  className?: any;
  editValue?: string | number;
  disabled?: boolean;
  icon?: any;
  label?: string;
  caption?: string;
  suffix?: string;
  prefix?: string;
  min?: number;
  max?: number;
  onFocus?: any;
  readOnly?: boolean;
  maxLength?: number;
  error?: string | number;
  positive?: boolean;
  wraperClass?: string;
  labelFontSize?: number;
  id?: string;
  errorFlag?: boolean;
}
let useStyles = createUseStyles((theme: any) => {
  return {
    text: {
      border: ({ errorFlag }: any) => {
        return !errorFlag
          ? `1px solid ${theme.borderColor}`
          : `1px solid ${theme.red6}`;
      },
      "&:focus": {
        border: ({ errorFlag }: any) => {
          return !errorFlag
            ? `1px solid ${theme.borderColor}`
            : `1px solid ${theme.red6}`;
        }
      },
      borderRadius: 2,
      maxHeight: (pdfLoading: boolean) => {
        return pdfLoading ? "initial" : 32;
      },
      minHeight: (pdfLoading: boolean) => {
        return pdfLoading && "32px";
      },
      overflowWrap: (pdfLoading: boolean) => {
        return pdfLoading && "break-word";
      },
      padding: (pdfLoading: boolean) => {
        return pdfLoading ? 5 : "none";
      },
      background: "white",
      "& .ant-input-suffix": {
        fontSize: 12,
        color: theme.textLightBlack
      }
    },
    plainText: {
      fontSize: 16,
      lineHeight: "32px"
    },
    passwordInput: {
      paddingLeft: 10,
      paddingTop: 3
    },
    label: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    },
    caption: {
      color: "#8C8C8C",
      fontSize: 12,
      fontWeight: 300,
      display: "flex"
    },
    error: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: 12,
      color: theme.error,
      fontWeight: 300,
      display: "flex",
      lineHeight: "20px"
    }
  };
});
const TextField: React.FC<Props> = props => {
  let { disabled, autoComplete = "on" } = props;
  const { pdfLoading } = useContext(PdfContext);
  const errorFlag = props.errorFlag;
  const classes = useStyles({ pdfLoading, errorFlag });
  const { t } = useTranslation();

  const [textError, setTextError] = useState("" as string);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (
      props.type &&
      props.type === "number" &&
      props.maxLength &&
      parseInt(value) > props.maxLength
    ) {
      return;
    }
    if (props.maxLength && value.length >= props.maxLength) {
      setTextError("Maximum number of characters");
      setTimeout(() => {
        setTextError("");
      }, 3000);
      return;
    }

    if (props.positive && parseInt(value) <= 0) {
      setTextError("Please enter value that is not negative and zero");
      setTimeout(() => {
        setTextError("");
      }, 3000);
    }

    props.onChange && props.onChange(event);
  };

  if (pdfLoading) {
    return (
      <>
        <Typography className={classes.label}>{props.label}</Typography>
        <div className={`${classes.text} ${props.className}`} id={props.id}>
          {props.editValue}
        </div>
        <Text className={classes.caption}>{props.caption}</Text>
      </>
    );
  }
  return (
    <div className={props.wraperClass}>
      <Typography
        fontSize={props.labelFontSize ? `${props.labelFontSize}px` : undefined}
        className={classes.label}
      >
        {props.label}
      </Typography>
      {props.type === "password" ? (
        <Input.Password
          className={`${classes.text} ${classes.passwordInput} ${props.className}`}
          placeholder={props.placeholder}
          name={props.name}
          size={"large"}
          disabled={disabled}
          prefix={props.icon}
          onBlur={props.onBlur}
          onChange={handleChange}
          onFocus={props.onFocus}
          readOnly={props.readOnly}
        />
      ) : props.disabled ? (
        <Typography className={classes.plainText}>
          {props.editValue || "-"}
        </Typography>
      ) : (
        <Input
          autoComplete={autoComplete}
          type={props.type}
          min={props.min}
          max={props.max}
          className={`${classes.text} ${props.className}`}
          placeholder={props.placeholder}
          name={props.name}
          value={props.editValue ? props.editValue : undefined}
          prefix={props.icon || props.prefix}
          size={"large"}
          disabled={disabled}
          onChange={handleChange}
          onBlur={props.onBlur}
          suffix={props.suffix}
          onFocus={props.onFocus}
          readOnly={props.readOnly}
          id={props.id}
        />
      )}
      {textError && <p className={classes.error}>{t(textError)}</p>}

      {props.error && <p className={classes.error}>{t(`${props.error}`)}</p>}

      <Text className={classes.caption}>{props.caption}</Text>
    </div>
  );
};
export { TextField };
