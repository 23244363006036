import "firebase/auth";

import * as Sentry from "@sentry/browser";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { API } from "./api/api";
import { Alert } from "./components/atoms/Alert";
import { firebase } from "./firebase";
import { LocalStorageService } from "./services/LocalStorageService";

type ContextProps = {
  loading: boolean;
  user: firebase.User | null;
  profile: any;
  uid: string;
  authenticatedAdmin?: boolean;
  authenticatedOwner?: boolean;
  authenticatedUser?: boolean;
  authenticated?: boolean;
  externalUser?: boolean;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
  defaultTeam: number;
  defaultTeamName: string;
  setDefaultTeam: React.Dispatch<React.SetStateAction<number>>;
  setProfile: React.Dispatch<React.SetStateAction<{}>>;
  invitationCode: string;
};

export const AuthContext = React.createContext({} as ContextProps);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as firebase.User | null);
  const [profile, setProfile] = useState<any>({});
  const [defaultTeam, setDefaultTeam] = useState(0);
  const [defaultTeamName, setDefaultTeamName] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true as boolean);
  const [isAdmin, setAdmin] = useState(false as boolean);
  const [isOwner, setOwner] = useState(false as boolean);
  const [isUser, setIsUser] = useState(false as boolean);
  const [isExternal, setIsExternal] = useState(false as boolean);
  const [invitationCode, setInvitationCode] = useState("");

  const localStorageService = LocalStorageService.getService();

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(tokenToAPI);
  };

  useEffect(() => {
    if (profile.teams) {
      const team = profile.teams.find((team: any) => {
        return team.id === defaultTeam;
      });
      if (team) {
        setDefaultTeamName(team.name);
        setInvitationCode(team.invitation_code);
        setIsExternal(team.external_team);
      }
      const userRole = profile.roles.find((role: any) => {
        return role.team_id === defaultTeam;
      });
      if (userRole && userRole.role === "owner") {
        setOwner(true);
        setIsUser(false);
      } else {
        setOwner(false);
        setIsUser(true);
      }
    }
  }, [profile, defaultTeam]);

  useEffect(() => {
    initialLoad();
  }, []);

  const getProfile = async (uid: string) => {
    try {
      const response = await API.get(`users/${uid}`);
      const teamSetting = response.data.user_settings.find((setting: any) => {
        return setting.name === "current_team";
      });
      if (teamSetting) {
        setDefaultTeam(parseInt(teamSetting.value));
      } else {
        if (response.data.teams && response.data.teams.length > 0) {
          setDefaultTeam(response.data.teams[0].id);
        }
      }
      return response.data;
    } catch (error) {
      Alert("error", "error", error, t);
      return {};
    }
  };

  const setSentryUser = (uid: string) => {
    Sentry.configureScope(scope => {
      scope.setUser({ id: uid });
    });
  };
  const tokenToAPI = useCallback(async (res: firebase.User | null) => {
    setLoading(true);
    if (res !== null) {
      const idTokenResult = await res.getIdTokenResult();
      if (idTokenResult.claims && idTokenResult.claims.admin) {
        setAdmin(true);
      }

      const idToken = idTokenResult.token;
      localStorageService.setAccessToken(idToken);

      setSentryUser(res.uid);

      setUser(res);
      if (!idTokenResult.claims.admin) {
        setProfile(await getProfile(res.uid));
      }
    } else {
      setAdmin(false);
      setOwner(false);
      setIsUser(false);
      setSentryUser("");
    }

    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        profile,
        setDefaultTeam,
        defaultTeam,
        defaultTeamName,
        uid: (user && user.uid) || "",
        authenticatedAdmin: isAdmin,
        authenticatedOwner: isOwner,
        authenticatedUser: isUser,
        authenticated: isUser || isOwner,
        externalUser: isOwner ? false : isExternal,
        setUser,
        setProfile,
        invitationCode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
