import React from "react";
import { createUseStyles } from "react-jss";

import Bonbon from "../../assets/bonbon.svg";
import LogoColor from "../../assets/logo-color.svg";
import LogoWhite from "../../assets/logo-white.svg";

let useStyles = createUseStyles((theme: any) => {
  return {
    logo: {
      cursor: "pointer",
      maxHeight: "40px",
      margin: "auto",
      padding: "5px"
    }
  };
});
interface Props {
  onClick?: (event?: any) => void;
  logo?: "bonbon" | "porous";
  src?: string;
  color?: "white" | "black";
  textSize?: "large" | "small";
}

const Logo: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);

  const currentLogo =
    props.color && props.color === "black" ? LogoColor : LogoWhite;

  return props.logo === "bonbon" ? (
    <img
      className={classes.logo}
      src={Bonbon}
      alt="Logo"
      onClick={props.onClick}
    />
  ) : props.src ? (
    <img
      className={classes.logo}
      src={props.src}
      alt="Logo"
      onClick={props.onClick}
      width={props.textSize === "large" ? 200 : ""}
    />
  ) : (
    <img
      className={classes.logo}
      src={currentLogo}
      alt="Logo"
      onClick={props.onClick}
      width={props.textSize === "large" ? 200 : ""}
    />
  );
};
export { Logo };
