import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  color: any;
  size: "40" | "25" | any;
  border?: boolean;
  className?: any;
}

const useStyles = createUseStyles((theme: any) => {
  return {
    circle: {
      borderRadius: "50%",
      background: ({ color }: Props) => {
        return color;
      },
      height: ({ size }: Props) => {
        return `${size}px`;
      },
      width: ({ size }: Props) => {
        return `${size}px`;
      },
      border: ({ border }: Props) => {
        return border ? `1px solid ${theme.textDark}` : "none";
      }
    }
  };
});

const Circle: React.FC<Props> = props => {
  const classes = useStyles(props);

  return <div className={`${classes.circle} ${props.className}`} />;
};
export { Circle };
