import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: any) => {
  return {
    body: {
      position: "fixed",
      width: "100%",
      height: "100%",
      display: "flex",
      zIndex: 1000000,
      flexDirection: "column",
      backgroundColor: "white",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden"
    },
    backButton: {
      background: theme.primary,
      color: "white",
      padding: 6,
      fontSize: "14px",
      lineHeight: "20px",
      minWidth: "160px",
      borderRadius: "3px"
    },
    h1: {
      fontSize: "15vmin",
      marginBottom: "40px"
    }
  };
});

interface Props {
  externalUser?: boolean;
}

const NotFound = ({ externalUser }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.body}>
      <h1 className={classes.h1}>404</h1>
      <p>{t("Sorry, the page you requested does not exist.")}</p>
      {!externalUser && (
        <Button type="primary" href="/" className={classes.backButton}>
          {t("Back to home")}
        </Button>
      )}
    </div>
  );
};

export { NotFound };
