import React, { useCallback, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  API,
  Container,
  exceptionHandler,
  firebase,
  LocalStorageService,
  Logo,
  Wrapper
} from "shared-components";

import { SignUpComponent } from "../../organisms/SignUpComponent";

const useStyles = createUseStyles((theme: any) => {
  return {
    bottomLogo: {
      position: "fixed",
      bottom: "3%",
      left: "50%",
      transform: "translate(-50%)"
    },
    formError: {
      color: theme.errorLight,
      marginTop: "3%",
      marginBottom: "3%"
    }
  };
});

const SignUp = (props: any) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("" as string);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const localStorageService = LocalStorageService.getService();
  const { t } = useTranslation();
  const history = useHistory();

  const createNewUser = useCallback(async (email: string, password: string) => {
    try {
      const token = await executeRecaptcha!("userSignUp");
      const recaptchaData: any = await API.post(`verify-recaptcha`, {
        response: token
      });
      if (
        recaptchaData &&
        recaptchaData.data &&
        recaptchaData.data.score > 0.5
      ) {
        await API.post(`users`, { email, password });
        Alert("success", "success", t("Your account has been created"), t);
        setLoading(false);

        await signIn(email, password);
      }
    } catch (error) {
      setError(error && error.response && error.response.data.error.message);
      setLoading(false);
      exceptionHandler(error, t);
    }
  }, []);

  const signIn = async (email: string, password: string) => {
    setLoading(true);
    try {
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch(error => {
          exceptionHandler(error, t);
        });

      if (!result || !result.user || !firebase.auth().currentUser) {
        setLoading(false);
      } else {
        const idTokenResult = await result.user!.getIdTokenResult();
        const token = idTokenResult.token;
        localStorageService.setAccessToken(token);
        if (props.state && props.state.prevPath) {
          history.push(
            props.state.prevPath.pathname + props.state.prevPath.search
          );
        } else {
          history.push("/cases");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleSubmit = ({ email, password }: any) => {
    setLoading(true);
    createNewUser(email, password);
  };

  return (
    <Container center>
      <Wrapper>
        <Logo />
        {error ? <div className={classes.formError}>{t(error)}</div> : null}
        <SignUpComponent onSubmit={handleSubmit} loading={loading} />
      </Wrapper>

      <div className={classes.bottomLogo}>
        <Logo logo="bonbon" />
      </div>
    </Container>
  );
};

export { SignUp };
