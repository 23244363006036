export const findingOptions = {
  quality_of_specimen: [
    { label: "Diagnostic", value: "diagnostic" },
    { label: "Fair", value: "fair" },
    { label: "Not diagnostic", value: "not_diagnostic" }
  ],
  dense_fibrosis: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  loose_fibrosis: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  architectural_destruction: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  honeycombing: [
    { label: "Large HC-marked", value: "large_hc_marked" },
    { label: "Large HC-weakly", value: "large_hc_weakly" },
    { label: "Microscopic HC-marked", value: "microscopic_hc_marked" },
    { label: "Microscopic HC-weakly", value: "microscopic_hc_weakly" },
    { label: "Bronchiolectasia-marked", value: "bronchiolectasia_marked" },
    { label: "Bronchiolectasia-weakly", value: "bronchiolectasia_weakly" }
  ],
  fibroblastic_focus: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  distribution: [
    { label: "Diffuse", value: "diffuse" },
    { label: "Patchy", value: "patchy" },
    { label: "Unclear", value: "unclear" },
    { label: "Diffuse but endstage", value: "diffuse_but_endstage" }
  ],
  accentuation: [
    { label: "Peripheral", value: "peripheral" },
    { label: "Airway centered", value: "airway_centered" },
    { label: "Lymphatic", value: "lymphatic" },
    { label: "Random", value: "random" },
    { label: "Unclear", value: "unclear" }
  ],
  accentuation_2: [
    { label: "Peripheral", value: "peripheral" },
    { label: "Airway centered", value: "airway_centered" },
    { label: "Lymphatic", value: "lymphatic" },
    { label: "Random", value: "random" },
    { label: "Unclear", value: "unclear" }
  ],
  completely_normal_lung: [
    { label: "Present", value: "present" },
    { label: "Abscent", value: "abscent" },
    { label: "Unclear", value: "unclear" }
  ],
  fibroelastosis: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  organizing_pneumonia: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" },
    { label: "Scarred-marked", value: "scarred_marked" },
    { label: "Scarred-weakly", value: "scarred_weakly" },
    { label: "Unclear", value: "unclear" }
  ],
  lymphoid_infiltration: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  emphysema: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ],
  airspace_macrophages: [
    { label: "RB-marked", value: "rb_arked" },
    { label: "RB-weakly", value: "rb_weakly" },
    { label: "Foamy-marked", value: "foamy_marked" },
    { label: "Foamy-weakly", value: "foamy_weakly" },
    { label: "Hemosidene-marked", value: "hemosidene_marked" },
    { label: "Hemosiderin-weakly", value: "hemosiderin_weakly" },
    { label: "Pink-marked", value: "pink_marked" },
    { label: "Pink-weakly", value: "pink_weakly" }
  ],
  airway_disease: [
    { label: "PBM-marked", value: "pbm_marked" },
    { label: "PBM-weakly", value: "pbm_weakly" },
    { label: "FB/FBB-marked", value: "fb_fbb_marked" },
    { label: "FB/FBB-weakly", value: "fb_fbb_weakly" },
    { label: "Constrictive-marked", value: "constrictive_marked" },
    { label: "Constrictive-weakly", value: "constrictive_weakly" },
    { label: "Mucostasis-marked", value: "mucostasis_marked" },
    { label: "Mucostasis-weakly", value: "mucostasis_weakly" },
    { label: "Cellular-marked", value: "cellular_marked" },
    { label: "Cellular-weakly", value: "cellular_weakly" }
  ],
  granuloma: [
    { label: "Poorly formed-marked", value: "poorly_formed_marked" },
    { label: "Poorly formed-weakly", value: "poorly_formed_weakly" },
    { label: "Well formed-marked", value: "well_formed_marked" },
    { label: "Well formed-weakly", value: "well_formed_weakly" },
    { label: "Necrotizing-marked", value: "necrotizing_marked" },
    { label: "Necrotizing-weakly", value: "necrotizing_weakly" }
  ],
  giant_cells: [
    { label: "Interstitial-marked", value: "interstitial_marked" },
    { label: "Interstitial-weakly", value: "interstitial_weakly" },
    { label: "Airspace-marked", value: "airspace_marked" },
    { label: "Airspace-weakly", value: "airspace_weakly" }
  ],
  exudate: [
    { label: "Airspace edema-marked", value: "airspace_edema_marked" },
    { label: "Airspace edema-weak", value: "airspace_edema_weak" },
    { label: "Fibrin-marked", value: "fibrin_marked" },
    { label: "Fibrin-weakly", value: "fibrin_weakly" },
    { label: "Hyaline membrane-marked", value: "hyaline_membrane_marked" },
    { label: "Hyaline membrane-weakly", value: "hyaline_membrane_weakly" },
    { label: "Hemorrhage-marked", value: "hemorrhage_marked" },
    { label: "Hemorrhage-weakly", value: "hemorrhage_weakly" }
  ],
  lymphoid_follicle: [
    {
      label: "With germinal center-marked",
      value: "with_germinal_center_marked"
    },
    {
      label: "With germinal center-weakly",
      value: "with_germinal_center_weakly"
    },
    { label: "No germinal center-marked", value: "no_germinal_center_marked" },
    { label: "No germinal center-weakly", value: "no_germinal_center_weakly" }
  ],
  other_inflammatory_cells: [
    { label: "Neutrophil-marked", value: "neutrophil_marked" },
    { label: "Neutrophil-weakly", value: "neutrophil_weakly" },
    { label: "Eosinophil-marked", value: "eosinophil_marked" },
    { label: "Eosinophil-weakly", value: "eosinophil_weakly" }
  ],
  vascular_abnormality: [
    { label: "Large vessel-marked", value: "large_vessel_marked" },
    { label: "Large vessel-weakly", value: "large_vessel_weakly" },
    { label: "Small vessel-marked", value: "small_vessel_marked" },
    { label: "Small vessel-weakly", value: "small_vessel_weakly" },
    { label: "Lymphatic vessel-marked", value: "lymphatic_vessel_marked" },
    { label: "Lymphatic vessel-weakly", value: "lymphatic_vessel_weakly" }
  ],
  Pneumocytes: [
    { label: "Type I only", value: "type_I_only" },
    { label: "Type II-marked", value: "type_II_marked" },
    { label: "Type II-weakly", value: "type_II_weakly" },
    { label: "Foamy-marked", value: "foamy_marked" },
    { label: "Foamy-weakly", value: "foamy_weakly" },
    { label: "Denudated-marked", value: "denudated_marked" },
    { label: "Denudated-weakly", value: "denudated_weakly" },
    { label: "Kuhn's hyaline-marked", value: "marKuhn_hyaline_marked" },
    { label: "Hyperplasia with atypia", value: "hyperplasia_with_atypia" }
  ],
  marked_deposition: [
    { label: "Carbon", value: "carbon" },
    { label: "Asbestos body", value: "asbestos body" },
    { label: "Ferrugenous body", value: "ferrugenous body" },
    { label: "Foreign body", value: "foreign body" }
  ],
  smooth_muscle_hyperplasia: [
    { label: "Marked", value: "marked" },
    { label: "Weakly", value: "weakly" }
  ]
};

export const diagnosisOptions = {
  confidence: [
    { label: "Definite", value: "definite" },
    { label: "Probable", value: "probable" },
    { label: "Possible", value: "possible" }
  ],
  pathology_diagnosis: [
    { label: "Definite UIP", value: "definite_UIP" },
    { label: "Probable UIP", value: "probable_UIP" },
    { label: "Indeterminate for UIP", value: "indeterminate_for_UIP" },
    { label: "Alternative diagnosis", value: "alternative_diagnosis" }
  ],
  hp_guideline_diagnosis: [
    { label: "HP (non-Fibrotic)", value: "hp_non_fibrotic" },
    { label: "Probable HP (non-Fibrotic)", value: "probable_hp_non_fibrotic" },
    {
      label: "Indeterminate for HP (non-Fibrotic)",
      value: "indeterminate_hp_non_fibrotic"
    },
    { label: "HP (Fibrotic)", value: "hp_fibrotic" },
    { label: "Probable HP (Fibrotic)", value: "probable_hp_fibrotic" },
    {
      label: "Indeterminate for HP (Fibrotic)",
      value: "indeterminate_hp_fibrotic"
    },
    { label: "Alternative", value: "alternative" }
  ],
  predictive_etiology: [
    { label: "Idiopathic", value: "idiopathic" },
    { label: "CTD", value: "ctd" },
    { label: "HP", value: "hp" },
    { label: "Smoking-related", value: "smoking_related" },
    { label: "Drug-related", value: "drug_related" },
    { label: "Vasculitis", value: "vasculitis" },
    { label: "Infection", value: "infection" },
    { label: "Pneumoconiosis", value: "pneumoconiosis" },
    { label: "Others", value: "others" }
  ],
  predicted_prognosis: [
    { label: "Favorable", value: "favorable" },
    { label: "Stable", value: "stable" },
    { label: "Indolent", value: "indolent" },
    { label: "Progressive", value: "progressive" },
    { label: "Rapidly progressive", value: "rapidly_progressive" },
    { label: "Unclear", value: "unclear" }
  ],
  suggestive_treatment: [
    { label: "Anti-fibrotic agent", value: "anti_fibrotic_agent" },
    { label: "Anti-inflammation", value: "anti_inflammation" },
    { label: "Follow up", value: "follow_up" },
    { label: "Surgery", value: "surgery" },
    { label: "Additional biopsy", value: "additional_biopsy" },
    { label: "Transplantation", value: "transplantation" },
    {
      label: "Removal of causative agent",
      value: "removal_of_causative_agent"
    },
    { label: "Anti-biotics", value: "anti_biotics" },
    { label: "Unclear", value: "unclear" }
  ]
};
