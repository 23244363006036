import { QuestionCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

import { i18n } from "../../../i18n";

const dialogStyle = {};

const DeleteConfirm = (handleDelete: any, title?: string, okText?: string) => {
  const { confirm } = Modal;

  confirm({
    style: dialogStyle,
    title: title ? i18n.t(title) : i18n.t("Do you want to remove the item?"),
    icon: <QuestionCircleOutlined />,
    content: i18n.t(
      "This item will be removed immediately. You can’t undo this action."
    ),
    okText: okText ? i18n.t(okText) : i18n.t("Remove"),
    okType: "primary",
    centered: true,
    cancelText: i18n.t("Cancel"),
    onOk() {
      handleDelete();
    }
  });
};

export { DeleteConfirm };
