import { DownloadOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Col, Form, Pagination, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useParams } from "react-router-dom";
import {
  Alert,
  API,
  Button,
  DeleteConfirm,
  DeleteIcon,
  exceptionHandler,
  Loader,
  TextArea,
  Typography
} from "shared-components";

import { UploadRequestFormModal } from "./UploadRequestFormModal";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      padding: "15px 113px 15px 40px",
      width: "100%"
    },
    rowWrapper: {
      "& .ant-col": {
        marginBottom: 5,
        position: "relative"
      }
    },
    addButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      display: "flex",
      alignItems: "center",
      float: "right",
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    editButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      fontSize: 12,
      marginLeft: 11,
      borderRadius: 2,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    saveBtn: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      fontSize: 12,
      float: "right",
      marginLeft: 11,
      borderRadius: 2,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    cancelBtn: {
      backgroundColor: theme.gray5,
      color: theme.textWhite,
      float: "right",
      fontSize: 12,
      marginLeft: 11,
      borderRadius: 2,
      borderColor: theme.gray5,
      "&:hover ": {
        backgroundColor: theme.gray5,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.gray5,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.gray5,
        color: theme.textWhite
      }
    },
    title: {
      color: theme.gray6,
      letterSpacing: 0,
      float: "left",
      "& span": {
        fontSize: 10
      }
    },
    noRequest: {
      marginTop: 34,
      marginBottom: 160
    },
    pdfNoPreview: {
      marginTop: 34,
      marginBottom: 160
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    colWrapper: {
      marginTop: 65,
      marginLeft: 70,
      maxWidth: 950,
      minWidth: 575
    },
    fileProperties: {
      borderBottom: `1px solid ${theme.colorLight}`
    },
    colTypo: {
      color: theme.darkBackground,
      float: "left",
      paddingLeft: 5
    },
    pagination: {
      textAlign: "center",
      marginTop: 23
    },
    actions: {
      display: "flex",
      float: "right",
      "& :first-child": {
        marginRight: "10px"
      },
      "& :nth-child(2n)": {
        marginLeft: "10px"
      }
    },
    memo: {
      marginTop: 55,
      "& p": {
        color: theme.gray6
      }
    },
    noMemo: {
      color: theme.colorLight,
      marginTop: 10,
      marginBottom: 30
    },
    preview: {
      "& img": {
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
      }
    },
    col: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

const RequestList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true as boolean);
  const [visibility, setVisibility] = useState(false);
  const [requests, setRequests] = useState([] as RequestFormData[]);
  const [count, setCount] = useState(0 as number);
  const [currentRequest, setCurrentRequest] = useState({} as RequestFormData);
  const [form] = Form.useForm();
  const [editBtnClicked, setEditBtnClicked] = useState(false as boolean);
  let { id }: any = useParams();
  const caseId = parseInt(id);

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 1
  } as RequestFormPagination);

  useEffect(() => {
    getRequests(pagination);
  }, [pagination]);

  const getRequests = async (pagination: RequestFormPagination) => {
    setLoading(true);
    try {
      const response: any = await API.get(`request-forms`, {
        params: {
          caseId: caseId,
          page: pagination.page,
          pageSize: pagination.pageSize
        }
      });
      setLoading(false);
      if (!response.data) {
        setRequests([]);
        setCount(0);
        return;
      }
      setRequests(response.data);
      setCurrentRequest(response.data[0]);
      setCount(response.count);
    } catch (error) {
      setLoading(false);
      setRequests([]);
      exceptionHandler(error, t);
    }
  };

  const toggleVisibility = () => {
    setVisibility(!visibility);
  };

  const handleEditBtnClick = (request: RequestFormData) => {
    setEditBtnClicked(true);
    setCurrentRequest(request);
  };

  const handleChange = (_: string, value: string) => {
    setCurrentRequest(request => {
      return { ...request, comment: value };
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await API.put(
        `request-forms/${currentRequest.id}`,
        currentRequest
      );
      setCurrentRequest(response.data);
      setEditBtnClicked(false);
      setLoading(false);
      form.resetFields();
      setVisibility(false);
      Alert("success", "success", t("Memo added successfully"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleFileDelete = () => {
    DeleteConfirm(async () => {
      setLoading(true);
      try {
        await API.delete(`request-forms/${currentRequest.id}`);
        getRequests({ page: pagination.page - 1, pageSize: 1 });
        setLoading(false);
        setVisibility(false);
        Alert("success", "success", t("File deleted successfully"), t);
      } catch (error) {
        setLoading(false);
        exceptionHandler(error, t);
      }
    });
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination({ page: page, pageSize: pageSize });
  };

  const proxyUrl = "https://cors-anywhere.herokuapp.com/";

  const toDataURL = (url: string) => {
    return fetch(proxyUrl + url)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        return URL.createObjectURL(blob);
      });
  };

  const handleFileDownload = async () => {
    const link = document.createElement("a");
    link.href = await toDataURL(currentRequest.file_id);
    link.download = currentRequest.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.container}>
      <Row gutter={16} className={classes.rowWrapper}>
        <Col span={24}>
          <Typography
            fontSize="24px"
            lineHeight="20px"
            className={classes.title}
          >
            {t("Request Form")}
          </Typography>
          <Button
            icon={<UnorderedListOutlined />}
            className={classes.addButton}
            onClick={toggleVisibility}
          >
            {t("Add form")}
          </Button>
        </Col>
        <div className="clearfix" />
      </Row>
      <div>
        {loading ? (
          <div className={classes.loader}>
            <Loader />
          </div>
        ) : requests.length === 0 ? (
          <Typography
            fontSize="24px"
            lineHeight="20px"
            className={`${classes.title} ${classes.noRequest}`}
          >
            {t("No request form is registered.")}
          </Typography>
        ) : (
          <>
            <Row>
              {requests &&
                requests.map((request: RequestFormData) => {
                  return (
                    <>
                      <Col span={24} className={classes.col}>
                        <div className={classes.colWrapper}>
                          <div className={classes.fileProperties}>
                            <Typography
                              fontSize="10px"
                              lineHeight="20px"
                              className={classes.colTypo}
                            >
                              {request.file_name}
                            </Typography>
                            <div className={classes.actions}>
                              <Typography
                                fontSize="12px"
                                lineHeight="20px"
                                className={classes.title}
                              >
                                <span>{t("Created")}</span>
                                {moment(request.created_at).format(
                                  "YYYY/MM/DD HH:mm"
                                )}
                              </Typography>
                              <DeleteIcon onClick={handleFileDelete} />
                              <DownloadOutlined onClick={handleFileDownload} />
                            </div>
                            <div className="clearfix" />
                          </div>
                          {!request.file_name.includes(".pdf") && (
                            <div className={classes.preview}>
                              <img src={`${request.file_id}`} alt="" />
                            </div>
                          )}
                          {request.file_name.endsWith(".pdf") && (
                            <div className={classes.preview}>
                              <object
                                id="pdf"
                                data={request.file_id}
                                type="application/pdf"
                                width="100%"
                                height="1000"
                              >
                                <Typography
                                  fontSize="18px"
                                  lineHeight="20px"
                                  className={classes.pdfNoPreview}
                                >
                                  {t(
                                    "Please download PDF from the top right icon."
                                  )}
                                </Typography>
                              </object>
                            </div>
                          )}
                          <Pagination
                            className={classes.pagination}
                            defaultCurrent={1}
                            current={pagination.page}
                            total={count}
                            pageSize={1}
                            onChange={handlePaginationChange}
                          />
                          <div className={classes.memo}>
                            {editBtnClicked ? (
                              <Form form={form} onFinish={handleSubmit}>
                                <TextArea
                                  name={"comment"}
                                  label={t("Memo")}
                                  onChange={handleChange}
                                  value={currentRequest.comment}
                                  rows={5}
                                />
                                <Button
                                  className={classes.saveBtn}
                                  type="primary"
                                  size="small"
                                  htmlType="submit"
                                >
                                  {t("Save")}
                                </Button>
                                <Button
                                  className={classes.cancelBtn}
                                  type="primary"
                                  size="small"
                                  onClick={() => {
                                    setEditBtnClicked(false);
                                  }}
                                >
                                  {t("Cancel")}
                                </Button>
                              </Form>
                            ) : (
                              <div>
                                <Typography
                                  fontSize="12px"
                                  lineHeight="20px"
                                  className={classes.title}
                                >
                                  {t("Memo")}
                                </Typography>
                                <Button
                                  className={classes.editButton}
                                  type="primary"
                                  size="small"
                                  onClick={() => {
                                    handleEditBtnClick(currentRequest);
                                  }}
                                >
                                  {t("Edit")}
                                </Button>
                                <Typography
                                  fontSize="16px"
                                  lineHeight="20px"
                                  className={classes.noMemo}
                                >
                                  {currentRequest && currentRequest.comment
                                    ? currentRequest.comment
                                    : t("There is no memo.")}
                                </Typography>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </>
                  );
                })}
            </Row>
          </>
        )}
      </div>
      <UploadRequestFormModal
        visibility={visibility}
        setVisibility={toggleVisibility}
        getRequests={getRequests}
      />
    </div>
  );
};

export { RequestList };
