import * as Sentry from "@sentry/browser";
import { message as Toast } from "antd";
import { TFunction } from "i18next";

import { Alert } from "./components/atoms/Alert";
import { ErrorMessages } from "./ErrorMessages";
import { i18n } from "./i18n";

const initSentry = () => {
  Toast.config({
    maxCount: 3,
    duration: 5,
    rtl: true
  });

  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn:
      "https://92ca3bddd58e4d069a5b566fbd63b2b6@o434041.ingest.sentry.io/5390382",
    beforeSend(event: any, hint: any) {
      const isNonErrorException =
        event?.exception?.values[0].value.startsWith(
          "Non-Error exception captured"
        ) ||
        hint?.originalException["message"].startsWith(
          "Non-Error exception captured"
        );

      if (isNonErrorException) {
        // We want to ignore those kind of errors
        return null;
      }

      if (process.env.NODE_ENV === "development") {
        /* eslint no-console: ["error", { allow: ["error"] }] */
        console.error("Error Details: ", event);
        return null;
      }

      let message =
        "Something is technically wrong. Thank you for noticing. We're going to fix it up and have things back to normal soon.";
      if (i18n.language[0] === "ja") {
        message =
          "何らかの問題が発生しました。早急に解決できるよう対応致します。しばらく経っても改善されない場合はお問い合わせ頂きますようお願い申し上げます。";
      }
      Toast.error(message, 5);

      return event;
    }
  });
};

const captureMessage = (
  message: string,
  level?: Sentry.Severity,
  extra?: any
) => {
  Sentry.withScope(scope => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    scope.setLevel(level || Sentry.Severity.Warning);
    Sentry.captureMessage(message);
  });
};

const captureException = (exception: any, extra?: any) => {
  Sentry.withScope(scope => {
    if (extra) {
      scope.setExtra("extra", extra);
    }
    scope.setLevel(Sentry.Severity.Error);
    Sentry.captureException(exception);
  });
};

const exceptionHandler = (error: any, t?: TFunction) => {
  if (typeof error === "string" || error.message === "Network Error") {
    Alert("error", "error", error, t);
  } else {
    if (error.code && ErrorMessages[error.code]) {
      const errorMessage = ErrorMessages[error.code];
      Alert("error", "error", errorMessage, t);
    } else if (error.response) {
      if (error.response.status && error.response.status >= 500) {
        captureException(error);
      } else {
        Alert("error", "error", error, t);
      }
    } else {
      captureException(error);
    }
  }
};

export { initSentry, captureMessage, captureException, exceptionHandler };
