import { Cascader } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";

interface Props {
  options: any;
  onChange?: any;
  placeholder?: string;
  size?: "large" | "medium" | "small";
  className?: any;
  allowClear?: boolean;
  editValue?: any;
  popupClassName?: any;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    cascader: {
      width: ({ size }: Props) => {
        switch (size) {
          case "large":
            return 350;
          case "medium":
            return 270;
          case "small":
            return 196;
          default:
            return 350;
        }
      },
      border: "none",
      "&:focus": {
        border: "0px",
        outline: "none"
      },
      "&:active": {
        border: "0px",
        outline: "none"
      },
      "& .ant-cascader-picker": {
        border: "none"
      },
      "& > *": {
        border: "none",
        padding: ({ size }: Props) => {
          switch (size) {
            case "large":
              return "1px 0px 2px 5px";
            case "medium":
              return 270;
            case "small":
              return 196;
            default:
              return 350;
          }
        }
      }
    },
    popup: {
      width: ({ size }: Props) => {
        switch (size) {
          case "large":
            return 350;
          case "medium":
            return 270;
          case "small":
            return 196;
          default:
            return 350;
        }
      },
      "& ul": {
        width: "100%"
      }
    }
  };
});

const CascaderComponent = (props: Props) => {
  let { size = "large" } = props;
  const classes = useStyles({ size });

  const filter = (inputValue: any, path: any) => {
    return path.some((option: any) => {
      return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    });
  };
  const defaultValue = props.editValue ? [props.editValue] : [];
  return (
    <Cascader
      value={defaultValue}
      options={props.options}
      onChange={props.onChange}
      className={`${classes.cascader} ${props.className}`}
      popupClassName={`${classes.popup} ${props.popupClassName}`}
      placeholder={props.placeholder}
      allowClear={props.allowClear || false}
      showSearch={{ filter }}
    />
  );
};
export { CascaderComponent };
