import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Loader } from "shared-components";

import { RequestList } from "../Request/RequestList";
import Report, { ReportProps } from "./Report";
import { ReportNavigation } from "./ReportNavigation";

const useStyles = createUseStyles((theme: any) => {
  return {
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    }
  };
});

interface Props extends ReportProps {
  loading: boolean;
  getCase: (reportID?: number | undefined) => Promise<void>;
  editable: boolean;
}

const ReportWrapper = ({
  state,
  getCase,
  hasSidebar,
  loading,
  editable,
  ...rest
}: Props) => {
  const classes = useStyles();

  const [request, setRequest] = useState(false as boolean);

  const isConsult = state.consultation;
  return (
    <>
      <ReportNavigation
        allReports={state.allReports}
        caseId={state.id}
        reportId={state.reportId}
        confirmedAt={state.confirmedAt}
        diagnosisRequestId={state.diagnosisRequestId}
        getCase={getCase}
        setRequest={setRequest}
        isConsult={isConsult}
        editable={editable}
      />
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : !request ? (
        <Report state={state} hasSidebar={hasSidebar} {...rest} />
      ) : (
        <RequestList />
      )}
    </>
  );
};

export default ReportWrapper;
