import { DatePicker, Typography } from "antd";
import moment from "moment";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

let useStyles = createUseStyles((theme: any) => {
  return {
    label: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px",
      "& > :first-child": {
        padding: 0
      }
    },
    picker: {
      "& .ant-picker-suffix": {
        display: "flex"
      },
      "& .ant-picker-clear": {
        display: "flex"
      }
    }
  };
});

export default function({
  name,
  label,
  control,
  defaultValue,
  onChange,
  className,
  disable,
  id
}: DatePickerProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Typography.Text className={classes.label}>{label}</Typography.Text>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange: rendererOnChange, name, value }) => {
          return (
            <DatePicker
              id={id}
              disabled={disable}
              className={classes.picker}
              name={name}
              onChange={(value, dateString) => {
                rendererOnChange(value?.format());
                onChange(value?.format());
              }}
              defaultValue={value && moment(value)}
              placeholder={t("Select date")}
            />
          );
        }}
      />
    </div>
  );
}
