interface actions {
  [key: string]: string;
}

interface statusList {
  Removed: number;
  Editing: number;
  Estimating: number;
  EstimateReturned: number;
  Ordering: number;
  Working: number;
  Checking: number;
  Complete: number;
  Canceling: number;
}

export const requestActions: actions = {
  request_quote: "Requested quote",
  edit_request: "Edited request",
  order: "Ordered",
  return_request: "Returned request",
  send_quote: "Sent quote",
  request_check: "Requested check",
  return_check: "Returned check",
  complete_check: "Completed check",
  cancel_after_ordered: "Cancel after ordered",
  cancel_before_ordering: "Cancel before ordering"
};

export const statusLabel: { [key: number]: string } = {
  0: "Removed",
  1: "Editing",
  2: "Estimating",
  4: "Estimate Returned",
  8: "Ordering",
  16: "Working",
  32: "Checking",
  64: "Done",
  128: "Canceling"
};

export const statusValue: statusList = {
  Removed: 0,
  Editing: 1,
  Estimating: 2,
  EstimateReturned: 4,
  Ordering: 8,
  Working: 16,
  Checking: 32,
  Complete: 64,
  Canceling: 128
};

export const actionList: any = {
  request_quote: { label: "Requested quote", message: "" },
  edit_request: {
    label: "Edited request",
    message: "Are you sure you want to edit the request?"
  },
  order: {
    label: "Ordered",
    message: "Are you sure you want to order?"
  },
  return_request: {
    label: "Returned request",
    message: "Are you sure you want to return the request?"
  },
  send_quote: {
    label: "Sent  quote",
    message: "Are you sure you want to send the quote?"
  },
  request_check: {
    label: "Requested check",
    message: "Are you sure you want to send the request check?"
  },
  return_check: {
    label: "Returned check",
    message: ""
  },
  complete_check: {
    label: "Completed check",
    message: "Are you sure you want to complete the check?"
  },
  cancel_request: {
    label: "Cancel request",
    message:
      "Are you sure you want to cancel the request? This item will be cancelled immediately. You can't undo this action."
  }
};
