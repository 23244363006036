import { Col, Modal, Row, Table } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  AuthContext,
  Button,
  DatePickerComponent,
  debounceFunction,
  exceptionHandler,
  Search
} from "shared-components";

import { statusValue } from "./actionValueMap";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalBox: {
      height: 680,
      width: "850px !important",
      "& .ant-modal-content": {
        height: "100%",
        "& .ant-modal-body": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 50
        }
      }
    },
    table: {
      marginTop: 50,
      width: "100%",
      "& .ant-table-cell": {
        paddingTop: 10,
        paddingBottom: 10,
        whiteSpace: "nowrap"
      }
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      marginTop: 50,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    filterColumns: {
      marginBottom: 10
    }
  };
});

interface Props {
  open: boolean;
  onClose?: any;
  cases: DiagnosisCasesData[];
  setCases: React.Dispatch<React.SetStateAction<DiagnosisCasesData[]>>;
  form: DiagnosisRequestFormData;
}

export const SelectCaseModal = ({
  open,
  onClose,
  cases,
  setCases,
  form
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false as boolean);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 5
  } as CaseFilters);

  const [count, setCount] = useState(0 as number);
  const [allCases, setAllCases] = useState([] as DiagnosisCasesData[]);
  const [selectedCases, setSelectedCases] = useState(
    [] as DiagnosisCasesData[]
  );

  const { defaultTeam } = useContext(AuthContext);

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    const newFilters = { ...filters, [name]: value };
    if (name !== "page") {
      newFilters.page = 1;
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (defaultTeam) {
      debounceFunction(() => {
        return getCases(filters);
      });
    } else {
      setLoading(false);
    }
  }, [defaultTeam, filters]);

  const getCases = async (filters: CaseFilters) => {
    setLoading(true);
    try {
      const response: any = await API.get(`cases`, {
        params: {
          teamId: defaultTeam,
          from: (filters && filters.from) || undefined,
          to: (filters && filters.to) || undefined,
          search: (filters && filters.search) || undefined,
          page: filters.page,
          pageSize: filters.pageSize,
          no_client: true
        }
      });
      setLoading(false);
      if (!response.data) {
        setAllCases([]);
        setCount(0);
        return;
      }

      const newCases = response.data.map((data: any) => {
        const { report } = data;

        return {
          id: data.id,
          consult_number: "",
          consult_pathologist: "",
          medical_record_id: report.medical_record_id,
          organs: report.organs,
          pathologist: report.pathologist_name || report.pathologist_email,
          pathology_number: data.pathology_number,
          status: ""
        };
      });

      setAllCases([...newCases]);
      setCount(response.count);
    } catch (error) {
      setLoading(false);
      setAllCases([]);
      exceptionHandler(error, t);
    }
  };

  const columns = [
    {
      title: t("Medical record ID"),
      dataIndex: "medical_record_id",
      key: "medical_record_id"
    },
    {
      title: t("Pathology number"),
      dataIndex: "pathology_number",
      key: "pathology_number"
    },
    {
      title: t("Pathologist"),
      dataIndex: "pathologist",
      key: "pathologist"
    },
    {
      title: t("Organs"),
      dataIndex: "organs",
      key: "organs",
      render: (value: string) => {
        const makeOrgansArray = value.split(",");
        const requiredOrgansArray = makeOrgansArray.map(organ => {
          return t(`${organ.substring(organ.indexOf("-") + 1)}`);
        });
        return value.length > 40
          ? requiredOrgansArray.toString().slice(0, 40) + "..."
          : value;
      }
    }
  ];

  useEffect(() => {
    if (cases !== selectedCases) {
      setSelectedCases(cases);
    }
  }, [cases]);

  const rowSelection = {
    onSelect: (record: DiagnosisCasesData, selected: boolean) => {
      if (selected) {
        setSelectedCases([...selectedCases, record]);
      } else {
        setSelectedCases(
          selectedCases.filter((caseData: DiagnosisCasesData) => {
            return caseData.id !== record.id;
          })
        );
      }
    },
    selectedRowKeys: selectedCases.map((caseData: DiagnosisCasesData) => {
      return caseData.id;
    }),
    onSelectAll: (
      selected: boolean,
      selectedRows: DiagnosisCasesData[],
      changeRows: DiagnosisCasesData[]
    ) => {
      if (selected) {
        const newSelected = changeRows.filter((row: DiagnosisCasesData) => {
          return !selectedCases.some((selectedRow: DiagnosisCasesData) => {
            return selectedRow.id === row.id;
          });
        });
        setSelectedCases([...selectedCases, ...newSelected]);
      } else {
        setSelectedCases(
          selectedCases.filter((caseData: DiagnosisCasesData) => {
            return !changeRows.some((selectedRow: DiagnosisCasesData) => {
              return selectedRow.id === caseData.id;
            });
          })
        );
      }
    }
  };

  const handleDone = async () => {
    const oldCases = cases;
    setCases(selectedCases);
    onClose();
    if (form.status === statusValue.Editing) {
      try {
        await API.put(`requests/${form.id}`, {
          team_client_id: form.team_client_id || undefined,
          request_type: form.request_type || undefined,
          request_body: form.request_body || undefined,
          price: form.price || undefined,
          due_date: form.due_date || undefined,
          case_ids:
            selectedCases && selectedCases.length > 0
              ? selectedCases.map((caseData: DiagnosisCasesData) => {
                  return caseData.id;
                })
              : undefined
        });
      } catch (error) {
        setCases(oldCases);
        exceptionHandler(error, t);
      }
    }
  };

  return (
    <Modal
      onCancel={onClose}
      visible={open}
      mask={false}
      footer={null}
      className={classes.modalBox}
    >
      <Row gutter={15}>
        <Col lg={12} xs={24} className={classes.filterColumns}>
          <Search
            name="search"
            placeholder={t("Hospital name, Doctor name, etc...")}
            onChange={handleFilterChange}
          />
        </Col>
        <Col lg={6} xs={24} className={classes.filterColumns}>
          <DatePickerComponent
            disabledDate={disabledFromDate}
            name="from"
            placeholder={t("From")}
            onChange={handleFilterChange}
          />
        </Col>

        <Col lg={6} xs={24} className={classes.filterColumns}>
          <DatePickerComponent
            name="to"
            disabledDate={disabledToDate}
            placeholder={t("To")}
            onChange={handleFilterChange}
          />
        </Col>
      </Row>
      <Table
        loading={loading}
        className={classes.table}
        columns={columns}
        dataSource={allCases}
        rowSelection={{
          type: "checkbox",
          ...rowSelection
        }}
        pagination={{
          current: filters.page,
          pageSize: filters.pageSize,
          position: ["bottomCenter"],
          total: count,
          onChange: (page: number) => {
            return handleFilterChange("page", page);
          }
        }}
        // size="small"
        rowKey={"id"}
        onRow={(record: DiagnosisCasesData) => {
          return {
            onClick() {
              const isSelected =
                selectedCases
                  .map(cases => {
                    return cases.id;
                  })
                  .indexOf(record.id) > -1;
              rowSelection.onSelect(record, !isSelected);
            }
          };
        }}
      />

      <Button
        type="primary"
        size="small"
        onClick={handleDone}
        className={classes.createButton}
      >
        {t("Done")}{" "}
      </Button>
    </Modal>
  );
};
