import { Col, Empty, Row } from "antd";
import moment, { Moment } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  CaseCard,
  DatePickerComponent,
  exceptionHandler,
  Loader,
  Search
} from "shared-components";

import { checkForDoubleCheck } from "../utils";

let useStyles = createUseStyles((theme: any) => {
  return {
    title: {
      fontSize: 14,
      color: theme.formLabelColor,
      lineHeight: "20px"
    },
    itemContainer: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column"
    },
    datePicker: {
      background: "white",
      border: `1px solid ${theme.colorLight}`,
      borderRadius: 3,
      marginBottom: 10,
      "& input": {
        background: "white"
      }
    },
    card: {
      width: 320
    },
    wrapper: {
      padding: "0px 4px 50px 25px"
    },
    rightDiv: {
      float: "right"
    },
    cardWrapper: {
      marginTop: 22,
      display: "flex",
      flexDirection: "column"
    },
    search: {
      width: 320,
      border: `1px solid ${theme.colorLight}`,
      borderRadius: 3,
      background: "white",
      "& input": {
        background: "white"
      },
      marginBottom: 10
    },
    row: {
      width: 330
    },
    count: {
      fontSize: 16,
      color: theme.colorLight,
      lineHeight: "21px"
    },
    emptyContainer: {
      width: 290
    }
  };
});

const SeeCase = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false as boolean);
  const { defaultTeam, uid } = useContext(AuthContext);
  const [cases, setCases] = useState([] as any);
  const { t } = useTranslation();
  const [filterChanged, setFilterChanged] = useState(false as boolean);
  const [filters, setFilters] = useState({
    from: "",
    to: "",
    search: ""
  });

  useEffect(() => {
    if (filters.to === "" && filters.from === "" && filters.search === "") {
      setCases([]);
      setLoading(false);
    } else {
      getCases(filters);
    }
  }, [filters]);

  const disabledToDate = (current: Moment) => {
    return current && current < moment(filters.from);
  };

  const disabledFromDate = (current: Moment) => {
    return current && current > moment(filters.to);
  };

  const handleMyBookmark = async (index: number, id: string) => {
    try {
      await API.post("/toggle-user-marks", {
        case_id: id,
        user_id: uid
      });
      const newCases = [...cases];
      newCases[index].my_bookmark = !newCases[index].my_bookmark;
      setCases(newCases);
      if (newCases[index].my_bookmark) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleTeamBookmark = async (index: number, id: string) => {
    try {
      await API.post("/toggle-team-marks", {
        case_id: id,
        team_id: defaultTeam,
        marked: !cases[index].marked
      });
      const newCases = [...cases];
      newCases[index].marked = !newCases[index].marked;
      setCases(newCases);
      if (newCases[index].marked) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      await API.delete(`cases/${id}`);
      await getCases(filters);
      setLoading(false);
      Alert("success", "success", t("Case is deleted successfully"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };
  const getCases = async (filters: any) => {
    setLoading(true);
    try {
      const response: any = await API.get(`cases`, {
        params: {
          teamId: defaultTeam,
          from: (filters && filters.from) || undefined,
          to: (filters && filters.to) || undefined,
          search: (filters && filters.search) || undefined
        }
      });
      setLoading(false);
      if (!response.data) {
        setCases([]);
        return;
      }
      const newCases = response.data.map((data: any) => {
        const dateDifference = moment(moment()).diff(data.created_at, "day");

        const { report } = data;

        return {
          id: data.id,
          name: report.patient_name,
          organ: report.organs,
          created_at: report.created_at,
          updated_at: report.updated_at,
          pathology_number: data.pathology_number,
          completed: report.confirmed_at !== null,
          double_check: checkForDoubleCheck(report.double_check),
          is_new: dateDifference < 1,
          is_old: dateDifference >= 7,
          tbd: dateDifference < 7 && dateDifference >= 1,
          marked: data.marked,
          my_bookmark: data.user_mark,
          medicalRecordId: report.medical_record_id
        };
      });
      setCases([...newCases]);
    } catch (error) {
      setLoading(false);
      setCases([]);
      exceptionHandler(error, t);
    }
  };

  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    if (value) {
      setFilterChanged(true);
    } else {
      setFilterChanged(false);
    }
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };

  const handleCaseCardClick = (caseId: string) => {
    window.open(`/cases/${caseId}`);
  };

  return (
    <div className={classes.wrapper}>
      <span className={classes.title}>{t("See case")}</span>
      <div className={classes.rightDiv}>
        {cases.length !== 0 && (
          <span className={classes.count}>
            {cases.length} {t("cases")}
          </span>
        )}
      </div>

      <div className={classes.itemContainer}>
        <Search
          name="search"
          className={classes.search}
          placeholder={t("Hospital name, Doctor name, etc...")}
          onChange={handleFilterChange}
        />
        <Row gutter={10} className={classes.row}>
          <Col span={12}>
            <DatePickerComponent
              className={classes.datePicker}
              name="from"
              disabledDate={disabledFromDate}
              placeholder={t("From")}
              onChange={handleFilterChange}
            />
          </Col>
          <Col span={12}>
            <DatePickerComponent
              className={classes.datePicker}
              name="to"
              disabledDate={disabledToDate}
              placeholder={t("To")}
              onChange={handleFilterChange}
            />
          </Col>
        </Row>
        <div className={classes.cardWrapper}>
          {loading ? (
            <Loader size="large" />
          ) : filterChanged && cases.length === 0 ? (
            <Empty
              description={t(
                "There are no cases that match your search conditions."
              )}
              className={classes.emptyContainer}
            />
          ) : (
            cases.length !== 0 &&
            cases.map((patient: any, index: number) => {
              return (
                <CaseCard
                  noMargin
                  className={classes.card}
                  onClick={() => {
                    handleCaseCardClick(patient.id);
                  }}
                  patientInfo={patient}
                  handleMyBookmark={() => {
                    handleMyBookmark(index, patient.id);
                  }}
                  handleTeamBookmark={() => {
                    handleTeamBookmark(index, patient.id);
                  }}
                  handleDelete={() => {
                    handleDelete(patient.id);
                  }}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export { SeeCase };
