import React from "react";
import { createUseStyles } from "react-jss";

import { Typography } from "../Typography";

interface Props {
  heading?: string;
  children?: any;
  className?: any;
  width?: number;
  headingSpacing?: string;
  paddingTop?: string;
}

let useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      border: `1px solid ${theme.formLabelColor}`,
      borderRadius: "10px",
      width: ({ width }: Props) => {
        return width ? width : 640;
      },
      margin: "auto",
      paddingTop: ({ paddingTop }: Props) => {
        return paddingTop ? paddingTop : "30px";
      },
      overflow: "hidden",
      "&:hover": {
        "overflow-y": "auto"
      },
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    heading: {
      color: theme.textDarkGrey,
      padding: ({ headingSpacing }: Props) => {
        return headingSpacing ? headingSpacing : 39;
      },
      fontWeight: 300
    },
    "@media screen and (max-width: 600px)": {
      container: {
        margin: "2% 10% 5% 10%",
        width: "auto"
      }
    },
    borderBoxContainer: {
      background: theme.lightBg,
      paddingBottom: 24
    }
  };
});

const BorderBoxWrapper: React.FC<Props> = props => {
  const classes = useStyles(props);

  return (
    <div className={`${classes.borderBoxContainer} ${props.className}`}>
      <Typography
        fontSize="24px"
        lineHeight="36px"
        center
        className={classes.heading}
      >
        {props.heading}
      </Typography>

      <div className={classes.container}>{props.children}</div>
    </div>
  );
};
export { BorderBoxWrapper };
