import { Checkbox, Col, Radio, Row } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  API,
  Button,
  exceptionHandler,
  SelectComponent,
  TextField,
  Typography
} from "shared-components";

import {
  appendixOptions,
  commonTargetPartOptions,
  occupationPrimaryTumorOptions,
  TargetPartOptions
} from "./dataColorectal";

let useStyles = createUseStyles((theme: any) => {
  return {
    wrapper: {
      width: "100%",
      marginTop: 9,
      background: theme.lightBg,
      "& span": {
        fontFamily: theme.brandFont
      }
    },
    container: (isConsult: boolean) => {
      return {
        padding: "8px 45px 35px 25px",
        background: isConsult ? theme.greenBackground : theme.grayBlueBg
      };
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    select: {
      maxWidth: 200,
      "& .ant-select": {
        marginBottom: 0
      },
      "& .ant-select-selector": {
        height: "29px !important",
        background: "none !important",
        borderColor: `${theme.selectText} !important`
      },
      " & .ant-select-arrow": {
        color: `${theme.selectText} !important`
      }
    },
    formContainer: {},
    formLabel: {},
    formTitle: {
      fontSize: 17,
      color: theme.textBlue,
      marginBottom: 15
    },
    formItem: {
      marginBottom: 30
    },
    checkboxFormItem: {
      marginBottom: 10
    },
    multileColumnFormItem: {
      display: "flex",
      marginBottom: 30
    },
    formBlock: {
      marginRight: 25,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end"
    },
    noMargin: {
      margin: 0
    },
    formRow: {
      display: "flex",
      alignItems: "flex-end"
    },
    selectOption: {
      marginBottom: 0,
      width: 340
    },
    formAdditionalText: {
      marginLeft: 7,
      marginRight: 7,
      fontSize: 18,
      display: "flex",
      height: "100%",
      alignItems: "center"
    },
    formAdditionalTextCalc: {
      marginLeft: 5,
      marginRight: 5,
      fontSize: 18,
      whiteSpace: "wrap",
      display: "flex",
      height: "100%",
      alignItems: "center"
    },
    xsWidth: {
      width: 60
    },
    smallWidth: {
      width: 75
    },
    selectComponent: {
      marginBottom: 0,
      width: 140,
      "& .ant-select-selector": {
        marginBottom: 3
      }
    },
    selectWrap: {
      width: "auto"
    },
    largeWidth: {
      width: 340
    },
    targetPartWidth: {
      width: 220
    },
    radioAndTextField: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& .ant-radio-wrapper": {
        display: "flex",
        alignItems: "center",
        marginRight: "20px"
      }
    },
    primaryTumorBlock: {
      display: "flex",
      alignItems: "center"
    },
    innerBlock: {
      display: "flex",
      flexDirection: "column",
      marginRight: 30,
      "& .ant-radio-wrapper": {
        display: "flex",
        alignItems: "center"
      },
      "& .ant-radio-group": {
        display: "flex"
      }
    },
    radioBlock: {
      "& .ant-radio-wrapper": {
        marginRight: "20px"
      }
    },

    radioLeftMargin: {
      marginLeft: 15
    },
    checkboxGroup: {
      width: "100%",
      "& .ant-col": {
        marginBottom: 15,
        "& .ant-checkbox-wrapper": {
          display: "flex",
          "& .ant-checkbox": {
            marginTop: 7
          }
        }
      },
      "& span": {
        fontSize: 16
      }
    },
    noWrap: {
      flexWrap: "nowrap"
    },
    createButton: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      minHeight: 28,
      minWidth: 145,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    buttonWrap: {
      display: "flex",
      justifyContent: "center"
    }
  };
});

interface Props {
  reportId: number;
  formValue: Option;
  setFormValue: React.Dispatch<React.SetStateAction<Option>>;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseData: (response: any) => void;
  isConsult?: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Colorectal = ({
  reportId,
  formValue,
  setFormValue,
  setSaving,
  setCaseData,
  isConsult,
  setModalOpen
}: Props) => {
  const classes = useStyles(isConsult);
  const { t } = useTranslation();

  const [form, setForm] = useState({} as Option);

  const benignEpithelialTumorOptions = getBenignEpithelialTumorOptions(t);

  const histologicTypeOptions = getHistologicTypeOptions(t);

  const [targetPart, setTargetPart] = useState(false);
  const [targetPartOptions, setTargetPartOptions] = useState(
    {} as TargetPartOptions
  );
  const [histopathological, setHistopathological] = useState(false);
  const [resetTargetPart, setResetTargetPart] = useState(false);
  const [formErrors, setFormErrors] = useState({} as any);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (!name) {
      return;
    }

    const oldFormValues = form;
    const newFormValues = { ...oldFormValues, [name]: value };
    setForm(newFormValues);

    if (value === "") {
      setFormErrors({ ...formErrors, [name]: "" });
      return;
    }

    if (value <= 0) {
      setFormErrors({
        ...formErrors,
        [name]: t("Please enter value that is not negative and zero")
      });
    } else {
      setFormErrors({ ...formErrors, [name]: "" });
    }
  };

  const handleSelectChange = (name: string, value: any) => {
    return handleChange({ target: { name, value } });
  };

  const handleSelectTargetPart = (name: string, value: any) => {
    setResetTargetPart(true);
    return handleChange({ target: { name, value } });
  };

  const handleCheckBoxChange = (
    name: string,
    checkedValue: CheckboxValueType[]
  ) => {
    handleChange({ target: { name, value: checkedValue.join(",") } });
  };

  const validateForm = () => {
    const error = {} as any;

    Object.keys(form).map((key: string) => {
      if (
        key === "resection_distance" ||
        key === "orthogonal_diameter" ||
        key === "primary_tumor_orthogonal" ||
        key === "primary_tumor_height" ||
        key === "tumor_diameter" ||
        key === "intestinal_tract_diameter" ||
        key === "HM0_text" ||
        key === "VM0_text" ||
        key === "primary_tumor_number"
      ) {
        if (form[key] === "") {
          return;
        }

        if (Number(form[key]) <= 0) {
          error[key] = true;
        }
      }
    });

    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      return;
    }

    handleConfirm();
    setFormErrors({});
  };

  const handleConfirm = async () => {
    setSaving(true);
    try {
      const data = Object.keys(form).map((key: string) => {
        return {
          report_id: reportId,
          name: key,
          value: form[key]
        };
      });
      await API.post("/form-value-bulk", { form_values: data });
      setFormValue({ ...form });
    } catch (error) {
      exceptionHandler(error, t);
    }
    setSaving(false);
    setModalOpen(false);
  };

  useEffect(() => {
    setForm({ ...formValue });
  }, [formValue]);

  useEffect(() => {
    if (resetTargetPart) resetTargetPartOptions();

    const value = form.target_part;
    if (value === "ColonRectum" || value === "CanalAndSkin") {
      setHistopathological(false);
      setTargetPartOptions(commonTargetPartOptions);
      setTargetPart(true);
    } else if (value === "Appendix") {
      setHistopathological(true);
      setTargetPartOptions(appendixOptions);
      setTargetPart(true);
    }
  }, [form.target_part, resetTargetPart]);

  const resetTargetPartOptions = () => {
    const oldFormValues = form;
    const newFormValues = {
      ...oldFormValues,
      t: "",
      n: "",
      m: "",
      g: "",
      stage2: ""
    };
    setForm(newFormValues);
    setResetTargetPart(false);
  };

  const morphologicalOptions = () => {
    var option = [];
    for (var i = 0; i < 6; i++) {
      option.push(
        <Radio value={`Type ${i}`} key={i}>
          <Trans i18nKey="morphologicalOptions">Type {{ i }}</Trans>
        </Radio>
      );
    }
    return option;
  };

  const superficialTypeOptions = () => {
    var options = ["0-Ⅰp", "0-Ⅰsp", "0-Ⅰs", "0-Ⅱa", "0-Ⅱb", "0-Ⅱc"];
    var selected = [];
    for (var i = 0; i < options.length; i++) {
      selected.push(
        <Radio value={options[i]} key={i}>
          {options[i]}
        </Radio>
      );
    }
    return selected;
  };

  return (
    <div className={classes.wrapper} id="colorectal">
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <Typography className={classes.formTitle}>
            {t("①Clinical information")}
          </Typography>
          <div className={classes.formItem}>
            <TextField
              labelFontSize={12}
              onChange={handleChange}
              name="clinical_diagnosis"
              editValue={form.clinical_diagnosis}
              placeholder=""
              label={t("Clinical diagnosis")}
            />
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Resection")}
            </Typography>
            <Radio.Group
              name="resection"
              value={form.resection}
              onChange={handleChange}
              className={`${classes.radioAndTextField} ${classes.noWrap}`}
            >
              <Radio value={"Polypectomy"}>{t("Polypectomy")}</Radio>
              <Radio value={"EMR"}>{t("EMR")}</Radio>
              <Radio value={"ESD"}>{t("ESD")}</Radio>
              <Radio value={"Others"}>{t("Others")}</Radio>
              {form.resection === "Others" && (
                <TextField
                  labelFontSize={12}
                  className={classes.largeWidth}
                  onChange={handleChange}
                  name="resection_others"
                  disabled={form.resection !== "Others"}
                  editValue={
                    form.resection === "Others" ? form.resection_others : ""
                  }
                />
              )}
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Presence or absence of surgery previous treatment")}
            </Typography>
            <Radio.Group
              name="previous_treatment"
              value={form.previous_treatment}
              onChange={handleChange}
              className={classes.radioAndTextField}
            >
              <Radio value={"yes"}>{t("Yes2")}</Radio>
              {form.previous_treatment === "yes" && (
                <TextField
                  labelFontSize={12}
                  className={classes.largeWidth}
                  onChange={handleChange}
                  name="previous_treatment_yes"
                  disabled={form.previous_treatment !== "yes"}
                  editValue={
                    form.previous_treatment === "yes"
                      ? form.previous_treatment_yes
                      : ""
                  }
                />
              )}
              <Radio className={classes.radioLeftMargin} value={"no"}>
                {t("No2")}
              </Radio>
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Clinical staging cTNMーcT")}
            </Typography>
            <Radio.Group
              name="stage_ct"
              value={form.stage_ct}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              <Radio value="Tis">{t("Tis")}</Radio>
              <Radio value="T1">{t("T1")}</Radio>
              <Radio value="T2">{t("T2")}</Radio>
              <Radio value="T3">{t("T3")}</Radio>
              <Radio value="T4a">{t("T4a")}</Radio>
              <Radio value="T4b">{t("T4b")}</Radio>
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Clinical staging cTNMーcN")}
            </Typography>
            <Radio.Group
              name="stage_cn"
              value={form.stage_cn}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              <Radio value="N0">{t("N0")}</Radio>
              <Radio value="N+">{t("N(+)")}</Radio>
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Clinical staging cTNMーcM")}
            </Typography>
            <Radio.Group
              name="stage_cm"
              value={form.stage_cm}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              <Radio value="M0">{t("M0")}</Radio>
              <Radio value="M1">{t("M1")}</Radio>
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Clinical staging cTNMーcStage")}
            </Typography>
            <Radio.Group
              name="stage_cstage"
              value={form.stage_cstage}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              <Radio value="I">{t("I")}</Radio>
              <Radio value="II">{t("II")}</Radio>
              <Radio value="III">{t("III")}</Radio>
              <Radio value="IV">{t("IV")}</Radio>
            </Radio.Group>
          </div>
          <Typography className={classes.formTitle}>
            {t("②Primary tumor")}
          </Typography>
          <div className={classes.formItem}>
            <div className={classes.primaryTumorBlock}>
              <div className={classes.innerBlock}>
                <Typography fontSize="12px" className={classes.formLabel}>
                  {t("Number")}
                </Typography>
                <Radio.Group
                  name="tumor_count"
                  value={form.tumor_count}
                  onChange={handleChange}
                  className={classes.radioBlock}
                >
                  <Radio value="single">{t("Single")}</Radio>
                  <Radio value="multiple">{t("Multiple")}</Radio>
                  {form.tumor_count === "multiple" && (
                    <TextField
                      labelFontSize={12}
                      placeholder="0"
                      disabled={form.tumor_count !== "multiple"}
                      editValue={
                        form.tumor_count === "multiple"
                          ? form.primary_tumor_number
                          : ""
                      }
                      onChange={handleChange}
                      name="primary_tumor_number"
                      type="number"
                      className={classes.smallWidth}
                      errorFlag={formErrors.primary_tumor_number}
                    />
                  )}
                </Radio.Group>
              </div>
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectChange}
                name="occupation_primary_tumor"
                value={form.occupation_primary_tumor}
                label={t("The occupation site of the primary tumor")}
                size="middle"
                className={`${classes.selectComponent} ${classes.largeWidth}`}
                options={occupationPrimaryTumorOptions}
              />
            </div>
          </div>
          <div className={classes.multileColumnFormItem}>
            <div className={classes.formBlock}>
              <Typography fontSize="12px" className={classes.formLabel}>
                {t("Distance to the resection side")}
              </Typography>
              <div className={classes.formRow}>
                <TextField
                  labelFontSize={12}
                  placeholder="0"
                  type="number"
                  onChange={handleChange}
                  name="resection_distance"
                  editValue={form.resection_distance}
                  className={classes.xsWidth}
                  errorFlag={formErrors.resection_distance}
                />
                <Typography className={classes.formAdditionalText}>
                  mm
                </Typography>
              </div>
            </div>
            <div className={classes.formBlock}>
              <Typography fontSize="12px" className={classes.formLabel}>
                {t(
                  "(Diameter)× (Diameter orthogonal to maximum diameter)× (Height)"
                )}
              </Typography>
              <div className={classes.formRow}>
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="orthogonal_diameter"
                  editValue={form.orthogonal_diameter}
                  placeholder="0"
                  type="number"
                  className={classes.xsWidth}
                  errorFlag={formErrors.orthogonal_diameter}
                />
                <Typography className={classes.formAdditionalText}>
                  ×
                </Typography>
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="primary_tumor_orthogonal"
                  editValue={form.primary_tumor_orthogonal}
                  placeholder="0"
                  type="number"
                  className={classes.xsWidth}
                  errorFlag={formErrors.primary_tumor_orthogonal}
                />
                <Typography className={classes.formAdditionalText}>
                  ×
                </Typography>
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="primary_tumor_height"
                  editValue={form.primary_tumor_height}
                  placeholder="0"
                  type="number"
                  className={classes.xsWidth}
                  errorFlag={formErrors.primary_tumor_height}
                />
                <Typography className={classes.formAdditionalText}>
                  mm
                </Typography>
              </div>
            </div>
            <div className={`${classes.formBlock} ${classes.noMargin}`}>
              <Typography fontSize="12px" className={classes.formLabel}>
                {t(
                  "tumor size (% of circumference of bowel) (Tumor diameter) ÷ (Intestinal tract diameter )"
                )}
              </Typography>
              <div className={classes.formRow}>
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="tumor_diameter"
                  editValue={form.tumor_diameter}
                  placeholder="0"
                  type="number"
                  className={classes.xsWidth}
                  errorFlag={formErrors.tumor_diameter}
                />
                <Typography className={classes.formAdditionalText}>
                  ÷
                </Typography>
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="intestinal_tract_diameter"
                  editValue={form.intestinal_tract_diameter}
                  placeholder="0"
                  type="number"
                  className={classes.xsWidth}
                  errorFlag={formErrors.intestinal_tract_diameter}
                />
                <Typography className={classes.formAdditionalTextCalc}>
                  {`× 100 = ${
                    form.tumor_diameter && form.intestinal_tract_diameter
                      ? (
                          (Number(form.tumor_diameter) /
                            Number(form.intestinal_tract_diameter)) *
                          100
                        ).toFixed(0)
                      : 0
                  } %`}
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Morphological type")}
            </Typography>
            <Radio.Group
              name="morphological_type"
              value={form.morphological_type}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              {morphologicalOptions()}
            </Radio.Group>
          </div>
          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Subclassification of the 0 type(Superficial type)")}
            </Typography>
            <Radio.Group
              name="superficial_type"
              value={form.superficial_type}
              onChange={handleChange}
              className={classes.radioBlock}
            >
              {superficialTypeOptions()}
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <TextField
              labelFontSize={12}
              onChange={handleChange}
              name="primary_tumor_others"
              editValue={form.primary_tumor_others}
              placeholder=""
              label={t("Others")}
            />
          </div>

          <Typography className={classes.formTitle}>
            {t("③Histologic type")}
          </Typography>

          <div className={classes.checkboxFormItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Benign epithelial tumor")}
            </Typography>
            <Checkbox.Group
              className={classes.checkboxGroup}
              defaultValue={[]}
              name="benign"
              value={form.benign ? form.benign.split(",") : []}
              onChange={(checkedValue: CheckboxValueType[]) => {
                return handleCheckBoxChange("benign", checkedValue);
              }}
            >
              <Row>
                {benignEpithelialTumorOptions.map(
                  (option: Option, index: number) => {
                    return (
                      <Col span={8} key={index}>
                        <Checkbox value={option.value}>
                          {t(option.label)}
                        </Checkbox>
                      </Col>
                    );
                  }
                )}
              </Row>
            </Checkbox.Group>
          </div>

          <div className={classes.checkboxFormItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Malignant epithelial tumor")}
            </Typography>
            <Checkbox.Group
              name="malignant"
              value={form.malignant ? form.malignant.split(",") : []}
              className={classes.checkboxGroup}
              defaultValue={[]}
              onChange={(checkedValue: CheckboxValueType[]) => {
                return handleCheckBoxChange("malignant", checkedValue);
              }}
            >
              <Row>
                {histologicTypeOptions.map((option: Option, index: number) => {
                  return (
                    <Col span={8} key={index}>
                      <Checkbox value={option.value}>{option.label}</Checkbox>
                    </Col>
                  );
                })}
              </Row>
            </Checkbox.Group>
          </div>

          <div className={classes.formItem}>
            <TextField
              labelFontSize={12}
              onChange={handleChange}
              name="histologic_others"
              editValue={form.histologic_others}
              placeholder=""
              label={t("Others")}
            />
          </div>

          <Typography className={classes.formTitle}>{t("④Staging")}</Typography>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t(
                "Classification of Colorectal Carcinoma Carcinoma 9th Edition"
              )}
            </Typography>
            <div className={classes.formRow}>
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectChange}
                name="pT"
                value={form.pT}
                label={t("pT (Wall invasion depth)")}
                size="middle"
                placeholder="TX"
                className={classes.selectComponent}
                options={commonTargetPartOptions.primaryTumor}
              />
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectChange}
                name="pN"
                value={form.pN}
                label={t("pN (Lymph node metastases)")}
                size="middle"
                placeholder="NX"
                className={classes.selectComponent}
                options={commonTargetPartOptions.lymphNode}
              />
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectChange}
                name="pM"
                value={form.pM}
                label={t("pM (Distant metastasis)")}
                size="middle"
                placeholder="M0"
                className={classes.selectComponent}
                options={commonTargetPartOptions.metastasis}
              />
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectChange}
                name="stage"
                value={form.stage}
                label={t("Stage (Progress classification)")}
                size="middle"
                placeholder="I"
                className={classes.selectComponent}
                wrapperClassName={classes.selectWrap}
                options={commonTargetPartOptions.stage}
              />
            </div>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("UICC / TNM Classification 8th Edition")}
            </Typography>
            <div className={classes.formRow}>
              <SelectComponent
                labelFontSize={12}
                onChange={handleSelectTargetPart}
                name="target_part"
                value={form.target_part}
                label={t("Target part")}
                size="middle"
                placeholder={t("Colon / rectum")}
                className={`${classes.selectComponent} ${classes.targetPartWidth}`}
                wrapperClassName={classes.selectWrap}
                options={[
                  { label: `${t("ColonRectum")}`, value: "ColonRectum" },
                  { label: `${t("Appendix")}`, value: "Appendix" },
                  {
                    label: `${t("Anal canal and Perianal skin")}`,
                    value: "CanalAndSkin"
                  }
                ]}
              />
            </div>
          </div>

          {targetPart && (
            <div className={classes.formItem}>
              <div className={classes.formRow}>
                <SelectComponent
                  labelFontSize={12}
                  onChange={handleSelectChange}
                  name="t"
                  value={form.t !== "" ? form.t : undefined}
                  label={t("T (Primary tumor）")}
                  size="middle"
                  placeholder="TX"
                  className={classes.selectComponent}
                  options={targetPartOptions.primaryTumor}
                />
                <SelectComponent
                  labelFontSize={12}
                  onChange={handleSelectChange}
                  name="n"
                  value={form.n !== "" ? form.n : undefined}
                  label={t("N (Regional lymph node)")}
                  size="middle"
                  placeholder="NX"
                  className={classes.selectComponent}
                  options={targetPartOptions.lymphNode}
                />
                <SelectComponent
                  labelFontSize={12}
                  onChange={handleSelectChange}
                  name="m"
                  value={form.m !== "" ? form.m : undefined}
                  label={t("M (Distant metastasis)")}
                  size="middle"
                  placeholder="M0"
                  className={classes.selectComponent}
                  options={targetPartOptions.metastasis}
                />
                {histopathological && (
                  <SelectComponent
                    labelFontSize={12}
                    onChange={handleSelectChange}
                    name="g"
                    value={form.g !== "" ? form.g : undefined}
                    label={t(
                      "G (Histopathological degree of differentiation classification）"
                    )}
                    size="middle"
                    placeholder="GX"
                    className={classes.selectComponent}
                    options={targetPartOptions.histopathological}
                  />
                )}

                <SelectComponent
                  labelFontSize={12}
                  onChange={handleSelectChange}
                  name="stage2"
                  value={form.stage2 !== "" ? form.stage2 : undefined}
                  label={t("Stage")}
                  size="middle"
                  placeholder="0"
                  className={classes.selectComponent}
                  options={targetPartOptions.stage}
                />
              </div>
            </div>
          )}

          <Typography className={classes.formTitle}>
            {t("⑤Resection stump/Cancer persistence")}
          </Typography>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Horizontal stump (Mucosa stump) (HM)")}
            </Typography>
            <Radio.Group
              onChange={handleChange}
              name="HM"
              value={form.HM}
              className={classes.radioAndTextField}
            >
              <Radio value="HMX">{t("HMX")}</Radio>
              <Radio value="HM0">{t("HM0")}</Radio>
              {form.HM === "HM0" && (
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="HM0_text"
                  disabled={form.HM !== "HM0"}
                  editValue={form.HM === "HM0" ? form.HM0_text : ""}
                  placeholder="0"
                  className={classes.smallWidth}
                  type="number"
                  errorFlag={formErrors.HM0_text}
                />
              )}
              <Typography className={classes.formAdditionalText}>mm</Typography>
              <Radio value="HM1">{t("HM1")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Perpendicular stump (stratum submucosum stump) (DM)")}
            </Typography>
            <Radio.Group
              onChange={handleChange}
              name="VM"
              value={form.VM}
              className={classes.radioAndTextField}
            >
              <Radio value="VMX">{t("VMX")}</Radio>
              <Radio value="VM0">{t("VM0")}</Radio>
              {form.VM === "VM0" && (
                <TextField
                  labelFontSize={12}
                  onChange={handleChange}
                  name="VM0_text"
                  disabled={form.VM !== "VM0"}
                  editValue={form.VM === "VM0" ? form.VM0_text : ""}
                  placeholder="0"
                  className={classes.smallWidth}
                  type="number"
                  errorFlag={formErrors.VM0_text}
                />
              )}
              <Typography className={classes.formAdditionalText}>mm</Typography>
              <Radio value="VM1">{t("VM1")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Cancer persistence (R)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="cancer_persistence"
              value={form.cancer_persistence}
            >
              <Radio value="ERX">{t("ERX")}</Radio>
              <Radio value="ER0">{t("ER0")}</Radio>
              <Radio value="ER1a">{t("ER1a")}</Radio>
              <Radio value="ER1b">{t("ER1b")}</Radio>
              <Radio value="ER2">{t("ER2")}</Radio>
            </Radio.Group>
          </div>

          <Typography className={classes.formTitle}>
            {t("⑥Histological description")}
          </Typography>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Lymphatic involvement (Ly)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="lymphatic"
              value={form.lymphatic}
            >
              <Radio value="LyX">{t("LyX")}</Radio>
              <Radio value="Ly0">{t("Ly0")}</Radio>
              <Radio value="Ly1a">{t("Ly1a")}</Radio>
              <Radio value="Ly1b">{t("Ly1b")}</Radio>
              <Radio value="Ly1c">{t("Ly1c")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Vein involvement (V)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="vein"
              value={form.vein}
            >
              <Radio value="VX">{t("VX")}</Radio>
              <Radio value="V0">{t("V0")}</Radio>
              <Radio value="V1a">{t("V1a")}</Radio>
              <Radio value="V1b">{t("V1b")}</Radio>
              <Radio value="V1c">{t("V1c")}</Radio>
              <Radio value="V2">{t("V2")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Nerve involvement (Pn)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="nerve"
              value={form.nerve}
            >
              <Radio value="PnX">{t("PnX")}</Radio>
              <Radio value="Pn0">{t("Pn0")}</Radio>
              <Radio value="Pn1a">{t("Pn1a")}</Radio>
              <Radio value="Pn1b">{t("Pn1b")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Invasive proliferative style (IFN)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="IFN"
              value={form.IFN}
            >
              <Radio value="IFNa">{t("IFNa")}</Radio>
              <Radio value="IFNb">{t("IFNb")}</Radio>
              <Radio value="IFNc">{t("IFNc")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Budding (BD)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="budding"
              value={form.budding}
            >
              <Radio value="BDX">{t("BDX")}</Radio>
              <Radio value="BD1">{t("BD1")}</Radio>
              <Radio value="BD2">{t("BD2")}</Radio>
              <Radio value="BD3">{t("BD3")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Liver metastasis (H)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="liver_metasis"
              value={form.liver_metasis}
            >
              <Radio value="HX">{t("HX")}</Radio>
              <Radio value="H0">{t("H0")}</Radio>
              <Radio value="H1">{t("H1")}</Radio>
              <Radio value="H2">{t("H2")}</Radio>
              <Radio value="H3">{t("H3")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Peritoneal metastases (P)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="peritoneal_metasis"
              value={form.peritoneal_metasis}
            >
              <Radio value="PX">{t("PX")}</Radio>
              <Radio value="P0">{t("P0")}</Radio>
              <Radio value="P1">{t("P1")}</Radio>
              <Radio value="P2">{t("P2")}</Radio>
              <Radio value="P3">{t("P3")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Lung metastases (PUL)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="lung_metasis"
              value={form.lung_metasis}
            >
              <Radio value="PULX">{t("PULX")}</Radio>
              <Radio value="PUL0">{t("PUL0")}</Radio>
              <Radio value="PUL1">{t("PUL1")}</Radio>
              <Radio value="PUL2">{t("PUL2")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t("Cytodiagnosis of ascites (CY)")}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="ascites"
              value={form.ascites}
            >
              <Radio value="CY0">{t("CY0")}</Radio>
              <Radio value="CY1">{t("CY1")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <Typography fontSize="12px" className={classes.formLabel}>
              {t(
                "Histologic effect of treatment judgment of medical treatment and the radiotherapy"
              )}
            </Typography>
            <Radio.Group
              className={classes.radioBlock}
              onChange={handleChange}
              name="Ly"
              value={form.Ly}
            >
              <Radio value="Grade 0">{t("Grade 0")}</Radio>
              <Radio value="Grade 1a">{t("Grade 1a")}</Radio>
              <Radio value="Grade 1b">{t("Grade 1b")}</Radio>
              <Radio value="Grade 2">{t("Grade 2")}</Radio>
              <Radio value="Grade 3">{t("Grade 3")}</Radio>
            </Radio.Group>
          </div>

          <div className={classes.formItem}>
            <TextField
              labelFontSize={12}
              onChange={handleChange}
              name="histological_description_others"
              editValue={form.histological_description_others}
              placeholder=""
              label={t("Others")}
            />
          </div>
          <div className={classes.buttonWrap}>
            <Button
              type="primary"
              size="small"
              onClick={validateForm}
              className={classes.createButton}
            >
              {t("OK")}{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const getBenignEpithelialTumorOptions = (t: TFunction) => {
  return [
    { label: t("Tubular adenoma"), value: "Tubular adenoma" },
    { label: t("Tubulovillous adenoma"), value: "Tubulovillous adenoma" },
    { label: t("Villous adenoma"), value: "Villous adenoma" },
    {
      label: t("Troditinonal serrated adenoma"),
      value: "Troditinonal serrated adenoma"
    }
  ];
};

export const getHistologicTypeOptions = (t: TFunction) => {
  return [
    { label: t("Papilary adenocarcinoma (pap)"), value: "pap" },
    {
      label: t("Tubular adenocarcinoma: Well differentiated type (tub1)"),
      value: "tub1"
    },
    {
      label: t("Tubular adenocarcinoma: Moderately differentiated type (tub2)"),
      value: "tub2"
    },
    {
      label: t("Poorly differentiated adenocarcinoma: Solid type (por1)"),
      value: "por1"
    },
    {
      label: t("Poorly differentiated adenocarcinoma: Non-solid type (por2)"),
      value: "por2"
    },
    {
      label: t("Mucinous adenocarcinoma (muc)"),
      value: "muc"
    },
    { label: t("Signet-ring cell carcinoma (sig)"), value: "sig" },
    { label: t("Medullary carcinoma (med)"), value: "med" },
    { label: t("Adenosquamous carcinoma (asc)"), value: "asc" },
    { label: t("Squamous cell carcinoma (scc)"), value: "scc" },
    { label: t("Carcinoid tumor"), value: "Carcinoid tumor" },
    { label: t("Endocrine cell carcinoma"), value: "Endocrine cell carcinoma" }
  ];
};
