import { default as loadImage, parseMetaData } from "blueimp-load-image";

interface ResizePhotoResponse {
  url: string;
  obj: any;
}

export const resizePhoto = async (
  file: any,
  maxHeight: number,
  maxWidth: number
): Promise<ResizePhotoResponse> => {
  return new Promise((resolve, reject) => {
    parseMetaData(file, (data: any) => {
      const options = {
        maxHeight,
        maxWidth,
        canvas: true,
        orientation: null
      };
      if (data.exif) {
        options.orientation = data.exif.get("Orientation") as any;
      }
      loadImage(
        file,
        (canvas: any) => {
          const resizedPhotoURL = canvas.toDataURL("image/png");
          canvas.toBlob((resizedPhotoObject: any) => {
            resolve({ url: resizedPhotoURL, obj: resizedPhotoObject });
          });
        },
        options
      );
    });
  });
};
