import { Layout } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { Switch } from "react-router-dom";
import { theme } from "shared-components";

import { HeaderWrapper } from "./components/molecules/Header";
import { AccountSetting } from "./components/pages/Auth/AccountSetting";
import { ChangeEmail } from "./components/pages/Auth/ChangeEmail";
import { ChangePassword } from "./components/pages/Auth/ChangePassword";
import { EditProfile } from "./components/pages/Auth/EditProfile";
import { PublicReport } from "./components/pages/Cases/Report/PublicReport";
import { HomeWithSiderBar } from "./HomeWIthSiderBar";
import { PrivateRoute } from "./PrivateRoute";
import { RestrictedRoute } from "./RestrictedRoute";

const useStyles = createUseStyles(() => {
  return {
    mainContainer: {
      width: "100%",
      height: "100vh",
      backgroundColor: theme.background
    }
  };
});

const Home = () => {
  const classes = useStyles();

  return (
    <Layout className={classes.mainContainer}>
      <HeaderWrapper />
      <Switch>
        <RestrictedRoute
          exact
          path="/public-report/:reportCode"
          component={PublicReport}
        />
        <PrivateRoute exact path="/change-email" component={ChangeEmail} />
        <PrivateRoute exact path="/edit-profile" component={EditProfile} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />
        <PrivateRoute
          exact
          path="/account-settings"
          component={AccountSetting}
        />
        <PrivateRoute path="/" component={HomeWithSiderBar} />
      </Switch>
    </Layout>
  );
};

export { Home };
