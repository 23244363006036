import {
  DownloadOutlined,
  MoreOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { Form, Popover, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  Button,
  exceptionHandler,
  Loader,
  Modal,
  TextField,
  Typography
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";

const useStyles = createUseStyles((theme: any) => {
  return {
    container: {
      margin: "50px 100px"
    },
    table: {
      marginTop: 92
    },
    addClientBtn: {
      backgroundColor: theme.textBlue,
      color: theme.textWhite,
      float: "right",
      borderRadius: 2,
      "&:hover ": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:focus": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      },
      "&:active": {
        backgroundColor: theme.textBlue,
        color: theme.textWhite
      }
    },
    header: {},
    modalBox: {
      height: 480,
      width: 850,
      marginTop: 5,
      "& .ant-modal-body": {
        display: "flex",
        flexDirection: "column"
        // alignItems: "center"
      }
    },
    modal: {
      maxWidth: "60%",
      marginBottom: 15
    },
    text: {
      maxWidth: "100%",
      fontSize: 16,
      color: theme.darkBackground
    },
    modalButton: {
      textAlign: "right"
    },
    buttonWrapper: {
      textAlign: "center",
      marginBottom: 18,
      marginTop: 18
    },
    addButton: {
      backgroundColor: theme.darkBlueButton,
      width: 145,
      height: 28,
      padding: 2,
      borderRadius: 2
    },
    heading: {
      float: "left"
    },
    actions: {
      display: "flex",
      flexDirection: "column"
    },
    actionMenu: {
      padding: "10px 15px",
      minWidth: 120,
      cursor: "pointer",
      "&:hover": {
        background: theme.background
      }
    },
    popoverContainer: {
      "& .ant-popover-inner-content": {
        padding: "0px !important"
      }
    },
    iconMenu: {
      padding: "0px 5px",
      fontSize: "16px"
    },
    buttonIcon: {
      fontSize: 16
    },
    csvTeamCode: {
      color: theme.darkBlueButton,
      fontSize: 12,
      lineHeight: "14px",
      letterSpacing: 0,
      marginTop: 26,
      border: "none",
      "&:hover ": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:focus": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      },
      "&:active": {
        backgroundColor: theme.textWhite,
        color: theme.textBlue
      }
    },
    csvButton: {
      maxWidth: 319,
      backgroundColor: "#C7C7C7",
      color: "#000000",
      letterSpacing: "0.72px",
      fontSize: 12,
      marginTop: 15,
      "&:hover ": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:focus": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:active": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      }
    },
    formWrapper: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    },
    title: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "28px",
      color: theme.primary,
      marginTop: "-5px",
      marginBottom: "19px",
      letterSpacing: "0px"
    },
    inProgress: {
      display: "flex",
      height: 500,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    teamCodeDone: {
      paddingLeft: 25,
      height: 428
    },
    doneInfo: {
      marginTop: 17
    },
    message: {
      fontFamily: "Noto Sans JP",
      fontSize: 16,
      lineHeight: "19px",
      color: theme.gray7,
      letterSpacing: 0
    },
    errorCsvButton: {
      maxWidth: 155,
      marginTop: 21,
      marginBottom: 26,
      backgroundColor: "#C7C7C7",
      color: "#000000",
      letterSpacing: "0.72px",
      fontSize: 12,
      "&:hover ": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:focus": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      },
      "&:active": {
        backgroundColor: "#C7C7C7",
        color: "#000000"
      }
    },
    errorMessage: {
      fontFamily: "Noto Sans JP",
      fontSize: 14,
      lineHeight: "22px",
      color: theme.gray7,
      letterSpacing: 0
    },
    teamCodeError: {
      paddingLeft: 30,
      height: 756,
      display: "flex",
      flexDirection: "column"
    }
  };
});

const Clients = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true as boolean);
  const [clients, setClients] = useState([] as TeamClientData[]);
  const [visibility, setVisibility] = useState(false);
  const [form] = Form.useForm();
  const [invitationCode, setInvitationCode] = useState("" as string);

  const [processing, setProcessing] = useState(false as boolean);
  const [done, setDone] = useState(false as boolean);
  const [errors, setErrors] = useState("");
  const [templateDownloadLoading, setTemplateDownloading] = useState(
    false as boolean
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const columns = [
    {
      title: t("Team name"),
      dataIndex: "team_name",
      key: "team_name"
    },
    {
      title: t("Registration Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string, data: TeamClientData) => {
        return moment(data.created_at).format("YYYY-MM-DD");
      }
    },

    {
      title: t("Action"),
      key: "action",
      render: (text: string, data: TeamClientData) => {
        return (
          <Popover
            content={() => {
              return popOverContent(data);
            }}
            overlayClassName={classes.popoverContainer}
            trigger="click"
          >
            <MoreOutlined className={classes.iconMenu} />
          </Popover>
        );
      }
    }
  ];

  useEffect(() => {
    getTeamClients();
  }, [done]);

  const getTeamClients = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`clients`);
      setLoading(false);
      if (!response.data) {
        setClients([]);
        return;
      }
      setClients(response.data);
    } catch (error) {
      setLoading(false);
      setClients([]);
      exceptionHandler(error, t);
    }
  };

  const toggleVisibility = () => {
    setVisibility(!visibility);
    setProcessing(false);
    setDone(false);
    setErrors("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationCode(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await API.post(`clients`, {
        invitation_code: invitationCode
      });
      getTeamClients();
      setLoading(false);
      form.resetFields();
      setVisibility(false);
      Alert(
        "success",
        "success",
        t("Team with provided team code is added as client."),
        t
      );
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleClientRemove = async (id: number) => {
    setLoading(true);
    try {
      await API.delete(`clients/${id}`);
      getTeamClients();
      setLoading(false);
      Alert("success", "success", t("Client removed successfully."), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleCSVUpload = async (event: any) => {
    setProcessing(true);

    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    try {
      const response: any = await API.post(
        `clients/bulk-registration`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      );

      if (response && response.data === "success") {
        setProcessing(false);
        setDone(true);
      }
    } catch (error) {
      setProcessing(false);
      setErrors(error.response.data.error.message);
      exceptionHandler(t, error);
    }
  };

  const errorDisplay = (errors: any) => {
    let rowData;
    let columnData;
    let errorData;

    if (errors.includes("Row number:")) {
      const array = errors?.split(",") || [];
      const data = array.map((item: any) => {
        return item.substring(item.indexOf(",") + 0);
      });
      rowData = data[0].includes("Row number:") && data[0].split(": ")[1];
      columnData = data[1].includes("Column:") && data[1].split(": ")[1];
      errorData = data[2].includes("Error:") && data[2].split(": ")[1];
    }

    let errorMessage =
      t("Row number: ") +
      rowData +
      ", " +
      t("Column: ") +
      columnData +
      ", " +
      t("Error: ") +
      t(errorData);
    return errorMessage;
  };

  const handleTemplateDownload = async () => {
    const templateName = "clients-teamcode-sample.csv";
    try {
      setTemplateDownloading(true);
      const res: any = await API.get(`/download-csv-templates?${templateName}`);
      if (res && res.data && res.data.templateUrl) {
        window.open(res.data.templateUrl);
      }
    } catch (error) {
      exceptionHandler(t, error);
    }
    setTemplateDownloading(false);
  };

  const popOverContent = (data: TeamClientData) => {
    return (
      <div className={classes.actions}>
        <p
          className={classes.actionMenu}
          onClick={() => {
            return handleClientRemove(data.id);
          }}
        >
          {t("Remove")}
        </p>
      </div>
    );
  };

  return (
    <ComponentWrapper>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography fontSize={"24px"} className={classes.heading}>
            {t("Customer management")}
          </Typography>
          <Button
            type="primary"
            size="middle"
            onClick={toggleVisibility}
            className={classes.addClientBtn}
          >
            {t("Add client")}{" "}
          </Button>
        </div>
        <div className="clearfix" />
        <Table
          loading={loading}
          dataSource={clients}
          columns={columns}
          pagination={false}
          className={classes.table}
          rowKey="id"
        />
      </div>
      <Modal
        onCancel={toggleVisibility}
        visible={visibility}
        mask={false}
        className={classes.modalBox}
      >
        {processing ? (
          <div className={classes.inProgress}>
            <Typography className={classes.title}>
              {t("Batch registration in progress")}
            </Typography>
            <Loader size="large" />
          </div>
        ) : done ? (
          <div className={classes.teamCodeDone}>
            <Typography className={classes.title}>
              {t("Contact information batch registration")}
            </Typography>
            <div className={classes.doneInfo}>
              <Typography className={classes.message}>
                {t("Bulk registration of contacts has been completed.")}
              </Typography>
            </div>
          </div>
        ) : errors !== "" ? (
          <div className={classes.teamCodeError}>
            <Typography className={classes.title}>
              {t("Contact information batch registration")}
            </Typography>
            <div className={classes.doneInfo}>
              <Typography className={classes.message}>
                {t("The following error has occurred.")}
              </Typography>
              <Typography className={classes.message}>
                {t(
                  "Please correct the incorrect part and upload the CSV again."
                )}
              </Typography>
            </div>
            <input
              ref={inputRef}
              onChange={file => {
                handleCSVUpload(file);
              }}
              type="file"
              accept=".csv"
              multiple={false}
              style={{ display: "none" }}
            />
            <Button
              icon={<UploadOutlined className={classes.buttonIcon} />}
              className={classes.errorCsvButton}
              onClick={() => {
                return inputRef.current && inputRef.current.click();
              }}
            >
              {t("Upload CSV")}
            </Button>
            <Typography className={classes.errorMessage}>
              *{" "}
              {errors.includes("Row number:")
                ? errorDisplay(errors)
                : t(errors)}
            </Typography>
          </div>
        ) : (
          <div className={classes.formWrapper}>
            <Form form={form} className={classes.modal} onFinish={handleSubmit}>
              <Form.Item
                id="invitationCode"
                name="invitationCode"
                rules={[
                  {
                    required: true,
                    message: t("Required")
                  },
                  {
                    max: 7,
                    message: t("Maximum limit has exceeded")
                  }
                ]}
              >
                <TextField
                  name="invitationCode"
                  className={classes.text}
                  label={t("Team code")}
                  onChange={handleChange}
                />
              </Form.Item>
              <div className={classes.buttonWrapper}>
                <Button
                  className={classes.addButton}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("Add")}
                </Button>
              </div>
            </Form>
            <input
              ref={inputRef}
              onChange={file => {
                handleCSVUpload(file);
              }}
              type="file"
              accept=".csv"
              multiple={false}
              style={{ display: "none" }}
            />
            <Button
              icon={<UploadOutlined className={classes.buttonIcon} />}
              className={classes.csvButton}
              htmlType={"button"}
              onClick={() => {
                return inputRef.current && inputRef.current.click();
              }}
            >
              {t("Bulk registration of contacts (CSV upload)")}
            </Button>
            <Button
              className={classes.csvTeamCode}
              icon={<DownloadOutlined className={classes.buttonIcon} />}
              loading={templateDownloadLoading}
              onClick={handleTemplateDownload}
            >
              {t("Download template CSV")}
            </Button>
          </div>
        )}
      </Modal>
    </ComponentWrapper>
  );
};

export { Clients };
