import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { OpenSlideViewer } from "./OpenSlideViewer";

interface Props {
  dbId: number;
  selectedImage: CaseImages;
  reportId: number;
  caseConfirm?: boolean;
  pointer?: boolean;
}

export const getOrgans = (t: TFunction) => {
  return [
    {
      label: t("Others"),
      value: "Others",
      children: [
        {
          value: "Others",
          label: "Others"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Bronchi"),
      value: "Respiratory syste-bronchi",
      children: [
        {
          value: "Bronchi",
          label: "Bronchi"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Respiratory syste-lung",
      label: t("Lung"),
      children: [
        {
          value: "S1",
          label: "S1"
        },
        {
          value: "S2",
          label: "S2"
        },
        {
          value: "S3",
          label: "S3"
        },
        {
          value: "S4",
          label: "S4"
        },
        {
          value: "S5",
          label: "S5"
        },
        {
          value: "S6",
          label: "S6"
        },
        {
          value: "S7",
          label: "S7"
        },
        {
          value: "S8",
          label: "S8"
        },
        {
          value: "S9",
          label: "S9"
        },
        {
          value: "S10",
          label: "S10"
        }
      ],
      options: [
        {
          label: t("UIP"),
          value: "UIP",
          options: [
            { label: "Dense fibrosis", value: "Dense fibrosis" },
            { label: "Loose fibrosis", value: "Loose fibrosis" },
            {
              label: "Architectural destruction",
              value: "Architectural destruction"
            },
            { label: "Honeycombing", value: "Honeycombing" },
            { label: "Fibroblastic focus", value: "Fibroblastic focus" },
            {
              label: "Completely normal lung",
              value: "Completely normal lung"
            },
            { label: "Fibroelastosis", value: "Fibroelastosis" },
            { label: "Organizing pneumonia", value: "Organizing pneumonia" },
            { label: "Lymphoid infiltration", value: "Lymphoid infiltration" },
            { label: "Emphysema", value: "Emphysema" },
            { label: "Airspace macrophages", value: "Airspace macrophages" },
            { label: "Airway disease", value: "Airway disease" },
            { label: "Granuloma", value: "Granuloma" },
            {
              label: "Giant cells/Cholesterol cleft",
              value: "Giant cells/Cholesterol cleft"
            },
            { label: "Exudate", value: "Exudate" },
            { label: "Lymphoid follicle", value: "Lymphoid follicle" },
            {
              label: "Other inflammatory cells",
              value: "Other inflammatory cells"
            },
            { label: "Vascular abnormality", value: "Vascular abnormality" },
            { label: "Pneumocytes", value: "Pneumocytes" },
            { label: "Marked Deposition", value: "Marked Deposition" },
            {
              label: "Smooth mscule hyperplasia",
              value: "Smooth mscule hyperplasia"
            },
            { label: t("Others"), value: "Others" }
          ]
        },
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Respiratory syste-right lung",
      label: t("Right Lung"),
      children: [
        {
          value: "S1",
          label: "S1"
        },
        {
          value: "S2",
          label: "S2"
        },
        {
          value: "S3",
          label: "S3"
        },
        {
          value: "S4",
          label: "S4"
        },
        {
          value: "S5",
          label: "S5"
        },
        {
          value: "S6",
          label: "S6"
        },
        {
          value: "S7",
          label: "S7"
        },
        {
          value: "S8",
          label: "S8"
        },
        {
          value: "S9",
          label: "S9"
        },
        {
          value: "S10",
          label: "S10"
        }
      ],
      options: [
        {
          label: t("UIP"),
          value: "UIP",
          options: [
            { label: "Dense fibrosis", value: "Dense fibrosis" },
            { label: "Loose fibrosis", value: "Loose fibrosis" },
            {
              label: "Architectural destruction",
              value: "Architectural destruction"
            },
            { label: "Honeycombing", value: "Honeycombing" },
            { label: "Fibroblastic focus", value: "Fibroblastic focus" },
            {
              label: "Completely normal lung",
              value: "Completely normal lung"
            },
            { label: "Fibroelastosis", value: "Fibroelastosis" },
            { label: "Organizing pneumonia", value: "Organizing pneumonia" },
            { label: "Lymphoid infiltration", value: "Lymphoid infiltration" },
            { label: "Emphysema", value: "Emphysema" },
            { label: "Airspace macrophages", value: "Airspace macrophages" },
            { label: "Airway disease", value: "Airway disease" },
            { label: "Granuloma", value: "Granuloma" },
            {
              label: "Giant cells/Cholesterol cleft",
              value: "Giant cells/Cholesterol cleft"
            },
            { label: "Exudate", value: "Exudate" },
            { label: "Lymphoid follicle", value: "Lymphoid follicle" },
            {
              label: "Other inflammatory cells",
              value: "Other inflammatory cells"
            },
            { label: "Vascular abnormality", value: "Vascular abnormality" },
            { label: "Pneumocytes", value: "Pneumocytes" },
            { label: "Marked Deposition", value: "Marked Deposition" },
            {
              label: "Smooth mscule hyperplasia",
              value: "Smooth mscule hyperplasia"
            },
            { label: t("Others"), value: "Others" }
          ]
        },
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Respiratory syste-left lung",
      label: t("Left Lung"),
      children: [
        {
          value: "S1+2",
          label: "S1+2"
        },
        {
          value: "S3",
          label: "S3"
        },
        {
          value: "S4",
          label: "S4"
        },
        {
          value: "S5",
          label: "S5"
        },
        {
          value: "S6",
          label: "S6"
        },
        {
          value: "S8",
          label: "S8"
        },
        {
          value: "S9",
          label: "S9"
        },
        {
          value: "S10",
          label: "S10"
        }
      ],
      options: [
        {
          label: t("UIP"),
          value: "UIP",
          options: [
            { label: "Dense fibrosis", value: "Dense fibrosis" },
            { label: "Loose fibrosis", value: "Loose fibrosis" },
            {
              label: "Architectural destruction",
              value: "Architectural destruction"
            },
            { label: "Honeycombing", value: "Honeycombing" },
            { label: "Fibroblastic focus", value: "Fibroblastic focus" },
            {
              label: "Completely normal lung",
              value: "Completely normal lung"
            },
            { label: "Fibroelastosis", value: "Fibroelastosis" },
            { label: "Organizing pneumonia", value: "Organizing pneumonia" },
            { label: "Lymphoid infiltration", value: "Lymphoid infiltration" },
            { label: "Emphysema", value: "Emphysema" },
            { label: "Airspace macrophages", value: "Airspace macrophages" },
            { label: "Airway disease", value: "Airway disease" },
            { label: "Granuloma", value: "Granuloma" },
            {
              label: "Giant cells/Cholesterol cleft",
              value: "Giant cells/Cholesterol cleft"
            },
            { label: "Exudate", value: "Exudate" },
            { label: "Lymphoid follicle", value: "Lymphoid follicle" },
            {
              label: "Other inflammatory cells",
              value: "Other inflammatory cells"
            },
            { label: "Vascular abnormality", value: "Vascular abnormality" },
            { label: "Pneumocytes", value: "Pneumocytes" },
            { label: "Marked Deposition", value: "Marked Deposition" },
            {
              label: "Smooth mscule hyperplasia",
              value: "Smooth mscule hyperplasia"
            },
            { label: t("Others"), value: "Others" }
          ]
        },
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-stomach",
      label: t("Stomach"),
      children: [
        {
          value: "U-Ant",
          label: "U-Ant"
        },
        {
          value: "U-Post",
          label: "U-Post"
        },
        {
          value: "U-Less",
          label: "U-Less"
        },
        {
          value: "U-Gre",
          label: "U-Gre"
        },
        {
          value: "M-Ant",
          label: "M-Ant"
        },
        {
          value: "M-Post",
          label: "M-Post"
        },
        {
          value: "M-Less",
          label: "M-Less"
        },
        {
          value: "M-Gre",
          label: "M-Gre"
        },
        {
          value: "L-Ant",
          label: "L-Ant"
        },
        {
          value: "L-Post",
          label: "L-Post"
        },
        {
          value: "L-Less",
          label: "L-Less"
        },
        {
          value: "L-Gre",
          label: "L-Gre"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-esophagus",
      label: t("Esophagus"),
      children: [
        {
          value: "Ant",
          label: "Ant"
        },
        {
          value: "Post",
          label: "Post"
        },
        {
          value: "Less",
          label: "Less"
        },
        {
          value: "Gre",
          label: "Gre"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-duodenum",
      label: t("Duodenum"),
      children: [
        {
          value: "Ant",
          label: "Ant"
        },
        {
          value: "Post",
          label: "Post"
        },
        {
          value: "Less",
          label: "Less"
        },
        {
          value: "Gre",
          label: "Gre"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-jejunum",
      label: t("Jejunum"),
      children: [
        {
          value: "Jejunum",
          label: "Jejunum"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-ileum",
      label: t("Ileum"),
      children: [
        {
          value: "Ileum",
          label: "Ileum"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-appendix",
      label: t("Appendix"),
      children: [
        {
          value: "Cecum",
          label: "Cecum"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Gallbladder/Bile duct"),
      value: "Digestive organ-gallbladder/bile duct",
      children: [
        {
          value: "Gallbladder/Bile duct",
          label: "Gallbladder/Bile duct"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-ascending colon",
      label: t("Ascending colon"),
      children: [
        {
          value: "Ascending colon",
          label: "Ascending colon"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-transverse colon",
      label: t("Transverse colon"),
      children: [
        {
          value: "Transverse colon",
          label: "Transverse colon"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-descending colon",
      label: t("Descending colon"),
      children: [
        {
          value: "Descending colon",
          label: "Descending colon"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-sigmoid colon",
      label: t("Sigmoid colon"),
      children: [
        {
          value: "Sigmoid colon",
          label: "Sigmoid colon"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-rectum",
      label: t("Rectum"),
      children: [
        {
          value: "RS-Ant",
          label: "RS-Ant"
        },
        {
          value: "RS-Post",
          label: "RS-Post"
        },
        {
          value: "RS-Lt",
          label: "RS-Lt"
        },
        {
          value: "RS-Rt",
          label: "RS-Rt"
        },
        {
          value: "Ra-Ant",
          label: "Ra-Ant"
        },
        {
          value: "Ra-Post",
          label: "Ra-Post"
        },
        {
          value: "Ra-Lt",
          label: "Ra-Lt"
        },
        {
          value: "Ra-Rt",
          label: "Ra-Rt"
        },
        {
          value: "Rb-Ant",
          label: "Rb-Ant"
        },
        {
          value: "Rb-Post",
          label: "Rb-Post"
        },
        {
          value: "Rb-Lt",
          label: "Rb-Lt"
        },
        {
          value: "Rb-Rt",
          label: "Rb-Rt"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-vermiform appendix",
      label: t("Vermiform appendix"),
      children: [
        {
          value: "Vermiform appendix",
          label: "Vermiform appendix"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      value: "Digestive organ-anus",
      label: t("Anus"),
      children: [
        {
          value: "Ant",
          label: "Ant"
        },
        {
          value: "Post",
          label: "Post"
        },
        {
          value: "Lt",
          label: "Lt"
        },
        {
          value: "Rt",
          label: "Rt"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Liver"),
      value: "Digestive organ-liver",
      children: [
        {
          value: "Liver",
          label: "Liver"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Pancreas"),
      value: "Digestive organ-pancreas",
      children: [
        {
          value: "Pancreas",
          label: "Pancreas"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Vulva"),
      value: "Female organ-vulva",
      children: [
        {
          value: "Vulva",
          label: "Vulva"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Uterine vagina/Cervix"),
      value: "Female organ-uterine vagina/cervix",
      children: [
        {
          value: "Uterine vagina/Cervix",
          label: "Uterine vagina/Cervix"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Endometrium of uterine body"),
      value: "Female organ-endometrium of uterine body",
      children: [
        {
          value: "Endometrium of uterine body",
          label: "Endometrium of uterine body"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Womb contents"),
      value: "Female organ-womb contents",
      children: [
        {
          value: "Womb contents",
          label: "Womb contents"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Left ovary"),
      value: "Female organ-left ovary",
      children: [
        {
          value: "Left ovary",
          label: "Left ovary"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right ovary"),
      value: "Female organ-right ovary",
      children: [
        {
          value: "Right ovary",
          label: "Right ovary"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Left fallopian tube"),
      value: "Female organ-left fallopian tube",
      children: [
        {
          value: "Left fallopian tube",
          label: "Left fallopian tube"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right fallopian tube"),
      value: "Female organ-right fallopian tube",
      children: [
        {
          value: "Right fallopian tube",
          label: "Right fallopian tube"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Left kidney"),
      value: "Urinary organ-left kidney",
      children: [
        {
          value: "Left kidney",
          label: "Left kidney"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right kidney"),
      value: "Urinary organ-right kidney",
      children: [
        {
          value: "Right kidney",
          label: "Right kidney"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Bladder"),
      value: "Urinary organ-bladder",
      children: [
        {
          value: "Bladder",
          label: "Bladder"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("prostate"),
      value: "Urinary organ-prostate",
      children: [
        {
          value: "prostate",
          label: "prostate"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Left testis"),
      value: "Urinary organ-left testis",
      children: [
        {
          value: "Left testis",
          label: "Left testis"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right testis"),
      value: "Urinary organ-right testis",
      children: [
        {
          value: "Right testis",
          label: "Right testis"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Oral cavity/Lips"),
      value: "Mouth/Nose-oral cavity/lips",
      children: [
        {
          value: "Oral cavity/Lips",
          label: "Oral cavity/Lips"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Salivary gland"),
      value: "Mouth/Nose-salivary gland",
      children: [
        {
          value: "Salivary gland",
          label: "Salivary gland"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Pharynx"),
      value: "Mouth/Nose-pharynx",
      children: [
        {
          value: "Pharynx",
          label: "Pharynx"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Tonsils"),
      value: "Mouth/Nose-tonsils",
      children: [
        {
          value: "Tonsils",
          label: "Tonsils"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Larynx"),
      value: "Mouth/Nose-larynx",
      children: [
        {
          value: "Larynx",
          label: "Larynx"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Nasal cavity"),
      value: "Mouth/Nose-nasal cavity",
      children: [
        {
          value: "Nasal cavity",
          label: "Nasal cavity"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Skin"),
      value: "Skin/Mammary gland-skin",
      children: [
        {
          value: "Skin",
          label: "Skin"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Subcutaneous tumor"),
      value: "Skin/Mammary gland-subcutaneous tumor",
      children: [
        {
          value: "Subcutaneous tumor",
          label: "Subcutaneous tumor"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Soft tissue"),
      value: "Skin/Mammary gland-soft tissue",
      children: [
        {
          value: "Soft tissue",
          label: "Soft tissue"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Left mammary gland"),
      value: "Skin/Mammary gland-left mammary gland",
      children: [
        {
          value: "Left mammary gland",
          label: "Left mammary gland"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right mammary gland"),
      value: "Skin/Mammary gland-right mammary gland",
      children: [
        {
          value: "Right mammary gland",
          label: "Right mammary gland"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Bone/cartilage"),
      value: "Locomotor organs/Blood vessels-bone/cartilage",
      children: [
        {
          value: "Bone/cartilage",
          label: "Bone/cartilage"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Synovium"),
      value: "Locomotor organs/Blood vessels-synovium",
      children: [
        {
          value: "Synovium",
          label: "Synovium"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Joint"),
      value: "Locomotor organs/Blood vessels-joint",
      children: [
        {
          value: "Joint",
          label: "Joint"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Muscle"),
      value: "Locomotor organs/Blood vessels-muscle",
      children: [
        {
          value: "Muscle",
          label: "Muscle"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Blood vessels"),
      value: "Locomotor organs/Blood vessels-blood vessels",
      children: [
        {
          value: "Blood vessels",
          label: "Blood vessels"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Spleen"),
      value: "Hematopoietic organ-spleen",
      children: [
        {
          value: "Spleen",
          label: "Spleen"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Bone marrow"),
      value: "Hematopoietic organ-bone marrow",
      children: [
        {
          value: "Bone marrow",
          label: "Bone marrow"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Lymph node"),
      value: "Hematopoietic organ-lymph node",
      children: [
        {
          value: "Lymph node",
          label: "Lymph node"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },

    {
      label: t("Left thyroid"),
      value: "Endocrine-left thyroid",
      children: [
        {
          value: "Left thyroid",
          label: "Left thyroid"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Right thyroid"),
      value: "Endocrine-right thyroid",
      children: [
        {
          value: "Right thyroid",
          label: "Right thyroid"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    },
    {
      label: t("Others"),
      value: "Others",
      children: [
        {
          value: "Others",
          label: "Others"
        }
      ],
      options: [
        {
          label: t("Others"),
          value: "Others",
          options: [{ label: t("Others"), value: "Others" }]
        }
      ]
    }
  ];
};

export const getSignificance = (t: TFunction) => {
  return [
    { label: t("Critical"), value: "Critical" },
    { label: t("Urgent"), value: "Urgent" },
    { label: t("Significant Unexpected"), value: "Significant Unexpected" },
    { label: t("Significant Expected"), value: "Significant Expected" },
    { label: t("Insignificant"), value: "Insignificant" }
  ];
};

const Annotation = ({
  dbId,
  reportId,
  selectedImage,
  caseConfirm,
  pointer
}: Props) => {
  const { t } = useTranslation();

  const tagVocabulary = getOrgans(t).find((organ: any) => {
    return organ.value === selectedImage.organ || organ.value === "Others";
  })?.options;
  const observationOptions = getSignificance(t);
  return (
    <OpenSlideViewer
      caseId={dbId}
      selectedImage={selectedImage}
      reportId={reportId}
      manifest={selectedImage.Image}
      caseConfirm={caseConfirm}
      tagVocabulary={tagVocabulary}
      observationOptions={observationOptions}
      pointer={pointer}
      disableAnnotation={false}
    />
  );
};

export default Annotation;
