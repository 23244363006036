import { Divider, Upload } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useLocation } from "react-router-dom";
import {
  Alert,
  AuthContext,
  Button,
  Modal,
  Typography
} from "shared-components";
import { API, exceptionHandler, POROUSAPI } from "shared-components/src";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      "& .ant-modal-body": {
        padding: "33px 0px 0px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-modal-content": {
        top: "-140px"
      },
      "& .ant-upload-drag": {
        marginTop: 33,
        marginBottom: 6,
        height: 135,
        width: 514,
        background: theme.textWhite,
        borderRadius: 3,
        borderColor: theme.formLabelColor
      },
      "& article": {
        textAlign: "center"
      }
    },
    button: {
      height: 28,
      width: 160,
      fontSize: 14,
      lineHeight: "20px",
      borderRadius: 3,
      marginTop: 11,
      marginBottom: 56.5
    },
    title: {
      fontSize: 27,
      lineHeight: "39px",
      letterSpacing: "1.62px",
      color: theme.formLabelColor
    },
    dropText: {
      color: theme.primary,
      fontSize: 18,
      lineHeight: "27px"
    },
    orText: {
      textAlign: "center",
      fontSize: 18,
      lineHeight: "27px",
      color: theme.textBlack
    },
    upload: {
      "& .ant-upload-text-icon": {
        display: "inline-block"
      },
      "& span": {
        display: "inline"
      }
    },
    divider: {
      margin: "0px !important",
      borderColor: theme.colorGrey
    },
    imagesContainer: {
      display: "flex",
      margin: "15.5px 15px",
      flexWrap: "wrap",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-track": {
        background: theme.lightGrey
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.scrollColor,
        borderRadius: "10px"
      }
    },
    imageTag: {
      fontSize: 10,
      lineHeight: "22px",
      letterSpacing: "0.6px",
      color: theme.colorTextGrey,
      padding: "5px 9px 5px 10px",
      background: theme.background,
      borderRadius: 3,
      marginRight: 5,
      marginBottom: 5
    }
  };
});

interface Props {
  visible: boolean;
  setImageModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  setCaseImagesRef: any;
  images: React.MutableRefObject<CaseImages[]>;
  caseId: number;
  teamId: number;
  reportId: number;
  setImageUploadingShow?: React.Dispatch<React.SetStateAction<boolean>>;
  setImageUploadingList?: React.Dispatch<any>;
  setUploadedImagesRef: React.Dispatch<any>;
  uploadedImages: React.MutableRefObject<any[]>;
  setLoadingFilesToUpload: any;
}

const UploadImageModal = ({
  visible,
  setImageModalVisibility,
  setCaseImagesRef,
  images,
  caseId,
  teamId,
  reportId,
  setImageUploadingShow,
  setImageUploadingList,
  setUploadedImagesRef,
  uploadedImages,
  setLoadingFilesToUpload
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation<any>();

  const { Dragger } = Upload;
  const [uploading, setUploading] = useState(false);
  const [buttonUpload, setButtonUpload] = useState(false);

  const [uploadedList, setUploadedList] = useState([] as number[]);
  const uploadedRef = useRef(uploadedList);
  const setUploadedRef = async (data: any, handleFunction?: any) => {
    uploadedRef.current = data;
    setUploadedList(data);
  };
  const [slideIdList, setSlideIdList] = useState([] as string[]);
  const slideIdListRef = useRef(slideIdList);
  const setSlideIdListRef = async (data: any, handleFunction?: any) => {
    slideIdListRef.current = data;
    setSlideIdList(data);
  };
  const [fileList, setFileList] = useState([] as any);
  const { defaultTeam } = useContext(AuthContext);

  useEffect(() => {
    if (uploadedRef.current.length > 0) {
      setImageUploadingList!(uploadedRef.current);
    }
    return () => {
      setImageUploadingList!([]);
    };
  }, [uploadedList]);

  useEffect(() => {
    if (fileList.length > 0) {
      setImageUploadingShow!(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (location && location.state && location.state.folder && reportId) {
      const { folder } = location.state;

      setLoadingFilesToUpload(false);

      let newFile: any = [];
      folder.forEach((item: any, index: number) => {
        newFile[index] = {};
        newFile[index].file = item;
        newFile[index].file.status = "done";
        newFile[index].file.originFileObj = {};
        newFile[index].file.originFileObj = item;
      });

      newFile.forEach((file: any) => {
        handleImageUpload(file);
      });
    }
  }, [location, reportId]);

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleImageModalClose = () => {
    setFileList([]);
    setImageModalVisibility(false);
  };

  const handleImageUpload = async (info: any) => {
    if (info.file.status === "done") {
      setImageUploadingShow!(true);
      if (info.file) {
        setUploadedImagesRef([...uploadedImages.current, info.file]);
        if (
          info.fileList &&
          info.file.uid === info.fileList[info.fileList.length - 1].uid
        )
          setLoadingFilesToUpload(false);
      }
      try {
        const [slideId] = await uploadImageToPorous(info);
        setSlideIdListRef([...slideIdListRef.current, slideId]);
        await getImage(slideId);
      } catch (error) {
        setUploading(false);
        setButtonUpload(false);
        exceptionHandler(error, t);
      }
    }
  };

  const progressHandler = (event: any, file: any) => {
    const newList = uploadedImages.current.map((item: any) => {
      if (item.uid === file.uid) {
        const updatedItem = {
          ...item,
          name: item.name,
          percent: Math.floor((event.loaded / file.size) * 100)
        };
        return updatedItem;
      }
      return item;
    });
    if (newList.length > 0) setUploadedImagesRef(newList);
  };

  const putFileToCloudStorage = (file: any, url: any) => {
    try {
      return new Promise(resolve => {
        let xhr = new XMLHttpRequest();
        xhr.onload = () => {
          resolve(xhr.response);
        };
        xhr.upload.addEventListener(
          "progress",
          event => {
            return progressHandler(event, file);
          },
          false
        );
        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-type", "application/octet-stream");
        const _send = xhr.send;
        xhr.send = function() {
          _send.call(xhr, file);
        };
        xhr.send();
      });
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const uploadImageToPorous = async (info: any) => {
    const signedURL: any = await getSignedURL(
      teamId ? teamId.toString() : defaultTeam.toString(),
      info.file.originFileObj.name
    );
    await putFileToCloudStorage(info.file.originFileObj, signedURL.data.url);
    return [signedURL.data.id];
  };

  const getSignedURL = (groupId: string, filename: string) => {
    try {
      return POROUSAPI.post(
        `prepare-upload`,
        {
          groupId: groupId,
          filename: filename
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const createThumbnail = (slideId: string, height: number, width: number) => {
    try {
      return POROUSAPI.post(`/slides/${slideId}/crop`, {
        height: height,
        width: width,
        theta: 0,
        x: 0,
        y: 0
      });
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const getImage = async (slideId: string) => {
    try {
      const imageResp: any = await POROUSAPI.get(`slides/${slideId}`);
      checkImage(slideId, imageResp);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  const checkImage = async (slideId: string, imageResp: any) => {
    try {
      if (imageResp && imageResp.data) {
        if (
          imageResp.data.state === "waiting" ||
          imageResp.data.state === "processing"
        ) {
          const response: any = await API.post(`uploads`, {
            case_id: caseId,
            report_id: reportId,
            name: imageResp.data.fileName,
            format: imageResp.data.format,
            overlap: imageResp.data.overlap,
            height: imageResp.data.height,
            width: imageResp.data.width,
            tile_size: imageResp.data.tileSize,
            image_url: `https://storage.googleapis.com/${process.env.REACT_APP_POROUS_API_TILEBUCKET}/${imageResp.data.groupId}/${slideId}/${imageResp.data.slug}/`,
            thumbnail: "",
            slide_id: slideId
          });
          const newImage = {
            id: response.data.id,
            url: "",
            name: response.data.name,
            dataUrl: "",
            organ: response.data.organ,
            part: response.data.part,
            staining: response.data.staining,
            slide_id: response.data.slide_id,
            Image: {
              Format: response.data.format,
              Overlap: response.data.overlap,
              Size: {
                Height: response.data.height,
                Width: response.data.width
              },
              TileSize: response.data.tile_size,
              Url: response.data.image_url
            }
          };
          setUploadedRef([...uploadedRef.current, response.data.id]);
          setCaseImagesRef([...images.current, newImage], () => {
            setUploading(false);
            setButtonUpload(false);
          });
          setUploading(false);
          setButtonUpload(false);
        }
        if (imageResp.data.state === "failed") {
          setUploading(false);
          setButtonUpload(false);
          Alert("error", "error", "Failed to upload Image", t);
          return;
        }
        if (imageResp.data.state === "done") {
          const thumbnail: any = await createThumbnail(
            slideId,
            imageResp.data.height,
            imageResp.data.width
          );
          const response: any = await API.post(`uploads`, {
            case_id: caseId,
            report_id: reportId,
            name: imageResp.data.fileName,
            format: imageResp.data.format,
            overlap: imageResp.data.overlap,
            height: imageResp.data.height,
            width: imageResp.data.width,
            tile_size: imageResp.data.tileSize,
            image_url: `https://storage.googleapis.com/${process.env.REACT_APP_POROUS_API_TILEBUCKET}/${imageResp.data.groupId}/${slideId}/${imageResp.data.slug}/`,
            thumbnail: thumbnail.data.url,
            slide_id: slideId
          });
          const newImage = {
            id: response.data.id,
            url: response.data.thumbnail,
            name: response.data.name,
            dataUrl: "",
            organ: response.data.organ,
            part: response.data.part,
            staining: response.data.staining,
            slide_id: response.data.slide_id,
            Image: {
              Format: response.data.format,
              Overlap: response.data.overlap,
              Size: {
                Height: response.data.height,
                Width: response.data.width
              },
              TileSize: response.data.tile_size,
              Url: response.data.image_url
            }
          };
          setUploadedRef([...uploadedRef.current, response.data.id]);
          setCaseImagesRef([...images.current, newImage], () => {
            setUploading(false);
            setButtonUpload(false);
          });
        }
      } else {
        setUploading(false);
        setButtonUpload(false);
      }
    } catch (error) {
      setUploading(false);
      setButtonUpload(false);
      exceptionHandler(error, t);
    }
  };
  return (
    <Modal
      className={classes.modalContainer}
      visible={visible}
      onCancel={handleImageModalClose}
      width="850px"
      mask={false}
    >
      <Typography className={classes.title}>{t("Upload Image")}</Typography>

      <Dragger
        multiple
        customRequest={dummyRequest}
        showUploadList={false}
        onChange={handleImageUpload}
        beforeUpload={(file, fileList) => {
          setUploading(true);
          setFileList([...fileList]);
          return true;
        }}
      >
        <Typography className={classes.dropText}>{t("Drop File")}</Typography>
      </Dragger>

      <Typography className={classes.orText}>{t("OR")}</Typography>

      <Upload
        multiple
        accept="*/*"
        className={classes.upload}
        onChange={handleImageUpload}
        customRequest={dummyRequest}
        showUploadList={false}
        disabled={uploading}
        beforeUpload={(file, fileList) => {
          setUploading(true);
          setButtonUpload(true);
          setFileList([...fileList]);
          return true;
        }}
      >
        <Button
          className={classes.button}
          size="small"
          type="primary"
          loading={buttonUpload}
        >
          {t("Select Files")}
        </Button>
      </Upload>

      <Divider className={classes.divider} />

      <div className={classes.imagesContainer}>
        {/* {images.current.map((image: CaseImages, index: any) => {
          return (
            uploadedRef.current.includes(image.id) && (
              <span className={classes.imageTag} key={index}>
                {image.name}
              </span>
            )
          );
        })} */}
        {!uploading &&
          fileList.map((item: any, index: number) => {
            return (
              <span className={classes.imageTag} key={index}>
                {item.name}
              </span>
            );
          })}
      </div>
    </Modal>
  );
};

export { UploadImageModal };
