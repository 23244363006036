import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import {
  Alert,
  API,
  AuthContext,
  Button,
  exceptionHandler,
  Modal,
  Typography
} from "shared-components";

const useStyles = createUseStyles((theme: any) => {
  return {
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& .ant-modal-body": {
        minWidth: 850,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      "& .ant-upload-text-icon": {
        "& svg": {
          display: "none"
        }
      }
    },
    container: {
      height: "100%",
      width: "100%",
      padding: "20px 30px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "center"
    },
    button: {
      height: 28,
      width: 160,
      fontSize: 14,
      marginTop: 17,
      marginRight: 20,
      marginLeft: 20,
      lineHeight: "17px",
      letterSpacing: "0px"
    },
    titleContainer: {
      display: "flex",
      justifyContent: "center"
    },
    lowerContainer: {
      marginTop: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    radioContainer: {
      marginTop: 20,
      textAlign: "left"
    },
    radio: {
      display: "block",
      marginBottom: 10
    },
    title: {
      fontStyle: "normal",
      fontSize: 24,
      lineHeight: "28px",
      color: theme.primary,
      marginBottom: 24
    },
    modalText: {
      marginBottom: 4,
      fontSize: 16
    }
  };
});

interface Props {
  visible: boolean;
  onCancel: () => void;
  caseTeams: any[];
}
export const SwitchTeamModal = ({ visible, caseTeams, onCancel }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { defaultTeamName, setDefaultTeam } = useContext(AuthContext);

  const [currentTeam, setCurrentTeam] = useState(0 as number);

  const onSwitch = async () => {
    try {
      const response = await API.post(`members/change-teams`, {
        team_id: multiple ? currentTeam : caseTeams[0]?.id
      });

      if (response.data) {
        setDefaultTeam(multiple ? currentTeam : caseTeams[0]?.id);
        Alert("success", "success", t("Team Changed Successfully."), t);
        window.location.reload();
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  useEffect(() => {
    if (caseTeams && caseTeams.length) {
      setCurrentTeam(caseTeams[0].id);
    }
  }, [caseTeams]);

  const onChange = async (event: RadioChangeEvent) => {
    setCurrentTeam(event.target.value);
  };

  const multiple = caseTeams && caseTeams.length > 1 ? true : false;

  return (
    <Modal
      className={classes.modalContainer}
      visible={visible}
      onCancel={onCancel}
      width="850px"
      mask={false}
    >
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{t("Switch Team")}</Typography>
        </div>
        {!multiple ? (
          <div>
            <Typography className={classes.modalText}>
              {t(
                "The team you are currently selecting is different from the team of the case you accessed."
              )}
            </Typography>
            <Typography className={classes.modalText}>
              {t("Do you want to switch teams?")}
            </Typography>
            <div className={classes.lowerContainer}>
              <Typography className={classes.modalText}>
                {t("Team currently selected: ") + defaultTeamName}
              </Typography>
              <Typography className={classes.modalText}>
                {t("Team for the case: ") + caseTeams[0]?.name}
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <Typography className={classes.modalText}>
              {t(
                "The currently selected team and the team of the accessed case are different."
              )}
            </Typography>
            <div className={classes.lowerContainer}>
              <Typography className={classes.modalText}>
                {t("Team currently selected: ") + defaultTeamName}
              </Typography>
              <Radio.Group
                onChange={onChange}
                value={currentTeam}
                className={classes.radioContainer}
              >
                {caseTeams &&
                  caseTeams.length &&
                  caseTeams.map((team: any) => {
                    return (
                      <Radio className={classes.radio} value={team.id}>
                        {team.name}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </div>
          </div>
        )}

        <div className={classes.buttonsContainer}>
          <Button className={classes.button} size="large" onClick={onCancel}>
            {t("Cancel")}
          </Button>
          <Button
            className={classes.button}
            size="large"
            type="primary"
            onClick={onSwitch}
          >
            {t("Switch")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
