import React from "react";

interface Props {
  padding: string;
}

const Spacer = (props: Props) => {
  return <span style={{ padding: props.padding }}></span>;
};

export { Spacer };
