import { Col, Divider, Empty, Row } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import {
  Alert,
  API,
  AuthContext,
  exceptionHandler,
  Loader
} from "shared-components";

import { ComponentWrapper } from "../../molecules/ComponentWrapper";
import { Member } from "../../organisms/DoubleCheck";
import { DraggableCard } from "./DraggableCard";
import { FilterComponent } from "./FilterComponent";

const useStyles = createUseStyles((theme: any) => {
  return {
    divider: {
      marginTop: 10,
      marginBottom: "18.5px",
      borderColor: theme.colorGrey
    },
    centerAlign: {
      display: "flex",
      justifyContent: "center"
    },
    "@media screen and (max-width: 991px)": {
      centerAlign: {
        marginLeft: "14%"
      }
    },
    "@media screen and (max-width: 767px)": {
      centerAlign: {
        marginLeft: "3%"
      }
    },
    rowWrapper: {
      overflowY: "auto",
      paddingTop: 3,
      paddingLeft: 32,
      paddingRight: 50,
      flex: 1
    },
    loader: {
      textAlign: "center",
      width: "100%",
      marginTop: "25%"
    },
    componentWrapper: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column"
    },
    row: {
      "@media(min-width: 1300px)": {
        width: 1014,
        margin: "auto"
      }
    }
  };
});

export interface Filter {
  page: number;
  pageSize: number;
  orderBy: string;
  marked: boolean;
  userBookmark: boolean;
}

const Assignment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [cases, setCases] = useState([] as CaseListData[]);
  const { defaultTeam, uid } = useContext(AuthContext);
  const [members, setMembers] = useState([] as any);
  const [error, setError] = useState("");
  const [count, setCount] = useState(0 as number);
  const [loading, setLoading] = useState(true as boolean);
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 30,
    orderBy: "desc"
  } as Filter);

  useEffect(() => {
    getCases();
  }, [defaultTeam, filters]);

  useEffect(() => {
    getMemberList();
  }, [defaultTeam]);

  const getMemberList = async () => {
    try {
      const response = await API.get(`members`, {
        params: { teamId: defaultTeam }
      });
      setMembers(response.data);
    } catch (error) {
      exceptionHandler(error, t);
    }
  };

  const getCases = async () => {
    setLoading(true);
    try {
      const response: any = await API.get(`cases`, {
        params: {
          teamId: defaultTeam,
          unassigned: true,
          page: filters.page,
          pageSize: filters.pageSize,
          orderBy: filters.orderBy,
          marked: (filters && filters.marked) || undefined,
          user_bookmark: (filters && filters.userBookmark) || undefined
        }
      });
      setLoading(false);
      if (!response.data || response.data.length === 0) {
        setCases([]);
        setCount(0);
        setError(t("No unassigned cases"));
        return;
      }

      const newCases = response.data.map((data: any) => {
        const dateDifference = moment(moment()).diff(
          data.report.created_at,
          "day"
        );
        return {
          id: data.id,
          reportId: data.report.id,
          name: data.report.patient_name,
          organ: data.report.organs,
          created_at: data.report.created_at,
          updated_at: data.report.updated_at,
          pathology_number: data.pathology_number,
          completed: data.report.confirmed_at !== null,
          double_check: data.report.double_check.length !== 0,
          is_new: dateDifference < 1,
          is_old: dateDifference >= 7,
          tbd: dateDifference < 7 && dateDifference >= 1,
          marked: data.marked,
          my_bookmark: data.user_mark
        };
      });
      setCases([...newCases]);
      setCount(response.count);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleMyBookmark = async (index: number, id: number) => {
    try {
      await API.post("/toggle-user-marks", {
        case_id: id,
        user_id: uid
      });
      const newCases = [...cases];
      newCases[index].my_bookmark = !newCases[index].my_bookmark;
      setCases(newCases);
      if (newCases[index].my_bookmark) {
        Alert("success", "success", t("Case is added as your bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from your bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  const handleTeamBookmark = async (index: number, id: number) => {
    try {
      const newCases = [...cases];
      await API.post("/toggle-team-marks", {
        case_id: id,
        team_id: defaultTeam,
        marked: !newCases[index].marked
      });
      newCases[index].marked = !newCases[index].marked;
      setCases(newCases);
      if (newCases[index].marked) {
        Alert("success", "success", t("Case is added as team bookmark"), t);
      } else {
        Alert("success", "success", t("Case is removed from team bookmark"), t);
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await API.delete(`cases/${id}`);
      await getCases();
      setLoading(false);
      Alert("success", "success", t("Case is deleted successfully"), t);
    } catch (error) {
      setLoading(false);
      exceptionHandler(error, t);
    }
  };

  const handleAssignment = async (id: number, pathology_number: string) => {
    try {
      const pathologist_user_id = localStorage.getItem("pathologist_id");
      if (pathologist_user_id) {
        if (members.length > 0) {
          const teamUsers = members.filter((member: Member) => {
            return member.id === pathologist_user_id;
          });
          const response = await API.put(`reports/${id}`, {
            pathologist_user_id,
            team_id: defaultTeam,
            pathology_number,
            team_user_id: teamUsers.length > 0 ? teamUsers[0].team_user_id : 0
          });
          if (response) {
            incrementMember(pathologist_user_id);
            setFilters({ ...filters, pageSize: filters.pageSize - 1 });
            filterCases(id);
            Alert("success", "success", t("Successfully assigned"), t);
          }
        }
      }
    } catch (error) {
      exceptionHandler(error, t);
    }
  };
  const handleFilterChange = (
    name: string,
    value: string | number | boolean
  ) => {
    setLoading(true);
    const newFilters = { ...filters, [name]: value };
    if (name !== "page") {
      newFilters.page = 1;
    }
    setFilters(newFilters);
  };

  const incrementMember = (id: string) => {
    const newMember = members.filter((member: any) => {
      return member.id === id && member.assigned_cases_count++;
    });
    setCases(newMember);
  };
  const filterCases = (id: number) => {
    const newCase = cases.filter((caseItem: CaseListData) => {
      return caseItem.reportId !== id;
    });
    setCases(newCase);
  };

  return (
    <ComponentWrapper sidebar className={classes.componentWrapper}>
      <FilterComponent
        count={count}
        filters={filters}
        members={members}
        handleFilterChange={handleFilterChange}
      />

      <Divider className={classes.divider} />

      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : cases.length === 0 ? (
        <Empty
          description={
            error
              ? error
              : t("There are no cases that match your search conditions.")
          }
        />
      ) : (
        <div className={classes.rowWrapper}>
          <Row className={classes.row}>
            {cases.map((patient: CaseListData, index: number) => {
              return (
                <Col
                  lg={12}
                  md={18}
                  sm={24}
                  xs={24}
                  xl={8}
                  key={index}
                  className={classes.centerAlign}
                >
                  <DraggableCard
                    onClick={() => {
                      return history.push(`/cases/${patient.id}`);
                    }}
                    patient={patient}
                    handleAssignment={() => {
                      handleAssignment(
                        patient.reportId,
                        patient.pathology_number
                      );
                    }}
                    handleMyBookmark={() => {
                      handleMyBookmark(index, patient.id);
                    }}
                    handleTeamBookmark={() => {
                      handleTeamBookmark(index, patient.id);
                    }}
                    handleDelete={() => {
                      handleDelete(patient.id);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      )}
    </ComponentWrapper>
  );
};
export { Assignment };
