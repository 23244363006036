import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { PdfContext, SelectComponent, TextArea } from "shared-components";
import { ShowData } from "shared-components/src/components/atoms/Form-Item/ShowData";

import { diagnosisOptions } from "./formOptions";
import { FormTitle } from "./Layout";

let useStyles = createUseStyles((theme: any) => {
  return {
    lowerContainer: (isConsult: boolean) => {
      return {
        marginTop: 9,
        padding: "8px 45px 35px 55px",
        backgroundColor: isConsult ? theme.greenBackground : theme.grayBlueBg
      };
    },
    plusHoldingContainer: {
      position: "relative"
    },
    plusIconHolder: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: 7,
      left: "-28px",
      borderRight: "2px solid #C9C9C9",
      height: 48,
      paddingRight: 14
    },
    plusIcon: {
      cursor: "pointer"
    },
    dropDown: {
      marginBottom: 12
    },
    cancel: {
      cursor: "pointer"
    },
    longPlusIconHolder: {
      height: 114
    },
    showData: {
      marginBottom: 0
    },
    confidence: {
      "& .valueStyle-0-2-156": {
        fontSize: 14
      }
    },
    textArea: {
      "& .valueStyle-0-2-156": {
        lineHeight: "4px"
      }
    }
  };
});

interface Option {
  [key: string]: string;
}

interface Props {
  reportId: number;
  formValues: Option;
  handleChange: (name: string, value: string) => any;
  hasSidebar: boolean;
  isConsult?: boolean;
  publicReport?: boolean;
  readOnly?: boolean;
}

const Diagnosis = ({
  formValues,
  handleChange,
  hasSidebar,
  isConsult,
  publicReport,
  readOnly
}: Props) => {
  const { pdfLoading } = useContext(PdfContext);
  const classes = useStyles(isConsult);
  const { t } = useTranslation();
  const [pathology, setPathology] = useState([] as string[]);
  const [predictive, setPredictive] = useState([] as string[]);
  const [predicted, setPredicted] = useState(false as boolean);
  const [suggestive, setSuggestive] = useState(false as boolean);

  const checkName = (type: string) => {
    let field: any;
    let set: any;
    let name: string;
    switch (type) {
      case "pathology": {
        field = pathology;
        set = setPathology;
        name = "pathology_diagnosis";
        return { field, set, name };
      }
      case "predictive": {
        field = predictive;
        set = setPredictive;
        name = "predictive_etiology";
        return { field, set, name };
      }
      case "predicted": {
        set = setPredicted;
        return { set };
      }
      case "suggestive": {
        set = setSuggestive;
        return { set };
      }
      default: {
        field = [];
        set = ({ ...rest }) => {};
        name = "";
        return { field, set, name };
      }
    }
  };

  const increase = (type: string) => {
    const { field, set, name } = checkName(type);

    if (field === undefined) {
      set(true);
      return;
    }

    if (field.length === 2) {
      return;
    }
    if (field.includes(`${name}_2`)) {
      if (!field.includes(`${name}_3`)) field.push(`${name}_3`);
    } else {
      field.push(`${name}_2`);
    }
    set([...field]);
  };

  const decrease = (type: string, name: string) => {
    const { field, set } = checkName(type);
    if (field === undefined) {
      set(false);
      handleChange(name, "");
      return;
    }
    let index = field.indexOf(name);
    if (index !== -1) field.splice(index, 1);
    set([...field]);
    handleChange(name, "");
  };

  useEffect(() => {
    const pathologyField: string[] = [];
    const predictiveField: string[] = [];
    [2, 3].forEach(item => {
      if (formValues[`pathology_diagnosis_${item}`]) {
        if (!pathology.includes(`pathology_diagnosis_${item}`)) {
          pathologyField.push(`pathology_diagnosis_${item}`);
          setPathology([...pathology, ...pathologyField]);
        }
      }
      if (formValues[`predictive_etiology_${item}`]) {
        if (!predictive.includes(`predictive_etiology_${item}`)) {
          predictiveField.push(`predictive_etiology_${item}`);
          setPredictive([...predictive, ...predictiveField]);
        }
      }
    });
    if (formValues.predicted_prognosis_2) {
      setPredicted(true);
    }
    if (formValues.suggestive_treatment_2) {
      setSuggestive(true);
    }
  }, [formValues]);

  const findLabel = (field: any, value: string) => {
    const options: any = diagnosisOptions;
    const returnVal = options[field].filter((item: any) => {
      return item.value === value;
    });
    return returnVal[0] ? returnVal[0].label : "";
  };

  const readOnlyView = readOnly || publicReport || hasSidebar;

  return (
    <div className={classes.lowerContainer} id="diagnosis">
      <FormTitle isConsult={isConsult}>{t("Diagnosis")}</FormTitle>
      {readOnlyView ? (
        <>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Pathology Diagnosis")}
                subLabel={t("Confidence")}
                value={findLabel("confidence", formValues.pathology_diagnosis)}
                className={`${classes.showData} ${classes.confidence}`}
              />
            </Col>
          </Row>
          {formValues.pathology_diagnosis_comment && (
            <ShowData
              value={formValues.pathology_diagnosis_comment}
              label={" "}
              className={classes.textArea}
            />
          )}
          {pathology.map((item, key) => {
            return (
              <>
                <Row
                  gutter={25}
                  className={classes.plusHoldingContainer}
                  key={key}
                  data-testid={item}
                >
                  <Col lg={8} md={12} sm={24} xs={24}>
                    <ShowData
                      className={`${classes.showData} ${classes.confidence}`}
                      value={findLabel("confidence", formValues[item])}
                      label={" "}
                    />
                  </Col>
                </Row>
                <ShowData
                  value={
                    item === "pathology_diagnosis_2"
                      ? formValues.pathology_diagnosis_comment_2
                      : formValues.pathology_diagnosis_comment_3
                  }
                  label={" "}
                  className={classes.textArea}
                />
              </>
            );
          })}
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("IPF guideline diagnosis")}
                value={findLabel(
                  "pathology_diagnosis",
                  formValues.ipf_guideline_diagnosis
                )}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("HP guideline diagnosis")}
                value={findLabel(
                  "hp_guideline_diagnosis",
                  formValues.hp_guideline_diagnosis
                )}
              />
            </Col>
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Predictive etiology")}
                value={findLabel(
                  "predictive_etiology",
                  formValues.predictive_etiology
                )}
              />
            </Col>
            {predictive.map((item: string, key: number) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  sm={24}
                  xs={24}
                  key={key}
                  data-testid={item}
                >
                  <br />
                  <ShowData
                    label={" "}
                    value={findLabel("predictive_etiology", formValues[item])}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Predicted prognosis")}
                value={findLabel(
                  "predicted_prognosis",
                  formValues.predicted_prognosis
                )}
              />
            </Col>
            {predicted && (
              <Col
                lg={8}
                md={12}
                sm={24}
                xs={24}
                data-testid={"predicted_prognosis_2"}
              >
                <br />
                <ShowData
                  label={" "}
                  value={findLabel(
                    "predicted_prognosis",
                    formValues.predicted_prognosis_2
                  )}
                />
              </Col>
            )}
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <ShowData
                label={t("Suggestive treatment")}
                value={findLabel(
                  "suggestive_treatment",
                  formValues.suggestive_treatment
                )}
              />
            </Col>
            {suggestive && (
              <Col
                lg={8}
                md={12}
                sm={24}
                xs={24}
                data-testid={"suggestive_treatment_2"}
              >
                <ShowData
                  value={findLabel(
                    "suggestive_treatment",
                    formValues.suggestive_treatment_2
                  )}
                  label={" "}
                />
              </Col>
            )}
          </Row>
          <ShowData label={t("Comments")} value={formValues.comments} />
        </>
      ) : (
        <>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["confidence"]}
                size="middle"
                className={classes.dropDown}
                label={t("Pathology Diagnosis")}
                subLabel={t("Confidence")}
                name="pathology_diagnosis"
                placeholder={diagnosisOptions["confidence"][0].label}
                value={formValues.pathology_diagnosis}
                onChange={handleChange}
                max={3}
                allowClear={true}
              />
            </Col>
            {!pdfLoading && (
              <div
                className={`${classes.plusIconHolder} ${classes.longPlusIconHolder}`}
              >
                <PlusCircleOutlined
                  width="17px"
                  height="17px"
                  className={classes.plusIcon}
                  data-testid={"pathologyPlus"}
                  onClick={() => {
                    return increase("pathology");
                  }}
                />
              </div>
            )}
          </Row>
          <TextArea
            name="pathology_diagnosis_comment"
            value={formValues.pathology_diagnosis_comment}
            onChange={handleChange}
            rows={5}
            maxLength={1024}
          />
          {pathology.map((item, key) => {
            return (
              <>
                <Row
                  gutter={25}
                  className={classes.plusHoldingContainer}
                  key={key}
                  data-testid={item}
                >
                  <Col lg={8} md={12} sm={24} xs={24}>
                    <span
                      className={classes.cancel}
                      data-testid={"pathologyCross"}
                      onClick={() => {
                        return decrease("pathology", item);
                      }}
                    >
                      {!pdfLoading ? "x" : <br />}
                    </span>
                    <SelectComponent
                      options={diagnosisOptions["confidence"]}
                      size="middle"
                      className={classes.dropDown}
                      name={item}
                      placeholder={diagnosisOptions["confidence"][0].label}
                      value={formValues[item]}
                      onChange={handleChange}
                      allowClear={true}
                    />
                  </Col>
                </Row>
                <TextArea
                  name={
                    item === "pathology_diagnosis_2"
                      ? "pathology_diagnosis_comment_2"
                      : "pathology_diagnosis_comment_3"
                  }
                  value={
                    item === "pathology_diagnosis_2"
                      ? formValues.pathology_diagnosis_comment_2
                      : formValues.pathology_diagnosis_comment_3
                  }
                  onChange={handleChange}
                  rows={5}
                  maxLength={1024}
                />
              </>
            );
          })}
          <Row gutter={25}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["pathology_diagnosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("IPF guideline diagnosis")}
                name="ipf_guideline_diagnosis"
                placeholder={diagnosisOptions["pathology_diagnosis"][0].label}
                value={formValues.ipf_guideline_diagnosis}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["hp_guideline_diagnosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("HP guideline diagnosis")}
                name="hp_guideline_diagnosis"
                placeholder={
                  diagnosisOptions["hp_guideline_diagnosis"][0].label
                }
                value={formValues.hp_guideline_diagnosis}
                onChange={handleChange}
                allowClear={true}
              />
            </Col>
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["predictive_etiology"]}
                size="middle"
                className={classes.dropDown}
                label={t("Predictive etiology")}
                name="predictive_etiology"
                placeholder={diagnosisOptions["predictive_etiology"][0].label}
                value={formValues.predictive_etiology}
                onChange={handleChange}
                max={3}
                allowClear={true}
              />
              {!pdfLoading && (
                <div className={classes.plusIconHolder}>
                  <PlusCircleOutlined
                    width="17px"
                    height="17px"
                    className={classes.plusIcon}
                    data-testid={"predictivePlus"}
                    onClick={() => {
                      return increase("predictive");
                    }}
                  />
                </div>
              )}
            </Col>
            {predictive.map((item: string, key: number) => {
              return (
                <Col
                  lg={8}
                  md={12}
                  sm={24}
                  xs={24}
                  key={key}
                  data-testid={item}
                >
                  <span
                    className={classes.cancel}
                    data-testid={"predictiveCross"}
                    onClick={() => {
                      return decrease("predictive", item);
                    }}
                  >
                    {!pdfLoading ? "x" : <br />}
                  </span>
                  <SelectComponent
                    options={diagnosisOptions["predictive_etiology"]}
                    size="middle"
                    className={classes.dropDown}
                    name={item}
                    data-testid={item}
                    placeholder={
                      diagnosisOptions["predictive_etiology"][0].label
                    }
                    value={formValues[item]}
                    onChange={handleChange}
                    allowClear={true}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["predicted_prognosis"]}
                size="middle"
                className={classes.dropDown}
                label={t("Predicted prognosis")}
                name="predicted_prognosis"
                placeholder={diagnosisOptions["predicted_prognosis"][0].label}
                value={formValues.predicted_prognosis}
                onChange={handleChange}
                max={2}
                allowClear={true}
              />
            </Col>
            {predicted && (
              <Col
                lg={8}
                md={12}
                sm={24}
                xs={24}
                data-testid={"predicted_prognosis_2"}
              >
                <span
                  className={classes.cancel}
                  data-testid={"predictedPrognosisCross"}
                  onClick={() => {
                    return decrease("predicted", "predicted_prognosis_2");
                  }}
                >
                  {!pdfLoading ? "x" : <br />}
                </span>
                <SelectComponent
                  options={diagnosisOptions["predicted_prognosis"]}
                  size="middle"
                  className={classes.dropDown}
                  name="predicted_prognosis_2"
                  placeholder={diagnosisOptions["predicted_prognosis"][0].label}
                  value={formValues.predicted_prognosis_2}
                  onChange={handleChange}
                  data-testid={"predicted_prognosis_2"}
                  allowClear={true}
                />
              </Col>
            )}
            {!pdfLoading && (
              <div className={classes.plusIconHolder}>
                <PlusCircleOutlined
                  width="17px"
                  height="17px"
                  className={classes.plusIcon}
                  data-testid={"predictedPlus"}
                  onClick={() => {
                    return increase("predicted");
                  }}
                />
              </div>
            )}
          </Row>
          <Row gutter={25} className={classes.plusHoldingContainer}>
            <Col lg={8} md={12} sm={24} xs={24}>
              <SelectComponent
                options={diagnosisOptions["suggestive_treatment"]}
                size="middle"
                className={classes.dropDown}
                label={t("Suggestive treatment")}
                name="suggestive_treatment"
                placeholder={diagnosisOptions["suggestive_treatment"][0].label}
                value={formValues.suggestive_treatment}
                onChange={handleChange}
                max={2}
                allowClear={true}
              />
            </Col>
            {suggestive && (
              <Col
                lg={8}
                md={12}
                sm={24}
                xs={24}
                data-testid={"suggestive_treatment_2"}
              >
                <span
                  className={classes.cancel}
                  data-testid={"suggestiveCross"}
                  onClick={() => {
                    return decrease("suggestive", "suggestive_treatment_2");
                  }}
                >
                  {!pdfLoading ? "x" : <br />}
                </span>
                <SelectComponent
                  options={diagnosisOptions["suggestive_treatment"]}
                  size="middle"
                  className={classes.dropDown}
                  name="suggestive_treatment_2"
                  placeholder={
                    diagnosisOptions["suggestive_treatment"][0].label
                  }
                  value={formValues.suggestive_treatment_2}
                  onChange={handleChange}
                  allowClear={true}
                />
              </Col>
            )}
            {!pdfLoading && (
              <div className={classes.plusIconHolder}>
                <PlusCircleOutlined
                  width="17px"
                  height="17px"
                  className={classes.plusIcon}
                  data-testid={"suggestivePLus"}
                  onClick={() => {
                    return increase("suggestive");
                  }}
                />
              </div>
            )}
          </Row>
          <TextArea
            label={t("Comments")}
            name="comments"
            value={formValues.comments}
            onChange={handleChange}
            rows={5}
            maxLength={1024}
          />
        </>
      )}
    </div>
  );
};

export { Diagnosis };
