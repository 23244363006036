import { Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";

import { Typography } from "../Typography";

const { Option } = Select;

const useStyles = createUseStyles((theme: any) => {
  return {
    fieldWrapper: {
      display: "flex",
      width: "100%",
      "& .ant-select": {
        width: "100%"
      },
      "& .ant-select-arrow": {
        right: 8
      }
    },
    labelStyle: {
      fontSize: 14,
      color: theme.formLabelColor,
      display: "flex",
      lineHeight: "20px"
    },
    yearFieldStyle: {
      width: "37%",
      marginRight: 2
    },
    monthFieldStyle: {
      width: "33.5%",
      marginRight: 2
    },
    dateFieldStyle: {
      width: "27.5%"
    }
  };
});

interface DateInterface {
  year: number;
  month: number;
  day: number;
}

interface Props {
  id?: string;
  label?: string;
  value: string;
  onChange: (value: any) => void;
  disabled?: boolean;
  className?: string;
}

export const SelectDateComponent = ({
  label,
  value,
  onChange,
  disabled,
  className
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const today = new Date();
  const [dateValues, setDateValues] = useState({} as DateInterface);
  const [years, setYears] = useState([] as Number[]);
  const [days, setDays] = useState([] as Number[]);

  const months = [
    { label: "Jan", value: 1 },
    { label: "Feb", value: 2 },
    { label: "Mar", value: 3 },
    { label: "Apr", value: 4 },
    { label: "May", value: 5 },
    { label: "Jun", value: 6 },
    { label: "Jul", value: 7 },
    { label: "Aug", value: 8 },
    { label: "Sep", value: 9 },
    { label: "Oct", value: 10 },
    { label: "Nov", value: 11 },
    { label: "Dec", value: 12 }
  ];

  useEffect(() => {
    const getYears = [];
    for (let i = today.getFullYear(); i >= 1900; i--) {
      getYears.push(i);
    }
    setYears(getYears);

    if (value) {
      let dateValue = new Date(value);
      setDateValues({
        ...dateValues,
        year: dateValue.getFullYear(),
        month: dateValue.getMonth() + 1,
        day: dateValue.getDate()
      });
    }
  }, []);

  useEffect(() => {
    setDays(getDays(dateValues.year, dateValues.month));
  }, [dateValues.year, dateValues.month]);

  useEffect(() => {
    if (dateValues.year && dateValues.month && dateValues.day) {
      const finalValue = moment(
        `${dateValues.year}-${dateValues.month}-${dateValues.day}`
      );
      onChange(finalValue);
    }
  }, [dateValues]);

  const getDays = (year: number, month: number) => {
    const monthDays = [] as Number[];
    const months30 = [4, 6, 9, 11];
    const leapYear = year % 4 === 0;

    const maxDays =
      month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;

    for (let i = 1; i <= maxDays; i++) {
      monthDays.push(i);
    }
    return monthDays;
  };

  const disableMonth = (month: number) => {
    if (dateValues.year === today.getFullYear() && month > today.getMonth() + 1)
      return true;
  };
  const disableDay = (day: number) => {
    if (
      dateValues.year === today.getFullYear() &&
      dateValues.month === today.getMonth() + 1 &&
      day > today.getDate()
    )
      return true;
  };

  const handleChangeYear = (year: number) => {
    if (year === today.getFullYear() && dateValues.month > today.getMonth() + 1)
      setDateValues({
        ...dateValues,
        year: year,
        month: Number(),
        day: Number()
      });
    else if (
      year === today.getFullYear() &&
      dateValues.month > today.getMonth() &&
      dateValues.day &&
      dateValues.day > today.getDate()
    )
      setDateValues({ ...dateValues, year: year, day: Number() });
    else setDateValues({ ...dateValues, year: year });
  };
  const handleChangeMonth = (month: number) => {
    if (
      (dateValues.year === today.getFullYear() &&
        month > today.getMonth() &&
        dateValues.day &&
        dateValues.day > today.getDate()) ||
      !getDays(dateValues.year, month).includes(dateValues.day)
    )
      setDateValues({ ...dateValues, month: month, day: Number() });
    else setDateValues({ ...dateValues, month: month });
  };

  return (
    <div className={`${className}`}>
      {label && <Typography className={classes.labelStyle}>{label}</Typography>}
      <div className={classes.fieldWrapper}>
        <div className={classes.yearFieldStyle}>
          <Select
            onChange={handleChangeYear}
            value={dateValues.year}
            disabled={disabled}
          >
            {years.map((year: any) => {
              return (
                <Option key={year} value={year}>
                  {year}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={classes.monthFieldStyle}>
          <Select
            onChange={handleChangeMonth}
            value={dateValues.month > 0 ? dateValues.month : undefined}
            disabled={disabled}
          >
            {months.map((month: any) => {
              return (
                <Option
                  key={month.value}
                  value={month.value}
                  disabled={disableMonth(month.value)}
                >
                  {t(`${month.label}`)}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className={classes.dateFieldStyle}>
          <Select
            onChange={value => {
              return setDateValues({ ...dateValues, day: value });
            }}
            value={dateValues.day > 0 ? dateValues.day : undefined}
            disabled={disabled}
          >
            {days.map((day: any) => {
              return (
                <Option key={day} value={day} disabled={disableDay(day)}>
                  {day}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  );
};
