import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { API, exceptionHandler } from "shared-components";

import { CaseFormValue } from "../organisms/RenderForm";

function getValue(caseFormValues: CaseFormValue[], name: string) {
  return (
    caseFormValues.find(caseFormValue => {
      return caseFormValue.name === name;
    })?.value ?? ""
  );
}
export function useCaseFormValue(
  reportId: string,
  name: string,
  caseFormValues: CaseFormValue[]
): [string, Dispatch<SetStateAction<string>>, () => Promise<void>] {
  const [currentValue, setCurrentValue] = useState(
    getValue(caseFormValues, name)
  );
  const [prevValue, setPrevValue] = useState(currentValue);
  const { t } = useTranslation();

  const commit = async () => {
    try {
      await API.post("/form-value", {
        report_id: parseInt(reportId),
        name: name,
        value: currentValue
      });
      setPrevValue(currentValue);
    } catch (error) {
      setCurrentValue(prevValue);
      exceptionHandler(error, t);
    }
  };

  return [currentValue, setCurrentValue, commit];
}
